// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import DOMPurify from 'dompurify';
// Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { validateEmailAddress, groupMailboxes,
  convertBodyBackToHTML } from '../Libs/emailHelper';
import { getCurrentUserEmail, replaceAllOccurances } from '../Libs/fileHelper';
import { postSendEmail } from '../Libs/emailAPIHelper';
import { createPDF } from '../Libs/pdfHelper';
import { htmlToPDFTemplateFormatting } from '../Libs/templateHelper';
// Resources
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import LetterIcon from '@mui/icons-material/PictureAsPdf';
import EmailIcon from '@mui/icons-material/Email';
import bifPDF from '../Res/Bereavement Instruction Form.pdf';

const useStyles = makeStyles((theme) => ({
  textBodyStyle: {
    height: '300px',
    width: '-webkit-fill-available',
    marginTop: theme.spacing(3),
    fontSize: '13px',
  },
  previewTextBodyStyle: {
    height: '300px',
    width: '-webkit-fill-available',
    marginTop: theme.spacing(3),
    marginBottom: '3px',
    overflowY: 'auto',
    padding: '2px',
    border: '1px solid',
    borderColor: '#858585',
    borderRadius: '2px',
    fontFamily: 'monospace',
    opacity: '0.70',
    backgroundColor: '#F9F9F9',
  },
  confirmationContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function EmailPopup({ popupOpen, setPopupOpen,
  emailStates, setEmailStates, setSnackbarProps, customerData, scheduleData, currUser, emailConfigData }) {
  const classes = useStyles();
  const sxClasses = {
    dialogTitle: {
      paddingTop: 1,
      textAlign: 'center',
    },
    iconButton: {
      position: 'absolute',
      top: 1,
      right: 1,
      padding: 0,
    },
    iconButtonPDF: {
      position: 'absolute',
      top: 2,
      left: 2,
      padding: 0,
    },
    iconPreviewButton: {
      position: 'absolute',
      top: '234px',
      right: '24px',
      padding: 0,
    },
  };
  const sending = React.useRef(false);


  EmailPopup.propTypes = {
    popupOpen: PropTypes.bool.isRequired,
    setPopupOpen: PropTypes.func.isRequired,
    emailStates: PropTypes.object.isRequired,
    setEmailStates: PropTypes.func.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
    customerData: PropTypes.object.isRequired,
    scheduleData: PropTypes.object.isRequired,
    currUser: PropTypes.object.isRequired,
    emailConfigData: PropTypes.object.isRequired,
  };

  const [defaultEmailSenderAddress, setDefaultEmailSenderAddress] = React.useState(
      emailConfigData.defaultMailbox);
  const [sendingState, setSendingState] = React.useState(false);
  const [sendingTextState, setSendingTextState] = React.useState('Sending');
  const [previewEmail, setPreviewEmail] = React.useState(false);
  const sendingAnimationID = React.useRef(0);

  const handleSend = () => {
    if (emailStates.sendAsPDF) {
      handleSendPDF();
    } else {
      handleSendEmail();
    }
  };

  const handleSendPDF = () => {
    console.log('Generate PDF now');
    if (!sending.current) {
      // Prevent multiple pdf triggers by over enthusiastic clickers
      sending.current = true;
      setSendingState(true);
      setSendingTextState('Generating');
      sendingText();
      createPDF(customerData, scheduleData, currUser, emailStates.emailBody);
      if (emailStates.emailAttachment === 'System/Bereavement Instruction Form.pdf') {
        window.open(bifPDF);
      }
      emailStates.sendAction({ ...emailStates,
        groupMailbox: defaultEmailSenderAddress });
      setPopupOpen(false);
      setSendingState(false);
      clearInterval(sendingAnimationID.current);
      sending.current = false;
    }
  };

  const handleSendEmail = async () => {
    if (emailStates.emailBody === '') {
      setSnackbarProps({ open: true,
        message: 'The body of the email is empty.',
        severity: 'info' });
      return;
    }
    if (emailStates.emailSubject === '') {
      setSnackbarProps({ open: true,
        message: 'The subject of the email is empty.',
        severity: 'info' });
      return;
    }
    if (emailStates.emailSentTo === '') {
      setSnackbarProps({ open: true,
        message: 'You have not entered an email address to send to.',
        severity: 'info' });
      return;
    }
    if (defaultEmailSenderAddress === '') {
      setSnackbarProps({ open: true,
        message: 'You have not selected an email address to send from.',
        severity: 'info' });
      return;
    }
    if (emailStates.emailBody.includes('WARNING: Missing Template')) {
      setSnackbarProps({ open: true,
        message: 'The body of the email is using a missing template.',
        severity: 'info' });
      return;
    }
    if (!validateEmailAddress(emailStates.emailSentTo)) {
      setSnackbarProps({ open: true,
        message: 'The email address you have entered is not valid.',
        severity: 'info' });
      return;
    }
    if (!sending.current) {
      // Prevent multiple email triggers by over enthusiastic clickers
      sending.current = true;
      setSendingState(true);
      setSendingTextState('Sending');
      sendingText();
      const apiResult = await triggerEmail();
      if (apiResult.status === 200) {
        emailStates.sendAction({ ...emailStates,
          groupMailbox: defaultEmailSenderAddress });
        setPopupOpen(false);
      }
      setSendingState(false);
      clearInterval(sendingAnimationID.current);
      sending.current = false;
    }
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  function handleEmailFieldChange(event) {
    setEmailStates({
      ...emailStates,
      [event.target.id]: event.target.value,
    });
  }

  const handleDelete = () => {
    setEmailStates({
      emailSentTo: '',
      emailSentFrom: '',
      emailSubject: '',
      emailBody: '',
      sendAction: ()=>{},
    });
    setPopupOpen(false);
  };

  const handleSendMethodChange = () => {
    let adjustBody = emailStates.emailBody;
    if (emailStates.sendAsPDF) {
      // Switching to Email
      adjustBody = adjustBody + emailConfigData.signature;
      setSnackbarProps({ open: true,
        message: 'Some HTML formatting is not available when converting PDF to Email. ' +
          'Email addresses will not have clickable mailTo links that prepopulate schedule information.',
        severity: 'info' });
    } else {
      // Switching to PDF
      adjustBody = adjustBody.replace(emailConfigData.signature, '');
      adjustBody = htmlToPDFTemplateFormatting(adjustBody);
    }
    setEmailStates({
      ...emailStates,
      emailBody: adjustBody,
      sendAsPDF: !emailStates.sendAsPDF,
    });
  };

  const triggerEmail = async () => {
    let body = {
      sendTo: emailStates.emailSentTo,
      sendFrom: getCurrentUserEmail(currUser),
      groupMailbox: defaultEmailSenderAddress,
      subject: emailStates.emailSubject,
      emailBody: convertBodyBackToHTML(emailStates.emailBody),
    };
    if (emailStates.emailAttachment) {
      body = { ...body,
        emailAttachment: emailStates.emailAttachment,
      };
    }
    return await postSendEmail(body, setSnackbarProps);
  };


  const handleSendFromChange = (event) => {
    setDefaultEmailSenderAddress(event.target.value);
  };

  const sendingText = () => {
    let i = 0;
    sendingAnimationID.current = setInterval(() => {
      i+= 1;
      const newSendingText = Array(Math.floor((i+2)/2)).join('.') + 'Sending' +
      Array(Math.floor((i+1)/2)).join('.');
      setSendingTextState(newSendingText);
    }, 200);
  };

  const sanitiseEmailBodyHTML = () => {
    let cleanedBody = emailStates.emailBody;
    cleanedBody = replaceAllOccurances(cleanedBody, '\n', '<br>');
    cleanedBody = DOMPurify.sanitize(cleanedBody);
    return cleanedBody;
  };

  return (
    <Dialog open={popupOpen} onClose={handleClose} aria-labelledby="form-dialog-title" >
      {emailStates.sendAsPDF ?
        <DialogTitle sx={sxClasses.dialogTitle} id="form-dialog-title">Generate PDF</DialogTitle>:
        <DialogTitle sx={sxClasses.dialogTitle} id="form-dialog-title">Send Email</DialogTitle>
      }
      <IconButton sx={sxClasses.iconButton} onClick={handleDelete}>
        <DeleteIcon />
      </IconButton>
      {!emailStates.sendAsPDF ?
        <IconButton sx={sxClasses.iconButtonPDF} onClick={handleSendMethodChange}>
          <LetterIcon />
        </IconButton>:
        <IconButton sx={sxClasses.iconButtonPDF} onClick={handleSendMethodChange}>
          <EmailIcon />
        </IconButton>
      }
      <DialogContent>
        <TextField select
          margin="dense"
          id="emailSentFrom"
          label="Send From"
          type="email"
          variant='outlined'
          disabled={emailStates.sendAsPDF}
          fullWidth
          value={defaultEmailSenderAddress}
          onChange={handleSendFromChange}>
          {groupMailboxes.map((item, index)=> (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="dense"
          id="emailSentTo"
          label="Send To"
          type="email"
          inputProps={{ style: { paddingTop: 5, paddingBottom: 5 } }}
          fullWidth
          disabled={emailStates.sendAsPDF}
          value={emailStates.emailSentTo}
          onChange={handleEmailFieldChange}
        />
        <TextField
          margin="dense"
          id="emailSubject"
          label="Subject"
          type="subject"
          inputProps={{ style: { paddingTop: 5, paddingBottom: 5 } }}
          fullWidth
          disabled={emailStates.sendAsPDF}
          value={emailStates.emailSubject}
          onChange={handleEmailFieldChange}
        />
        {!previewEmail ?
          <React.Fragment>
            <IconButton sx={sxClasses.iconPreviewButton} onClick={()=>{
              setPreviewEmail(true);
            }}>
              <PreviewIcon />
            </IconButton>
            <textarea
              className={classes.textBodyStyle}
              margin="dense"
              id="emailBody"
              label="Body"
              type="body"
              value={emailStates.emailBody}
              onChange={handleEmailFieldChange}
            />
          </React.Fragment> :
          <React.Fragment>
            <IconButton sx={sxClasses.iconPreviewButton} onClick={()=>{
              setPreviewEmail(false);
            }}>
              <EditIcon />
            </IconButton>
            <div className={classes.previewTextBodyStyle}
              dangerouslySetInnerHTML={{
                __html: sanitiseEmailBodyHTML(),
              }}></div>
          </React.Fragment>}
      </DialogContent>
      <DialogActions>
        {sendingState &&
          <div>
            {sendingTextState}
          </div>
        }
        <Button onClick={handleDelete} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSend} color="primary">
          {emailStates.sendAsPDF ? 'Create' : 'Send'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
