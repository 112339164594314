// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Components
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import TitlePaper from '../../Components/StyledMUIComponents/TitlePaper';
import { sumClaimsData, upgradedDate, reverseSortArrayByProperty,
  currencyFormatter } from '../../Libs/fileHelper';
import { isVanEnvironment } from '../../Libs/StageConfig';
// Resources
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


const useStyles = makeStyles((theme) => ({
  tableStyle: {
    height: '150px',
    padding: theme.spacing(1),
    paddingRight: theme.spacing(0),
    borderColor: '#000000',
    border: '1px solid',
  },
  titleFields: {
    margin: '5px',
  },
  footerFields: {
    margin: '5px',
  },
  divStyle: {
    paddingRight: theme.spacing(1),
  },
  smallStyle: {
    width: '100%',
    backgroundColor: theme.palette.primary.backgroundFore,
  },
}));

export default function CustomerClaimsData(
    { claimsData, expanded, handleExpandedChange, claimLastUpdate },
) {
  const classes = useStyles();
  const sxClasses = {
    cellStyle: {
      padding: '2px',
    },
    footerFieldsValue: {
      position: 'absolute',
      marginLeft: '200px',
      marginTop: '-44px',
      width: '90px',
    },
  };

  CustomerClaimsData.propTypes = {
    claimsData: PropTypes.array.isRequired,
    expanded: PropTypes.bool.isRequired,
    handleExpandedChange: PropTypes.func.isRequired,
    claimLastUpdate: PropTypes.number,
  };

  const [pageIndex, setPageIndex] = React.useState(0);
  const handleChangePage = (event, newPage) => {
    setPageIndex(newPage);
  };
  const sliceDataByPage = () => {
    return reverseSortArrayByProperty(claimsData, 'DateOfClaimEpoch').slice(
        pageIndex*100, (pageIndex+1) * 100);
  };

  return (
    <React.Fragment>
      { expanded ?
    <React.Fragment>
      <div className={classes.divStyle}>
        <Grid container spacing={2}>
          <Grid item xs={10} >
            <TitlePaper className={classes.titleFields}>
          Service Claims
            </TitlePaper>
          </Grid>
          <Grid item xs={2} >
            <IconButton onClick={handleExpandedChange} edge="start" color="inherit" aria-label="menu">
              <ExpandLessIcon />
            </IconButton>
          </Grid>
        </Grid>
        <TableContainer component={Paper} className={classes.tableStyle}>
          <Table sx={sxClasses.cellStyle} size='small' aria-label='a dense table'>
            <TableHead>
              <TableRow>
                <TableCell sx={sxClasses.cellStyle}>Schedule</TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>Date</TableCell>
                {isVanEnvironment() &&
                  <TableCell sx={sxClasses.cellStyle} align='center'>Distance</TableCell>
                }
                <TableCell sx={sxClasses.cellStyle} align='center'>Parts</TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>Labour</TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>Other</TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sliceDataByPage().map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={sxClasses.cellStyle} component='th' scope='row'>
                    {row.ScheduleID}
                  </TableCell>
                  <TableCell sx={sxClasses.cellStyle} align='center'>{row.DateOfClaim}</TableCell>
                  {isVanEnvironment() &&
                    <TableCell sx={sxClasses.cellStyle} align='center'>{row.ClaimDistanceKM}</TableCell>
                  }
                  <TableCell sx={sxClasses.cellStyle} align='center'>{row.ClaimPartsAmount}</TableCell>
                  <TableCell sx={sxClasses.cellStyle} align='center'>{row.ClaimLabourAmount}</TableCell>
                  <TableCell sx={sxClasses.cellStyle} align='center'>{row.ClaimOtherAmount}</TableCell>
                  <TableCell sx={sxClasses.cellStyle} align='center'>{row.ClaimTotalAmount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {claimsData.length > 100 &&
            <TablePagination sx={{ padding: 0 }}
              rowsPerPageOptions={[100]}
              component="div"
              count={claimsData.length}
              rowsPerPage={100}
              page={pageIndex}
              onPageChange={handleChangePage}
            />
          }
        </TableContainer>
        {claimLastUpdate !== undefined &&
          <React.Fragment>
            <TitlePaper className={classes.footerFields} >
            Claims Data From:
            </TitlePaper>
            <TitlePaper sx={sxClasses.footerFieldsValue}>
              {claimLastUpdate !== undefined && claimLastUpdate == 0 ?
                'No Data' : upgradedDate(claimLastUpdate)}
            </TitlePaper>
          </React.Fragment>
        }
      </div>
    </React.Fragment> :
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <TextField disabled={true}
          value={currencyFormatter(sumClaimsData(claimsData)) + ' - ' + claimsData.length +
          ' Claim' + (claimsData.length !== 1 ? 's' : '')}
          label={claimLastUpdate !== undefined && (claimLastUpdate == 0 ? 'No Claims Data Loaded' :
          'Service Claims - Updated ' + upgradedDate(claimLastUpdate))}
          variant="outlined"
          className={classes.smallStyle}
          sx={{
            marginTop: 1,
          }}
          inputProps={{
            style: {
              padding: 8,
              color: '#000000',
              WebkitTextFillColor: 'unset',
            },
          }}
          id='txtClaimsData'/>
      </Grid>
      <Grid item xs={2} >
        <IconButton onClick={handleExpandedChange} edge="start" color="inherit" aria-label="menu">
          <ExpandMoreIcon />
        </IconButton>
      </Grid>
    </Grid>
      }
    </React.Fragment>
  );
}
