// Functions
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// Components
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TitlePaper from '../Components/StyledMUIComponents/TitlePaper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import ExcessMileageCalculatorPopup from '../Components/ExcessMileageCalculatorPopup';
import { checkWholeNumber, getCurrentUser, isCompleteProductCodeSchedule,
  ukDateStringToDate, dateToMMMYYYString, currencyFormatter } from '../Libs/fileHelper';
import { createAndCompletePendingItem } from '../Libs/apiHelper';
import { emailCancellationTemplateText, pdfCancellationTemplateText } from '../Libs/templateHelper';
import { generateEMLReference } from '../Libs/emailHelper';
import { excessMileageChargeCalculation, createYearlyMileageData, calculateCurrentYear,
} from '../Libs/mileageCalculatorHelper';
import { carVanSwitch } from '../Libs/StageConfig';


export default function TerminationsCalculation(
    { customerData, scheduleData, claimsData, agedData, setSendingEmail, setEmailStates, emailStates,
      validatorProps, setSnackbarProps, currUser, pendingData, setPendingData, hotValue,
      emailConfigData },
) {
  TerminationsCalculation.propTypes = {
    scheduleData: PropTypes.shape({
      ScheduleID: PropTypes.string.isRequired,
      ChassisNo: PropTypes.string,
      Registration: PropTypes.string,
      CustomerCofico: PropTypes.string,
      StartDate: PropTypes.string,
      ProductCode: PropTypes.string,
      EndDate: PropTypes.string,
      PaymentFrequency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      RegDate: PropTypes.string,
      ModelGroup: PropTypes.string,
      PencePerMile: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      LaunchDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      Term: PropTypes.number,
      HasVAT: PropTypes.string,
      Status: PropTypes.string,
      IsSuspended: PropTypes.string,
      ServicesAvailable: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ChargeAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      OneOffAmount: PropTypes.number,
      ChargeAmountIncVAT: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      Remarks: PropTypes.string,
      CustomerID: PropTypes.string.isRequired,
      CustomerName: PropTypes.string.isRequired,
      SchedulePpkArray: PropTypes.array,
      ScheduleAdjustmentArray: PropTypes.array,
      ScheduleEstDistanceArray: PropTypes.array,
      ScheduleActDistanceArray: PropTypes.array,
      SchedLastTacho: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    customerData: PropTypes.shape({
      CustomerID: PropTypes.string.isRequired,
      CustomerName: PropTypes.string.isRequired,
      CustomerAddress: PropTypes.string.isRequired,
      CustomerPostcode: PropTypes.string.isRequired,
      CustomerEmail: PropTypes.string.isRequired,
      CustomerPhone: PropTypes.string.isRequired,
      CustomerMobile: PropTypes.string.isRequired,
    }),
    claimsData: PropTypes.array.isRequired,
    agedData: PropTypes.array.isRequired,
    validatorProps: PropTypes.shape({
      validatorData: PropTypes.object.isRequired,
      setValidatorData: PropTypes.func.isRequired,
    }),
    setSnackbarProps: PropTypes.func.isRequired,
    pendingData: PropTypes.array.isRequired,
    setPendingData: PropTypes.func.isRequired,
    currUser: PropTypes.object.isRequired,
    hotValue: PropTypes.string,
    setSendingEmail: PropTypes.func.isRequired,
    setEmailStates: PropTypes.func.isRequired,
    emailStates: PropTypes.object.isRequired,
    emailConfigData: PropTypes.object.isRequired,
  };
  const sxClasses = {
    cellStyle: {
      padding: '2px',
      borderBottom: 'none',
    },
    widerCellStyle: {
      padding: '8px',
      borderBottom: 'none',
    },
    containerStyle: {
      position: 'absolute',
      paddingTop: 5,
      paddingLeft: 1,
      paddingRight: 1,
      width: '47vw',
      top: '135px',
      height: 'auto',
      minHeight: '640px',
      minWidth: '370px',
      bottom: 2,
      border: '1px solid',
      borderColor: '#000000',
    },
    titleFields: {
      position: 'absolute',
      height: '20px',
      top: '134px',
      margin: '5px',
      zIndex: 1,
    },
    disabledTextStyle: {
      backgroundColor: '#ADD8E6',
      borderColor: '#aaaaaa',
      border: '1px solid',
      color: '#ffffff',
    },
    disabledTextGreenStyle: {
      backgroundColor: '#ADD8E6',
      borderColor: '#aaaaaa',
      border: '1px solid',
      color: '#ffffff',
    },
    enabledTextStyle: {
      // borderColor: '#aaaaaa',
      // border: '1px solid',
    },
    smallButtonStyle: {
      padding: 0,
      width: '100%',
      textTransform: 'none',
    },
    figureContainerStyle: {
      marginTop: '4px',
      marginBottom: '4px',
      padding: 1,
      borderColor: '#000000',
      border: '1px dashed',
    },
    leftGridCellStyle: {
      margin: 'auto',
      padding: 2,
    },
    leftButtonStyle: {
      margin: 1,
      width: '100%',
      textTransform: 'none',
    },
    rightGridCellStyle: {
      padding: '2px',
      margin: 'auto',
    },
    rightButtonStyle: {
      padding: 1,
      width: '100%',
      textTransform: 'none',
    },
    checkboxGridStyle: {
      padding: 0.5,
    },
    checkboxDivStyle: {
      borderColor: '#000000',
      border: '1px solid',
      lineHeight: 0,
      textAlign: 'center',
    },
  };
  const navigate = useNavigate();
  const [checkboxState, setCheckboxState] = useState({
    retailerFunded: parseInt(scheduleData.ChargeAmount) === 0,
    hostileTermation: (hotValue === 'true'),
    emailRetailer: false,
  });

  // Calculation Formulas
  const interfaceAdj = () => {
    return 0;
  };

  const adminAdjStartValue = () => {
    if ((claimsArrayToClaimsNumber() >= scheduleData.ServicesAvailable) ||
      isCompleteProductCodeSchedule(scheduleData)) {
      return -40;
    } else {
      return '';
    }
  };

  const [adjStates, setAdjStates] = useState({
    incomeMonthAdj: { value: -interfaceAdj(), wholeNum: true },
    incomeAdj: { value: '', wholeNum: false },
    agedAdj: { value: '', wholeNum: false },
    servicesAmountAdj: { value: '', wholeNum: false },
    adminAdj: { value: adminAdjStartValue(), wholeNum: false },
    servicesUsedAdj: { value: claimsArrayToClaimsNumber(), wholeNum: true },
    allServicesUsedAdj: { value: '', wholeNum: false },
    mileageAdj: { value: '', wholeNum: false },
  });

  const incomeMonth = () => {
    if ((new Date()) > ukDateStringToDate(scheduleData.EndDate)) {
      return scheduleData.Term;
    } else {
      // Months between start date and now
      const calculatedMonths = calculateIncomeMonthsDiff();
      if (calculatedMonths > scheduleData.Term) {
        return scheduleData.Term;
      } else {
        return calculatedMonths;
      }
    }
  };

  const calculateIncomeMonthsDiff = () => {
    const currentDate = new Date();
    let fifthInvoiceAdjust = 0;
    if (currentDate.getDate() >= 5) {
      fifthInvoiceAdjust = 1;
    }
    const currentTotalMonth = (currentDate.getFullYear() * 12) + (currentDate.getMonth() + 1);
    const startDateAsDate = ukDateStringToDate(scheduleData.StartDate);
    const startDateTotalMonth = (startDateAsDate.getFullYear() * 12) + (startDateAsDate.getMonth() + 1);
    return (currentTotalMonth - startDateTotalMonth) + 1 + fifthInvoiceAdjust;
  };


  const paymentsRemaining = scheduleData.Term - incomeMonth();
  const paymentsRemainingAmount = paymentsRemaining * scheduleData.ChargeAmount;

  const latestRental = () => {
    const startAsDate = ukDateStringToDate(scheduleData.StartDate);
    return dateToMMMYYYString(new Date(startAsDate.getFullYear(),
        startAsDate.getMonth()+incomeMonthTotal()-1,
        1),
    );
  };

  const incomeStart = scheduleData.OneOffAmount || 0;
  const agedAmount = 0;
  const servicesAmount = claimsArrayToClaimsAmount();
  const adminAmount = 40;


  // Total Fields Calculations
  const incomeMonthTotal = () => {
    return addStringIgnoreNull(incomeMonth(), adjStates.incomeMonthAdj.value);
  };

  const income = () => {
    const incomeAmount = addStringIgnoreNull((incomeMonthTotal() * scheduleData.ChargeAmount), incomeStart);
    const scheduleTotalIncomeAmount = addStringIgnoreNull(
        (scheduleData.Term * scheduleData.ChargeAmount), incomeStart);
    if (incomeAmount > scheduleTotalIncomeAmount) {
      return scheduleTotalIncomeAmount;
    } else {
      return incomeAmount;
    }
  };

  const incomeTotal = () => {
    return (addStringIgnoreNull(income(), adjStates.incomeAdj.value));
  };

  const agedTotal = () => {
    return (addStringIgnoreNull(agedAmount, adjStates.agedAdj.value));
  };

  const servicesAmountTotal = () => {
    return (addStringIgnoreNull(servicesAmount, adjStates.servicesAmountAdj.value));
  };

  const adminTotal = () => {
    return (addStringIgnoreNull(adminAmount, adjStates.adminAdj.value));
  };

  const allServicesUsedAmount = () => {
    if (isCompleteProductCodeSchedule(scheduleData)) {
      return 0;
    }
    if (adjStates.servicesUsedAdj.value >= scheduleData.ServicesAvailable) {
      return ((scheduleData.Term * scheduleData.ChargeAmount) - servicesAmountTotal());
    } else {
      return 0;
    }
  };

  const allServicesUsedTotal = () => {
    return (addStringIgnoreNull(allServicesUsedAmount(), adjStates.allServicesUsedAdj.value));
  };

  // Cancellation And txtIconFigureGross
  const cancellationFigureNet = () => {
    return (incomeTotal()-
    -expenditureNet()-
    adminTotal());
  };

  const expenditureNet = () => {
    return (-agedTotal()-
    servicesAmountTotal()-
    allServicesUsedTotal()-
    excessMileageTotal());
  };

  const iconIncomeTotal = () => {
    const incomeAmount = (incomeMonth() * scheduleData.ChargeAmount) +
    addStringIgnoreNull(incomeStart, adjStates.incomeAdj.value);
    const scheduleTotalIncomeAmount = (scheduleData.Term * scheduleData.ChargeAmount) +
    addStringIgnoreNull(incomeStart, adjStates.incomeAdj.value);
    if (incomeAmount > scheduleTotalIncomeAmount) {
      return scheduleTotalIncomeAmount;
    } else {
      return incomeAmount;
    }
  };

  const iconFigureNet = () => {
    if (cancellationFigureNet()<0 && parseInt(adjStates.incomeMonthAdj.value) <0) {
      return (-1*(adjStates.incomeMonthAdj.value * scheduleData.ChargeAmount));
    } else {
      return (iconIncomeTotal()-servicesAmountTotal()-adminTotal()-allServicesUsedTotal());
    }
  };

  // Handle Change Functions
  const handleCheckboxChange = (event) => {
    setCheckboxState({ ...checkboxState, [event.target.name]: event.target.checked });
    if (event.target.name === 'retailerFunded') {
      validatorProps.setValidatorData(
          { ...validatorProps.validatorData, hidden: !event.target.checked },
      );
    }
  };

  const handleAdjStateChange = (event) => {
    // ignore silly high values
    if (Math.abs(event.target.value) > 1000000) {
      // Display message input too large
      setSnackbarProps({ open: true,
        message: 'You have entered a value that is too large.',
        severity: 'warning' });
      return;
    }
    // Special case for servicesUsedAdj not allowing negatives
    if (event.target.id === 'servicesUsedAdj') {
      if (event.target.value === '-') {
        // Display message services used cannot be negative
        setSnackbarProps({ open: true,
          message: 'Number of services used cannot be negative.',
          severity: 'warning' });
        return;
      }
    }
    // Special case for combined IncomeMonth and IncomeAdj not allowing negatives
    if (event.target.id === 'incomeMonthAdj') {
      if ((incomeMonth() + parseInt(event.target.value)) < 0) {
        // Display message total months cannot be negative
        setSnackbarProps({ open: true,
          message: 'Number of total income months cannot be negative',
          severity: 'warning' });
        return;
      }
    }
    if (adjStates[event.target.id].wholeNum) {
      if (checkWholeNumber(event.target.value)) {
        setAdjStates({ ...adjStates, [event.target.id]: {
          value: event.target.value, wholeNum: adjStates[event.target.id].wholeNum,
        } });
      } else {
        // Display message input must be Whole Numbers
        setSnackbarProps({ open: true,
          message: 'You may only enter whole numbers',
          severity: 'warning' });
      }
    } else {
      if (checkNumber(event.target.value)) {
        setAdjStates({ ...adjStates, [event.target.id]: {
          value: event.target.value, wholeNum: adjStates[event.target.id].wholeNum,
        } });
      } else {
        // Display message input must be valid Number
        setSnackbarProps({ open: true,
          message: 'You may only enter a valid currency amount',
          severity: 'warning' });
      }
    }
  };

  // Other Function
  const waiveFeePressed = () => {
    if (adjStates.adminAdj.value === -adminAmount) {
      setAdjStates({ ...adjStates, adminAdj: {
        value: 0, wholeNum: adjStates.adminAdj.wholeNum,
      } });
    } else {
      setAdjStates({ ...adjStates, adminAdj: {
        value: -adminAmount, wholeNum: adjStates.adminAdj.wholeNum,
      } });
    }
  };

  const removeAdjPressed = () => {
    if (adjStates.allServicesUsedAdj.value === 0 || adjStates.allServicesUsedAdj.value === '') {
      if (
        allServicesUsedAmount() !== 0 &&
        adjStates.adminAdj.value !== 0 &&
        adjStates.adminAdj.value !== '') {
        setAdjStates({ ...adjStates,
          adminAdj: { value: 0, wholeNum: adjStates.adminAdj.wholeNum },
          allServicesUsedAdj: {
            value: -parseFloat(allServicesUsedAmount()).toFixed(2),
            wholeNum: adjStates.allServicesUsedAdj.wholeNum },
        });
        // Display message to show admin fee readded
        setSnackbarProps({ open: true, message: 'Admin Fee Re-added', severity: 'info' });
      } else {
        setAdjStates({ ...adjStates, allServicesUsedAdj: {
          value: -parseFloat(allServicesUsedAmount()).toFixed(2),
          wholeNum: adjStates.allServicesUsedAdj.wholeNum } });
      }
    } else {
      if (adjStates.adminAdj.value === 0 || adjStates.adminAdj.value === '') {
        setAdjStates({ ...adjStates,
          allServicesUsedAdj: { value: 0, wholeNum: adjStates.allServicesUsedAdj.wholeNum },
          adminAdj: { value: -adminAmount, wholeNum: adjStates.adminAdj.wholeNum },
        });
        // Display message to show admin fee removed
        setSnackbarProps({ open: true, message: 'Admin Fee Removed', severity: 'info' });
      } else {
        setAdjStates({ ...adjStates, allServicesUsedAdj: {
          value: 0,
          wholeNum: adjStates.allServicesUsedAdj.wholeNum } });
      }
    }
  };

  const checkNumber = (string) => {
    if (string === '' || string === '-' || string === '.') {
      return true;
    }
    const regexp = /^-?[0-9]\d*\.?\d?\d?$/;
    return regexp.test(string);
  };

  const addStringIgnoreNull = (string1, string2) => {
    let numb1 = 0;
    if (string1 !== null && string1 !== '' && string1 !== '-' && string1 !== '.') {
      if (string1.toString().toLowerCase().includes('.')) {
        numb1 = parseFloat(string1);
      } else {
        numb1 = parseInt(string1);
      }
    }
    let numb2 = 0;
    if (string2 !== null && string2 !== '' && string2 !== '-' && string2 !== '.') {
      if (string2.toString().toLowerCase().includes('.')) {
        numb2 = parseFloat(string2);
      } else {
        numb2 = parseInt(string2);
      }
    }
    return (numb1 + numb2);
  };

  const pendingItemText = (type) => {
    if (cancellationFigureNet() > 0) {
      return ('Termination ' + type + ' Raised (NET): ' +
      currencyFormatter(cancellationFigureNet()) + ' Credit. Amount to Invoice(NET): ' +
      currencyFormatter(iconFigureNet()) + ' Credit' + endingPendingItemText());
    } else {
      return ('Termination ' + type + ' Raised (NET): ' +
      currencyFormatter(cancellationFigureNet()) + ' Debit. Amount to Invoice(NET): ' +
      currencyFormatter(iconFigureNet()) + ' Debit' + endingPendingItemText());
    }
  };

  const endingPendingItemText = () => {
    return ('\nIncome ' + currencyFormatter(incomeTotal()) +
      ' – Expenditure ' + currencyFormatter(expenditureNet()) +
      ' – Admin Charge ' + currencyFormatter(adminTotal()) +
      '= ' + currencyFormatter(cancellationFigureNet()));
  };

  function claimsArrayToClaimsNumber() {
    let claimsCount = 0;
    for (const claim of claimsData) {
      if (ukDateStringToDate(claim.DateOfClaim) >= ukDateStringToDate(scheduleData.StartDate) &&
        ukDateStringToDate(claim.DateOfClaim) <= ukDateStringToDate(scheduleData.EndDate)) {
        claimsCount = claimsCount + 1;
      }
    }
    return claimsCount;
  }

  function claimsArrayToClaimsAmount() {
    let claimsAmount = 0;
    for (const claim of claimsData) {
      if (ukDateStringToDate(claim.DateOfClaim) >= ukDateStringToDate(scheduleData.StartDate) &&
        ukDateStringToDate(claim.DateOfClaim) <= ukDateStringToDate(scheduleData.EndDate)) {
        claimsAmount = claimsAmount + claim.ClaimTotalAmount;
      }
    }
    return claimsAmount;
  }

  async function savePressed() {
    const body = {
      ScheduleID: scheduleData.ScheduleID,
      Status: 'History',
      ActionType: 'TRC',
      CustomerName: scheduleData.CustomerName,
      ScheduleStatus: scheduleData.Status,
      Reference: pendingItemText('Figure'),
      CustomerID: scheduleData.CustomerID,
      CustomerCofico: scheduleData.CustomerCofico,
    };
    const response = await createAndCompletePendingItem(body,
        getCurrentUser(currUser), pendingData, setPendingData, setSnackbarProps);
    if (response.status === 200) {
      navigate(-1);
      setSnackbarProps({ open: true,
        message: 'Save Successful',
        severity: 'success' });
    }
  }

  const getTerminationValues = () => {
    return ({
      adjStates: adjStates,
      latestRental: latestRental(),
      cancellationFigureGross: parseFloat(cancellationFigureNet() * 1.2).toFixed(2),
      incomeTotal: incomeTotal(),
      servicesAmountTotal: servicesAmountTotal(),
      adminTotal: adminTotal(),
      excessMileageTotal: excessMileageTotal(),
    });
  };

  async function saveAndConfirmationTriggered(sendAsPDF) {
    if ( validatorDataFail()) {
      return;
    }

    const terminationValues = getTerminationValues();

    const combinedProps = {
      customerData,
      scheduleData,
      terminationValues,
      validatorProps,
    };

    let emailSentToAddress = customerData.CustomerEmail;
    let emailBodyStart = 'Dear ' + customerData.CustomerName + ',\n\n';
    if (checkboxState.emailRetailer) {
      emailSentToAddress = '';
      emailBodyStart = 'Dear ' + validatorProps.validatorData.retailerName + ',\n\n';
    }

    let startingBody = '';
    if (sendAsPDF) {
      startingBody = pdfCancellationTemplateText(pickTemplate('Confirmation'), combinedProps);
    } else {
      startingBody = emailBodyStart +
          emailCancellationTemplateText(pickTemplate('Confirmation'), combinedProps,
              emailConfigData.signature);
    }

    setEmailStates(
        {
          emailSentTo: emailSentToAddress,
          emailSentFrom: emailConfigData.defaultMailbox,
          emailSubject: 'Mercedes-Benz Service Contract Cancellation Confirmation - ' +
          scheduleData.ScheduleID + ' - ' + scheduleData.Registration,
          emailBody: startingBody,
          sendAsPDF: sendAsPDF,
          sendAction: saveAndEmailConfirmationDelayedAction,
        },
    );

    setSendingEmail(true);
  }

  async function saveAndQuoteTriggered(sendAsPDF) {
    if ( validatorDataFail()) {
      return;
    }

    const terminationValues = getTerminationValues();

    const combinedProps = {
      customerData,
      scheduleData,
      terminationValues,
      validatorProps,
    };

    let emailSentToAddress = customerData.CustomerEmail;
    let emailBodyStart = 'Dear ' + customerData.CustomerName + ',\n\n';
    if (checkboxState.emailRetailer) {
      emailSentToAddress = '';
      emailBodyStart = 'Dear ' + validatorProps.validatorData.retailerName + ',\n\n';
    }

    let startingBody = '';
    if (sendAsPDF) {
      startingBody = pdfCancellationTemplateText(pickTemplate('Quote'), combinedProps);
    } else {
      startingBody = emailBodyStart +
          emailCancellationTemplateText(pickTemplate('Quote'), combinedProps,
              emailConfigData.signature);
    }

    setEmailStates(
        {
          emailSentTo: emailSentToAddress,
          emailSentFrom: emailConfigData.defaultMailbox,
          emailSubject: 'Mercedes-Benz Service Contract Cancellation Quote - ' +
          scheduleData.ScheduleID + ' - ' + scheduleData.Registration,
          emailBody: startingBody,
          sendAsPDF: sendAsPDF,
          sendAction: saveAndEmailQuoteDelayedAction,
        },
    );

    setSendingEmail(true);
  }

  const saveAndEmailConfirmationDelayedAction = async (eventEmailStates) => {
    const body = {
      ScheduleID: scheduleData.ScheduleID,
      Status: 'History',
      ActionType: 'TRC',
      CustomerName: scheduleData.CustomerName,
      ScheduleStatus: scheduleData.Status,
      Reference: pendingItemText('Confirmation'),
      CustomerID: scheduleData.CustomerID,
      CustomerCofico: scheduleData.CustomerCofico,
    };

    const response = await createAndCompletePendingItem(body,
        getCurrentUser(currUser), pendingData, setPendingData, setSnackbarProps);
    if (response.status === 200) {
      // Email Pending note
      let body2 = {
        ScheduleID: scheduleData.ScheduleID,
        Status: 'History',
        CustomerName: scheduleData.CustomerName,
        ScheduleStatus: scheduleData.Status,
        CustomerID: scheduleData.CustomerID,
        CustomerCofico: scheduleData.CustomerCofico,
      };
      if (eventEmailStates.sendAsPDF) {
        body2 = {
          ...body2,
          ActionType: 'LET',
          Reference: 'Letter Sent: \n\n' + eventEmailStates.emailBody,
        };
      } else {
      // Log EML item
        body2 = {
          ...body2,
          ActionType: 'EML',
          Reference: generateEMLReference(eventEmailStates.groupMailbox, eventEmailStates.emailSentTo,
              eventEmailStates.emailSubject, eventEmailStates.emailBody),
        };
      }

      const response2 = await createAndCompletePendingItem(body2,
          getCurrentUser(currUser), response.updatedPendingData, setPendingData, setSnackbarProps);
      if (response2.status === 200) {
        navigate(-1);
      }
    }
  };

  const saveAndEmailQuoteDelayedAction = async (eventEmailStates) => {
    const body = {
      ScheduleID: scheduleData.ScheduleID,
      Status: 'History',
      ActionType: 'TRC',
      CustomerName: scheduleData.CustomerName,
      ScheduleStatus: scheduleData.Status,
      Reference: pendingItemText('Quote'),
      CustomerID: scheduleData.CustomerID,
      CustomerCofico: scheduleData.CustomerCofico,
    };

    const response = await createAndCompletePendingItem(body,
        getCurrentUser(currUser), pendingData, setPendingData, setSnackbarProps);
    if (response.status === 200) {
      // Email Pending note
      let body2 = {
        ScheduleID: scheduleData.ScheduleID,
        Status: 'History',
        CustomerName: scheduleData.CustomerName,
        ScheduleStatus: scheduleData.Status,
        CustomerID: scheduleData.CustomerID,
        CustomerCofico: scheduleData.CustomerCofico,
      };
      if (eventEmailStates.sendAsPDF) {
        body2 = {
          ...body2,
          ActionType: 'LET',
          Reference: 'Letter Sent: \n\n' + eventEmailStates.emailBody,
        };
      } else {
      // Log EML item
        body2 = {
          ...body2,
          ActionType: 'EML',
          Reference: generateEMLReference(eventEmailStates.groupMailbox, eventEmailStates.emailSentTo,
              eventEmailStates.emailSubject, eventEmailStates.emailBody),
        };
      }

      const response2 = await createAndCompletePendingItem(body2,
          getCurrentUser(currUser), response.updatedPendingData, setPendingData, setSnackbarProps);
      if (response2.status === 200) {
        navigate(-1);
      }
    }
  };

  function pickTemplate(terminationType) {
    return (carVanSwitch(pickTemplateCar(terminationType), pickTemplateVan(terminationType)));
  }

  const getSubTerminationType = () => {
    if (adjStates.servicesUsedAdj.value === '0' || adjStates.servicesUsedAdj.value === 0) {
      if (adminTotal() === 0) {
        return 'NoAdminNoServices';
      } else {
        return 'NoServices';
      }
    }
    if (allServicesUsedTotal() !== 0) {
      return 'AllServices';
    }
    if (adminTotal() === 0) {
      if (cancellationFigureNet() < 0) {
        return 'NoAdminServicesCharge';
      } else {
        return 'NoAdminServicesRefund';
      }
    }
    if (cancellationFigureNet() > 0) {
      return 'Refund';
    } else if (cancellationFigureNet() === 0) {
      return 'BreakEven';
    } else {
      return 'Settlement';
    }
  };

  function pickTemplateCar(terminationType) {
    let mainTemplateType = '';
    if (checkboxState.hostileTermation) {
      mainTemplateType = 'Hostile Termination';
    } else {
      mainTemplateType = 'Cancellation ' + terminationType;
    }

    const subTemplateType = getSubTerminationType();

    let dealerFunded = '';
    if (checkboxState.retailerFunded) {
      dealerFunded = ' DealerFunded';
    }

    return mainTemplateType + ' ' + subTemplateType + dealerFunded;
  }

  function pickTemplateVan(terminationType) {
    if (checkboxState.emailRetailer) {
    // Retailer Templates
      if (checkboxState.retailerFunded) {
        return 'Cancellation ' + terminationType + ' Retailer Funded Retailer';
      } else {
        if (adjStates.servicesUsedAdj.value === '0' || adjStates.servicesUsedAdj.value === 0) {
          if (adminTotal() === 0) {
            return 'Cancellation ' + terminationType + ' No Admin Retailer';
          } else {
            return 'Cancellation ' + terminationType + ' No Services Retailer';
          }
        } else {
          if (allServicesUsedTotal() !== 0) {
            return 'Cancellation ' + terminationType + ' All Services Retailer';
          } else {
            if (cancellationFigureNet() > 0) {
              return 'Cancellation ' + terminationType + ' Refund Retailer';
            } else if (cancellationFigureNet() === 0) {
              return 'Cancellation ' + terminationType + ' Break Even Retailer';
            } else {
              return 'Cancellation ' + terminationType + ' Settlement Retailer';
            }
          }
        }
      }
    }

    if (isCompleteProductCodeSchedule(scheduleData)) {
      if (cancellationFigureNet() >= 0) {
        return 'ServiceCare Complete Cancellation Confirmation No Charge';
      } else {
        return 'ServiceCare Complete Cancellation Confirmation Debit';
      }
    } else {
      if (checkboxState.hostileTermation) {
        if (cancellationFigureNet() >= 0) {
          return 'ServiceCare Maintenance Hostile Termination Credit';
        } else {
          return 'ServiceCare Maintenance Hostile Termination Debit';
        }
      } else {
        if (checkboxState.retailerFunded) {
          return 'ServiceCare Maintenance Cancellation Confirmation Retailer Funded';
        }
        // Customer Templates
        if (adjStates.servicesUsedAdj.value === '0' || adjStates.servicesUsedAdj.value === 0) {
          return 'ServiceCare Maintenance Cancellation Confirmation No Services';
        } else {
          if (allServicesUsedTotal() !== 0) {
            return 'ServiceCare Maintenance Cancellation Confirmation All Services';
          } else {
            if (cancellationFigureNet() > 0) {
              return 'ServiceCare Maintenance Cancellation Confirmation Credit';
            } else if (cancellationFigureNet() === 0) {
              return 'ServiceCare Maintenance Cancellation Confirmation Break Even';
            } else {
              return 'ServiceCare Maintenance Cancellation Confirmation Debit';
            }
          }
        }
      }
    }
  }

  function validatorDataFail() {
    if (checkboxState.retailerFunded) {
      if (validatorProps.validatorData.retailerName === 'Mercedes Benz of') {
        setSnackbarProps({ open: true,
          message: 'Please enter the retailer\'s name and try again.',
          severity: 'info' });
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // Excess Mileage Functionality
  const [excessMileageData, setExcessMileageData] = React.useState({
    popupOpen: false,
    scheduleLastTacho: scheduleData.SchedLastTacho || 0,
    yearlyData: createYearlyMileageData(scheduleData, claimsData, scheduleData.SchedLastTacho || 0),
    calculationDate: new Date(),
    scheduleStartDate: ukDateStringToDate(scheduleData.StartDate),
  });

  const excessMileageShowDetails = () => {
    setExcessMileageData({
      ...excessMileageData,
      popupOpen: true,
    });
  };

  const excessMileageTotal = () => {
    if (isCompleteProductCodeSchedule(scheduleData)) {
      return Number(excessMileageChargeCalculation(excessMileageData,
          calculateCurrentYear(scheduleData) + 1)) + Number(adjStates.mileageAdj.value);
    } else {
      return 0;
    }
  };

  const mileageReviewClosed = () => {
    setExcessMileageData({
      ...excessMileageData,
      popupOpen: false,
    });
  };

  return (
    <React.Fragment>
      <TitlePaper sx={sxClasses.titleFields} >
      Calculations
      </TitlePaper>
      <TableContainer component={Paper} sx={sxClasses.containerStyle}>
        <Table sx={sxClasses.cellStyle} size='small' aria-label='a dense table'>
          <TableBody>
            <TableRow>
              <TableCell sx={sxClasses.cellStyle} align='center'> </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Amount:</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Manual Adjust:</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Total:</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'> </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={sxClasses.cellStyle} align='center'>Income Months:</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField disabled value={incomeMonth()} id='txtIncomeMonth'
                  inputProps={{ style: { textAlign: 'center', color: '#333333', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextStyle}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField value={adjStates.incomeMonthAdj.value} id='incomeMonthAdj'
                  inputProps={{ style: { textAlign: 'center', padding: 2 } }}
                  sx={sxClasses.enabledTextStyle} onChange={handleAdjStateChange}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField disabled value={incomeMonthTotal()} id='txtIncomeMonthTotal'
                  inputProps={{ style: { textAlign: 'center', color: '#333333', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextStyle}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'> </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={sxClasses.cellStyle} align='center'>Latest Rental:</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField disabled value={latestRental()}
                  id='txtLatestRental'
                  inputProps={{ style: { textAlign: 'center', color: '#333333', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextStyle}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Initial Payment:</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField disabled value={currencyFormatter(incomeStart)}
                  id='txtInitialPaymentTotal'
                  inputProps={{ style: { textAlign: 'center', color: '#333333', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextStyle}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'> </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={sxClasses.cellStyle} align='center'>Payments Remaining:</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField disabled value={paymentsRemaining}
                  id='txtPaymentsRemaining'
                  inputProps={{ style: { textAlign: 'center', color: '#333333', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextStyle}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'> </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField disabled
                  value={currencyFormatter(paymentsRemainingAmount)}
                  id='txtPaymentsRemainingTotal'
                  inputProps={{ style: { textAlign: 'center', color: '#333333', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextStyle}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'> </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={sxClasses.cellStyle} align='center'> </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Amount:</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Manual Adjust:</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Total:</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'> </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={sxClasses.cellStyle} align='center'>Income:</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField disabled
                  value={currencyFormatter(income())}
                  id='txtIncomeAmount'
                  inputProps={{ style: { textAlign: 'center', color: '#333333', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextStyle}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField value={adjStates.incomeAdj.value}
                  id='incomeAdj'
                  inputProps={{ style: { textAlign: 'center', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.enabledTextStyle}
                  onChange={handleAdjStateChange}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField disabled
                  value={currencyFormatter(incomeTotal())}
                  id='txtIncomeAmountTotal'
                  inputProps={{ style: { textAlign: 'center', color: '#333333', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextStyle}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'> </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={sxClasses.cellStyle} align='center'>Aged Debt Total:</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'> </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField value={adjStates.agedAdj.value} id='agedAdj'
                  inputProps={{ style: { textAlign: 'center', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.enabledTextStyle}
                  onChange={handleAdjStateChange}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField disabled
                  value={currencyFormatter(agedTotal())}
                  id='txtAgedTotal'
                  inputProps={{ style: { textAlign: 'center', color: '#333333', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextStyle}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'> </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={sxClasses.cellStyle} align='center'>Service Claims Total:</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField disabled
                  value={currencyFormatter(servicesAmount)}
                  id='txtClaimsAmount'
                  inputProps={{ style: { textAlign: 'center', color: '#333333', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextStyle}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField value={adjStates.servicesAmountAdj.value}
                  id='servicesAmountAdj'
                  inputProps={{ style: { textAlign: 'center', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.enabledTextStyle}
                  onChange={handleAdjStateChange}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField disabled
                  value={currencyFormatter(servicesAmountTotal())}
                  id='txtClaimsTotal'
                  inputProps={{ style: { textAlign: 'center', color: '#333333', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextStyle}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'> </TableCell>
            </TableRow>
            {isCompleteProductCodeSchedule(scheduleData) &&
              <TableRow>
                <TableCell sx={sxClasses.cellStyle} align='center'>Excess Mileage Charge:</TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>
                  <TextField disabled
                    value={currencyFormatter(excessMileageChargeCalculation(excessMileageData,
                        calculateCurrentYear(scheduleData) + 1))}
                    id='txtExcessMileageAmount'
                    inputProps={{ style: { textAlign: 'center', color: '#333333' } }}
                    sx={sxClasses.disabledTextStyle}/>
                </TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>
                  <TextField value={adjStates.mileageAdj.value}
                    id='mileageAdj'
                    inputProps={{ style: { textAlign: 'center' } }}
                    sx={sxClasses.enabledTextStyle}
                    onChange={handleAdjStateChange}/>
                </TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>
                  <TextField disabled
                    value={currencyFormatter(excessMileageTotal())}
                    id='txtExcessMileageTotal'
                    inputProps={{ style: { textAlign: 'center', color: '#333333' } }}
                    sx={sxClasses.disabledTextStyle}/>
                </TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>
                  <Button variant='contained' color='primary'
                    sx={sxClasses.smallButtonStyle}
                    onClick={excessMileageShowDetails}>
                    Show Details
                  </Button>
                </TableCell>
              </TableRow>
            }
            <TableRow>
              <TableCell sx={sxClasses.cellStyle} align='center'>Admin Fee:</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField disabled
                  value={currencyFormatter(adminAmount)}
                  id='txtAdminAmount'
                  inputProps={{ style: { textAlign: 'center', color: '#333333', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextStyle}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField value={adjStates.adminAdj.value}
                  id='adminAdj'
                  inputProps={{ style: { textAlign: 'center', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.enabledTextStyle}
                  onChange={handleAdjStateChange}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField disabled
                  value={currencyFormatter(adminTotal())}
                  id='txtAdminTotal'
                  inputProps={{ style: { textAlign: 'center', color: '#333333', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextStyle}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <Button variant='contained' color='primary'
                  sx={sxClasses.smallButtonStyle}
                  onClick={waiveFeePressed}>
                  Waive Fee
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={sxClasses.cellStyle} align='center'>Services Used:</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'> </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>
                <TextField value={adjStates.servicesUsedAdj.value}
                  id='servicesUsedAdj'
                  inputProps={{ style: { textAlign: 'center', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.enabledTextStyle}
                  onChange={handleAdjStateChange}/>
              </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'> </TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'> </TableCell>
            </TableRow>
            {!isCompleteProductCodeSchedule(scheduleData) &&
              <TableRow>
                <TableCell sx={sxClasses.cellStyle} align='center'>
                All Services Used Adj:
                </TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>
                  <TextField disabled
                    value={currencyFormatter(allServicesUsedAmount())}
                    id='txtServicesUsed'
                    inputProps={{ style: { textAlign: 'center', color: '#333333' } }}
                    sx={sxClasses.disabledTextStyle}/>
                </TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>
                  <TextField value={adjStates.allServicesUsedAdj.value}
                    id='allServicesUsedAdj'
                    inputProps={{ style: { textAlign: 'center' } }}
                    sx={sxClasses.enabledTextStyle}
                    onChange={handleAdjStateChange}/>
                </TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>
                  <TextField disabled
                    value={currencyFormatter(allServicesUsedTotal())}
                    id='txtServicesUsedTotal'
                    inputProps={{ style: { textAlign: 'center', color: '#333333' } }}
                    sx={sxClasses.disabledTextStyle}/>
                </TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>
                  <Button variant='contained' color='primary'
                    sx={sxClasses.smallButtonStyle} onClick={removeAdjPressed}>
                    Remove Adj
                  </Button>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
        <Table sx={sxClasses.figureContainerStyle} size='small' aria-label='a dense table'>
          <TableBody>
            <TableRow>
              <TableCell sx={sxClasses.widerCellStyle} align='center'>
              Cancellation Figure (NET):
              </TableCell>
              <TableCell sx={sxClasses.widerCellStyle} align='center'>
                {cancellationFigureNet() >= 0 ?
                <TextField disabled
                  value={currencyFormatter(Math.abs(cancellationFigureNet()))}
                  id='txtCancellationFigureNet'
                  inputProps={{ style: { textAlign: 'center', color: '#0CB829', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextGreenStyle}/> :
                <TextField disabled
                  value={currencyFormatter(Math.abs(cancellationFigureNet()))}
                  id='txtCancellationFigureNet'
                  inputProps={{ style: { textAlign: 'center', color: '#ff0f0f', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextStyle}/>
                }
              </TableCell>
              <TableCell sx={sxClasses.widerCellStyle} align='center'>
              iCON Invoice Figure (NET):
              </TableCell>
              <TableCell sx={sxClasses.widerCellStyle} align='center'>
                {iconFigureNet() >= 0 ?
                <TextField disabled
                  value={currencyFormatter(Math.abs(iconFigureNet()))}
                  id='txtIconFigureNet'
                  inputProps={{ style: { textAlign: 'center', color: '#0CB829', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextGreenStyle}/> :
                  <TextField disabled
                    value={currencyFormatter(Math.abs(iconFigureNet()))}
                    id='txtIconFigureNet'
                    inputProps={{ style: { textAlign: 'center', color: '#ff0f0f', padding: 2,
                      WebkitTextFillColor: 'unset' } }}
                    sx={sxClasses.disabledTextStyle}/>
                }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={sxClasses.widerCellStyle} align='center'>
              Cancellation Figure (GROSS):
              </TableCell>
              <TableCell sx={sxClasses.widerCellStyle} align='center'>
                {cancellationFigureNet() >= 0 ?
                <TextField disabled
                  value={currencyFormatter(Math.abs(cancellationFigureNet() * 1.2))}
                  id='txtCancellationFigureGross'
                  inputProps={{ style: { textAlign: 'center', color: '#0CB829', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextGreenStyle}/> :
                <TextField disabled
                  value={currencyFormatter(Math.abs(cancellationFigureNet() * 1.2))}
                  id='txtCancellationFigureGross'
                  inputProps={{ style: { textAlign: 'center', color: '#ff0f0f', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextStyle}/>
                }
              </TableCell>
              <TableCell sx={sxClasses.widerCellStyle} align='center'>
              iCON Invoice Figure (GROSS):
              </TableCell>
              <TableCell sx={sxClasses.widerCellStyle} align='center'>
                {iconFigureNet() >= 0 ?
                <TextField disabled
                  value={currencyFormatter(Math.abs(iconFigureNet() * 1.2))}
                  id='txtIconFigureGross'
                  inputProps={{ style: { textAlign: 'center', color: '#0CB829', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextGreenStyle}/> :
                <TextField disabled
                  value={currencyFormatter(Math.abs(iconFigureNet() * 1.2))}
                  id='txtIconFigureGross'
                  inputProps={{ style: { textAlign: 'center', color: '#ff0f0f', padding: 2,
                    WebkitTextFillColor: 'unset' } }}
                  sx={sxClasses.disabledTextStyle}/>
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Grid container spacing={0}>
          <Grid item xs={3} sx={sxClasses.leftGridCellStyle}>
            <Button sx={sxClasses.leftButtonStyle}
              variant='contained' color='primary' onClick={() => {
                savePressed();
              }}>
              Save
            </Button>
            <Button sx={sxClasses.leftButtonStyle}
              variant='contained' color='primary' onClick={() => {
                navigate(-1);
              }}>
              Exit Without Saving
            </Button>
          </Grid>
          <Grid item xs={9}>
            <Grid container>
              <Grid item xs={6} sx={sxClasses.rightGridCellStyle}>
                <Button sx={sxClasses.rightButtonStyle}
                  variant='contained' color='primary' onClick={() => {
                    saveAndConfirmationTriggered(false);
                  }}>
                  Save + Email Confirmation
                </Button>
              </Grid>
              <Grid item xs={6} sx={sxClasses.rightGridCellStyle}>
                <Button sx={sxClasses.rightButtonStyle}
                  variant='contained' color='primary' onClick={() => {
                    saveAndQuoteTriggered(false);
                  }}>
                  Save + Email Quote
                </Button>
              </Grid>
              <Grid item xs={6} sx={sxClasses.rightGridCellStyle}>
                <Button sx={sxClasses.rightButtonStyle}
                  variant='contained' color='primary' onClick={() => {
                    saveAndConfirmationTriggered(true);
                  }}>
                  Save + Post Confirmation
                </Button>
              </Grid>
              <Grid item xs={6} sx={sxClasses.rightGridCellStyle}>
                <Button sx={sxClasses.rightButtonStyle}
                  variant='contained' color='primary' onClick={() => {
                    saveAndQuoteTriggered(true);
                  }}>
                  Save + Post Quote
                </Button>
              </Grid>
              <Grid item xs={4} sx={sxClasses.checkboxGridStyle}>
                <Paper sx={sxClasses.checkboxDivStyle}>
                  Retailer Funded
                  <Checkbox checked={checkboxState.retailerFunded}
                    onChange={handleCheckboxChange} name='retailerFunded' color='primary' />
                </Paper>
              </Grid>
              <Grid item xs={4} sx={sxClasses.checkboxGridStyle}>
                <Paper sx={sxClasses.checkboxDivStyle}>
                  Hostile Term
                  <Checkbox checked={checkboxState.hostileTermation}
                    onChange={handleCheckboxChange} name='hostileTermation' color='primary' />
                </Paper>
              </Grid>
              <Grid item xs={4} sx={sxClasses.checkboxGridStyle}>
                <Paper sx={sxClasses.checkboxDivStyle}>
                  Email Retailer
                  <Checkbox checked={checkboxState.emailRetailer}
                    onChange={handleCheckboxChange} name='emailRetailer' color='primary' />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TableContainer>
      <ExcessMileageCalculatorPopup
        popupOpen={excessMileageData.popupOpen}
        excessMileageData={excessMileageData}
        setExcessMileageData={setExcessMileageData}
        scheduleData={scheduleData}
        claimsData={claimsData}
        setSnackbarProps={setSnackbarProps}
        mileageReviewClosed={mileageReviewClosed}/>
    </React.Fragment>
  );
}
