// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { upgradedDate, reverseSortArrayByProperty } from '../../Libs/fileHelper';
// Components
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// Resources

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import GetApp from '@mui/icons-material/GetApp';


const useStyles = makeStyles((theme) => ({
  cellStyle: {
    padding: '5px',
    borderBottom: '0px',
  },
  refCellStyle: {
    padding: '5px',
    borderBottom: '0px',
    paddingLeft: theme.spacing(1),
    maxHeight: '20px',
    minWidth: '270px',
    maxWidth: '23vw',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  containerStyle: {
    display: 'block',
    position: 'relative',
    paddingRight: theme.spacing(3),
    paddingBottom: '40px',
  },
  tableContStyle: {
    padding: theme.spacing(1),
    height: 'calc(100vh - 260px)',
    width: '100%',
    minHeight: '415px',
    border: '1px solid',
    borderColor: '#000000',
  },
  tableStyle: {
    padding: '5px',
    borderBottom: '0px',
    marginTop: '-12px',
    maxHeight: 'calc(100vh - 290px)',
  },
  rowStyle: {
    borderTop: '1px solid',
    borderColor: '#cccccc',
  },
  iconButton: {
    marginLeft: 'max(calc(50vw - 60px), 545px)',
    marginTop: -theme.spacing(1),
    padding: 0,
    color: theme.palette.primary.main,
  },
  buttonStyle: {
    marginLeft: theme.spacing(2),
  },
}));

export default function FlexContractRecords({ flexData, setSnackbarProps, linkClaim, unLinkClaim,
  editFlexRecord, exportFlexRecords }) {
  const classes = useStyles();

  FlexContractRecords.propTypes = {
    flexData: PropTypes.array.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
    linkClaim: PropTypes.func.isRequired,
    unLinkClaim: PropTypes.func.isRequired,
    editFlexRecord: PropTypes.func.isRequired,
    exportFlexRecords: PropTypes.func.isRequired,
  };

  const [pageIndex, setPageIndex] = React.useState(0);
  const handleChangePage = (event, newPage) => {
    setPageIndex(newPage);
  };
  const sliceDataByPage = () => {
    return reverseSortArrayByProperty(flexData.filter((item) => item.UUID !== 'Main'),
        'mileage').slice(
        pageIndex*100, (pageIndex+1) * 100);
  };

  return (
    <div className={classes.containerStyle}>
      <TableContainer component={Paper} className={classes.tableContStyle}>
        <IconButton className={classes.iconButton} onClick={exportFlexRecords}>
          <GetApp />
        </IconButton>
        <Table className={classes.tableStyle} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellStyle}>Dealer Requestor</TableCell>
              <TableCell className={classes.cellStyle} align='center'>Request Date</TableCell>
              <TableCell className={classes.cellStyle} align='center'>Mileage</TableCell>
              <TableCell className={classes.cellStyle} align='center'>Service Type</TableCell>
              <TableCell className={classes.cellStyle} align='center'>Registration</TableCell>
              <TableCell className={classes.cellStyle} align='center'>Claim Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sliceDataByPage().map((row, index) => (
              <FlexExpandedDropdown key={index} rowData={row} linkClaim={linkClaim}
                unLinkClaim={unLinkClaim} editFlexRecord={editFlexRecord}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination sx={{ paddingBottom: 0,
        position: 'absolute', bottom: '0px', right: '0px' }}
      rowsPerPageOptions={[100]}
      component="div"
      count={flexData.filter((item) => item.UUID !== 'Main').length}
      rowsPerPage={100}
      page={pageIndex}
      onPageChange={handleChangePage}
      />
    </div>
  );
}


const FlexExpandedDropdown = ({ rowData, linkClaim, unLinkClaim, editFlexRecord }) => {
  FlexExpandedDropdown.propTypes = {
    rowData: PropTypes.object.isRequired,
    linkClaim: PropTypes.func.isRequired,
    unLinkClaim: PropTypes.func.isRequired,
    editFlexRecord: PropTypes.func.isRequired,
  };
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  // To-DO Figure out way to remove react fragment as it is causing validate dom nesting error in log

  return (
    <React.Fragment>
      <TableRow >
        <TableCell component='th' scope='row'>
          {rowData.dealerRequestor}
        </TableCell>
        <TableCell align='center'>
          {upgradedDate(rowData.dealerRequestDate)}
        </TableCell>
        <TableCell calign='center'>
          {rowData.mileage}
        </TableCell>
        <TableCell align='center'>
          {rowData.serviceType + (rowData.autoChecked ? ' - Auto' : '')}
        </TableCell>
        <TableCell align='left'>
          {rowData.serviceRegistration}
        </TableCell>
        <TableCell align='left'>
          {(rowData.ConnectedClaim === 'None' || rowData.ConnectedClaim === undefined) ? 'None' : 'Linked'}
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            <KeyboardArrowDownIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} style={{ textAlign: 'center' }}>
              {rowData.ConnectedClaim === 'None' || rowData.ConnectedClaim === undefined ?
                <React.Fragment>
                  <Button variant='contained' color='primary' className={classes.buttonStyle}
                    onClick={() => {
                      editFlexRecord(rowData);
                    }}>
                    Edit
                  </Button>
                  <Button variant='contained' color='primary' className={classes.buttonStyle}
                    onClick={() => {
                      linkClaim(rowData);
                    }}>
                    Link Claim
                  </Button>
                </React.Fragment> :
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>DN</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Parts</TableCell>
                      <TableCell>Labour</TableCell>
                      <TableCell>Other</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>Mileage</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowData.ConnectedClaimDetails !== undefined &&
                      <TableRow key={1}>
                        <TableCell>{rowData.ConnectedClaimDetails.DealerNumber}</TableCell>
                        <TableCell component="th" scope="row">
                          {upgradedDate(rowData.ConnectedClaimDetails.DateOfClaimEpoch)}
                        </TableCell>
                        <TableCell>{rowData.ConnectedClaimDetails.ClaimPartsAmount}</TableCell>
                        <TableCell>{rowData.ConnectedClaimDetails.ClaimLabourAmount}</TableCell>
                        <TableCell>{rowData.ConnectedClaimDetails.ClaimOtherAmount}</TableCell>
                        <TableCell>{rowData.ConnectedClaimDetails.ClaimTotalAmount}</TableCell>
                        <TableCell>
                          {Math.round(
                              rowData.ConnectedClaimDetails.ClaimDistanceKM/1.609,
                          )}
                        </TableCell>
                        <TableCell>{rowData.ConnectedClaimDetails.ServiceType}</TableCell>
                        <TableCell>
                          <Button variant='contained' color='primary'
                            onClick={() => {
                              unLinkClaim(rowData);
                            }}>
                            Unlink
                          </Button>
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
