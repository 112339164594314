// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { upgradedDateWithTime, sortArrayByProperty, getCurrentUser,
  checkReadOnlyGroup } from '../Libs/fileHelper';
import { API } from 'aws-amplify';
import { createAndCompletePendingItem, updatePendingItem } from '../Libs/apiHelper';
import { makeStyles } from '@mui/styles';
// Components
import Button from '@mui/material/Button';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import FeedbackIcon from '@mui/icons-material/Feedback';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import config from '../Libs/Config';
import { currentStage, getCurrentEnvironment, isVanEnvironment } from '../Libs/StageConfig';
// Resources

const useStyles = makeStyles((theme) => ({
  containerStyle: {
    textAlign: 'center',
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));

export default function CustomerPendingInteraction(props) {
  const classes = useStyles();
  const sxClasses = {
    buttonStyle: {
      margin: 1,
      width: '200px',
    },
    GridStyle: {
      border: '1px solid',
      borderColor: '#000000',
      padding: 1,
      paddingLeft: 1,
      paddingRight: 1,
      backgroundColor: 'palette.primary.backgroundFore',
    },
    disabledTextStyles: {
      marginTop: '6px',
      width: '100%',
      color: '#000000',
    },
    datePickerStyle: {
      marginTop: '12px',
      marginLeft: '-8px',
      width: '100%',
      color: '#000000',
    },
    actionCodeStyle: {
      marginTop: '6px',
      width: '100%',
    },
    referenceStyle: {
      marginTop: '10px',
      width: '100%',
    },
    priorityTrueButton: {
      color: '#FF0000',
    },
  };
  const [selectedPendingReferenceHelperText, setSelectedPendingReferenceHelperText] = React.useState('');
  const [selectedPendingActionCodeHelperText, setSelectedPendingActionCodeHelperText] = React.useState('');
  const priorityChangeInProgress = React.useRef(false);

  CustomerPendingInteraction.propTypes = {
    setSelected: PropTypes.func.isRequired,
    setPendingDisabled: PropTypes.func.isRequired,
    pendingDisabled: PropTypes.bool.isRequired,
    pendingData: PropTypes.array.isRequired,
    setPendingData: PropTypes.func.isRequired,
    SelectedData: PropTypes.object.isRequired,
    currUser: PropTypes.object.isRequired,
    scheduleData: PropTypes.object.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
    actionCodeLookup: PropTypes.array.isRequired,
    hotTerminationPressed: PropTypes.func.isRequired,
    deceasedProcessPressed: PropTypes.func.isRequired,
    resendEmail: PropTypes.func.isRequired,
    mileageReviewButtonPressed: PropTypes.func.isRequired,
  };


  function newButtonPressed() {
    // Setup New position
    props.setSelected({
      ScheduleID: props.scheduleData.ScheduleID,
      Status: 'New',
      ActionType: ' ',
      CreatedDate: Date.now(),
      Reference: '',
      CreatedBy: getCurrentUser(props.currUser),
      CompletedDate: '',
      CompletedBy: '',
      ScheduledDate: null,
      CustomerCofico: props.scheduleData.CustomerCofico,
    });
    props.setPendingDisabled(false);
  }

  function editButtonPressed() {
    if (props.SelectedData.Status === 'History') {
      props.setSnackbarProps({ open: true,
        message: 'You cannot edit history items.',
        severity: 'info' });
      return;
    }
    if (props.SelectedData.Status !== '') {
      props.setPendingDisabled(false);
    }
  }

  function deleteButtonPressed() {
    if (props.SelectedData.CreatedBy !== getCurrentUser(props.currUser)) {
      props.setSnackbarProps({ open: true,
        message: 'You can only delete items you have created.',
        severity: 'info' });
      return;
    }

    const body = {
      ID: props.SelectedData.ID,
      userId: getCurrentUser(props.currUser),
    };

    API.post(config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory.NAME, '/delete', {
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      if (result.statusCode !== 200 & result.body !== undefined) {
        props.setSnackbarProps({ open: true,
          message: 'Something went wrong.',
          severity: 'error' });
      } else {
        const index = props.pendingData.findIndex((penditem) => penditem.ID === props.SelectedData.ID);
        const pendingDataCopy = [...props.pendingData];
        pendingDataCopy[index] = {
          ...props.SelectedData,
          DeletedDate: Date.now(),
          DeletedBy: getCurrentUser(props.currUser),
        };
        props.setPendingData(pendingDataCopy);
        props.setSelected({
          ID: '',
          ScheduleID: '',
          Status: '',
          ActionType: '',
          CreatedDate: '',
          Reference: '',
          CreatedBy: '',
          CompletedDate: '',
          CompletedBy: '',
          ScheduledDate: null,
          CustomerCofico: null,
        });
        props.setSnackbarProps({ open: true,
          message: 'Record Deleted',
          severity: 'success' });
      }
    }).catch( (error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    });
  }

  async function completeButtonPressed() {
    if (props.SelectedData.Reference === '') {
      setSelectedPendingReferenceHelperText('You must provide a reference');
      if (props.SelectedData.ActionType === '' || props.SelectedData.ActionType === ' ') {
        setSelectedPendingActionCodeHelperText('You must select an action code');
      }
      return;
    }
    if (props.SelectedData.ActionType === '' || props.SelectedData.ActionType === ' ') {
      setSelectedPendingActionCodeHelperText('You must select an action code');
      return;
    }
    if (props.SelectedData.ActionType === 'DEC' && props.SelectedData.decFields !== undefined) {
      if (props.SelectedData.decFields.accountBalance !== undefined &&
    props.SelectedData.decFields.bifRecieved !== undefined &&
    props.SelectedData.decFields.deathCertificateRecieved !== undefined &&
    props.SelectedData.decFields.refundActioned !== undefined &&
    props.SelectedData.decFields.accountClear !== undefined &&
    props.SelectedData.decFields.closedCofico !== undefined &&
    props.SelectedData.decFields.intentionRecieved !== undefined) {
        // DecFields Complete
      } else {
        if (props.SelectedData.Reference.toLowerCase().includes('no further action')) {
          // Dec Reference override
        } else {
          props.setSnackbarProps({ open: true,
            message: 'Not all Dec actions have been completed. To complete this record please ' +
              'ensure the reference has "No Further Action" as part of the note.',
            severity: 'info' });
          return;
        }
      }
    }
    props.setSelected({
      ...props.SelectedData,
      Status: 'History',
      CompletedDate: Date.now(),
      CompletedBy: getCurrentUser(props.currUser),
    });
    if (props.SelectedData.Status === 'New') {
      const body = {
        ScheduleID: props.scheduleData.ScheduleID,
        Status: 'History',
        ActionType: props.SelectedData.ActionType,
        CustomerName: props.scheduleData.CustomerName,
        ScheduleStatus: props.scheduleData.Status,
        Reference: props.SelectedData.Reference,
        CustomerID: props.scheduleData.CustomerID,
        ScheduledDate: props.SelectedData.ScheduledDate,
        CustomerCofico: props.SelectedData.CustomerCofico,
      };

      const response = await createAndCompletePendingItem(body, getCurrentUser(props.currUser),
          props.pendingData, props.setPendingData, props.setSnackbarProps, props.SelectedData,
          props.setSelected);
      if (response.status === 200) {
        props.setPendingDisabled(true);
      }
    } else {
      const body = {
        ID: props.SelectedData.ID,
        ActionType: props.SelectedData.ActionType,
        ScheduleStatus: props.scheduleData.Status,
        ScheduleSuspended: props.scheduleData.ScheduleSuspended,
        Reference: props.SelectedData.Reference,
        ScheduledDate: props.SelectedData.ScheduledDate,
        userId: getCurrentUser(props.currUser),
      };
      API.post(config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory.NAME,
          '/completepending', {
            body: body,
            headers: {
              'Content-Type': 'application/json',
            },
          }).then((result) => {
        if (result.statusCode !== 200 & result.body !== undefined) {
          props.setSnackbarProps({ open: true,
            message: 'Something went wrong: ' + result.body,
            severity: 'error' });
        } else {
          const index = props.pendingData.findIndex((penditem) => penditem.ID === props.SelectedData.ID);
          const pendingDataCopy = [...props.pendingData];
          pendingDataCopy[index] = {
            ...props.SelectedData,
            Status: 'History',
            CompletedDate: Date.now(),
            CompletedBy: getCurrentUser(props.currUser),
          };
          props.setPendingData(pendingDataCopy);
          props.setPendingDisabled(true);
        }
      }).catch( (error) => {
        props.setSnackbarProps({ open: true,
          message: 'Something went wrong: ' + error,
          severity: 'error' });
      });
    }
  }

  function saveButtonPressed() {
    if (props.SelectedData.Reference === '') {
      setSelectedPendingReferenceHelperText('You must provide a reference');
      if (props.SelectedData.ActionType === '' || props.SelectedData.ActionType === ' ') {
        setSelectedPendingActionCodeHelperText('You must select an action code');
      }
      return;
    }
    if (props.SelectedData.ActionType === '' || props.SelectedData.ActionType === ' ') {
      setSelectedPendingActionCodeHelperText('You must select an action code');
      return;
    }
    if (props.SelectedData.Status === 'New') {
      // Create New Record
      const body = {
        ScheduleID: props.scheduleData.ScheduleID,
        Status: 'Pending',
        ActionType: props.SelectedData.ActionType,
        CustomerName: props.scheduleData.CustomerName,
        ScheduleStatus: props.scheduleData.Status,
        Reference: props.SelectedData.Reference,
        CustomerID: props.scheduleData.CustomerID,
        userId: getCurrentUser(props.currUser),
        ScheduledDate: props.SelectedData.ScheduledDate,
        CustomerCofico: props.SelectedData.CustomerCofico,
      };
      API.post(config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory.NAME, '/postnew', {
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((result) => {
        if (result.statusCode !== 200 & result.body !== undefined) {
          props.setSnackbarProps({ open: true,
            message: 'Something went wrong: ' + result.body,
            severity: 'error' });
        } else {
          props.setSelected({
            ...props.SelectedData,
            ID: result.ID,
            Status: 'Pending',
          });
          props.setPendingData([
            ...props.pendingData,
            { ID: result.ID,
              Status: 'Pending',
              ActionType: props.SelectedData.ActionType,
              CreatedDate: props.SelectedData.CreatedDate,
              Reference: props.SelectedData.Reference,
              ScheduleID: props.SelectedData.ScheduleID,
              CreatedBy: getCurrentUser(props.currUser),
              CompletedDate: '',
              CompletedBy: '',
              ScheduledDate: props.SelectedData.ScheduledDate,
            },
          ]);
          props.setPendingDisabled(true);
        }
      }).catch( (error) => {
        props.setSnackbarProps({ open: true,
          message: 'Something went wrong: ' + error,
          severity: 'error' });
      });
    } else {
      // Update Existing Record
      const body = {
        ID: props.SelectedData.ID,
        ActionType: props.SelectedData.ActionType,
        ScheduleStatus: props.scheduleData.Status,
        ScheduleSuspended: props.scheduleData.ScheduleSuspended,
        Reference: props.SelectedData.Reference,
        ScheduledDate: props.SelectedData.ScheduledDate,
        userId: getCurrentUser(props.currUser),
      };
      API.post(config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory.NAME, '/update', {
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((result) => {
        if (result.statusCode !== 200) {
          props.setSnackbarProps({ open: true,
            message: 'Something went wrong: ',
            severity: 'error' });
        } else {
          const index = props.pendingData.findIndex((penditem) => penditem.ID === props.SelectedData.ID);
          const pendingDataCopy = [...props.pendingData];
          pendingDataCopy[index] = {
            ID: props.SelectedData.ID,
            Status: props.SelectedData.Status,
            ActionType: props.SelectedData.ActionType,
            CreatedDate: props.SelectedData.CreatedDate,
            Reference: props.SelectedData.Reference,
            ScheduleID: props.SelectedData.ScheduleID,
            CreatedBy: props.SelectedData.CreatedBy,
            CompletedDate: props.SelectedData.CompletedDate,
            CompletedBy: props.SelectedData.CompletedBy,
            ScheduledDate: props.SelectedData.ScheduledDate,
          };
          props.setPendingData(pendingDataCopy);
          props.setPendingDisabled(true);
        }
      }).catch( (error) => {
        props.setSnackbarProps({ open: true,
          message: 'Something went wrong: ' + error,
          severity: 'error' });
      });
    }
  }

  function cancelButtonPressed() {
    props.setPendingDisabled(true);
    setSelectedPendingReferenceHelperText('');
    setSelectedPendingActionCodeHelperText('');
  }

  function handleChangeRef(event) {
    if (!props.pendingDisabled) {
      setSelectedPendingReferenceHelperText('');
      props.setSelected({
        ...props.SelectedData,
        Reference: event.target.value,
      });
    }
  }

  function handleChangeActionCode(event) {
    if (!props.pendingDisabled) {
      setSelectedPendingActionCodeHelperText('');
      props.setSelected({
        ...props.SelectedData,
        ActionType: event.target.value,
      });
    }
  }

  function handleChangeScheduled(date) {
    if (!props.pendingDisabled) {
      props.setSelected({
        ...props.SelectedData,
        ScheduledDate: date.getTime(),
      });
    }
  }

  function blanksToSpace(value) {
    if (value === undefined || value === '' || value === null) {
      return ' ';
    } else {
      return value;
    }
  }

  const handleActionCodes = () => {
    let actionCodeArrayWithSpace = [...props.actionCodeLookup,
      { ActionType: ' ', ActionDescription: '' }];

    if ((actionCodeArrayWithSpace.findIndex(
        (actionCodeInfo) => actionCodeInfo.ActionType === props.SelectedData.ActionType) === -1)) {
      actionCodeArrayWithSpace = [...actionCodeArrayWithSpace,
        { ActionType: props.SelectedData.ActionType,
          ActionDescription: 'Unknown Code: ' + props.SelectedData.ActionType }];
    }

    if (props.pendingDisabled) {
      return sortArrayByProperty(actionCodeArrayWithSpace, 'ActionType');
    } else {
      return sortArrayByProperty(actionCodeArrayWithSpace.filter((code) =>
        code.SystemCode !== '1' || code.ActionType === props.SelectedData.ActionType), 'ActionType');
    }
  };

  const disabledStyle = () => {
    if (props.pendingDisabled) {
      return ({
        style: {
          opacity: 0.8,
        },
      });
    } else {
      return ({});
    }
  };

  const flipValue = (value) => {
    if (value) return false;
    return true;
  };

  const handlePriorityChange = async () => {
    if (priorityChangeInProgress.current) {
      props.setSnackbarProps({ open: true,
        message: 'Priority Change in Progress. Please Wait.',
        severity: 'info' });
      return;
    }
    priorityChangeInProgress.current = true;
    const newValue = flipValue(props.SelectedData.Priority);
    const body = {
      ID: props.SelectedData.ID,
      Priority: newValue,
    };
    const result = await updatePendingItem(body, props.setSnackbarProps);
    if (result.status === 200) {
      props.setSelected({
        ...props.SelectedData,
        Priority: newValue,
      });
      const index = props.pendingData.findIndex((penditem) => penditem.ID === props.SelectedData.ID);
      const pendingDataCopy = [...props.pendingData];
      pendingDataCopy[index] = {
        ...props.SelectedData,
        Priority: newValue,
      };
      props.setPendingData(pendingDataCopy);
      props.setSnackbarProps({ open: true,
        message: (newValue ? 'Item set as Priority.' : 'Item set to normal.'),
        severity: 'success' });
    }
    setTimeout(function() {
      priorityChangeInProgress.current = false;
      return;
    }, 1000);
  };

  return (
    <div className={classes.containerStyle} >
      <Grid container spacing={0} component={Paper} sx={sxClasses.GridStyle}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={7}>
              <TextField value={blanksToSpace(props.SelectedData.Status)}
                sx={sxClasses.disabledTextStyles}
                label="Status"
                variant='standard'
                inputProps={{
                  style: {
                    padding: 5,
                    color: '#000000',
                  },
                }}
                id='txtStatus'/>
            </Grid>
            <Grid item xs={5}>
              <TextField value={blanksToSpace(props.SelectedData.ScheduleID)}
                sx={sxClasses.disabledTextStyles}
                label="Schedule ID"
                variant='standard'
                inputProps={{
                  style: {
                    padding: 5,
                    color: '#000000',
                  },
                }}
                id='txtSchedule'/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={7}>
              <TextField
                value={blanksToSpace(upgradedDateWithTime(props.SelectedData.CreatedDate))}
                sx={sxClasses.disabledTextStyles}
                label="Created Date"
                variant='standard'
                inputProps={{
                  style: {
                    padding: 5,
                    color: '#000000',
                  },
                }}
                id='txtCreated'/>
            </Grid>
            <Grid item xs={5}>
              <TextField
                sx={sxClasses.disabledTextStyles}
                label="Created By"
                variant='standard'
                inputProps={{
                  style: {
                    padding: 5,
                    color: '#000000',
                  },
                }}
                value={blanksToSpace(props.SelectedData.CreatedBy)}
                id='txtCreatedBy'/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={7}>
              <TextField
                value={blanksToSpace(upgradedDateWithTime(props.SelectedData.CompletedDate))}
                sx={sxClasses.disabledTextStyles}
                label="Completed Date"
                variant='standard'
                inputProps={{
                  style: {
                    padding: 5,
                    color: '#000000',
                  },
                }}
                id='txtCompleted'/>
            </Grid>
            <Grid item xs={5}>
              <TextField
                value={blanksToSpace(props.SelectedData.CompletedBy)}
                sx={sxClasses.disabledTextStyles}
                label="Completed By"
                variant='standard'
                inputProps={{
                  style: {
                    padding: 5,
                    color: '#000000',
                  },
                }}
                id='txtCompletedBy'/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={7}>
              <TextField select disabled={props.pendingDisabled}
                sx={sxClasses.actionCodeStyle}
                label="Action Code"
                variant='standard'
                inputProps={{
                  style: {
                    padding: 5,
                    color: '#000000',
                  },
                }}
                value={blanksToSpace(props.SelectedData.ActionType)}
                onChange={handleChangeActionCode}
                id='txtActionCode'>
                {handleActionCodes().map((codes, index) => (
                  <MenuItem key={index} value={codes.ActionType}>
                    {codes.ActionType} - {codes.ActionDescription}
                  </MenuItem>
                ))}
              </TextField>
              <div className={classes.errorText} id='action-error-text'>
                {selectedPendingActionCodeHelperText}
              </div>
            </Grid>
            <Grid item xs={5}>
              <DesktopDatePicker disabled={props.pendingDisabled}
                margin="normal"
                id="txtScheduled"
                label="Scheduled Date"
                inputFormat="dd/MM/yyyy"
                value={props.SelectedData.ScheduledDate}
                onChange={handleChangeScheduled}
                KeyboardButtonProps={{
                  'aria-label': 'Scheduled Date',
                }}
                renderInput={(params) => <TextField {...params} sx={sxClasses.datePickerStyle}/>}
                inputProps={{
                  style: {
                    padding: 10,
                    color: '#000000',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            {(props.SelectedData.ActionType === 'HOT' && props.SelectedData.Status === 'Pending') ?
            <Grid item xs={12}>
              <Button sx={sxClasses.buttonStyle} variant='contained' color='primary' onClick={() => {
                props.hotTerminationPressed();
              }}>
                Hostile Termination
              </Button>
            </Grid> :
            null}
            {(props.SelectedData.ActionType === 'MRV' && props.SelectedData.Status !== 'Completed' &&
              !props.pendingDisabled && isVanEnvironment()) ?
            <Grid item xs={12}>
              <Button className={classes.buttonStyle} variant='contained' color='primary' onClick={() => {
                props.mileageReviewButtonPressed();
              }}>
                Mileage Review
              </Button>
            </Grid> :
            null}
            {(props.SelectedData.ActionType === 'DEC' && props.SelectedData.Status !== 'New') ?
            <Grid item xs={12}>
              <Button sx={sxClasses.buttonStyle} variant='contained' color='primary' onClick={() => {
                props.deceasedProcessPressed();
              }}>
                Deceased Process
              </Button>
            </Grid> :
            null}
            {(props.SelectedData.ActionType === 'EML' && props.SelectedData.Reference.length > 75) ?
            <Grid item xs={12}>
              <Button sx={sxClasses.buttonStyle} variant='contained' color='primary' onClick={() => {
                props.resendEmail();
              }}>
                Resend Email
              </Button>
            </Grid> :
            null}
            <Grid item xs={12}>
              <TextField
                value={blanksToSpace(props.SelectedData.Reference)}
                sx={sxClasses.referenceStyle}
                multiline
                maxRows={12}
                inputProps={disabledStyle()}
                label={'Reference'}
                variant="outlined"
                onChange={handleChangeRef}
                id='txtReference'/>
              <div className={classes.errorText} id='reference-error-text'>
                {selectedPendingReferenceHelperText}
              </div>
            </Grid>
          </Grid>
        </Grid>
        {(props.SelectedData.Status !== 'New' && props.SelectedData.Status !== '') &&
          <IconButton sx={{ position: 'absolute', marginTop: '-12px', right: '22px' }}
            onClick={handlePriorityChange}>
            <FeedbackIcon sx={(props.SelectedData.Priority) ?
                sxClasses.priorityTrueButton : null} />
          </IconButton>
        }
      </Grid>
      {props.pendingDisabled ?
      <React.Fragment>
        {!checkReadOnlyGroup(props.currUser) &&
        <React.Fragment>
          <Button sx={sxClasses.buttonStyle} variant='contained' color='primary' onClick={() => {
            newButtonPressed();
          }}>
            New
          </Button>
          <Button sx={sxClasses.buttonStyle} variant='contained' color='primary' onClick={() => {
            editButtonPressed();
          }}>
            Edit
          </Button>
          <Button sx={sxClasses.buttonStyle} variant='contained' color='primary' onClick={() => {
            deleteButtonPressed();
          }}>
            Delete
          </Button>
        </React.Fragment>
        }
      </React.Fragment> :
    <React.Fragment>
      <Button sx={sxClasses.buttonStyle} variant='contained' color='primary' onClick={() => {
        completeButtonPressed();
      }}>
        Complete Record
      </Button>
      <Button sx={sxClasses.buttonStyle} variant='contained' color='primary' onClick={() => {
        saveButtonPressed();
      }}>
        Save
      </Button>
      <Button sx={sxClasses.buttonStyle} variant='contained' color='primary' onClick={() => {
        cancelButtonPressed();
      }}>
        Cancel
      </Button>
    </React.Fragment>}
    </div>
  );
}
