// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Resources

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    width: '500px',
    margin: theme.spacing(1),
  },
  dialogTitle: {
    paddingTop: theme.spacing(1),
    textAlign: 'center',
  },
}));

export default function IdlePopup({ isIdle, idleTimeRemaining }) {
  const classes = useStyles();

  IdlePopup.propTypes = {
    isIdle: PropTypes.bool.isRequired,
    idleTimeRemaining: PropTypes.number.isRequired,
  };

  return (
    <Dialog open={isIdle} aria-labelledby="form-dialog-title" >
      <DialogTitle className={classes.dialogTitle} id="form-dialog-title">Idle Warning</DialogTitle>
      <DialogContent className={classes.dialogContainer}>
        Warning: You have been idle for 15 minutes.<br></br><br></br>
        You will be signed out in {idleTimeRemaining} seconds.
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  );
}
