// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
// Components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import ExitButton from '../Components/StyledMUIComponents/ExitButton';
import Paper from '@mui/material/Paper';
import TitlePaper from '../Components/StyledMUIComponents/TitlePaper';
import { getResultsForActionCode,
  getActionCodesWithCount, getListOfApprovedUsers } from '../Libs/apiHelper';
import config from '../Libs/Config';
import { currentStage, getCurrentEnvironment } from '../Libs/StageConfig';
import LoadingPopup from '../Components/GeneralComponents/GeneralLoadingPopup';
// Resources

const useStyles = makeStyles((theme) => ({
  main: {
    'alignItems': 'center',
    'textAlign': 'center',
  },
  contentSections: {
    'marginTop': '50px',
    'height': '70vh',
    'border': '1px solid',
    'borderColor': '#000000',
    'padding': theme.spacing(3),
    'textAlign': 'center',
    'overflow': 'scroll',
  },
  cellStyle: {
    padding: '0px',
  },
  titleFields: {
    'position': 'absolute',
    'marginTop': '32px',
    'marginLeft': '25px',
  },
  tableStyle: {
    height: '125px',
    width: '20vw',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    border: '1px solid',
    borderColor: '#000000',
    backgroundColor: theme.palette.primary.backgroundFore,
  },
  tablePadding: {
    paddingRight: theme.spacing(2),
  },
}));

export default function WorkAssignment(props) {
  const classes = useStyles();
  const sxClasses = {
    buttonStyle: {
      width: '25%',
      margin: 1,
    },
  };
  const navigate = useNavigate();

  WorkAssignment.propTypes = {
    setSnackbarProps: PropTypes.func.isRequired,
    setActionCodeLookup: PropTypes.func.isRequired,
  };

  const [checked, setChecked] = React.useState([]);
  const [actionTypeChecked, setActionTypeChecked] = React.useState([]);
  const [userData, setUserData] = React.useState([]);
  const [actionCodeData, setActionCodeData] = React.useState([]);
  const [assignInProgress, setAssignInProgress] = React.useState(false);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleToggleActionCode = (value) => () => {
    const currentIndex = actionTypeChecked.indexOf(value);
    const newChecked = [...actionTypeChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setActionTypeChecked(newChecked);
  };

  const refreshUserData = async () => {
    const userData = await getUserData();
    setUserData(userData);
  };

  const getUserData = async (nextToken) => {
    const response = await getListOfApprovedUsers(props.setSnackbarProps, nextToken);
    if (nextToken && (nextToken === response.nextToken)) {
      props.setSnackbarProps({ open: true,
        message: 'Issue getting user list. Some users may be missing.',
        severity: 'error' });
      return [];
    }
    if (response.status === 200) {
      if (response.nextToken) {
        const additionalData = await getUserData(response.nextToken);
        return [...additionalData, ...response.result];
      } else {
        return response.result;
      }
    } else {
      return [];
    }
  };

  React.useEffect(() => {
    refreshUserData();
    refreshActionCodeData();
  }, [] );

  const refreshActionCodeData = async () => {
    const response = await getActionCodesWithCount( props.setSnackbarProps, props.setActionCodeLookup);
    if (response.status === 200) {
      setActionCodeData(response.result);
    }
  };

  const assignTasksClicked = async () => {
    const userCount = checked.length;
    if (userCount === 0) {
      props.setSnackbarProps({ open: true,
        message: 'Please select at least one user',
        severity: 'info' });
      return;
    }
    if (actionTypeChecked.length === 0) {
      props.setSnackbarProps({ open: true,
        message: 'Please select at least one action type',
        severity: 'info' });
      return;
    }
    let completedTaskCount = 0;
    let pendingTaskCount = 0;
    let totalTaskCount = 0;
    let errorCount = 0;
    // Get list of selected Tasks
    setAssignInProgress(true);
    for (const task of actionTypeChecked) {
      const response = await getResultsForActionCode(task);
      if (response.status === 200) {
        totalTaskCount = totalTaskCount + response.result.length;
        for (const pendItem of response.result) {
          // perform update on pending item
          const body = {
            ID: pendItem.ID,
            AssignedTo: checked[pendingTaskCount % userCount],
          };
          pendingTaskCount ++;
          API.post(
              config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory?.NAME,
              '/assign', {
                body: body,
                headers: {
                  'Content-Type': 'application/json',
                },
              }).then((result) => {
            completedTaskCount ++;
            if (result.statusCode !== 200 || result.body !== '{}') {
              errorCount ++;
            }
            reportResults(errorCount, completedTaskCount, totalTaskCount);
            return;
          }).catch( (error) => {
            completedTaskCount ++;
            errorCount ++;
            reportResults(errorCount, completedTaskCount, totalTaskCount);
            return;
          });
        }
      } else {
        setAssignInProgress(false);
        props.setSnackbarProps({ open: true,
          message: 'Something went wrong: ' + response.status,
          severity: 'error' });
        return;
      }
    }
  };

  const reportResults = (errorCount, completedTaskCount, totalTaskCount) => {
    if (completedTaskCount < totalTaskCount) {
      return;
    }
    setAssignInProgress(false);
    if (errorCount === 0) {
      props.setSnackbarProps({ open: true,
        message: completedTaskCount + ' items assigned successfully.',
        severity: 'success' });
      return;
    } else {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + errorCount + ' items failed.',
        severity: 'error' });
      return;
    }
  };

  return (
    <Container component='main' className={classes.main}>
      <Grid container spacing={3}>
        <Grid item xs={6} >
          <TitlePaper className={classes.titleFields} variant='filled'>
            Users
          </TitlePaper>
          <Paper className={classes.contentSections} >
            <List>
              {userData.map((value) => {
                const labelId = `checkbox-list-label-${value}`;

                return (
                  <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={value} />
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={6} >
          <TitlePaper className={classes.titleFields} variant='filled'>
            Codes
          </TitlePaper>
          <Paper className={classes.contentSections} >
            <List>
              {actionCodeData.map((value) => {
                const labelId = `checkbox-list-label-${value}`;

                return (
                  <ListItem key={value.actionCode} role={undefined} dense button
                    onClick={handleToggleActionCode(value.actionCode)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={actionTypeChecked.indexOf(value.actionCode) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={
                      value.actionCode + ' [' + value.actionTotal + '] - ' + value.actionDesc
                    } />
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </Grid>
      </Grid>
      <Button variant='contained' color='primary' sx={sxClasses.buttonStyle}
        onClick={assignTasksClicked}>
        Assign Tasks
      </Button>
      <ExitButton variant='contained' color='primary' onClick={() => {
        navigate(-1);
      }}>
        Back
      </ExitButton>
      {assignInProgress &&
        <LoadingPopup
          loadingText={'Assigning....'} /> }
    </Container>
  );
}
