// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
// Components
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CustomerDetails from '../Components/GeneralComponents/CustomerDetails';
import ScheduleDetails from '../Components/GeneralComponents/ScheduleDetails';
import CustomerAgedDebt from '../Components/GeneralComponents/CustomerAgedDebt';
import CustomerClaimsData from '../Components/GeneralComponents/CustomerClaimsData';
import TerminationCalculation from '../FunctionalComponents/TerminationCalculation';
import EmailPopup from '../FunctionalComponents/EmailPopup';
import TerminationsRetailerNameEntry from '../Components/TerminationsRetailerNameEntry';
import { failsafeLocationParameter } from '../Libs/fileHelper';
import { emptyScheduleData } from '../Libs/emptyDataStructures';
// Resources

const useStyles = makeStyles((theme) => ({
  contentSections: {
    'border': '1px solid',
    'borderColor': '#000000',
    'padding': theme.spacing(3),
    'textAlign': 'center',
  },
}));

export default function TerminationsPage(props) {
  const classes = useStyles();
  const sxClasses = {
    mainStyle: {
      width: '100%',
      maxWidth: '100%',
      padding: 1,
    },
  };
  const location = useLocation();
  const navigate = useNavigate();

  TerminationsPage.propTypes = {
    scheduleData: PropTypes.array.isRequired,
    actionCodeLookup: PropTypes.array.isRequired,
    customerData: PropTypes.object.isRequired,
    pendingData: PropTypes.array.isRequired,
    currUser: PropTypes.object.isRequired,
    setScheduleData: PropTypes.func.isRequired,
    setCustomerData: PropTypes.func.isRequired,
    setPendingData: PropTypes.func.isRequired,
    setActionCodeLookup: PropTypes.func.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
    emailConfigData: PropTypes.object.isRequired,
  };

  const validatorHiddenDefault = () => {
    if (props.scheduleData.length === 0 ) {
      return false;
    } else {
      return (parseInt(props.scheduleData[scheduleIndex].ChargeAmount) !== 0);
    }
  };
  const scheduleIndex = failsafeLocationParameter(location, '');
  const [expandAged, setExpandAged] = React.useState(true);
  const [validatorData, setValidatorData] = React.useState({
    hidden: validatorHiddenDefault(),
    retailerName: 'Mercedes Benz of',
  });

  const selectedSchedule = () => {
    if (props.scheduleData.length === 0 ) {
      return emptyScheduleData;
    } else {
      return props.scheduleData[scheduleIndex];
    }
  };

  const handleBlankAged = () => {
    if (props.scheduleData[scheduleIndex] === undefined) {
      return [];
    } else if (props.scheduleData[scheduleIndex].AgedData === undefined ) {
      return [];
    } else {
      return props.scheduleData[scheduleIndex].AgedData;
    }
  };

  const handleBlankClaims = () => {
    if (props.scheduleData[scheduleIndex] === undefined) {
      return [];
    } else if (props.scheduleData[scheduleIndex].ClaimsData === undefined ) {
      return [];
    } else {
      return props.scheduleData[scheduleIndex].ClaimsData;
    }
  };

  const handleExpandedChangeAged = () => {
    if (expandAged) {
      setExpandAged(false);
    } else {
      setExpandAged(true);
    }
  };

  // Email Popup Functions
  const [sendingEmail, setSendingEmail] = React.useState(false);
  const [emailStates, setEmailStates] = React.useState({
    emailSentTo: props.customerData.CustomerEmail,
    emailSentFrom: props.emailConfigData.defaultMailbox,
    emailSubject: '',
    emailBody: '',
    sendAsPDF: false,
    sendAction: ()=>{},
  });
  const sendEmailFromCustomerDetails = () => {
    if (emailStates.emailBody === '') {
      setEmailStates({
        emailSentTo: props.customerData.CustomerEmail,
        emailSentFrom: props.emailConfigData.defaultMailbox,
        emailSubject: 'Mercedes-Benz Service Contract - ' + selectedSchedule().ScheduleID + ' - ' +
          selectedSchedule().Registration,
        emailBody: '\n\n' + props.emailConfigData.signature,
        sendAsPDF: false,
        sendAction: ()=>{},
      });
    }
    setSendingEmail(true);
  };

  return (
    <React.Fragment>
      {props.scheduleData.length === 0 ?
        <React.Fragment>
          <Typography variant='subtitle1'>Sorry, something went wrong!</Typography>
          <Button variant='contained' color='primary' onClick={() => {
            navigate(-1);
          }}>
            Go Back
          </Button>
        </React.Fragment> :
        <Container component='main' sx={sxClasses.mainStyle} maxWidth={false} disableGutters={true}>
          <Grid container spacing={1}>
            <Grid item xs={6} >
              <Grid container spacing={0}>
                <Grid item xs={11}>
                  <CustomerDetails customerData={props.customerData}
                    scheduleData={selectedSchedule()}
                    sendEmailFromCustomerDetails={sendEmailFromCustomerDetails}
                    terminationPage={true} />
                </Grid>
                <Grid item xs={12}>
                  <TerminationCalculation
                    customerData={props.customerData}
                    scheduleData={selectedSchedule()}
                    claimsData={handleBlankClaims()}
                    agedData={handleBlankAged()}
                    validatorProps={{ validatorData, setValidatorData }}
                    setSnackbarProps={props.setSnackbarProps}
                    currUser={props.currUser}
                    pendingData={props.pendingData}
                    setPendingData={props.setPendingData}
                    hotValue={failsafeLocationParameter(location, 'HotTerm')}
                    setSendingEmail={setSendingEmail}
                    emailStates={emailStates}
                    setEmailStates={setEmailStates}
                    emailConfigData={props.emailConfigData}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className={classes.contentSections} >
                    <ScheduleDetails scheduleData={selectedSchedule()}/>
                  </div>
                </Grid>
                <Grid item xs={12} >
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <CustomerAgedDebt agedData={handleBlankAged()} expanded={expandAged}
                            handleExpandedChange={handleExpandedChangeAged} sendingEmail={false}/>
                        </Grid>
                        <Grid item xs= {6}>
                          <CustomerClaimsData claimsData={handleBlankClaims()} expanded={expandAged}
                            handleExpandedChange={handleExpandedChangeAged}/>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: 1 }}>
                      <TerminationsRetailerNameEntry
                        validatorData={validatorData}
                        setValidatorData={setValidatorData}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <EmailPopup
            popupOpen={sendingEmail}
            setPopupOpen={setSendingEmail}
            emailStates={emailStates}
            setEmailStates={setEmailStates}
            setSnackbarProps={props.setSnackbarProps}
            customerData={props.customerData}
            scheduleData={selectedSchedule()}
            currUser={props.currUser}
            emailConfigData={props.emailConfigData}/>
        </Container>
      }
    </React.Fragment>
  );
}
