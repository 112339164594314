// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Components
import Grid from '@mui/material/Grid';
import ExitButton from '../Components/StyledMUIComponents/ExitButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
// Resources

const useStyles = makeStyles((theme) => ({
  popupBackgroundStyle: {
    backgroundColor: '#eeeeee',
    position: 'absolute',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    opacity: 0.5,
  },
  popupContainerStyle: {
    position: 'absolute',
    padding: theme.spacing(1),
    backgroundColor: 'rgba(255, 255, 255, 1)',
    width: '80vw',
    height: '80vh',
    margin: 'auto',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    filter: 'drop-shadow(2px 4px 6px black)',
  },
  tableStyle: {
    height: '70vh',
  },
  cellStyle: {
    textAlign: 'center',
    alignItems: 'center',
  },
  searchStyle: {
    textAlign: 'right',
    alignItems: 'right',
  },
}));

export default function SearchPopup({ setShowSearchPopup, orderBy, order,
  filterValue, handleFilterChange, sortHandler, filteredSortedData, navigateToCustomerFromPopup }) {
  const classes = useStyles();

  SearchPopup.propTypes = {
    setShowSearchPopup: PropTypes.func.isRequired,
    orderBy: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
    filterValue: PropTypes.string.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    sortHandler: PropTypes.func.isRequired,
    filteredSortedData: PropTypes.func.isRequired,
    navigateToCustomerFromPopup: PropTypes.func.isRequired,
  };

  return (
    <React.Fragment>
      <div component='popup' className={classes.popupBackgroundStyle} />
      <div component='popup' className={classes.popupContainerStyle}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.searchStyle} >
            <TextField value={filterValue}
              label="Filter"
              onChange={handleFilterChange}
              id='txtSearch'/>
          </Grid>
          <Grid item xs={12} >
            <Grid container spacing={0}>
              <TableContainer className={classes.tableStyle}>
                <Table size='small' aria-label='a dense table'>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.cellStyle}><TableSortLabel
                        active={orderBy === 'ScheduleID'}
                        direction={orderBy === 'ScheduleID' ? order : 'asc'}
                        onClick={() => {
                          sortHandler('ScheduleID');
                        }}
                      >Schedule ID</TableSortLabel>
                      </TableCell>
                      <TableCell align='center' className={classes.cellStyle}><TableSortLabel
                        active={orderBy === 'CustomerID'}
                        direction={orderBy === 'CustomerID' ? order : 'asc'}
                        onClick={() => {
                          sortHandler('CustomerID');
                        }}
                      >Customer ID</TableSortLabel>
                      </TableCell>
                      <TableCell align='center' className={classes.cellStyle}><TableSortLabel
                        active={orderBy === 'CustomerName'}
                        direction={orderBy === 'CustomerName' ? order : 'asc'}
                        onClick={() => {
                          sortHandler('CustomerName');
                        }}
                      >Customer Name</TableSortLabel>
                      </TableCell>
                      <TableCell align='center' className={classes.cellStyle}><TableSortLabel
                        active={orderBy === 'Registration'}
                        direction={orderBy === 'Registration' ? order : 'asc'}
                        onClick={() => {
                          sortHandler('Registration');
                        }}
                      >Registration</TableSortLabel>
                      </TableCell>
                      <TableCell align='center' className={classes.cellStyle}><TableSortLabel
                        active={orderBy === 'VIN'}
                        direction={orderBy === 'VIN' ? order : 'asc'}
                        onClick={() => {
                          sortHandler('ChassisNo');
                        }}
                      >VIN</TableSortLabel>
                      </TableCell>
                      <TableCell align='center' className={classes.cellStyle}><TableSortLabel
                        active={orderBy === 'Status'}
                        direction={orderBy === 'Status' ? order : 'asc'}
                        onClick={() => {
                          sortHandler('Status');
                        }}
                      >Status</TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredSortedData().map((row) => (
                      <TableRow key={row.ScheduleID} onClick={()=>{
                        navigateToCustomerFromPopup(row.CustomerID);
                      }}>
                        <TableCell component='th' scope='row'>
                          {row.ScheduleID}</TableCell>
                        <TableCell align='center'>
                          {(row.CustomerID)}</TableCell>
                        <TableCell align='center'>{row.CustomerName}</TableCell>
                        <TableCell align='center'>{row.Registration}</TableCell>
                        <TableCell align='center'>{row.ChassisNo}</TableCell>
                        <TableCell align='center'>{row.Status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        <ExitButton variant='contained' color='primary' onClick={() => {
          setShowSearchPopup(false);
        }}>
            Close
        </ExitButton>
      </div>
    </React.Fragment>
  );
}
