
import { API } from 'aws-amplify';
import * as XLSX from 'xlsx';

export const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

export const getAndExportFlexReportData = async (
    lastEvaluatedKeyScheduleID, lastEvaluatedKeyUUID, setSnackbarProps, previousData) => {
  const response = await API.get('Saltmine-Van-API-Flex', '/reporting', {
    queryStringParameters: {
      ExclusiveStartKeyScheduleID: lastEvaluatedKeyScheduleID,
      ExclusiveStartKeyUUID: lastEvaluatedKeyUUID,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((result) => {
    if (result.body === undefined) {
      return { result: [] };
    } else {
      return { LastEvaluatedKey: result.LastEvaluatedKey, result: JSON.parse(result.body) };
    }
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return { result: [] };
  });

  if (response.result === undefined) {
    return;
  }

  if (response.result.length === 0) {
    return;
  }

  const combinedData = [...previousData, ...response.result];

  if (response.LastEvaluatedKey !== undefined) {
    if (response.LastEvaluatedKey.UUID === lastEvaluatedKeyUUID) {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: Scan Evalulated Key Failure',
        severity: 'error' });
    } else {
      getAndExportFlexReportData(response.LastEvaluatedKey.ScheduleID, response.LastEvaluatedKey.UUID,
          setSnackbarProps, combinedData);
    }
  } else {
    getAdditionalData(combinedData, setSnackbarProps);
  }
};

const getAdditionalData = (flexData, setSnackbarProps) => {
  const flexDataMain = flexData.filter((item) => item.UUID === 'Main');
  let processedCount = 0;
  for (let i=0; i<flexDataMain.length; i++) {
    API.get('Saltmine-Van-API-ICONData', '/getscheduleid', {
      queryStringParameters: {
        ScheduleID: flexDataMain[i].ScheduleID,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      processedCount += 1;
      if (result.body !== undefined) {
        const resultJSON = JSON.parse(result.body);
        flexDataMain[i] = {
          ...flexDataMain[i],
          Registration: resultJSON.Registration,
          VIN: resultJSON.ChassisNo,
          CustomerName: resultJSON.CustomerName,
          Status: resultJSON.Status,
        };
      }
      if (processedCount === flexDataMain.length) {
        exportDataFlex(flexData, flexDataMain);
      }
    }).catch( (error) => {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    });
  }
};

export const exportDataFlex = (flexData, flexDataMain) => {
  const flexServicesA = flexData.filter((item) => item.serviceType === 'A');
  const flexServicesB = flexData.filter((item) => item.serviceType === 'B');
  const flexServicesAuto = flexData.filter((item) => item.autoChecked === true && item.UUID !== 'Main');

  for (let i=0; i<flexDataMain.length; i++) {
    const aUsed = flexServicesA.filter((item) => item.ScheduleID === flexDataMain[i].ScheduleID).length;
    const bUsed = flexServicesB.filter((item) => item.ScheduleID === flexDataMain[i].ScheduleID).length;
    const autoUsed = flexServicesAuto.filter((item) => item.ScheduleID === flexDataMain[i].ScheduleID).length;
    flexDataMain[i] = {
      ScheduleID: flexDataMain[i].ScheduleID,
      Registration: flexDataMain[i].Registration,
      VIN: flexDataMain[i].VIN,
      CustomerName: flexDataMain[i].CustomerName,
      Status: flexDataMain[i].Status,
      TotalServices: flexDataMain[i].TotalServices,
      AServices: flexDataMain[i].TotalServices / 2,
      AServicesUsed: aUsed,
      AServicesRemaining: (flexDataMain[i].TotalServices / 2) - aUsed,
      BServices: flexDataMain[i].TotalServices / 2,
      BServicesUsed: bUsed,
      BServicesRemaining: (flexDataMain[i].TotalServices / 2) - bUsed,
      AutoServices: flexDataMain[i].AutoServices || 0,
      AutoUsed: autoUsed,
      AutoRemaining: (flexDataMain[i].AutoServices || 0) - autoUsed,
    };
  }

  const ws = XLSX.utils.json_to_sheet(flexDataMain);

  const dataCombined = [].concat(flexServicesA, flexServicesB);
  for (let i=0; i<dataCombined.length; i++) {
    dataCombined[i] = staticColumnOrderClaimFlex(dataCombined[i]);
  }

  const ws2 = XLSX.utils.json_to_sheet(dataCombined);
  const dateColumns = [];
  for (const cell in ws2) {
    if (splitExcelCellString(cell).row === '1') {
      if (ws2[cell].v.toLowerCase().includes('dealerrequestdate')) {
        dateColumns.push(splitExcelCellString(cell).column);
      }
    }
  }

  for (let j = 0; j < dateColumns.length; j++) {
    for (let i = 0; i < dataCombined.length; i++) {
      if (ws2[dateColumns[j] + (i+2).toString()] !== undefined) {
        ws2[dateColumns[j] + (i + 2).toString()].z = 'dd/mm/yyyy';
      }
    }
  }

  const wb = { Sheets: { 'MainFlex': ws, 'Claims': ws2 }, SheetNames: ['MainFlex', 'Claims'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  createExcelFile(data, 'Flex Data Extract ' + currentDateAsStringHyph() + '.xlsx');
};

export const createExcelFile = (data, name) => {
  let textFile = null;
  textFile = window.URL.createObjectURL(data);

  const link = document.createElement('a');
  link.href = textFile;
  link.style.display = 'block';
  link.download = name;
  link.id = 'DownloadLink';
  link.click();
};

const staticColumnOrderClaimFlex = (object) => {
  return ({
    ScheduleID: object.ScheduleID,
    serviceRegistration: object.serviceRegistration,
    ConnectedClaim: object.ConnectedClaim,
    dealerRequestor: object.dealerRequestor,
    dealerRequestDate: convertDateToExcel(object.dealerRequestDate),
    serviceType: object.serviceType,
    modelType: object.modelType,
    autoIncluded: object.autoChecked || false,
    mileage: object.mileage,
  });
};

export const splitExcelCellString = (excelCell) => {
  const numberPosition = excelCell.search(/\d/);
  return { column: excelCell.substring(0, numberPosition),
    row: excelCell.substring(numberPosition, excelCell.length) };
};

export const currentDateAsStringHyph = () => {
  const currDate = new Date();
  let currDay = '';
  let currMonth = '';
  if (currDate.getDate() <10 ) {
    currDay = '0' + currDate.getDate();
  } else {
    currDay = currDate.getDate();
  }
  if ((currDate.getMonth() + 1) <10) {
    currMonth = '0' + (currDate.getMonth() + 1);
  } else {
    currMonth = currDate.getMonth() +1;
  }
  return currDay + '-' + currMonth + '-' + currDate.getFullYear();
};

export const convertDateToExcel = (value) => {
  if (value === undefined || value === null || value === '') {
    return value;
  } else {
    const timezoneOffsetMinutes = (new Date()).getTimezoneOffset();
    return ((parseInt(value) - (timezoneOffsetMinutes * 60000))/86400000) + 25569;
  }
};

export const exportDataToSinglePageExcel = (dataToExport, dateColumns, filename, sheetname) => {
  // const ws = XLSX.utils.json_to_sheet(invoiceRecords);
  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_json(ws, dataToExport, { origin: 'A1' });

  // Style Column Widths
  const wscols = [
    { wch: 15 }, { wch: 15 }, { wch: 15 },
    { wch: 15 }, { wch: 15 }, { wch: 15 },
    { wch: 15 }, { wch: 15 }, { wch: 15 },
    { wch: 15 }, { wch: 15 }, { wch: 15 },
  ];

  ws['!cols'] = wscols;

  // handle date formatting
  ws['B2'].z = 'dd/mm/yyyy';
  if (dateColumns) {
    if (dateColumns.length > 0) {
      for (let j=0; j<dateColumns.length; j++) {
        for (let i = 0; i < dataToExport.length; i++) {
          if (ws[dateColumns[j] + (i+2).toString()] !== undefined) {
            ws[dateColumns[j] + (i+2).toString()].z = 'dd/mm/yyyy';
          }
        }
      }
    }
  }

  const wb = { Sheets: { [sheetname]: ws }, SheetNames: [sheetname] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  createExcelFile(data, filename + '.xlsx');
};
