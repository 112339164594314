import { createTheme } from '@mui/material/styles';
// import '../Libs/font.css';

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: '0px',
      },
    },
  },
  palette: {
    primary: {
      main: '#0090f7',
      contrastText: '#fff',
      mutedMain: '#ADD8E6',
      selectedRow: '#CCE8FD',
      backgroundBack: '#eeeeee',
      backgroundFore: '#ffffff',
    },
    secondary: {
      main: '#000',
      contrastText: '#000',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    error: {
      main: '#F44336',
    },
    slaGreen: {
      backgroundColor: '#23882388',
    },
    slaAmber: {
      backgroundColor: '#FFBF00AA',
    },
    slaRed: {
      backgroundColor: '#D2222DDD',
    },
    type: 'light',
  },
});

export default theme;
