// Functions
import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// Resources

export default function BulkSendingPopup({ popupOpen, setPopupOpen, resultData, setResultData,
  sendingProgress }) {
  BulkSendingPopup.propTypes = {
    popupOpen: PropTypes.bool.isRequired,
    setPopupOpen: PropTypes.func.isRequired,
    resultData: PropTypes.array.isRequired,
    setResultData: PropTypes.func.isRequired,
    sendingProgress: PropTypes.string.isRequired,
  };

  const handleClose = () => {
    const resultDataCopy = [...resultData];
    for (let i=0; i<resultDataCopy.length; i++) {
      delete resultDataCopy[i].result;
    }
    setResultData(resultDataCopy);
    setPopupOpen(false);
  };

  return (
    <Dialog open={popupOpen || resultData.length > 0} aria-labelledby="form-dialog-title" >
      <DialogContent>
        {popupOpen && <div>Processing...{sendingProgress}</div>}
        {resultData.map((item, index) => (
          <div key={index}>
            {item.ScheduleID + ' - ' + item.result}
          </div>
        ))}
      </DialogContent>
      {resultData.length > 0 &&
      <DialogActions>
        <Button onClick={handleClose} fullWidth variant='contained' color="primary">
          Close
        </Button>
      </DialogActions>
      }
    </Dialog>
  );
}
