// Functions
import React from 'react';
import { makeStyles } from '@mui/styles';
// Components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// Resources
import MercLogo from '../../Res/MercLogo.png';

const useStyles = makeStyles((theme) => ({
  paper: {
    'padding': 4,
    'marginTop': 4,
    'marginBottom': 4,
    'font-size': 40,
    'textAlign': 'center',
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
  },
  bottomGrid: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  container: {
    marginTop: 8,
    width: '60vw',
    height: '80vh',
    border: '1px solid',
    borderColor: '#000000',
    backgroundColor: '#FFFFFF',
  },
  mercImag: {
    width: '50vw',
  },
}));

export default function AppConfidential() {
  const classes = useStyles();

  return (
    <div className='Confidential'>
      <Container component='main' className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.paper}>
            <img src={MercLogo} alt="" className={classes.mercImag}/>
          </Grid>
          <Grid item xs={12} className={classes.paper}>
            Please be aware that all information accessed using Saltmine is CONFIDENTIAL
          </Grid>
          <Grid item xs={12} className={classes.bottomGrid}>
            A company registered in England and Wales
          </Grid>
          <Grid item xs={12} className={classes.bottomGrid}>
            Registration Number: 2448457
          </Grid>
          <Grid item xs={12} className={classes.bottomGrid}>
            Registration Address: Tongwell, Milton Keynes, MK15 8BA
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
