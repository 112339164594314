// Functions
import React from 'react';
// Components
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// Resources

export default function NotFound() {
  function goHome() {
    window.location.href = '/';
  }

  return (
    <div className='NotFound'>
      <Typography variant='subtitle1'>Sorry, page not found!</Typography>
      <Button variant='contained' color='primary' onClick={() => {
        goHome();
      }}>
        Go Home
      </Button>
    </div>
  );
}
