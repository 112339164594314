// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { upgradedDate, reverseSortArrayByProperty } from '../../Libs/fileHelper';
// Resources


const useStyles = makeStyles((theme) => ({
  selectedRowStyle: {
    backgroundColor: theme.palette.primary.selectedRow,
  },
  unSelectedRowStyle: {
  },
}));

export default function FlexClaimsData(
    { claimsData, claimLastUpdate, selectedClaimRow, handleClaimRowClick },
) {
  const classes = useStyles();
  const sxClasses = {
    tableStyle: {
      height: '350px',
      marginTop: '44px',
      marginBottom: 1,
      padding: 1,
      paddingRight: 0,
      borderColor: '#000000',
      border: '1px solid',
    },
    innerTableStyle: {
      height: '320px',
      paddingTop: '2px',
      paddingLeft: '2px',
      paddingRight: '2px',
      paddingBottom: '0px',
      marginBottom: '0px',
    },
    cellStyle: {
      padding: '2px',
    },
    titleFields: {
      width: '150px',
      border: '1px solid',
      color: '#000000',
      padding: 1,
      borderColor: '#000000',
      backgroundColor: '#ffffff',
      backfaceVisibility: 'hidden',
      marginBottom: 1,
      position: 'absolute',
    },
    footerFields: {
      position: 'absolute',
      width: '165px',
      right: '124px',
      border: '1px solid',
      color: '#000000',
      padding: '8px',
      borderColor: '#000000',
      backgroundColor: '#ffffff',
      marginLeft: '200px',
      backfaceVisibility: 'hidden',
    },
    footerFieldsValue: {
      position: 'absolute',
      right: '8px',
      width: '90px',
      border: '1px solid',
      color: '#000000',
      padding: '8px',
      borderColor: '#000000',
      backgroundColor: '#ffffff',
      marginLeft: '400px',
      backfaceVisibility: 'hidden',
    },
  };

  FlexClaimsData.propTypes = {
    claimsData: PropTypes.array.isRequired,
    claimLastUpdate: PropTypes.number,
    selectedClaimRow: PropTypes.number.isRequired,
    handleClaimRowClick: PropTypes.func.isRequired,
  };

  const [pageIndex, setPageIndex] = React.useState(0);
  const handleChangePage = (event, newPage) => {
    setPageIndex(newPage);
  };
  const sliceDataByPage = () => {
    return reverseSortArrayByProperty(
        reverseSortArrayByProperty(claimsData, 'DateOfClaimEpoch'),
        'ClaimDistanceKM').slice(
        pageIndex*100, (pageIndex+1) * 100);
  };


  return (
    <React.Fragment>
      <Paper sx={sxClasses.titleFields} >
        Unlinked Claims
      </Paper>
      <Paper sx={sxClasses.footerFields} >
      Claims Data From:
      </Paper>
      <Paper sx={sxClasses.footerFieldsValue} >
        {upgradedDate(claimLastUpdate)}
      </Paper>
      <TableContainer component={Paper} sx={sxClasses.tableStyle}>
        <Table size='small' aria-label='a dense table' sx={sxClasses.innerTableStyle}>
          <TableHead>
            <TableRow>
              <TableCell sx={sxClasses.cellStyle}>Schedule</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Date</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Mileage</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>KM</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Parts</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Labour</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Other</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Total</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Type</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>DN</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sliceDataByPage().map((row, index) => (
              <TableRow key={index} className={index === selectedClaimRow ?
                classes.selectedRowStyle :
                classes.unSelectedRowStyle} onClick={()=>{
                handleClaimRowClick(row, index);
              }}>
                <TableCell sx={sxClasses.cellStyle} component='th' scope='row'>
                  {row.ScheduleID}
                </TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>
                  {row.DateOfClaim}
                </TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>
                  {(row.ClaimDistanceKM / 1.609).toFixed(1)}
                </TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>
                  {row.ClaimDistanceKM}
                </TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>
                  {row.Denied ? 'Denied' : row.ClaimPartsAmount}
                </TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>
                  {row.Denied ? 'Denied' : row.ClaimLabourAmount}
                </TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>
                  {row.Denied ? 'Denied' : row.ClaimOtherAmount}
                </TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>
                  {row.Denied ? 'Denied' : row.ClaimTotalAmount}
                </TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>
                  {row.ServiceType}
                </TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>
                  {row.DealerNumber}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination sx={{ paddingBottom: 0, marginTop: '-16px', marginBottom: '-26px', display: 'block' }}
          rowsPerPageOptions={[100]}
          count={claimsData.length}
          rowsPerPage={100}
          page={pageIndex}
          onPageChange={handleChangePage}
        />
      </TableContainer>
    </React.Fragment>
  );
}
