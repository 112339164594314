// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { API, Storage } from 'aws-amplify';
// Components
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import EditableTable from '../Components/EditableTable/EditableTable';
import ExitButton from '../Components/StyledMUIComponents/ExitButton';
import FullWidthButton from '../Components/StyledMUIComponents/FullWidthButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import { postActionType, deleteActionType, deleteDealerNumber, postDealerRecord } from '../Libs/apiHelper';
import { getCurrentUser, isValidScheduleID, checkWholeNumber } from '../Libs/fileHelper';
import { acceptableFileNames, acceptableFileContents, convertFileNames } from '../Libs/fileUploadHelper';
import LoadingPopup from '../Components/GeneralComponents/GeneralLoadingPopup';
import CustomerMigrationPopup from '../Components/CustomerMigrationPopup';
import config from '../Libs/Config';
import { currentStage, carVanSwitch, getCurrentEnvironment, isVanEnvironment } from '../Libs/StageConfig';
// Resources


export default function AdminPage(props) {
  const sxClasses = {
    contentSections: {
      marginTop: 2,
      border: '1px solid',
      borderColor: '#000000',
      padding: 1,
      textAlign: 'center',
    },
    spacer: {
      width: '100%',
      height: '20px',
    },
    submitStyle: {
      position: 'absolute',
      width: '30vw',
      right: '40vw',
      left: '40vw',
      bottom: '25px',
    },
    environmentContainer: {
      padding: 1.5,
      width: '15vw',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    paperStyles: {
      position: 'relative',
      height: '70vh',
      padding: 1,
    },
  };
  const navigate = useNavigate();

  AdminPage.propTypes = {
    setSnackbarProps: PropTypes.func.isRequired,
    actionCodeLookup: PropTypes.array.isRequired,
    setActionCodeLookup: PropTypes.func.isRequired,
    currUser: PropTypes.object.isRequired,
  };

  let pasteCallTime = Date.now()-2000;
  const [loadingText, setLoadingText] = React.useState('');
  const [longActionInProgress, setLongActionInProgress] = React.useState(false);
  const [longActionError, setLongActionError] = React.useState('');
  const [currentTitle, setCurrentTitle] = React.useState('');
  const currTitleRef = React.useRef(currentTitle);
  const [bulkAddData, setBulkAddData] = React.useState([]);
  const [bulkCompleteData, setBulkCompleteData] = React.useState([]);
  const [dealerList, setDealerList] = React.useState([]);
  const [customerMigrationPopupOpen, setCustomerMigrationPopupOpen] = React.useState(false);
  const failureMessage = 'Some or all of the data you have pasted has failed. ' +
  'Please check you have the right data copied to your clipboard';

  const [environmentAnchorEl, setEnvironmentAnchorEl] = React.useState(null);

  const toggleEnvironmentPopover = (event) => {
    setEnvironmentAnchorEl(environmentAnchorEl ? null : event.currentTarget);
  };

  React.useEffect(() => {
    // Default view clicked
    activityButtonClicked();
    if (isVanEnvironment()) {
      getDealerList();
    }

    if (props.actionCodeLookup.length === 0) {
      refreshActionCodes();
    }
    window.addEventListener('paste', pasteListener);
    // cleanup this component
    return () => {
      window.removeEventListener('paste', pasteListener);
    };
  }, [props.actionCodeLookup] );

  const activityButtonClicked = () => {
    setCurrentTitle('Activity Codes');
    currTitleRef.current = 'Activity Codes';
  };

  const dealerButtonClicked = () => {
    setCurrentTitle('Dealer List');
    currTitleRef.current = 'Dealer List';
  };

  const refreshActionCodes = () => {
    API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.ActionType.NAME, '/', {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      props.setActionCodeLookup(JSON.parse(result.body));
    }).catch( (error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    });
  };

  const getDealerList = () => {
    API.get('Saltmine-Van-API-Aux', '/dealer', {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      const jsonBody = JSON.parse(result.body);
      setDealerList(jsonBody);
    }).catch( (error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    });
  };

  const bulkCompleteButtonClicked = () => {
    props.setSnackbarProps({
      open: true,
      message: 'To create multiple records at once just paste from excel.',
      severity: 'info',
    });
    setCurrentTitle('Bulk Complete');
    currTitleRef.current = 'Bulk Complete';
  };

  const bulkAddButtonClicked = () => {
    props.setSnackbarProps({
      open: true,
      message: 'To create multiple records at once just paste from excel.',
      severity: 'info',
    });
    setCurrentTitle('Bulk Add');
    currTitleRef.current = 'Bulk Add';
  };

  const fileUploadHandler = async (e) => {
    e.persist();
    const files = e.target.files;

    let processedCount = 0;
    const processedCurrent = [];
    let processedTotal = 0;
    let errorText = '';

    setLongActionInProgress(true);
    setLoadingText('File Uploading - ' + processedCount + ' of ' + files.length + ' complete.\n');

    for (let i = 0; i < files.length; i++) {
      const selectedFile = files[i];
      processedTotal += selectedFile.size;

      if (!acceptableFileNames(selectedFile.name)) {
        processedCount += 1;
        errorText = errorText + selectedFile.name + ' - Failed File Name Validation\n';
        setLongActionError(errorText);
        processedTotal -= selectedFile.size;
        setLoadingText('Files Uploading - ' + processedCount + ' of ' + files.length + ' complete. ' +
        parseFloat(100*sumProgressArray(processedCurrent)/processedTotal).toFixed(2) + '%\n');
        if (processedCount === files.length) {
          setLongActionInProgress(false);
          setLoadingText('');
        }
      } else {
        const reader = new FileReader();
        reader.onload = function(e) {
        // The file's text will be printed here
          if (acceptableFileContents(e.target.result, selectedFile.name)) {
            Storage.put(convertFileNames(selectedFile.name), selectedFile, {
              progressCallback(progress) {
                processedCurrent[i] = progress.loaded;
                setLoadingText('Files Uploading - ' + processedCount + ' of ' + files.length + ' complete. ' +
                parseFloat(100*sumProgressArray(processedCurrent)/processedTotal).toFixed(2) + '%\n');
              },
            }).then( (result) => {
              processedCount += 1;
              processedCurrent[i] = selectedFile.size;
              setLoadingText('Files Uploading - ' + processedCount + ' of ' + files.length + ' complete. ' +
              parseFloat(100*sumProgressArray(processedCurrent)/processedTotal).toFixed(2) + '%\n');
              if (processedCount === files.length) {
                setLongActionInProgress(false);
                setLoadingText('');
                props.setSnackbarProps({
                  open: true,
                  message: 'Success: Files Uploaded',
                  severity: 'success',
                });
              }
            }).catch( (error) => {
              processedCount += 1;
              setLoadingText('Files Uploading - ' + processedCount + ' of ' + files.length + ' complete. ' +
              parseFloat(100*sumProgressArray(processedCurrent)/processedTotal).toFixed(2) + '%\n');
              if (processedCount === files.length) {
                setLongActionInProgress(false);
                setLoadingText('');
              }
              setLongActionError(longActionError + selectedFile.name + ' - Error: ' + error + '\n');
            });
          } else {
            processedCount += 1;
            setLongActionError(longActionError + selectedFile.name +
              ' - Failed File Format Validation\n');
            if (processedCount === files.length) {
              setLongActionInProgress(false);
              setLoadingText('');
            }
          }
        };

        reader.readAsText(selectedFile);
      }
    }
  };

  const sumProgressArray = (progressArray) => {
    let total = 0;
    for (let i = 0; i < progressArray.length; i++) {
      if (progressArray[i] !== undefined) {
        total = total + progressArray[i];
      }
    }
    return total;
  };

  const idContainedInArray = (arr, id) => {
    if (arr != null && arr.length >0) {
      for (let i=0; i<arr.length; i++) {
        if (arr[i].ID === id) {
          return true;
        }
      }
    }
    return false;
  };

  const schedAndActivityContainedInArray = (arr, schedule, activityCode) => {
    if (arr != null && arr.length >0) {
      for (let i=0; i<arr.length; i++) {
        if (arr[i].activityCode === activityCode && arr[i].schedule === schedule) {
          return true;
        }
      }
    }
    return false;
  };

  const handleBulkCompletePaste = async (pasteData) => {
    // Check for paste data in valid ID array format
    const appendData = [...bulkCompleteData];
    const lineSplitPasteDataArray = pasteData.split('\n');
    if (lineSplitPasteDataArray != null && lineSplitPasteDataArray.length >0) {
      for (let i=0; i<lineSplitPasteDataArray.length; i++) {
        if (lineSplitPasteDataArray[i] !== '' &&
          lineSplitPasteDataArray[i] !== null &&
          lineSplitPasteDataArray[i] !== undefined) {
          if (!idContainedInArray(appendData, lineSplitPasteDataArray[i])) {
            appendData.push({ ID: lineSplitPasteDataArray[i] });
          } else {
            // Duplicate Data. No message as paste event gets calls multiple times so will confus user
          }
        } else {
          // Invalid Input Data
          props.setSnackbarProps({
            open: true,
            message: failureMessage,
            severity: 'warning' });
        }
      }
    }

    if (appendData.length === bulkCompleteData.length) {
      return;
    }

    setBulkCompleteData(appendData);
  };

  const pasteListener = (e) => {
    // Prevent spam calls to listener that lock up website
    if (Date.now() - pasteCallTime < 2000) {
      return;
    }
    pasteCallTime = Date.now();

    const pasteData = e.clipboardData.getData('text/plain');
    // Intercept Paste events
    if (pasteData !== undefined && pasteData !== '' && pasteData !== null) {
    // Paste with data in the clipboard detected

      if (currTitleRef.current === 'Bulk Complete') {
        handleBulkCompletePaste(pasteData);
      } else if (currTitleRef.current === 'Bulk Add') {
        // Check for paste data in valid bulk add format
        const appendData = [...bulkAddData];
        const lineSplitPasteDataArray = pasteData.split('\n');
        if (lineSplitPasteDataArray != null && lineSplitPasteDataArray.length >0) {
          for (let i=0; i<lineSplitPasteDataArray.length; i++) {
            const tabSplitPasteDataArray = lineSplitPasteDataArray[i].split('\t');
            if (tabSplitPasteDataArray.length === 3) {
              // validate schedule is whole number
              if (
                isValidScheduleID(tabSplitPasteDataArray[0]) &
                tabSplitPasteDataArray[0] !== '' &
                tabSplitPasteDataArray[1].length === 3
              ) {
                if (
                  !schedAndActivityContainedInArray(
                      appendData,
                      tabSplitPasteDataArray[0],
                      tabSplitPasteDataArray[1],
                  )
                ) {
                  appendData.push({
                    schedule: tabSplitPasteDataArray[0],
                    activityCode: tabSplitPasteDataArray[1],
                    reference: tabSplitPasteDataArray[2],
                  });
                } else {
                // Duplicate Data. No message as paste event gets calls multiple times so will confus user
                }
              } else {
              // Invalid Input Data Quality
                props.setSnackbarProps({
                  open: true,
                  message: failureMessage,
                  severity: 'warning' });
              }
            } else {
            // Invalid Input Data Length
              props.setSnackbarProps({
                open: true,
                message: failureMessage,
                severity: 'warning' });
            }
          }
        }

        if (appendData.length === bulkAddData.length) {
          return;
        }

        setBulkAddData(appendData);
      }

      // Prevent the default paste action.
      e.preventDefault();
      return;
    }
  };


  // Action Code Functions
  const actionCodeValidation = (value) => {
    if (value.length > 3) {
      props.setSnackbarProps({ open: true,
        message: 'Action Codes are limited to 3 Characters',
        severity: 'info' });
      return false;
    }
    return true;
  };
  const actionCodeInputConversion = (value) => {
    return value.trim().toUpperCase();
  };

  const slaValidation = (value) => {
    if (!checkWholeNumber(value)) {
      props.setSnackbarProps({ open: true,
        message: 'SLA must be whole number of days.',
        severity: 'info' });
      return false;
    }
    return true;
  };

  const actionCodeHeaders = ['ActionType', 'ActionDescription', 'SLA', 'SystemCode', 'AgedProcess',
    'AddacsProcess'];
  const actionCodeConfigurationInformation = {
    ActionType: {
      align: 'center',
      label: 'Action Code',
      sort: true,
      validationFunction: actionCodeValidation,
      inputConversionFunction: actionCodeInputConversion,
    },
    ActionDescription: {
      label: 'Action Description',
    },
    SLA: {
      align: 'center',
      validationFunction: slaValidation,
    },
    SystemCode: {
      align: 'center',
      type: 'checkbox',
      label: 'System Process',
    },
    AgedProcess: {
      align: 'center',
      type: 'checkbox',
      label: 'Aged Process',
    },
    AddacsProcess: {
      align: 'center',
      type: 'checkbox',
      label: 'Addacs Process',
    },
  };

  const additionalActionCodeSaveFunction = (newData) => {
    // Data Validation
    if (newData.ActionType.length === 0) {
      props.setSnackbarProps({ open: true,
        message: 'Please enter an Action Code',
        severity: 'info' });
      return false;
    }
    if (newData.ActionDescription.length === 0) {
      props.setSnackbarProps({ open: true,
        message: 'Please enter an Action Description',
        severity: 'info' });
      return false;
    }
    if (newData.SLA) {
      if (!checkWholeNumber(newData.SLA)) {
        props.setSnackbarProps({ open: true,
          message: 'SLA must be a whole number of days',
          severity: 'info' });
        return false;
      }
    }
    // Check if code already exists
    const lookupIndex = props.actionCodeLookup.findIndex(
        (actionCode) => actionCode.ActionType === newData.ActionType,
    );
    if (newData.originalData) {
      // Logic applies to existing records: New records have undefined originalData
      if (lookupIndex !== -1 && newData.ActionType !== newData.originalData.ActionType) {
        props.setSnackbarProps({ open: true,
          message: 'This Action code already exists. Please delete or edit the existing code first.',
          severity: 'info' });
        return false;
      }
      // Remove exiting code if action Type has changed
      if (newData.ActionType !== newData.originalData.ActionType) {
        deleteActionType(newData.originalData.ActionType, props.setSnackbarProps);
      }
    }
    postActionType(newData, props.setSnackbarProps);

    return true;
  };

  const additionalActionCodeDeleteFunction = (newData) => {
    deleteActionType(newData.originalData.ActionType, props.setSnackbarProps);
    return true;
  };

  const scheduleIDValidation = (value) => {
    if (isValidScheduleID(value)) {
      return true;
    }
    props.setSnackbarProps({ open: true,
      message: 'Schedule ID must be of the format XXXXXXXX/XXXXXX',
      severity: 'info' });
    return false;
  };

  // Bulk Add Functions
  const bulkAddHeaders = ['schedule', 'activityCode', 'reference'];
  const bulkAddConfigurationInformation = {
    schedule: {
      label: 'Schedule ID',
      align: 'center',
      validationFunction: scheduleIDValidation,
    },
    activityCode: {
      label: 'Action Code',
      align: 'center',
      validationFunction: actionCodeValidation,
      inputConversionFunction: actionCodeInputConversion,
    },
    reference: {
      label: 'Reference',
    },
  };

  const additionalBulkAddSaveFunction = (newData) => {
    // Data Validation
    if (newData.schedule?.length === 0) {
      props.setSnackbarProps({ open: true,
        message: 'Please enter a schedule',
        severity: 'info' });
      return false;
    }
    if (newData.activityCode?.length === 0) {
      props.setSnackbarProps({ open: true,
        message: 'Please enter an Activity Code',
        severity: 'info' });
      return false;
    }
    if (newData.reference?.length === 0) {
      props.setSnackbarProps({ open: true,
        message: 'Please enter a reference',
        severity: 'info' });
      return false;
    }
    return true;
  };

  const additionalDealerListSaveFunction = (newData) => {
    if (newData.dealerNumber.length === 0) {
      props.setSnackbarProps({ open: true,
        message: 'Please enter a Dealer Number',
        severity: 'info' });
      return false;
    }
    if (newData.dealerName.length === 0) {
      props.setSnackbarProps({ open: true,
        message: 'Please enter a Dealer Name',
        severity: 'info' });
      return false;
    }
    // Check if code already exists
    const lookupIndex = dealerList.findIndex(
        (dealerItem) => dealerItem.dealerNumber === newData.dealerNumber,
    );
    if (newData.originalData) {
      // Logic applies to existing records: New records have undefined originalData
      if (lookupIndex !== -1 && newData.dealerNumber !== newData.originalData.dealerNumber) {
        props.setSnackbarProps({ open: true,
          message: 'This dealer number already exists. Please delete or edit the existing record first.',
          severity: 'info' });
        return false;
      }
      // Remove exiting code if action Type has changed
      if (newData.dealerNumber !== newData.originalData.dealerNumber) {
        deleteDealerNumber(newData.originalData.dealerNumber, props.setSnackbarProps);
      }
    }
    postDealerRecord(newData, props.setSnackbarProps);
    return true;
  };

  const additionalDealerListDeleteFunction = (newData) => {
    deleteDealerNumber(newData.originalData.dealerNumber, props.setSnackbarProps);
    return true;
  };

  // Bulk Complete Functions
  const additionalBulkCompleteSaveFunction = (newData) => {
    // Data Validation
    if (newData.ID?.length === 0) {
      props.setSnackbarProps({ open: true,
        message: 'Please enter a ID',
        severity: 'info' });
      return false;
    }
    return true;
  };

  const showSubmitButton = () => {
    if (currentTitle === 'Bulk Add') {
      if (bulkAddData.length > 0) {
        return true;
      } else {
        return false;
      }
    } else if (currentTitle === 'Bulk Complete') {
      if (bulkCompleteData.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const acknowledgeButtonPressed = () => {
    setLongActionError('');
  };

  const handleSubmit = async () => {
    if (currentTitle === 'Bulk Complete') {
      setLongActionInProgress(true);
      setLoadingText('Performing Bulk Complete on ' + bulkCompleteData.length + ' records.');
      const promises = bulkCompleteData.map((bulkCompleteItem) => {
        return performBulkComplete(bulkCompleteItem);
      });
      const bulkCompleteResults = await Promise.all(promises);
      const failureResults = bulkCompleteResults.filter((result) => result !== 'Success');
      if (failureResults.length === 0) {
        props.setSnackbarProps({ open: true,
          message: 'Bulk Complete Finished: all pending items completed.',
          severity: 'success' });
      } else {
        const conditionCheckFailures = bulkCompleteResults.filter(
            (result) => result.includes('conditional request failed')).length;
        const timeoutFailures = bulkCompleteResults.filter(
            (result) => result.includes('Cannot read property')).length;
        let errorMessage = '\n\n' + failureResults.length + ' records failed\n\n';
        if (conditionCheckFailures !== 0) {
          errorMessage = errorMessage + conditionCheckFailures +
            ' Condition Errors - Record is already complete or ID does not exist.\n\n';
        }
        if (timeoutFailures !== 0) {
          errorMessage = errorMessage + timeoutFailures +
            ' Timeout Errors - Try submitting again to complete.';
        }
        setLongActionError(errorMessage);
        props.setSnackbarProps({ open: true,
          message: 'Some records did not succesfully complete.',
          severity: 'error' });
      }
      setLongActionInProgress(false);
    }
    if (currentTitle === 'Bulk Add') {
      setLongActionInProgress(true);
      setLoadingText('Performing Add Complete on ' + bulkAddData.length + ' records.');
      let processedCount = 0;
      const targetCount = bulkAddData.length;
      const data = [...bulkAddData];
      const fullData = [];
      for (const dataLine of bulkAddData) {
        if (dataLine.schedule !== undefined &&
          dataLine.activityCode !== undefined &&
          dataLine.reference !== undefined) {
          // Get Schedule Details
          let apiToCall = '';
          let apiParams = {};
          if (!checkWholeNumber(dataLine.schedule)) {
            apiToCall = '/getscheduleid';
            apiParams = {
              ScheduleID: dataLine.schedule,
            };
          } else {
            apiToCall = '/getschedulenumber';
            apiParams = {
              ScheduleNo: parseInt(dataLine.schedule),
            };
          }
          API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.ICONInfo.NAME,
              apiToCall, {
                queryStringParameters: apiParams,
                headers: {
                  'Content-Type': 'application/json',
                },
              }).then((result) => {
            let returnedValue = JSON.parse(result.body);
            if (returnedValue.length) {
              returnedValue = returnedValue[0];
            }
            const body = {
              ScheduleID: dataLine.schedule,
              CustomerName: returnedValue.CustomerName,
              ScheduleStatus: returnedValue.Status,
              ActionType: dataLine.activityCode.toUpperCase(),
              Reference: dataLine.reference,
              CustomerID: returnedValue.CustomerID,
              CustomerCofico: returnedValue.CustomerCofico,
            };
            fullData.push(body);
            data.splice(data.indexOf(dataLine), 1);
            processedCount += 1;
            if (processedCount === targetCount) {
              bulkAddCreateRecords(data, fullData);
            }
          }).catch( (error) => {
            processedCount += 1;
            if (processedCount === targetCount) {
              bulkAddCreateRecords(data, fullData);
            }
          });
        }
      }
    }
  };

  const performBatchCreate = async (batchData) => {
    return await API.post(config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory.NAME,
        '/postnewbatch', {
          body: batchData,
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((result) => {
      return result;
    }).catch( (error) => {
      return { statusCode: 500, error: error };
    });
  };

  const bulkAddCreateRecords = async (data, fullData) => {
    // Add records on failure add to data from fullData
    let batch = [];
    for (const fullDataLine of fullData) {
      batch.push(fullDataLine);
      if (batch.length > 20) {
        const result = await performBatchCreate(batch);
        if (result.statusCode !== 200) {
          for (let i=0; i<batch.length; i++) {
            data.push({
              ScheduleID: batch[i].ScheduleID,
              ActionType: batch[i].ActionType,
              Reference: batch[i].Reference,
            });
          }
        }
        batch = [];
      }
    }

    // final batch
    if (batch.length !== 0) {
      const result = await performBatchCreate(batch);
      if (result.statusCode !== 200) {
        for (let i=0; i<batch.length; i++) {
          data.push({
            ScheduleID: batch[i].ScheduleID,
            ActionType: batch[i].ActionType,
            Reference: batch[i].Reference,
          });
        }
      }
    }

    finishedBulkAdd(data);
  };

  const finishedBulkAdd = (data) => {
    setLongActionInProgress(false);
    setLoadingText('');
    setBulkAddData(data);
    if (data.length === 0) {
      props.setSnackbarProps({ open: true,
        message: 'Bulk Add Complete: all pending items created.',
        severity: 'success' });
    } else {
      props.setSnackbarProps({ open: true,
        message: 'Some records did not succesfully create a pending item.',
        severity: 'error' });
    }
  };

  const performBulkComplete = async (dataLine) => {
    if (!dataLine) {
      return;
    }
    if (!dataLine.ID) {
      return;
    }
    const body = {
      ID: dataLine.ID.trim(),
      status: 'History',
      userId: getCurrentUser(props.currUser),
    };
    return await API.post(config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory.NAME,
        '/completepending', {
          body: body,
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((result) => {
      if (result.statusCode !== 200) {
        return 'Error - ' + result.body + ' - ' + result.errorMessage;
      } else {
        setBulkCompleteData((prevState) => {
          const data = [...prevState];
          data.splice(data.indexOf(dataLine), 1);
          return data;
        });
        return 'Success';
      }
    }).catch( (error) => {
      return 'Error - ' + error;
    });
  };

  const devCloudfrontURL = () => {
    return carVanSwitch('https://dm2fh45ncexrg.cloudfront.net', 'https://d3h0h089kpjm6c.cloudfront.net');
  };

  const uatCloudfrontURL = () => {
    return carVanSwitch('https://d2z2v3up30okvt.cloudfront.net', 'https://dvshn82odlha2.cloudfront.net');
  };

  return (
    <Container component='main'>
      <Grid container spacing={3} sx={sxClasses.contentSections}>
        <Grid item xs={12} >
          <Grid container spacing={3}>
            <Grid item xs={3} >
              <FullWidthButton variant='contained' color='primary'
                onClick={activityButtonClicked}>
              Activity Codes
              </FullWidthButton>
            </Grid>
            <Grid item xs={3} >
              <input
                accept=".csv, .xml"
                onChange={fileUploadHandler}
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file">
                <FullWidthButton color='primary' variant="contained" component="span">
                Upload
                </FullWidthButton>
              </label>
            </Grid>
            <Grid item xs={3} >
              <FullWidthButton variant='contained' color='primary'
                onClick={bulkCompleteButtonClicked}>
              Bulk Complete
              </FullWidthButton>
            </Grid>
            <Grid item xs={3} >
              <FullWidthButton variant='contained' color='primary'
                onClick={bulkAddButtonClicked}>
              Bulk Add
              </FullWidthButton>
            </Grid>
            {isVanEnvironment() &&
              <Grid item xs={3} >
                <FullWidthButton variant='contained' color='primary'
                  onClick={dealerButtonClicked}>
                  Dealer List
                </FullWidthButton>
              </Grid>
            }
            <Grid item xs={3} >
              <FullWidthButton variant='contained' color='primary'
                onClick={()=>{
                  setCustomerMigrationPopupOpen(true);
                }}>
                History Migration
              </FullWidthButton>
            </Grid>
            <Grid item xs={3} >
              <FullWidthButton variant='contained' color='primary'
                onClick={toggleEnvironmentPopover}>
              Environments
              </FullWidthButton>
              <Popover
                onClose={toggleEnvironmentPopover}
                open={Boolean(environmentAnchorEl)}
                anchorEl={environmentAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box sx={sxClasses.environmentContainer} >
                  <FullWidthButton color='primary' variant="contained" onClick={
                    ()=>(window.location.href = devCloudfrontURL())
                  }>
                  Dev
                  </FullWidthButton>
                  <FullWidthButton color='primary' variant="contained" onClick={
                    ()=>(window.location.href = uatCloudfrontURL())
                  }>
                  UAT
                  </FullWidthButton>
                </Box>
              </Popover>
            </Grid>
            <Grid item xs={3} >
              <FullWidthButton variant='contained' color='primary'
                onClick={() => {
                  navigate('/BulkCommunication');
                }}>
              Bulk Communication
              </FullWidthButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={sxClasses.spacer} />
      <Paper sx={sxClasses.paperStyles}>
        {currTitleRef.current === 'Activity Codes' &&
          <EditableTable
            tableData={props.actionCodeLookup}
            setTableData={props.setActionCodeLookup}
            headerArray={actionCodeHeaders}
            configurationInformation={actionCodeConfigurationInformation}
            additionalSaveFunction={additionalActionCodeSaveFunction}
            additionalDeleteFunction={additionalActionCodeDeleteFunction}
          />
        }
        {currTitleRef.current === 'Bulk Add' &&
          <EditableTable
            tableData={bulkAddData}
            setTableData={setBulkAddData}
            headerArray={bulkAddHeaders}
            configurationInformation={bulkAddConfigurationInformation}
            additionalSaveFunction={additionalBulkAddSaveFunction}
          />
        }
        {currTitleRef.current === 'Bulk Complete' &&
          <EditableTable
            tableData={bulkCompleteData}
            setTableData={setBulkCompleteData}
            headerArray={['ID']}
            additionalSaveFunction={additionalBulkCompleteSaveFunction}
          />
        }
        {currTitleRef.current === 'Dealer List' &&
          <EditableTable
            tableData={dealerList}
            setTableData={setDealerList}
            headerArray={['dealerNumber', 'dealerName']}
            additionalSaveFunction={additionalDealerListSaveFunction}
            additionalDeleteFunction={additionalDealerListDeleteFunction}
          />
        }
      </Paper>
      <ExitButton variant='contained' color='primary' onClick={() => {
        navigate(-1);
      }}>
        Back
      </ExitButton>
      {showSubmitButton() &&
      <Button variant='contained' color='primary' sx={sxClasses.submitStyle} onClick={handleSubmit}>
        Submit
      </Button>}
      {(longActionInProgress || longActionError !== '') &&
      <LoadingPopup
        loadingText={loadingText + longActionError}
        showAcknowledgeButton={!longActionInProgress}
        acknowledgeButtonPressed={acknowledgeButtonPressed} /> }
      <CustomerMigrationPopup
        popupOpen={customerMigrationPopupOpen}
        setPopupOpen={setCustomerMigrationPopupOpen}
        setSnackbarProps={props.setSnackbarProps} />
    </Container>
  );
}
