// Functions
import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { upgradedDate, sortArrayByProperty, pad } from '../../Libs/fileHelper';
// Resources
import DeleteIcon from '@mui/icons-material/Delete';

export default function FlexRecordsAddPopup({ popupOpen, setPopupOpen, addingRecordFields, activeRecord,
  handleRecordFieldsChange, deleteFlexRecord, saveFlexRecord, autoChecked, existingFlexData, dealerList }) {
  const sxClasses = {
    dialogTitle: {
      paddingTop: 1,
      textAlign: 'center',
    },
    textFieldStyle: {
      width: '100%',
    },
    iconButton: {
      position: 'absolute',
      top: 1,
      right: 1,
      padding: 0,
    },
    saveButtonStyle: {
      position: 'absolute',
      width: '13vw',
      left: '10px',
      bottom: '10px',
    },
    cancelButtonStyle: {
      position: 'absolute',
      width: '13vw',
      right: '10px',
      bottom: '10px',
    },
    dialogContentStyle: {
      marginBottom: '40px',
    },
  };


  FlexRecordsAddPopup.propTypes = {
    popupOpen: PropTypes.bool.isRequired,
    setPopupOpen: PropTypes.func.isRequired,
    addingRecordFields: PropTypes.object.isRequired,
    handleRecordFieldsChange: PropTypes.func.isRequired,
    deleteFlexRecord: PropTypes.func.isRequired,
    saveFlexRecord: PropTypes.func.isRequired,
    autoChecked: PropTypes.bool.isRequired,
    existingFlexData: PropTypes.array.isRequired,
    activeRecord: PropTypes.bool.isRequired,
    dealerList: PropTypes.array.isRequired,
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  const existingRecordMatch = () => {
    const matchingData = existingFlexData.filter(
        (data) => data.serviceRegistration === addingRecordFields.serviceRegistration &&
          data.UUID !== addingRecordFields.UUID);
    if (matchingData.length === 0) {
      return [];
    }
    return matchingData;
  };

  return (
    <Dialog open={popupOpen} onClose={handleClose} aria-labelledby="form-dialog-title" >
      <DialogTitle sx={sxClasses.dialogTitle} id="form-dialog-title">
        {addingRecordFields.UUID === 'New' ? 'Add Record' : 'Edit Record'}
      </DialogTitle>
      <IconButton sx={sxClasses.iconButton} onClick={deleteFlexRecord}>
        <DeleteIcon />
      </IconButton>
      <DialogContent sx={sxClasses.dialogContentStyle}>
        {addingRecordFields.CreatedBy &&
        <div>
          Created By: {addingRecordFields.CreatedBy}
        </div>}
        <TextField select
          margin="dense"
          id="dealerRequestor"
          name="dealerRequestor"
          label="Dealer Requestor"
          type="email"
          fullWidth
          onChange={handleRecordFieldsChange}
          value={addingRecordFields.dealerRequestor}
        >
          {sortArrayByProperty(dealerList, 'dealerName').map((item)=>(
            <MenuItem key={item.dealerNumber} value={pad(item.dealerNumber, 5) + ' - ' + item.dealerName}>
              {pad(item.dealerNumber, 6) + ' - ' + item.dealerName}
            </MenuItem>
          ))}
        </TextField>
        <DesktopDatePicker
          margin="normal"
          id="dealerRequestDate"
          name="dealerRequestDate"
          label="Dealer Request Date"
          inputFormat="dd/MM/yyyy"
          value={addingRecordFields.dealerRequestDate}
          onChange={handleRecordFieldsChange}
          KeyboardButtonProps={{
            'aria-label': 'Dealer Request Date',
          }}
          renderInput={(params) => <TextField {...params} sx={sxClasses.textFieldStyle}/>}
          inputProps={{
            style: {
              padding: 5,
              color: '#000000',
            },
          }}
        />
        <TextField
          margin="dense"
          id="mileage"
          name="mileage"
          label="Mileage"
          type="subject"
          fullWidth
          onChange={handleRecordFieldsChange}
          value={addingRecordFields.mileage}
        />
        <TextField select
          sx={sxClasses.textFieldStyle}
          label="Service Type"
          inputProps={{
            style: {
              padding: 5,
              color: '#000000',
            },
          }}
          value={addingRecordFields.serviceType}
          onChange={handleRecordFieldsChange}
          name='serviceType'
          id='serviceType'>
          <MenuItem key={0} value={'A'}>
            A
          </MenuItem>
          <MenuItem key={1} value={'B'}>
            B
          </MenuItem>
        </TextField>
        {autoChecked &&
          <FormControlLabel
            control={
              <Checkbox
                checked={addingRecordFields.autoChecked}
                onChange={handleRecordFieldsChange}
                name="autoChecked"
                color="primary"
              />
            }
            label="Auto Included"
          />
        }
        <TextField
          margin="dense"
          id="serviceRegistration"
          name="serviceRegistration"
          label="Registration"
          type="subject"
          fullWidth
          onChange={handleRecordFieldsChange}
          value={addingRecordFields.serviceRegistration}
        />
        {existingRecordMatch().map((row, index) => (
          <div style={ { color: '#B33A3A' } } key={index}>
            Warning Existing Claim Matching Registration {row.serviceRegistration}<br></br>
            Request Date: {upgradedDate(row.dealerRequestDate)}<br></br>
            Type: {row.serviceType}
          </div>
        ))}
        {!activeRecord &&
          <FormControlLabel
            control={
              <Checkbox
                checked={addingRecordFields.activeChecked}
                onChange={handleRecordFieldsChange}
                name="activeChecked"
                color="primary"
              />
            }
            label="Confirm Understanding that contract is not Active"
          />
        }
      </DialogContent>
      <DialogActions>
        <Button sx={sxClasses.saveButtonStyle} variant='contained' color='primary'
          onClick={saveFlexRecord}>
          Save
        </Button>
        <Button sx={sxClasses.cancelButtonStyle} variant='contained' color='primary'
          onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
