import React from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    paddingTop: theme.spacing(3),
  },
  image: {
    height: '250px',
    display: 'block',
    margin: 'auto',
  },
  title: {
    maxWidth: '530px',
  },
  subtitle: {
    maxWidth: '630px',
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default function AppNoApproval({ userHasAuthenticated }) {
  const classes = useStyles();

  AppNoApproval.propTypes = {
    userHasAuthenticated: PropTypes.func.isRequired,
  };

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Typography className={classes.title} variant="h2">
          Access Denied.
        </Typography>
        <Typography className={classes.subtitle} variant="subtitle1">
          To request access to Saltmine please email business.innovation@mercedes-benz.com.
        </Typography>
        <Typography className={classes.subtitle} variant="subtitle1">
          Once this is approved please sign out and log back in again.
        </Typography>
        <Button onClick={handleLogout} variant="contained" color="primary">
          Sign Out
        </Button>
      </Container>
    </div>
  );
}
