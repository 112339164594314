
import { jsPDF } from 'jspdf';
import { getCurrentUser, replaceAllOccurances, currentDateAsString, toTitleCase,
  isProjectLeoLive } from '../Libs/fileHelper';
import { pdfCancellationTemplateText, deceasedTemplatePDFText } from '../Libs/templateHelper';
import { carVanSwitch } from '../Libs/StageConfig';
// Resources
import HeaderImage from '../Res/MBLogo.png';
const fontType = 'MBCorpoSTextOffice-Light';
const boldFontType = 'MBCorpoSTextOffice-Bold';
import { addLightFont } from '../Res/MBCorpoSTextOffice-Light-normal.js';
import { addBoldFont } from '../Res/MBCorpoSTextOffice-Bold-bold.js';

export const createCancellationPDF = (customerData, scheduleData, currUser, templateType, combinedProps) => {
  createPDF(customerData, scheduleData, currUser, pdfCancellationTemplateText(templateType, combinedProps));
};

export const createDeceasedPDF = (customerData, scheduleData, currUser, templateType) => {
  createPDF(
      customerData,
      scheduleData,
      currUser,
      deceasedTemplatePDFText(templateType, customerData.CustomerName, 'XX/XX/XXXX'),
  );
};

export const createPDF = (customerData, scheduleData, currUser, templateText) => {
  const doc = jsPDF();
  addLightFont(doc);
  addBoldFont(doc);
  doc.setFont('MBCorpoSTextOffice-Light');
  formatPDF(doc, customerData, scheduleData, currUser, templateText);
  doc.save(scheduleData.ScheduleID + '.pdf');
};

export const createMultiPDF = (templateData, currUser) => {
  const doc = jsPDF();
  addLightFont(doc);
  addBoldFont(doc);
  doc.setFont('MBCorpoSTextOffice-Light');
  for (let i=0; i<templateData.length; i++) {
    if (i !== 0) {
      doc.addPage();
    }
    formatPDF(doc, templateData[i], templateData[i], currUser, templateData[i].templateText);
  }
  doc.save('BulkCommunication.pdf');
};

const addPageHeader = (doc) => {
  doc.addImage(HeaderImage, 'PNG', 95, 5, 20, 20, '', 'FAST');
  doc.setFont(fontType, 'normal');
  doc.setFontSize(11);
  if (isProjectLeoLive()) {
    doc.text('Mercedes-Benz', 145, 25);
  } else {
    doc.text('Mercedes-Benz\nFinancial Services', 152, 25);
  }
};

const addPageFooter = (doc) => {
  doc.setFontSize(7.5);
  if (isProjectLeoLive()) {
    doc.addImage(HeaderImage, 'PNG', 20, 271, 4, 4, '', 'FAST');
    doc.text('Mercedes-Benz UK Limited | Tongwell | Milton Keynes | MK15 8BA | T +44 (0)1908 245000 |' +
    ' www.mercedes-benz.co.uk\n\n' +
    'Registered in England and Wales, 2448457 (Registered Office) | VAT no. GB225027008\n\n' +
    '        Mercedes-Benz are trademarks of Mercedes-Benz Group AG', 20, 262);
  } else {
    doc.text('Mercedes-Benz Finance | Milton Keynes | MK15 8BA | www.mercedes-benz.co.uk\n' +
    'Mercedes-Benz Finance is a trading name of Mercedes-Benz Financial Services UK Limited | ' +
    'Authorised and regulated by the Financial Conduct Authority |\n' +
    'Registered in England No.2472364\n\n' +
    'Mercedes-Benz Finance takes your privacy seriously. For more information on how we use your ' +
    'personal information, our Privacy Policy is available on\n' +
    'request or at www.mercedes-benz.co.uk/privacy', 20, 262);
  }
};

const addPageAddressDetails = (doc, customerData, scheduleData) => {
  doc.setFontSize(11);
  doc.setFont(boldFontType, 'bold');
  doc.text('PRIVATE & CONFIDENTIAL', 20, 40);
  doc.setFont(fontType, 'normal');
  doc.setLineHeightFactor(1.15);
  doc.text(customerData.CustomerName +
    '\n' + replaceAllOccurances(toTitleCase(customerData.CustomerAddress), ', ', '\n') +
    '\n' + customerData.CustomerPostcode + '\n\n' + currentDateAsString(), 20, 47);
  doc.setLineHeightFactor(1.15);

  doc.setFont(boldFontType, 'bold');
  doc.text('Schedule ID', 160, 40);
  doc.setFont(fontType, 'normal');
  doc.text(scheduleData.ScheduleID.toString(), 160, 47);
  doc.setFont(boldFontType, 'bold');
  doc.text('Registration', 160, 54);
  doc.setFont(fontType, 'normal');
  doc.text(scheduleData.Registration, 160, 61);
};

export const formatPDF = (doc, customerData, scheduleData, currUser, templateText) => {
  addPageHeader(doc);
  addPageFooter(doc);
  addPageAddressDetails(doc, customerData, scheduleData);

  const endingText = 'Yours sincerely\n\n\n\n\n' + getCurrentUser(currUser) +
  '\nCustomer Service Administrator - ServiceCare\n\nPhone: +44 (0) 370 242 6200\n' +
  'Email: ' + carVanSwitch('pc.servicecontract@mercedes-benz.com', 'van.servicecontract@mercedes-benz.com');

  const splitTemplateTextArray = doc.splitTextToSize(templateText + endingText, 170);
  doc.setFontSize(11);
  doc.setFont(fontType, 'normal');
  doc.text(splitTemplateTextArray.slice(0, 35), 20, 90);

  if (splitTemplateTextArray.length > 35) {
    doc.addPage();
    addPageHeader(doc);
    addPageFooter(doc);
    doc.setFontSize(11);
    doc.setFont(fontType, 'normal');
    doc.text(splitTemplateTextArray.slice(35, splitTemplateTextArray.length), 20, 50);
  }
};
