// Functions
import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Resources

export default function ScheduleRemoveForm({ popupOpen, setPopupOpen, scheduleData, setScheduleData,
  callRemoveSchedule, setSnackbarProps }) {
  const sxClasses = {
    dialogTitle: {
      paddingTop: 1,
      textAlign: 'center',
    },
    textFieldStyle: {
      width: '100%',
    },
  };

  ScheduleRemoveForm.propTypes = {
    popupOpen: PropTypes.bool.isRequired,
    setPopupOpen: PropTypes.func.isRequired,
    scheduleData: PropTypes.object.isRequired,
    setScheduleData: PropTypes.func.isRequired,
    callRemoveSchedule: PropTypes.func.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const handleRemoveFleetChange = (event) => {
    if (event.getTime() <= scheduleData.addToFleetDate) {
      setSnackbarProps({ open: true,
        message: 'You cannot remove a vehicle before it was added.',
        severity: 'info',
      });
      setScheduleData({
        ...scheduleData,
        removeFromFleetDate: scheduleData.addToFleetDate + 1,
      });
      return;
    }
    setScheduleData({
      ...scheduleData,
      removeFromFleetDate: event.getTime(),
    });
  };

  return (
    <Dialog open={popupOpen} aria-labelledby="form-dialog-title"
      maxWidth={false}>
      <DialogTitle sx={sxClasses.dialogTitle} id="form-dialog-title">
        {'Schedule: ' + scheduleData?.ScheduleID}
      </DialogTitle>
      <DialogContent>
        <DesktopDatePicker
          margin="normal"
          id="removeFromFleetDate"
          name="removeFromFleetDate"
          label="Remove From Fleet Date"
          inputFormat="dd/MM/yyyy"
          value={scheduleData.removeFromFleetDate || null}
          onChange={handleRemoveFleetChange}
          KeyboardButtonProps={{
            'aria-label': 'Remove From Fleet Date',
          }}
          renderInput={(params) => <TextField {...params} margin="dense" sx={sxClasses.textFieldStyle}/>}
        />
      </DialogContent>
      <DialogActions>
        {scheduleData.removeFromFleetDate &&
          <Button variant='contained' color="primary" onClick={()=>{
            callRemoveSchedule();
            setPopupOpen(false);
          }}>
            Remove From Fleet
          </Button>
        }
        <Button onClick={closePopup} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
