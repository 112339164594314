// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { API } from 'aws-amplify';
// Components
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ExitButton from '../Components/StyledMUIComponents/ExitButton';
import ResultsPendingItem from '../Components/ResultsPendingItem';
import Paper from '@mui/material/Paper';
import TitlePaper from '../Components/StyledMUIComponents/TitlePaper';
import { getCurrentUser } from '../Libs/fileHelper';
import { getResultsForActionCode } from '../Libs/apiHelper';
import config from '../Libs/Config';
import { currentStage, getCurrentEnvironment } from '../Libs/StageConfig';
// Resources

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#000000',
    backgroundColor: '#ffffff',
    border: '1px solid',
    borderColor: '#000000',
  },
  contentSectionsLeft: {
    marginTop: '50px',
    height: '80vh',
    border: '1px solid',
    borderColor: '#000000',
    padding: theme.spacing(3),
    textAlign: 'center',
    overflow: 'scroll',
  },
  titleFields: {
    position: 'absolute',
    marginTop: '32px',
    marginLeft: '25px',
  },
  selectedCode: {
    position: 'absolute',
    marginTop: '32px',
    marginLeft: '215px',
  },
  mainStyle: {
    width: '100%',
    maxWidth: '100%',
  },
}));

export default function ResultsPage(props) {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const sxClasses = {
    showAllButton: {
      position: 'absolute',
      marginTop: '32px',
      marginLeft: '405px',
      width: '200px',
      height: '39px',
    },
  };

  const [actionTypeData, setActionTypeData] = React.useState([]);
  const [noResultsWarning, setNoResultsWarning] = React.useState('');
  const [showAll, setShowAll] = React.useState(false);

  ResultsPage.propTypes = {
    setSnackbarProps: PropTypes.func.isRequired,
    setCustomerData: PropTypes.func.isRequired,
    setPendingData: PropTypes.func.isRequired,
    currUser: PropTypes.object.isRequired,
    actionCodeLookup: PropTypes.array.isRequired,
    setActionCodeLookup: PropTypes.func.isRequired,
  };

  React.useEffect(() => {
    getActionCodeData();
    if (props.actionCodeLookup.length === 0) {
      refreshActionCodeLookup();
    }
  }, [] );

  const getActionCodeData = async () => {
    const response = await getResultsForActionCode(
        location.search.substring(2), props.setSnackbarProps,
    );
    if (response.status === 200) {
      setActionTypeData(response.result);
    } else {
      setNoResultsWarning('No Results');
    }
  };

  const handleAssignments = () => {
    const assignedItems = actionTypeData.filter((item) => item.AssignedTo === getCurrentUser(props.currUser));
    if (assignedItems.length === 0 || showAll) {
      return actionTypeData;
    } else {
      return assignedItems;
    }
  };

  const refreshActionCodeLookup = async () => {
    API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.ActionType.NAME, '/', {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      props.setActionCodeLookup(JSON.parse(result.body));
    }).catch( (error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    });
  };

  return (
    <Container component='main' className={classes.mainStyle} maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12} >
          <TitlePaper className={classes.titleFields} variant='filled'>
            Search Results
          </TitlePaper>
          <TitlePaper className={classes.selectedCode} >
            {location.search.substring(2)}
          </TitlePaper>
          {actionTypeData.filter((item) => item.AssignedTo === getCurrentUser(props.currUser)).length !== 0 &&
            <Button sx={sxClasses.showAllButton} variant='contained' color='primary' onClick={() => {
              setShowAll(!showAll);
            }}>
              {showAll ? 'Show Only Assigned' : 'Show All'}
            </Button>
          }
          <Paper className={classes.contentSectionsLeft}>
            <Grid container spacing={0}>
              <ResultsPendingItem actionTypeData={handleAssignments()} setPendingData={props.setPendingData}
                setCustomerData={props.setCustomerData}
                sla={props.actionCodeLookup.filter(
                    (item)=>item.ActionType === location.search.substring(2))[0]?.SLA}/>
              {noResultsWarning}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <ExitButton variant='contained' color='primary' onClick={() => {
        navigate(-1);
      }}>
        Back
      </ExitButton>
    </Container>
  );
}
