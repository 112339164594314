// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { isCompleteProductCodeSchedule } from '../Libs/fileHelper';
import { isCarEnvironment } from '../Libs/StageConfig';
// Resources

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    width: '500px',
    margin: theme.spacing(1),
  },
  dialogTitle: {
    paddingTop: theme.spacing(1),
    textAlign: 'center',
  },
  cancelButtonStyle: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1),
  },
  sendButtonStyle: {
    width: '200px',
    position: 'absolute',
    left: theme.spacing(1),
    bottom: theme.spacing(1),
  },
  textFieldStyle: {
    width: '100%',
  },
  titleStyle: {
    margin: 'auto',
  },
  errorText: {
    'padding': theme.spacing(1),
    'color': theme.palette.error.main,
    'textAlign': 'center',
  },
}));

export default function EmailTemplateSelectPopup( props ) {
  const classes = useStyles();
  const emailTemplates = () => {
    if (isCarEnvironment() || isCompleteProductCodeSchedule(props.scheduleData)) {
      return (['Bank Detail Change', 'Cancellation Acknowledgement', 'Cancellation Quote']);
    } else {
      return (['Bank Detail Change', 'Cancellation Acknowledgement']);
    }
  };
  const cancellationReasons = [
    'New Vehicle With New Plan',
    'Vehicle At Max Contract Kms',
    'Finance Or Cw Plan Ended',
    'Customer Ceased Trading',
    'Customer Withdrawn',
    'Contract Misrepresented',
    'Customer Deceased',
    'Distance Sold, Cooling Period',
    'Contingent Complete',
    'Finance Request',
    'Vehicle Sold',
    'Cancellation Request (Other)',
    'Vehicle Stolen / Written Off',
  ];
  const [selectedTemplate, setSelectedTemplate] = React.useState('');


  EmailTemplateSelectPopup.propTypes = {
    popupOpen: PropTypes.bool.isRequired,
    setPopupOpen: PropTypes.func.isRequired,
    scheduleData: PropTypes.object.isRequired,
    selectedCancellationReason: PropTypes.string.isRequired,
    setSelectedCancellationReason: PropTypes.func.isRequired,
    selectTemplateHandleNext: PropTypes.func.isRequired,
  };

  function handleClose() {
    props.setPopupOpen(false);
  }

  function handleTemplateChange(event) {
    setSelectedTemplate(event.target.value);
  }

  function handleCancellationReasonChange(event) {
    props.setSelectedCancellationReason(event.target.value);
  }

  const showCancellationReason = (selectedTemplateValue) => {
    return (
      selectedTemplateValue === 'Cancellation Acknowledgement' ||
      selectedTemplateValue === 'Cancellation Quote'
    );
  };

  return (
    <Dialog open={props.popupOpen} onClose={handleClose} aria-labelledby="form-dialog-title" >
      <DialogTitle className={classes.dialogTitle} id="form-dialog-title">Send Email</DialogTitle>
      <DialogContent className={classes.dialogContainer}>
        <Grid container spacing={1} className={classes.textFieldStyle}>
          <Grid item xs={12} >
            <Grid container spacing={0} className={classes.textFieldStyle}>
              <Grid item xs={4} className={classes.titleStyle}>
                Selected Template:
              </Grid>
              <Grid item xs={8} >
                <TextField select id='txtEmailTemplate'
                  value={selectedTemplate}
                  onChange={handleTemplateChange}
                  className={classes.textFieldStyle}>
                  {emailTemplates().map((templates, i) => (
                    <MenuItem key={i} value={templates}>
                      {templates}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          { showCancellationReason(selectedTemplate) ?
            <Grid item xs={12} >
              <Grid container spacing={0}>
                <Grid item xs={4} className={classes.titleStyle}>
                  Cancellation Reason:
                </Grid>
                <Grid item xs={8} >
                  <TextField select id='txtEmailTemplate'
                    value={props.selectedCancellationReason}
                    onChange={handleCancellationReasonChange}
                    className={classes.textFieldStyle}>
                    {cancellationReasons.map((reasons, i) => (
                      <MenuItem key={i} value={reasons}>
                        {reasons}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid> :
          null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={()=>{
          props.selectTemplateHandleNext(selectedTemplate, setSelectedTemplate, showCancellationReason);
        }} color="primary">
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
}
