// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Components
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import TitlePaper from '../../Components/StyledMUIComponents/TitlePaper';
import { sumAgedData, upgradedDate, currencyFormatter } from '../../Libs/fileHelper';
// Resources
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    'height': '150px',
    'padding': theme.spacing(1),
    'borderColor': '#000000',
    'border': '1px solid',
  },
  titleFields: {
    'margin': '5px',
  },
  footerFields: {
    'width': '165px',
    'margin': '5px',
  },
  smallStyle: {
    marginTop: 1,
    width: '100%',
    backgroundColor: theme.palette.primary.backgroundFore,
  },
}));

export default function CustomerAgedDebt(
    { agedData, expanded, handleExpandedChange, agedLastUpdate },
) {
  const classes = useStyles();
  const sxClasses = {
    cellStyle: {
      padding: '2px',
    },
    footerFieldsValue: {
      position: 'absolute',
      marginLeft: '200px',
      marginTop: '-44px',
      width: '90px',
    },
  };

  CustomerAgedDebt.propTypes = {
    agedData: PropTypes.array.isRequired,
    expanded: PropTypes.bool.isRequired,
    handleExpandedChange: PropTypes.func.isRequired,
    agedLastUpdate: PropTypes.number,
  };

  return (
    <React.Fragment>
      { expanded ?
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={9} >
          <TitlePaper className={classes.titleFields}>
          Aged Debt
          </TitlePaper>
        </Grid>
        <Grid item xs={3} >
          <IconButton onClick={handleExpandedChange} edge="start" color="inherit" aria-label="menu">
            <ExpandLessIcon />
          </IconButton>
        </Grid>
      </Grid>
      <TableContainer component={Paper} className={classes.tableStyle}>
        <Table sx={sxClasses.cellStyle} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell sx={sxClasses.cellStyle}>Cofico Account</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Customer Name</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Total Debt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {agedData.map((row, index) => (
              <TableRow key={row.DocumentNo}>
                <TableCell sx={sxClasses.cellStyle} component='th' scope='row'>
                  {row.CustomerCofico}
                </TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>{row.CustomerName}</TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>{row.DebtAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {agedLastUpdate !== undefined &&
        <React.Fragment>
          <TitlePaper className={classes.footerFields} >
            Aged Debt Data From:
          </TitlePaper>
          <TitlePaper sx={sxClasses.footerFieldsValue}>
            {agedLastUpdate === 0 ? 'No Data' : upgradedDate(agedLastUpdate)}
          </TitlePaper>
        </React.Fragment>
      }
    </React.Fragment> :
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <TextField disabled={true}
            value={currencyFormatter(sumAgedData(agedData))}
            label={agedLastUpdate !== undefined && (agedLastUpdate === 0 ? 'No Aged Data Loaded' :
              'Aged Debt - Updated ' + upgradedDate(agedLastUpdate))}
            variant="outlined"
            className={classes.smallStyle}
            sx={{
              marginTop: 1,
            }}
            inputProps={{
              style: {
                padding: 8,
                color: '#000000',
                WebkitTextFillColor: 'unset',
              },
            }}
            id='txtAgedDebt'/>
        </Grid>
        <Grid item xs={2} >
          <IconButton onClick={handleExpandedChange} edge="start" color="inherit" aria-label="menu">
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
      </Grid>
      }
    </React.Fragment>
  );
}
