// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import DOMPurify from 'dompurify';
// Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { replaceAllOccurances } from '../Libs/fileHelper';
// Resources

const useStyles = makeStyles((theme) => ({
  previewTextBodyStyle: {
    height: '300px',
    width: '-webkit-fill-available',
    marginTop: theme.spacing(1),
    marginBottom: '3px',
    overflowY: 'auto',
    padding: '2px',
    border: '1px solid',
    borderColor: '#858585',
    borderRadius: '2px',
    fontFamily: 'monospace',
    opacity: '0.70',
    backgroundColor: '#F9F9F9',
  },
  confirmationContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function BulkEmailPreviewPopup({ emailConfiguration, setEmailConfiguration, enteredData,
  calculatedEmailBody, calculateSubject }) {
  const classes = useStyles();
  const sxClasses = {
    dialogTitle: {
      paddingTop: 1,
      textAlign: 'center',
    },
    iconButton: {
      position: 'absolute',
      top: 1,
      right: 1,
      padding: 0,
    },
    iconPreviewButton: {
      position: 'absolute',
      top: '234px',
      right: '24px',
      padding: 0,
    },
  };

  BulkEmailPreviewPopup.propTypes = {
    emailConfiguration: PropTypes.object.isRequired,
    setEmailConfiguration: PropTypes.func.isRequired,
    enteredData: PropTypes.array.isRequired,
    calculatedEmailBody: PropTypes.string.isRequired,
    calculateSubject: PropTypes.string.isRequired,
  };

  const handleClose = () => {
    setEmailConfiguration({
      ...emailConfiguration,
      previewOpen: false,
    });
  };

  const sanitiseEmailBodyHTML = () => {
    let cleanedBody = calculatedEmailBody;
    cleanedBody = replaceAllOccurances(cleanedBody, '\n', '<br>');
    cleanedBody = DOMPurify.sanitize(cleanedBody);
    return cleanedBody;
  };

  const handleNextPrevious = (value) => {
    const newIndex = emailConfiguration.previewIndex + value;
    console.log(newIndex);
    if (newIndex >= enteredData.length) {
      setEmailConfiguration({
        ...emailConfiguration,
        previewIndex: 0,
      });
      return;
    }
    if (newIndex < 0) {
      setEmailConfiguration({
        ...emailConfiguration,
        previewIndex: enteredData.length - 1,
      });
      return;
    }
    setEmailConfiguration({
      ...emailConfiguration,
      previewIndex: newIndex,
    });
  };

  return (
    <Dialog open={emailConfiguration.previewOpen} onClose={handleClose} aria-labelledby="form-dialog-title" >
      <DialogTitle sx={sxClasses.dialogTitle} id="form-dialog-title">Preview</DialogTitle>
      <DialogContent>
        {!emailConfiguration.generateLetter &&
          <>
            <TextField disabled
              margin="dense"
              id="emailSentFrom"
              label="Send From"
              type="email"
              variant='outlined'
              fullWidth
              value={emailConfiguration.emailSender}>
            </TextField>
            <TextField disabled
              margin="dense"
              id="emailSentTo"
              label="Send To"
              type="email"
              size='small'
              fullWidth
              value={enteredData[emailConfiguration.previewIndex]?.CustomerEmail || 'Missing Email'}
            />
            <TextField disabled
              margin="dense"
              id="emailSubject"
              label="Subject"
              type="subject"
              size='small'
              fullWidth
              value={calculateSubject}
            />
          </>
        }
        <div className={classes.previewTextBodyStyle}
          dangerouslySetInnerHTML={{
            __html: sanitiseEmailBodyHTML(),
          }}></div>
        {(emailConfiguration.includeAttachment && !emailConfiguration.generateLetter) &&
            'Attached: ' + (enteredData[emailConfiguration.previewIndex]?.Attachment || '')
        }
      </DialogContent>
      {enteredData.length > 1 &&
        <DialogActions>
          <Button onClick={()=>{
            handleNextPrevious(-1);
          }} fullWidth variant='contained' color='primary'>
            Previous
          </Button><Button onClick={()=>{
            handleNextPrevious(1);
          }} fullWidth variant='contained' color="primary">
            Next
          </Button>
        </DialogActions>
      }
      <DialogActions>
        <Button onClick={handleClose} fullWidth variant='contained' color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
