
import { replaceAllOccurances } from '../../Libs/fileHelper';

export const sxClasses = {
  mainContainer: {
    width: '80vw',
  },
  paperStyles: {
    position: 'relative',
    height: '80vh',
    marginTop: 5,
    padding: 1,
  },
  previewButtonStyle: {
    position: 'absolute',
    left: '0px',
    bottom: '54px',
    marginLeft: 1,
    width: 'calc(100% - 16px)',
  },
  sendButtonStyle: {
    position: 'absolute',
    left: '0px',
    bottom: '8px',
    marginLeft: 1,
    width: 'calc(100% - 16px)',
  },
  configureTemplatesButton: {
    marginTop: 0.5,
    marginBottom: 2,
  },
  tabStlye: {
    border: '1px solid',
    borderColor: '#000000',
  },
  tabStlyeBackGround: {
    border: '1px solid',
    borderColor: '#000000',
    backgroundColor: '#ADD8E6',
  },
};

export const iconFieldsArray = ['ChargeAmount', 'Status', 'CustomerAddress', 'ProductCode', 'Registration',
  'CustomerPostcode', 'ScheduleRemarks', 'CustomerCofico', 'PencePerMile', 'CustomerEmail', 'ScheduleNo',
  'CustomerName', 'PaymentFrequency', 'LaunchDate', 'RegistrationDate', 'ChargeAmountIncVAT', 'StartDate',
  'TerminatedDate', 'ChassisNo', 'EndDate', 'CustomerID', 'ModelGroup', 'CustomerPhone', 'Term',
  'ServicesAvailable', 'CustomerMobile'];

export const templatesConfigurationInformation = {
  templateName: {
    label: 'Template Name',
  },
  templateText: {
    label: 'Template text',
    type: 'longText',
  },
};

export const removeHTMLtags = (htmltext) => {
  const alterElements = replaceAllOccurances(htmltext, '<input type="checkbox">', '[  ]');
  const divContainer= document.createElement('div');
  divContainer.innerHTML = alterElements;
  return divContainer.textContent || divContainer.innerText || '';
};

export const startingTemplateText = () => {
  return ('Dear «CustomerName»,\n\n' +
    '<b>Mercedes-Benz Service Contract: «ScheduleID» - ' +
    'Vehicle Registration No: «Registration»</b>\n\n');
};

export const schedContainedInArray = (arr, schedule) => {
  if (arr != null && arr.length >0) {
    for (let i=0; i<arr.length; i++) {
      if (arr[i].ScheduleID === schedule) {
        return true;
      }
    }
  }
  return false;
};

export const convertTrueFalseForTabs = (value) => {
  if (value === true) {
    return 1;
  }
  return 0;
};

export const convertTabsValueToTrueFalse = (value) => {
  if (value === 1) {
    return true;
  }
  return false;
};
