// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { getCurrentUser, checkReadOnlyGroup } from '../../Libs/fileHelper';
// Components
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import EmailConfigPopup from './EmailConfigPopup';
// Resources

export default function AuthProfile({ handleLogout, currUser, emailConfigData, setEmailConfigData,
  setSnackbarProps }) {
  const sxClasses = {
    authProfile: {
      padding: 1.5,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    name: {
      margin: 0.5,
      textTransform: 'capitalize',
    },
    button: {
      margin: 0.5,
      fontSize: '11px',
      width: '250px',
    },
    divider: {
      width: '100%',
      marginTop: 1,
      marginBottom: 1,
    },
  };

  AuthProfile.propTypes = {
    handleLogout: PropTypes.func.isRequired,
    currUser: PropTypes.object,
    emailConfigData: PropTypes.object.isRequired,
    setEmailConfigData: PropTypes.func.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
  };

  const openEmailConfig = () => {
    setEmailConfigData({
      ...emailConfigData,
      showPopup: true,
    });
  };

  return (
    <Paper>
      <Container sx={sxClasses.authProfile} maxWidth="xs">
        <Typography sx={sxClasses.name} variant="body1">
          {getCurrentUser(currUser)}
        </Typography>
        {!checkReadOnlyGroup(currUser) &&
          <Button onClick={openEmailConfig} sx={sxClasses.button}
            variant="contained" color="primary">Email Signature Config</Button>
        }
        <Divider sx={sxClasses.divider} variant="middle"/>
        <Button onClick={handleLogout} sx={sxClasses.button}
          variant="contained" color="primary">Sign Out</Button>
      </Container>
      <EmailConfigPopup emailConfigData={emailConfigData} setEmailConfigData={setEmailConfigData}
        setSnackbarProps={setSnackbarProps} />
    </Paper>
  );
}
