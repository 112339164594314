// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Routes } from 'react-router-dom';
import { checkAdminGroup } from '../Libs/fileHelper';
// Components
import AdminPage from '../Containers/AdminPage';
import BulkCommunicationsPage from '../Containers/BulkCommunicationsPage';
import CustomerPage from '../Containers/CustomerPage';
import InvoiceManagementPage from '../Containers/InvoiceManagementPage';
import DeceasedPage from '../Containers/DeceasedPage';
import DecProgressPage from '../Containers/DecProgressPage';
import FrontPage from '../Containers/FrontPage';
import FlexPage from '../Containers/FlexPage';
import NotFound from '../Containers/NotFound';
import SignIn from '../Containers/SignIn';
import ReportingPage from '../Containers/ReportingPage';
import ResultsPage from '../Containers/ResultsPage';
import TerminationsPage from '../Containers/TerminationsPage';
import WorkAssignment from '../Containers/WorkAssignmentPage';
import { isVanEnvironment } from '../Libs/StageConfig';
// Resources

export default function RoutesAll({ appProps }) {
  RoutesAll.propTypes = {
    appProps: PropTypes.object.isRequired,
  };

  return (
    <Routes>
      {appProps.isAuthenticated & checkAdminGroup(appProps.currUser) &&
        <>
          <Route path='/Reporting' element={<ReportingPage {...appProps}/>} />
          <Route path='/Admin' element={<AdminPage {...appProps}/>} />
          <Route path='/WorkAssignment' element={<WorkAssignment {...appProps}/>} />
          <Route path='/BulkCommunication' element={<BulkCommunicationsPage {...appProps}/>} />
        </>
      }
      {appProps.isAuthenticated &&
        <>
          <Route path='/' element={<FrontPage {...appProps}/>} />
          <Route path='/Results' element={<ResultsPage {...appProps}/>} />
          <Route path='/Customer' element={<CustomerPage {...appProps}/>} />
          <Route path='/Termination' element={<TerminationsPage {...appProps}/>} />
          <Route path='/DecPendingItem/:PendingItem'
            element={<DeceasedPage {...appProps}/>} />
          <Route path='/DecProgress' element={<DecProgressPage {...appProps}/>} />
          {isVanEnvironment() &&
            <Route path='/FlexManagement/:CustomerID/:ScheduleIDend'
              element={<FlexPage {...appProps}/>} />
          }
          {isVanEnvironment() &&
            <Route path='/InvoiceManagement/:CustomerID'
              element={<InvoiceManagementPage {...appProps}/>} />
          }
        </>
      }

      <Route path="/SignIn" element={<SignIn {...appProps} />} />

      { /* Finally, catch all unmatched routes */ }
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}
