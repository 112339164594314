// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { upgradedDate, getSLAStyle } from '../Libs/fileHelper';
import { emptyCustomerData } from '../Libs/emptyDataStructures';
// Components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// Resources

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    padding: '2px',
  },
  cellStyle: {
    padding: '2px',
  },
  cellFixedWidthStyle: {
    padding: '2px',
    minWidth: '200px',
  },
  refCellStyle: {
    padding: '2px',
    paddingLeft: theme.spacing(1),
    maxHeight: '20px',
    maxWidth: '240px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  slaGreen: theme.palette.slaGreen,
  slaAmber: theme.palette.slaAmber,
  slaRed: theme.palette.slaRed,
  emptyStyle: {},
}));

export default function ResultsPendingItem({ actionTypeData, setCustomerData, setPendingData, sla }) {
  const classes = useStyles();
  const navigate = useNavigate();

  ResultsPendingItem.propTypes = {
    actionTypeData: PropTypes.array.isRequired,
    setCustomerData: PropTypes.func.isRequired,
    setPendingData: PropTypes.func.isRequired,
    sla: PropTypes.string,
  };

  const navigateToCustomer = (customerID) => {
    setCustomerData(emptyCustomerData);
    setPendingData([]);
    navigate('/Customer?=' + customerID);
  };

  function sortArrayByCreatedAndScheduled(array) {
    return array.sort(function(a, b) {
      const x = handleNullScheduled(a['CreatedDate'], a['ScheduledDate']);
      const y = handleNullScheduled(b['CreatedDate'], b['ScheduledDate']);
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  function handleNullScheduled(value1, value2) {
    if (value2 === null || value2 === '' || value2 === undefined) {
      return parseInt(value1);
    } else {
      return parseInt(value2);
    }
  }

  const handleStyle = (createdDate, sla) => {
    if (sla) {
      const slastyle = getSLAStyle(createdDate, sla, classes);
      return slastyle;
    }
    return classes.emptyStyle;
  };

  return (
    <TableContainer>
      <Table className={classes.tableStyle} size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableStyle}>Schedule ID</TableCell>
            <TableCell className={classes.tableStyle} align='center'>Created Date</TableCell>
            <TableCell className={classes.tableStyle} align='center'>CreatedBy</TableCell>
            <TableCell className={classes.tableStyle} align='center'>Scheduled Date</TableCell>
            <TableCell className={classes.tableStyle} align='center'>Customer Name</TableCell>
            <TableCell className={classes.tableStyle} align='center'>CustomerID</TableCell>
            <TableCell className={classes.tableStyle} align='center'>Status</TableCell>
            <TableCell className={classes.tableStyle} align='center'>Reference</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortArrayByCreatedAndScheduled(actionTypeData).map((row) => (
            <TableRow key={row.ID} className={handleStyle(row.CreatedDate, sla)} onClick={()=>{
              navigateToCustomer(row.CustomerID);
            }}>
              <TableCell className={classes.cellStyle} component='th' scope='row'>
                {row.ScheduleID}</TableCell>
              <TableCell className={classes.cellStyle} align='center'>
                {upgradedDate(row.CreatedDate)}</TableCell>
              <TableCell className={classes.cellFixedWidthStyle} align='center'>{row.CreatedBy}</TableCell>
              <TableCell className={classes.cellStyle} align='center'>
                {upgradedDate(row.ScheduledDate)}</TableCell>
              <TableCell className={classes.cellFixedWidthStyle} align='center'>{row.CustomerName}</TableCell>
              <TableCell className={classes.cellStyle} align='center'>{row.CustomerID}</TableCell>
              <TableCell className={classes.cellStyle} align='center'>{row.Status}</TableCell>
              <TableCell className={classes.refCellStyle} align='center'>{row.Reference}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
