// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
// Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import config from '../Libs/Config';
import { currentStage, getCurrentEnvironment } from '../Libs/StageConfig';

export default function CustomerMigrationPopup({ popupOpen, setPopupOpen, setSnackbarProps }) {
  const sxClasses = {
    dialogTitle: {
      paddingTop: 1,
      textAlign: 'center',
    },
  };

  const [oldCustomerID, setOldCustomerID] = React.useState('');
  const [newCustomerID, setNewCustomerID] = React.useState('');
  const [migrationMessage, setMigrationMessage] = React.useState(false);
  const migratingInProgress = React.useRef(false);

  CustomerMigrationPopup.propTypes = {
    popupOpen: PropTypes.bool.isRequired,
    setPopupOpen: PropTypes.func.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  function handleChangeOldContract(event) {
    setOldCustomerID(event.target.value.trim().toUpperCase());
  }

  function handleChangeNewContract(event) {
    setNewCustomerID(event.target.value.trim().toUpperCase());
  }

  const getPendHistData = async () => {
    // Call PendHist List
    return await API.get(
        config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory.NAME, '/getcustomerid', {
          queryStringParameters: {
            CustomerID: oldCustomerID,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((result) => {
      return JSON.parse(result.body);
    }).catch( (error) => {
      return [];
    });
  };

  const migrateData = async () => {
    return await API.post(
        config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory.NAME, '/deceasedCopy', {
          body: {
            oldCustomerID: oldCustomerID,
            newCustomerID: newCustomerID,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((result) => {
      if (result.statusCode !== 200) {
        setSnackbarProps({ open: true,
          message: 'Something went Wrong: ' + result.body,
          severity: 'error' });
      }
      return { statusCode: result.statusCode, body: result.body };
    }).catch( (error) => {
      setSnackbarProps({ open: true,
        message: 'Something went Wrong: ' + error,
        severity: 'error' });
      return { statusCode: 201 };
    });
  };

  const handleSave = async () => {
    if (!oldCustomerID) {
      setSnackbarProps({ open: true,
        message: 'Please enter the Old Customer ID you wish to migrate.',
        severity: 'error' });
      return;
    }
    if (!newCustomerID) {
      setSnackbarProps({ open: true,
        message: 'Please enter the New Customer ID you wish to migrate the data to.',
        severity: 'error' });
      return;
    }
    if (!migratingInProgress.current) {
      migratingInProgress.current = true;
      setMigrationMessage(true);
      const currentPendingItems = await getPendHistData();
      if (currentPendingItems.length === 0) {
        setSnackbarProps({ open: true,
          message: 'No pending items found on customer id ' + oldCustomerID + ' to migrate.',
          severity: 'error' });
        migratingInProgress.current = false;
        setMigrationMessage(false);
        return;
      }
      const migrateDataResponse = await migrateData();
      if (migrateDataResponse.statusCode === 200) {
        setSnackbarProps({ open: true,
          message: migrateDataResponse.body,
          severity: 'success' });
        setOldCustomerID('');
        setNewCustomerID('');
        closePopup();
      }
      migratingInProgress.current = false;
      setMigrationMessage(false);
    } else {
      setSnackbarProps({ open: true,
        message: 'Migration currently in Progress.',
        severity: 'info' });
    }
  };

  return (
    <Dialog open={popupOpen} aria-labelledby="form-dialog-title"
      maxWidth={false}>
      <DialogTitle sx={sxClasses.dialogTitle} id="form-dialog-title">
        Customer Pending And History Migration
      </DialogTitle>
      <DialogContent>
        <TextField fullWidth id='oldCustomer' label='Old Customer ID'
          value={oldCustomerID} onChange={handleChangeOldContract}
          variant='standard'/>
        <TextField fullWidth id='newCustomer' label='New Customer iD'
          value={newCustomerID} onChange={handleChangeNewContract}
          variant='standard'/>
        {migrationMessage && 'Migration in Progress...'}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
        <Button onClick={closePopup} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
