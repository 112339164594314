// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
// Resources
import { currencyFormatter } from '../../Libs/fileHelper';
import { carVanSwitch } from '../../Libs/StageConfig';


const useStyles = makeStyles((theme) => ({
  tableStlye: {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(0),
  },
  remarksStyle: {
    fontSize: '0.875rem',
  },
}));

export default function ScheduleDetails(props) {
  const classes = useStyles();
  const cellStyle = {
    padding: '2px',
    borderBottom: '0px',
  };

  ScheduleDetails.propTypes = {
    scheduleData: PropTypes.shape({
      ScheduleID: PropTypes.string.isRequired,
      ChassisNo: PropTypes.string,
      Registration: PropTypes.string,
      CustomerCofico: PropTypes.string,
      StartDate: PropTypes.string,
      ProductCode: PropTypes.string,
      EndDate: PropTypes.string,
      PaymentFrequency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      RegistrationDate: PropTypes.string,
      ModelGroup: PropTypes.string,
      PencePerMile: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      LaunchDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      Term: PropTypes.number,
      Status: PropTypes.string,
      IsSuspended: PropTypes.string,
      ServicesAvailable: PropTypes.number,
      ScheduleRemarks: PropTypes.string,
      ChargeAmount: PropTypes.number,
      OneOffAmount: PropTypes.number,
    }),
  };

  const getScheduleDetailsData = () => {
    return carVanSwitch(
        scheduleDetailsData.filter((item)=>(item.header !== 'Pence Per KM')),
        scheduleDetailsData);
  };

  const scheduleDetailsData = [{
    header: 'Schedule ID',
    value: props.scheduleData?.ScheduleID,
  }, {
    header: 'Chassis No',
    value: props.scheduleData?.ChassisNo,
  }, {
    header: 'Registration',
    value: props.scheduleData?.Registration,
  }, {
    header: 'Cofico No',
    value: props.scheduleData?.CustomerCofico,
  }, {
    header: 'Start Date',
    value: props.scheduleData?.StartDate,
  }, {
    header: 'Product Code',
    value: props.scheduleData?.ProductCode,
  }, {
    header: 'End Date',
    value: props.scheduleData?.EndDate,
  }, {
    header: 'Pay Freq',
    value: props.scheduleData?.PaymentFrequency,
  }, {
    header: 'Registration Date',
    value: props.scheduleData?.RegistrationDate,
  }, {
    header: 'Model Code',
    value: props.scheduleData?.ModelGroup,
  }, {
    header: 'Pence Per KM',
    value: props.scheduleData?.PencePerMile,
  }, {
    header: 'Launch Date',
    value: props.scheduleData?.LaunchDate,
  }, {
    header: 'Term',
    value: props.scheduleData?.Term,
  }, {
    header: 'Status',
    value: props.scheduleData?.Status,
  }, {
    header: 'Schedule Services',
    value: props.scheduleData?.ServicesAvailable,
  }, {
    header: 'Schedule Charge Amt',
    value: 'NET: ' + currencyFormatter(props.scheduleData?.ChargeAmount) + ' // GROSS: ' +
      currencyFormatter(props.scheduleData?.ChargeAmount*1.2),
  }, {
    header: 'One Off Amt',
    value: currencyFormatter(props.scheduleData?.OneOffAmount),
  }];
  const rowData = [0, 2, 4, 6, 8, 10, 12, 14];

  return (
    <TableContainer className={classes.tableStlye} >
      <Table size='small' aria-label='a dense table'>
        <TableBody>
          {rowData.map((row) => (
            <TableRow key={row}>
              <TableCell sx={cellStyle} component='th' scope='row'>
                {getScheduleDetailsData()[row]?.header}
              </TableCell>
              <TableCell sx={cellStyle} align='center'>
                {getScheduleDetailsData()[row]?.value}
              </TableCell>
              <TableCell sx={cellStyle} component='th' scope='row'>
                {getScheduleDetailsData()[row+1]?.header}
              </TableCell>
              <TableCell sx={cellStyle} align='center'>
                {getScheduleDetailsData()[row+1]?.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.remarksStyle}>
        Schedule Remarks: {props.scheduleData?.ScheduleRemarks}
      </div>
    </TableContainer>
  );
}
