export default {
  car: {
    dev: {
      cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_1Vz4YvDTU',
        APP_CLIENT_ID: '5qn82jf1l1ffsgjh9inpevt0rf',
        IDENTITY_POOL_ID: 'eu-west-1:99734019-b075-470f-b59b-81f6f366efe9',
        DOMAIN: 'dev-saltmine2-domain.auth.eu-west-1.amazoncognito.com',
        SCOPE: ['openid', 'aws.cognito.signin.user.admin'],
        // REDIRECT_SIGN_IN: 'http://localhost:3000/',
        REDIRECT_SIGN_IN: 'https://dm2fh45ncexrg.cloudfront.net',
        REDIRECT_SIGN_OUT: 'https://sso-int.mercedes-benz.com/idp/startSLO.ping',
        RESPONSE_TYPE: 'code',
      },
      apiGateway: {
        PendingHistory: {
          NAME: 'Saltmine-API-PendingHistory',
          REGION: 'eu-west-1',
          URL: 'https://xkkc2c6qp8.execute-api.eu-west-1.amazonaws.com/dev',
        },
        ICONInfo: {
          NAME: 'Saltmine-API-ICONData',
          REGION: 'eu-west-1',
          URL: 'https://ri4eouwsa4.execute-api.eu-west-1.amazonaws.com/dev',
        },
        ActionType: {
          NAME: 'Saltmine-API-ActionType',
          REGION: 'eu-west-1',
          URL: 'https://ok51zmz9s6.execute-api.eu-west-1.amazonaws.com/dev',
        },
        UserInfo: {
          NAME: 'Saltmine-API-UserData',
          REGION: 'eu-west-1',
          URL: 'https://bv72ynbkak.execute-api.eu-west-1.amazonaws.com/dev',
        },
        ClaimsData: {
          NAME: 'Saltmine-API-Claims',
          REGION: 'eu-west-1',
          URL: 'https://wedip0n1fc.execute-api.eu-west-1.amazonaws.com/dev',
        },
        AgedData: {
          NAME: 'Saltmine-API-Aged',
          REGION: 'eu-west-1',
          URL: 'https://zsjgdwrnsl.execute-api.eu-west-1.amazonaws.com/dev',
        },
        Email: {
          NAME: 'Saltmine-API-Email',
          REGION: 'eu-west-1',
          URL: 'https://fa7gpiykij.execute-api.eu-west-1.amazonaws.com/dev',
        },
      },
      s3: {
        REGION: 'eu-west-1',
        BUCKET: 'saltmine-uploads-dev',
      },
      appSync: {
        REGION: 'eu-west-1',
        ENDPOINT: 'https://dgtollie5zezncir2dcbsx22s4.appsync-api.eu-west-1.amazonaws.com/graphql',
      },
    },
    uat: {
      cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_nWRFVAdLC',
        APP_CLIENT_ID: '76du7n9stnmvq441d75lfkf3aq',
        IDENTITY_POOL_ID: 'eu-west-1:4a48eb75-526e-4c96-8049-59cb54de22a9',
        DOMAIN: 'uat-saltmine2-domain.auth.eu-west-1.amazoncognito.com',
        SCOPE: ['openid', 'aws.cognito.signin.user.admin'],
        // REDIRECT_SIGN_IN: 'http://localhost:3000/',
        REDIRECT_SIGN_IN: 'https://d2z2v3up30okvt.cloudfront.net',
        REDIRECT_SIGN_OUT: 'https://sso-int.mercedes-benz.com/idp/startSLO.ping',
        RESPONSE_TYPE: 'code',
      },
      apiGateway: {
        PendingHistory: {
          NAME: 'Saltmine-API-PendingHistory',
          REGION: 'eu-west-1',
          URL: 'https://94gvrrdhjf.execute-api.eu-west-1.amazonaws.com/uat',
        },
        ICONInfo: {
          NAME: 'Saltmine-API-ICONData',
          REGION: 'eu-west-1',
          URL: 'https://hq2jh6hwbe.execute-api.eu-west-1.amazonaws.com/uat',
        },
        ActionType: {
          NAME: 'Saltmine-API-ActionType',
          REGION: 'eu-west-1',
          URL: 'https://jezm1wzneg.execute-api.eu-west-1.amazonaws.com/uat',
        },
        UserInfo: {
          NAME: 'Saltmine-API-UserData',
          REGION: 'eu-west-1',
          URL: 'https://elujtggl1c.execute-api.eu-west-1.amazonaws.com/uat',
        },
        ClaimsData: {
          NAME: 'Saltmine-API-Claims',
          REGION: 'eu-west-1',
          URL: 'https://y4f724b609.execute-api.eu-west-1.amazonaws.com/uat',
        },
        AgedData: {
          NAME: 'Saltmine-API-Aged',
          REGION: 'eu-west-1',
          URL: 'https://8eifn2aa81.execute-api.eu-west-1.amazonaws.com/uat',
        },
        Email: {
          NAME: 'Saltmine-API-Email',
          REGION: 'eu-west-1',
          URL: 'https://erf7lcymp5.execute-api.eu-west-1.amazonaws.com/uat',
        },
      },
      s3: {
        REGION: 'eu-west-1',
        BUCKET: 'saltmine-uploads-uat',
      },
      appSync: {
        REGION: 'eu-west-1',
        ENDPOINT: 'https://crpcicu3nvf7dddornu3acxshi.appsync-api.eu-west-1.amazonaws.com/graphql',
      },
    },
    prod: {
      cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_JLQHifWMO',
        APP_CLIENT_ID: 'l2bp84kacpmmf6sg8rsmpsjfb',
        IDENTITY_POOL_ID: 'eu-west-1:48ab48b0-d738-49ef-8a8f-11626db8ce44',
        DOMAIN: 'prod-saltmine2-domain.auth.eu-west-1.amazoncognito.com',
        SCOPE: ['openid', 'aws.cognito.signin.user.admin'],
        REDIRECT_SIGN_IN: 'https://www.mbfs-saltmine.com',
        REDIRECT_SIGN_OUT: 'https://sso.mercedes-benz.com/idp/startSLO.ping',
        RESPONSE_TYPE: 'code',
      },
      apiGateway: {
        PendingHistory: {
          NAME: 'Saltmine-API-PendingHistory',
          REGION: 'eu-west-1',
          URL: 'https://8khantoh45.execute-api.eu-west-1.amazonaws.com/prod',
        },
        ICONInfo: {
          NAME: 'Saltmine-API-ICONData',
          REGION: 'eu-west-1',
          URL: 'https://cu1mk9nxbk.execute-api.eu-west-1.amazonaws.com/prod',
        },
        ActionType: {
          NAME: 'Saltmine-API-ActionType',
          REGION: 'eu-west-1',
          URL: 'https://h3zrws7wq8.execute-api.eu-west-1.amazonaws.com/prod',
        },
        UserInfo: {
          NAME: 'Saltmine-API-UserData',
          REGION: 'eu-west-1',
          URL: 'https://m0vihqm1xd.execute-api.eu-west-1.amazonaws.com/prod',
        },
        ClaimsData: {
          NAME: 'Saltmine-API-Claims',
          REGION: 'eu-west-1',
          URL: 'https://7b16rwrimc.execute-api.eu-west-1.amazonaws.com/prod',
        },
        AgedData: {
          NAME: 'Saltmine-API-Aged',
          REGION: 'eu-west-1',
          URL: 'https://rji52lbcx4.execute-api.eu-west-1.amazonaws.com/prod',
        },
        Email: {
          NAME: 'Saltmine-API-Email',
          REGION: 'eu-west-1',
          URL: 'https://1ihgotvmlk.execute-api.eu-west-1.amazonaws.com/prod',
        },
      },
      s3: {
        REGION: 'eu-west-1',
        BUCKET: 'saltmine-uploads-prod',
      },
      appSync: {
        REGION: 'eu-west-1',
        ENDPOINT: 'https://nt4d2e5f7vhypfm3o5kc35mrpi.appsync-api.eu-west-1.amazonaws.com/graphql',
      },
    },
  },
  van: {
    dev: {
      cognito: {
        REGION: 'eu-west-2',
        USER_POOL_ID: 'eu-west-2_vsm6Ofs7M',
        APP_CLIENT_ID: '60u65jssnbbs0alccu1j6igm8f',
        IDENTITY_POOL_ID: 'eu-west-2:0d90e30d-aa8a-4427-a362-ba8a682d5a8f',
        DOMAIN: 'dev-saltmine-van2-domain.auth.eu-west-2.amazoncognito.com',
        SCOPE: ['openid', 'aws.cognito.signin.user.admin'],
        // REDIRECT_SIGN_IN: 'http://localhost:3000/',
        REDIRECT_SIGN_IN: 'https://d3h0h089kpjm6c.cloudfront.net',
        REDIRECT_SIGN_OUT: 'https://sso-int.mercedes-benz.com/idp/startSLO.ping',
        RESPONSE_TYPE: 'code',
      },
      apiGateway: {
        PendingHistory: {
          NAME: 'Saltmine-Van-API-PendingHistory',
          REGION: 'eu-west-2',
          URL: 'https://9c7elyol27.execute-api.eu-west-2.amazonaws.com/dev',
        },
        ICONInfo: {
          NAME: 'Saltmine-Van-API-ICONData',
          REGION: 'eu-west-2',
          URL: 'https://vhmcegffw5.execute-api.eu-west-2.amazonaws.com/dev',
        },
        ActionType: {
          NAME: 'Saltmine-Van-API-ActionType',
          REGION: 'eu-west-2',
          URL: 'https://d26zf07yel.execute-api.eu-west-2.amazonaws.com/dev',
        },
        UserInfo: {
          NAME: 'Saltmine-Van-API-UserData',
          REGION: 'eu-west-2',
          URL: 'https://fn891vpdtd.execute-api.eu-west-2.amazonaws.com/dev',
        },
        ClaimsData: {
          NAME: 'Saltmine-Van-API-Claims',
          REGION: 'eu-west-2',
          URL: 'https://21qhejjwq0.execute-api.eu-west-2.amazonaws.com/dev',
        },
        AgedData: {
          NAME: 'Saltmine-Van-API-Aged',
          REGION: 'eu-west-2',
          URL: 'https://30xcu1872c.execute-api.eu-west-2.amazonaws.com/dev',
        },
        Email: {
          NAME: 'Saltmine-API-Email',
          REGION: 'eu-west-2',
          URL: 'https://j3orqgi64a.execute-api.eu-west-2.amazonaws.com/dev',
        },
        Flex: {
          NAME: 'Saltmine-Van-API-Flex',
          REGION: 'eu-west-2',
          URL: 'https://ap4x1gubga.execute-api.eu-west-2.amazonaws.com/dev',
        },
        Invoice: {
          NAME: 'Saltmine-Van-API-Invoice',
          REGION: 'eu-west-2',
          URL: 'https://02oew2gcbl.execute-api.eu-west-2.amazonaws.com/dev',
        },
        Aux: {
          NAME: 'Saltmine-Van-API-Aux',
          REGION: 'eu-west-2',
          URL: 'https://sy1hh2go99.execute-api.eu-west-2.amazonaws.com/dev',
        },
      },
      s3: {
        REGION: 'eu-west-2',
        BUCKET: 'saltmine-van-uploads-dev',
      },
      appSync: {
        REGION: 'eu-west-2',
        ENDPOINT: 'https://dbylmcnuxffsle6namouuhrasa.appsync-api.eu-west-2.amazonaws.com/graphql',
      },
    },
    uat: {
      cognito: {
        REGION: 'eu-west-2',
        USER_POOL_ID: 'eu-west-2_x27Qd8l5u',
        APP_CLIENT_ID: '5qrcnkkt87ujnv6vkc3ro7c9nt',
        IDENTITY_POOL_ID: 'eu-west-2:d52ecc6a-7017-475b-9bb1-c7084023f6e6',
        DOMAIN: 'uat-saltmine-van2-domain.auth.eu-west-2.amazoncognito.com',
        SCOPE: ['openid', 'aws.cognito.signin.user.admin'],
        // REDIRECT_SIGN_IN: 'http://localhost:3000/',
        REDIRECT_SIGN_IN: 'https://dvshn82odlha2.cloudfront.net',
        REDIRECT_SIGN_OUT: 'https://sso-int.mercedes-benz.com/idp/startSLO.ping',
        RESPONSE_TYPE: 'code',
      },
      apiGateway: {
        PendingHistory: {
          NAME: 'Saltmine-Van-API-PendingHistory',
          REGION: 'eu-west-2',
          URL: 'https://pm4wlb20n6.execute-api.eu-west-2.amazonaws.com/uat',
        },
        ICONInfo: {
          NAME: 'Saltmine-Van-API-ICONData',
          REGION: 'eu-west-2',
          URL: 'https://11oc1xr4v2.execute-api.eu-west-2.amazonaws.com/uat',
        },
        ActionType: {
          NAME: 'Saltmine-Van-API-ActionType',
          REGION: 'eu-west-2',
          URL: 'https://lh1hyno0f7.execute-api.eu-west-2.amazonaws.com/uat',
        },
        UserInfo: {
          NAME: 'Saltmine-Van-API-UserData',
          REGION: 'eu-west-2',
          URL: 'https://driwyn7gy3.execute-api.eu-west-2.amazonaws.com/uat',
        },
        ClaimsData: {
          NAME: 'Saltmine-Van-API-Claims',
          REGION: 'eu-west-2',
          URL: 'https://9xbt82rrh1.execute-api.eu-west-2.amazonaws.com/uat',
        },
        AgedData: {
          NAME: 'Saltmine-Van-API-Aged',
          REGION: 'eu-west-2',
          URL: 'https://b5rs9dn1t5.execute-api.eu-west-2.amazonaws.com/uat',
        },
        Email: {
          NAME: 'Saltmine-API-Email',
          REGION: 'eu-west-2',
          URL: 'https://lhfszrqdla.execute-api.eu-west-2.amazonaws.com/uat',
        },
        Flex: {
          NAME: 'Saltmine-Van-API-Flex',
          REGION: 'eu-west-2',
          URL: 'https://gsjibjhg4h.execute-api.eu-west-2.amazonaws.com/uat',
        },
        Invoice: {
          NAME: 'Saltmine-Van-API-Invoice',
          REGION: 'eu-west-2',
          URL: 'https://fcy18smogk.execute-api.eu-west-2.amazonaws.com/uat',
        },
        Aux: {
          NAME: 'Saltmine-Van-API-Aux',
          REGION: 'eu-west-2',
          URL: 'https://ttyygspa9l.execute-api.eu-west-2.amazonaws.com/uat',
        },
      },
      s3: {
        REGION: 'eu-west-2',
        BUCKET: 'saltmine-van-uploads-uat',
      },
      appSync: {
        REGION: 'eu-west-2',
        ENDPOINT: 'https://qc3tt3iv4bhhli4e3aogzi2jia.appsync-api.eu-west-2.amazonaws.com/graphql',
      },
    },
    prod: {
      cognito: {
        REGION: 'eu-west-2',
        USER_POOL_ID: 'eu-west-2_HlpxVKUhB',
        APP_CLIENT_ID: '37nbrd8btvmk405rgkomjufnb1',
        IDENTITY_POOL_ID: 'eu-west-2:4a515598-116c-4c21-8feb-b3d91c5dd541',
        DOMAIN: 'prod-saltmine-van2-domain.auth.eu-west-2.amazoncognito.com',
        SCOPE: ['openid', 'aws.cognito.signin.user.admin'],
        REDIRECT_SIGN_IN: 'https://www.mbfs-saltminevan.com',
        REDIRECT_SIGN_OUT: 'https://sso.mercedes-benz.com/idp/startSLO.ping',
        RESPONSE_TYPE: 'code',
      },
      apiGateway: {
        PendingHistory: {
          NAME: 'Saltmine-Van-API-PendingHistory',
          REGION: 'eu-west-2',
          URL: 'https://y0l02lgq9g.execute-api.eu-west-2.amazonaws.com/prod',
        },
        ICONInfo: {
          NAME: 'Saltmine-Van-API-ICONData',
          REGION: 'eu-west-2',
          URL: 'https://782z3jz5b0.execute-api.eu-west-2.amazonaws.com/prod',
        },
        ActionType: {
          NAME: 'Saltmine-Van-API-ActionType',
          REGION: 'eu-west-2',
          URL: 'https://ro92j4v9w5.execute-api.eu-west-2.amazonaws.com/prod',
        },
        UserInfo: {
          NAME: 'Saltmine-Van-API-UserData',
          REGION: 'eu-west-2',
          URL: 'https://0ne63qm1xl.execute-api.eu-west-2.amazonaws.com/prod',
        },
        ClaimsData: {
          NAME: 'Saltmine-Van-API-Claims',
          REGION: 'eu-west-2',
          URL: 'https://1dn1vmqowh.execute-api.eu-west-2.amazonaws.com/prod',
        },
        AgedData: {
          NAME: 'Saltmine-Van-API-Aged',
          REGION: 'eu-west-2',
          URL: 'https://zh3zi1gj3g.execute-api.eu-west-2.amazonaws.com/prod',
        },
        Email: {
          NAME: 'Saltmine-API-Email',
          REGION: 'eu-west-2',
          URL: 'https://9l2qbujrkb.execute-api.eu-west-2.amazonaws.com/prod',
        },
        Flex: {
          NAME: 'Saltmine-Van-API-Flex',
          REGION: 'eu-west-2',
          URL: 'https://uuvwyiglm8.execute-api.eu-west-2.amazonaws.com/prod',
        },
        Invoice: {
          NAME: 'Saltmine-Van-API-Invoice',
          REGION: 'eu-west-2',
          URL: 'https://89mmo9md7g.execute-api.eu-west-2.amazonaws.com/prod',
        },
        Aux: {
          NAME: 'Saltmine-Van-API-Aux',
          REGION: 'eu-west-2',
          URL: 'https://dyrmumjg3l.execute-api.eu-west-2.amazonaws.com/prod',
        },
      },
      s3: {
        REGION: 'eu-west-2',
        BUCKET: 'saltmine-van-uploads-prod',
      },
      appSync: {
        REGION: 'eu-west-2',
        ENDPOINT: 'https://7pnzcaqzpraenknw2gmmnkvc3y.appsync-api.eu-west-2.amazonaws.com/graphql',
      },
    },
  },
};
