

let pasteCallTime = Date.now()-2000;

export const pasteSpamPrevention = () => {
  if (Date.now() - pasteCallTime < 2000) {
    return true;
  }
  pasteCallTime = Date.now();
  return false;
};

export const convertPasteDataToArray = (e) => {
  if (typeof e?.clipboardData?.getData !== 'function') return [];
  const pasteData = e.clipboardData.getData('text/plain');
  // Intercept Paste events
  if (pasteData === undefined || pasteData === '' || pasteData === null) {
    return [];
  }

  const lineSplitPasteDataArray = pasteData.split('\r\n');
  if (lineSplitPasteDataArray === null || lineSplitPasteDataArray.length === 0) {
    return [];
  }

  return lineSplitPasteDataArray;
};
