/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateSessionRecord = /* GraphQL */ `
  subscription onCreateSessionRecord {
    onCreateSessionRecord {
      id
      timeCreated
      user
    }
  }
`;
