// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
// Components
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { upgradedDate } from '../../Libs/fileHelper';
// Resources

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    padding: '2px',
  },
  cellStyle: {
    padding: '2px',
  },
  cellFixedWidthStyle: {
    padding: '2px',
    minWidth: '200px',
  },
  refCellStyle: {
    padding: '2px',
    paddingLeft: theme.spacing(1),
    maxHeight: '20px',
    maxWidth: '240px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export default function DecResultsPendingItem({ decStages }) {
  const classes = useStyles();

  DecResultsPendingItem.propTypes = {
    decStages: PropTypes.array.isRequired,
  };

  return (
    <TableContainer>
      <Table className={classes.tableStyle} size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className={classes.tableStyle}>Stage</TableCell>
            <TableCell className={classes.tableStyle} align='center'>Number of Records</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {decStages.map((row, index) => (
            <DecExpandedDropdown key={index} stage={row.stageName} rowData={row.records} history={history} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const DecExpandedDropdown = ({ rowData, stage }) => {
  const navigate = useNavigate();
  DecExpandedDropdown.propTypes = {
    stage: PropTypes.string.isRequired,
    rowData: PropTypes.array.isRequired,
  };
  const [open, setOpen] = React.useState(false);
  const safeLengthCount = (row) => {
    if (row === undefined) {
      return 0;
    } else {
      return row.length;
    }
  };
  return (
    <React.Fragment>
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {stage}
        </TableCell>
        <TableCell align="center">{safeLengthCount(rowData)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date Created</TableCell>
                    <TableCell>Schedule</TableCell>
                    <TableCell>Customer Name</TableCell>
                    <TableCell>Reference</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowData.map((row) => (
                    <TableRow key={row.ID} onClick={()=>{
                      navigate('/Customer?=' + row.CustomerID);
                    }}>
                      <TableCell component="th" scope="row">
                        {upgradedDate(row.CreatedDate)}
                      </TableCell>
                      <TableCell>{row.ScheduleID}</TableCell>
                      <TableCell>{row.CustomerName}</TableCell>
                      <TableCell>{row.Reference}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>);
};
