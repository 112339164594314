import 'core-js';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './FunctionalComponents/App';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import config from './Libs/Config';
import { currentStage, getCurrentEnvironment } from './Libs/StageConfig';

const currentConfig = config[getCurrentEnvironment()][currentStage()];

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: currentConfig.cognito.REGION,
    userPoolId: currentConfig.cognito.USER_POOL_ID,
    identityPoolId: currentConfig.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: currentConfig.cognito.APP_CLIENT_ID,
    oauth: {
      domain: currentConfig.cognito.DOMAIN,
      scope: currentConfig.cognito.SCOPE,
      redirectSignIn: currentConfig.cognito.REDIRECT_SIGN_IN,
      redirectSignOut: currentConfig.cognito.REDIRECT_SIGN_OUT,
      responseType: currentConfig.cognito.RESPONSE_TYPE,
    },
  },
  API: {
    endpoints: [
      {
        name: currentConfig.apiGateway.PendingHistory.NAME,
        region: currentConfig.apiGateway.PendingHistory.REGION,
        endpoint: currentConfig.apiGateway.PendingHistory.URL,
      },
      {
        name: currentConfig.apiGateway.ICONInfo.NAME,
        region: currentConfig.apiGateway.ICONInfo.REGION,
        endpoint: currentConfig.apiGateway.ICONInfo.URL,
      },
      {
        name: currentConfig.apiGateway.ActionType.NAME,
        region: currentConfig.apiGateway.ActionType.REGION,
        endpoint: currentConfig.apiGateway.ActionType.URL,
      },
      {
        name: currentConfig.apiGateway.UserInfo.NAME,
        region: currentConfig.apiGateway.UserInfo.REGION,
        endpoint: currentConfig.apiGateway.UserInfo.URL,
      },
      {
        name: currentConfig.apiGateway.ClaimsData.NAME,
        region: currentConfig.apiGateway.ClaimsData.REGION,
        endpoint: currentConfig.apiGateway.ClaimsData.URL,
      },
      {
        name: currentConfig.apiGateway.AgedData.NAME,
        region: currentConfig.apiGateway.AgedData.REGION,
        endpoint: currentConfig.apiGateway.AgedData.URL,
      },
      {
        name: currentConfig.apiGateway.Email.NAME,
        region: currentConfig.apiGateway.Email.REGION,
        endpoint: currentConfig.apiGateway.Email.URL,
      },
      {
        name: currentConfig.apiGateway.Flex?.NAME,
        region: currentConfig.apiGateway.Flex?.REGION,
        endpoint: currentConfig.apiGateway.Flex?.URL,
      },
      {
        name: currentConfig.apiGateway.Invoice?.NAME,
        region: currentConfig.apiGateway.Invoice?.REGION,
        endpoint: currentConfig.apiGateway.Invoice?.URL,
      },
      {
        name: currentConfig.apiGateway.Aux?.NAME,
        region: currentConfig.apiGateway.Aux?.REGION,
        endpoint: currentConfig.apiGateway.Aux?.URL,
      },
    ],
  },
  Storage: {
    region: currentConfig.s3.REGION,
    bucket: currentConfig.s3.BUCKET,
    identityPoolId: currentConfig.cognito.IDENTITY_POOL_ID,
  },
  aws_appsync_graphqlEndpoint: currentConfig.appSync.ENDPOINT,
  aws_appsync_region: currentConfig.appSync.REGION,
  aws_appsync_authenticationType: 'AWS_IAM',
});

ReactDOM.render(
    <Router>
      <App />
    </Router>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
