
import { API } from 'aws-amplify';
import { defaultEmailSignature } from '../Libs/templateHelper';

const replaceDaimlerWithMB = (string) => {
  return string.replace('@daimler.com', '@mercedes-benz.com');
};

export const getEmailConfigData = (setEmailConfigData, setSnackbarProps) => {
  API.get('Saltmine-API-Email', '/emailConfig', {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((result) => {
    if (result.statusCode === 200) {
      if (result.body) {
        const jsonBody = JSON.parse(result.body);
        setEmailConfigData({
          refreshed: true,
          default: false,
          defaultMailbox: replaceDaimlerWithMB(jsonBody.defaultMailbox),
          signature: jsonBody.signature,
          showPopup: false,
        });
      } else {
        setEmailConfigData({
          refreshed: true,
          default: true,
          defaultMailbox: '',
          signature: defaultEmailSignature(),
          showPopup: false,
        });
        setSnackbarProps({ open: true,
          message: 'Your email signature is set to default. ' +
            'You can update it via the profile button in the top right hand corner of the screen.',
          severity: 'info' });
      }
    } else {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + result.body,
        severity: 'error' });
    }
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
  });
};


export const getEmailTemplateData = async (setSnackbarProps) => {
  return await API.get('Saltmine-API-Email', '/emailTemplate', {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((result) => {
    if (result.statusCode === 200) {
      if (result.body) {
        const jsonBody = JSON.parse(result.body);
        return jsonBody;
      }
    } else {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + result.body,
        severity: 'error' });
      return [];
    }
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return [];
  });
};

export const postUpdateEmailTemplate = async (body, setSnackbarProps) => {
  return await API.post('Saltmine-API-Email', '/emailTemplate', {
    body: body,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((result) => {
    if (result.statusCode !== 200) {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + result.message,
        severity: 'error' });
    }
    return { status: result.statusCode, result: result.message };
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return { status: 500, result: error };
  });
};


export const deleteEmailTemplate = async (templateName, setSnackbarProps) => {
  return await API.del('Saltmine-API-Email', '/emailTemplate', {
    body: {
      templateName: templateName,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((result) => {
    if (result.statusCode !== 200 & result.body !== undefined) {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + result.body,
        severity: 'error' });
      return { status: result.statusCode, result: result.body };
    } else {
      return { status: 200 };
    }
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return { status: 500, result: error };
  });
};

export const postUpdateEmailConfig = async (body, setSnackbarProps) => {
  return await API.post('Saltmine-API-Email', '/emailConfig', {
    body: body,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((result) => {
    if (result.statusCode !== 200) {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + result.message,
        severity: 'error' });
    } else {
      setSnackbarProps({ open: true,
        message: 'Email Configuration Updated',
        severity: 'success' });
    }
    return { status: result.statusCode, result: result.message };
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return { status: 500, result: error };
  });
};

export const postSendEmail = async (body, setSnackbarProps) => {
  return await API.post('Saltmine-API-Email', '/sendEmail', {
    body: body,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((result) => {
    if (result.statusCode !== 200) {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + result.message,
        severity: 'error' });
    }
    return { status: result.statusCode, result: result.message };
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return { status: 500, result: error };
  });
};
