// Helper Functions
import { checkIsPositiveNumber, ukDateStringToDate, yearsBetween, isDate } from '../Libs/fileHelper';

export const excessMileageCalculation = (excessMileageData, reviewYear) => {
  const yearsBetweenStartAndCalculation = yearsBetween(
      excessMileageData.calculationDate,
      excessMileageData.scheduleStartDate);
  let totalAllowance = Number(excessMileageData.scheduleLastTacho);
  let totalUsage = 0;
  for (let i = 0; i < reviewYear; i++) {
    if (i < yearsBetweenStartAndCalculation && i > yearsBetweenStartAndCalculation - 1) {
      totalAllowance = totalAllowance + Math.round(
          excessMileageData.yearlyData[i]?.yearlyAllowance * (yearsBetweenStartAndCalculation - i));
    } else {
      if (i < yearsBetweenStartAndCalculation) {
        totalAllowance = totalAllowance + excessMileageData.yearlyData[i]?.yearlyAllowance;
      }
    }
    if (i === 0) {
      totalUsage = excessMileageData.yearlyData[i]?.mileageReading;
    } else {
      totalUsage = totalUsage + excessMileageData.yearlyData[i]?.mileageReading -
        excessMileageData.yearlyData[i-1]?.mileageReading;
    }
  }
  if (totalAllowance > totalUsage) {
    return 0;
  } else {
    return (totalUsage - totalAllowance);
  }
};

const handleZeroValues = (scheduleAllowanceData, i) => {
  if (safeArrayRead(scheduleAllowanceData, i) !== 0) {
    return safeArrayRead(scheduleAllowanceData, i);
  } else {
    if (i === 0) {
      return 0;
    } else {
      return handleZeroValues(scheduleAllowanceData, i - 1);
    }
  }
};

export const excessMileageChargeCalculation = (excessMileageData, reviewYear) => {
  if (excessMileageData.yearlyData[reviewYear - 1]?.mileageReading === 0) {
    return 'Mileage Reading Required';
  }
  let alreadyPaidAmount = 0;
  let alreadyPaidMileage = 0;
  for (let i = 0; i < reviewYear-1; i++) {
    alreadyPaidMileage = alreadyPaidMileage + excessMileageData.yearlyData[i]?.processedCharge /
      excessMileageData.yearlyData[i]?.pPK * 100;
    alreadyPaidAmount = alreadyPaidAmount + excessMileageData.yearlyData[i]?.processedCharge;
  }
  return (((excessMileageCalculation(excessMileageData, reviewYear) - alreadyPaidMileage) *
    Number(excessMileageData.yearlyData[reviewYear-1]?.pPK) / 100)).toFixed(2);
};

export const mileagePredictionBasedOnClaims = (startDate, endDate, claimsData, lastTacho, reviewYear) => {
  if (claimsData === undefined) {
    return 'No Claims';
  }
  if (!claimsData.length) {
    return 'No Claims';
  }
  if (claimsData.length === 0) {
    return 'No Claims';
  }
  const maxClaimDistance = Math.max(...claimsData.map((o) => o.ClaimDistanceKM || 0), 0);
  const maxClaimDate = Math.max(...claimsData.map((o) => o.DateOfClaimEpoch || 0), 0);
  const startDateEpoch = ukDateStringToDate(startDate);
  const yearsBetweenStartAndClaim = (maxClaimDate - startDateEpoch) / 31556926000;
  const adjustedReviewYear = Math.min(yearsBetween(
      ukDateStringToDate(endDate), ukDateStringToDate(startDate)), reviewYear);
  if (yearsBetweenStartAndClaim < 0) {
    // No Claims during schedule period, Use different prediction if enough data available
    if (claimsData.length < 2) {
      return 'No Claims';
    } else {
      const minClaimDistance = Math.min(...claimsData.map((o) => o.ClaimDistanceKM || 0));
      const minClaimDate = Math.min(...claimsData.map((o) => o.DateOfClaimEpoch || 0));
      const claimDistanceDiff = maxClaimDistance - minClaimDistance;
      const yearsBetweenStartAndEnd = (maxClaimDate - minClaimDate) / 31556926000;
      const predictedMileagePerYearMethod2 = claimDistanceDiff / yearsBetweenStartAndEnd;
      return Number(((predictedMileagePerYearMethod2 * adjustedReviewYear) + Number(lastTacho)).toFixed(0));
    }
  }
  const predictedMileagePerYear = (maxClaimDistance - Number(lastTacho)) /
    yearsBetweenStartAndClaim;
  return Number(((predictedMileagePerYear * adjustedReviewYear) + Number(lastTacho)).toFixed(0));
};

export const createYearlyMileageData = (scheduleData, claimsData, startTacho) => {
  const calculatedTerm = yearsBetween(
      ukDateStringToDate(scheduleData.EndDate), ukDateStringToDate(scheduleData.StartDate));
  const mileageData = [];
  for (let i = 0; i < Math.ceil(calculatedTerm); i++) {
    mileageData[i] = {
      reviewYear: i + 1,
      yearlyAllowance: handleZeroValues(scheduleData.ScheduleEstDistanceArray, i),
      mileageReading: getMileageReading(
          safeArrayRead(scheduleData.ScheduleActDistanceArray, i),
          safeArrayRead(scheduleData.ScheduleActDistanceArray, i - 1),
          mileagePredictionBasedOnClaims(scheduleData.StartDate, scheduleData.EndDate, claimsData,
              startTacho, i + 1),
      ),
      pPK: handleZeroValues(scheduleData.SchedulePpkArray, i),
      processedCharge: safeArrayRead(scheduleData.ScheduleAdjustmentArray, i),
    };
  }
  return mileageData;
};

const usePredictedMileage = (predictedMileage) => {
  if (predictedMileage === 'No Claims') {
    return 0;
  } else {
    return predictedMileage;
  }
};

const getMileageReading = (currentReading, previousReading, predictedMileage) => {
  if (currentReading === previousReading) {
    return usePredictedMileage(predictedMileage);
  }
  if (currentReading === 0) {
    return usePredictedMileage(predictedMileage);
  }
  return currentReading;
};

export const handleCalculationDateChangeEvent = (event, excessMileageData, setExcessMileageData) => {
  if (event <= excessMileageData.scheduleStartDate) {
    return;
  }
  setExcessMileageData({
    ...excessMileageData,
    calculationDate: event,
  });
};

export const handleExcessMileageChangeEvent = (event, reviewYear, excessMileageData,
    setExcessMileageData, setSnackbarProps) => {
  if (checkIsPositiveNumber(event.target.value)) {
    const editedMileageData = [...excessMileageData.yearlyData];
    if (event.target.name === 'pPK') {
      editedMileageData[reviewYear - 1] = {
        ...editedMileageData[reviewYear - 1],
        [event.target.name]: event.target.value,
      };
      setExcessMileageData({ ...excessMileageData,
        yearlyData: editedMileageData,
      });
    } else {
      editedMileageData[reviewYear - 1] = {
        ...editedMileageData[reviewYear - 1],
        [event.target.name]: Number(event.target.value),
      };
      setExcessMileageData({ ...excessMileageData,
        yearlyData: editedMileageData,
      });
      if (event.target.value.substring(event.target.value.length-1) === '.') {
        setSnackbarProps({ open: true,
          message: 'You may only enter whole numbers',
          severity: 'warning' });
      }
    }
  } else {
    setSnackbarProps({ open: true,
      message: 'You may only enter postive numbers',
      severity: 'warning' });
  }
};

export const handleStartTachoChangeEvent = (event, setTempScheduleLastTacho, setSnackbarProps) => {
  if (checkIsPositiveNumber(event.target.value)) {
    setTempScheduleLastTacho(Number(event.target.value));
  } else {
    console.log('setting snackbar');
    setSnackbarProps({ open: true,
      message: 'You may only enter postive numbers',
      severity: 'warning' });
  }
};

export const handleSaveStartTacho = (excessMileageData, setExcessMileageData, newTacho,
    scheduleData, claimsData) => {
  setExcessMileageData({
    ...excessMileageData,
    scheduleLastTacho: newTacho,
    yearlyData: createYearlyMileageData(scheduleData, claimsData, newTacho),
  });
};


export const calculateCurrentYear = (scheduleData) => {
  const startDateEpoch = ukDateStringToDate(scheduleData.StartDate);
  const endDateEpoch = ukDateStringToDate(scheduleData.EndDate);
  const dateNow = new Date();
  if (endDateEpoch < dateNow) {
    return Math.ceil(yearsBetween(endDateEpoch, startDateEpoch)) - 1;
  } else {
    return Math.ceil(yearsBetween(dateNow, startDateEpoch)) - 1;
  }
};

export const mileageReviewAnniversaryDate = (starDateAsString, endDateAsString) => {
  const anniversaryDate = ukDateStringToDate(starDateAsString);
  const endDateAsDate = ukDateStringToDate(endDateAsString);
  if (!isDate(anniversaryDate) || !isDate(endDateAsDate)) {
    return 0;
  }
  anniversaryDate.setFullYear(anniversaryDate.getFullYear() + calculateCurrentYear({
    StartDate: starDateAsString, EndDate: endDateAsString,
  }) + 1);
  endDateAsDate.setTime(endDateAsDate.getTime() + 86400000);
  if (endDateAsDate < anniversaryDate) {
    return endDateAsDate;
  } else {
    return anniversaryDate;
  }
};

const safeArrayRead = (array, i) => {
  if (array) {
    if (i >= array.length) {
      return 0;
    } else {
      return array[i];
    }
  } else {
    return 0;
  }
};
