// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Components
import ExitButton from '../../Components/StyledMUIComponents/ExitButton';
// Resources

const useStyles = makeStyles((theme) => ({
  popupBackgroundStyle: {
    backgroundColor: '#eeeeee',
    position: 'absolute',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    opacity: 0.5,
    zIndex: 20,
  },
  popupContainerStyle: {
    position: 'absolute',
    padding: theme.spacing(1),
    backgroundColor: 'rgba(255, 255, 255, 1)',
    width: '50vw',
    height: '25vh',
    margin: 'auto',
    overflow: 'auto',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    filter: 'drop-shadow(2px 4px 6px black)',
    whiteSpace: 'pre',
    zIndex: 21,
  },
}));


export default function LoadingPopup({ loadingText, showAcknowledgeButton, acknowledgeButtonPressed }) {
  const classes = useStyles();

  LoadingPopup.propTypes = {
    loadingText: PropTypes.string,
    showAcknowledgeButton: PropTypes.bool,
    acknowledgeButtonPressed: PropTypes.func,
  };

  return (
    <React.Fragment>
      <div component='popup' className={classes.popupBackgroundStyle} />
      <div component='popup' className={classes.popupContainerStyle}>
        {loadingText}
        { showAcknowledgeButton ?
        <ExitButton variant='contained' color='primary'
          onClick={acknowledgeButtonPressed}>
        Acknowledge
        </ExitButton> :
        null }
      </div>
    </React.Fragment>
  );
}
