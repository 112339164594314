import { replaceAllOccurances } from '../Libs/fileHelper';
import { carVanSwitch } from '../Libs/StageConfig';

// Resources
export const validateEmailAddress = (string) => {
  if (string === undefined) {
    return false;
  }
  // check length
  if (string.length < 8) {
    return false;
  }
  // check Regex
  const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  if (!emailRegex.test(string.toLowerCase())) {
    return false;
  }

  // Check Starting String
  const startingStringArray = ['not.given@',
    'not.got@', 'not.known@', 'not@', 'notgiven@',
    'nothing@', 'na@', 'no.email@', 'no@', 'noaddress@',
    'nocustomer@', 'noeamil@', 'noeemail@', 'noemail@',
    'noemailaddress@', 'noname@', 'none@'];
  for (const startingStringCheck of startingStringArray) {
    if (string.toLowerCase().startsWith(startingStringCheck)) {
      return false;
    }
  }

  // Check Contains String
  const containsStringArray = ['@nothing.co.uk', '@notknown.com',
    '@notknow.co.uk', '@not.com', '@notmail.com', '@a.co.uk',
    '@a.com', '@aaa.com', '@bbb.com', '@123.com', '@n.a.com',
    '@nothing.com', '@n.com', '@none.com', '@n0ne.com', '@na.com',
    '@unknown.co.uk', '@noemail.com', '@noemail.co.uk', '@no.co.uk',
    '@none.co.uk', '@noreply.com'];
  for (const containsStringCheck of containsStringArray) {
    if (string.toLowerCase().includes(containsStringCheck)) {
      return false;
    }
  }

  // All checks passed
  return true;
};

const getGroupMailboxes = () => {
  const carMailboxes = [
    'pc.servicecontract@mercedes-benz.com',
    'pccreditcontrol@mercedes-benz.com',
    'pcadmin@mercedes-benz.com',
  ];
  const vanMailboxes = [
    'van.servicecontract@mercedes-benz.com',
    'vanrmcreditcontrol@mercedes-benz.com',
  ];
  return carVanSwitch(carMailboxes, vanMailboxes);
};

export const groupMailboxes = getGroupMailboxes();

export const generateEMLReference = (groupMailbox, emailSentTo, emailSubject, emailBody, emailAttachment) => {
  let additionalAttachmentText = '\n';
  if (emailAttachment) {
    additionalAttachmentText = 'Attachment: ' + emailAttachment + '\n\n';
  }
  return ('From: ' + groupMailbox + '\n' +
  'Sent: ' + new Date() + '\n' +
  'To: ' + emailSentTo + '\n' +
  'Subject: ' + emailSubject + '\n' + additionalAttachmentText + emailBody);
};


export const convertBodyBackToHTML = (body) => {
  const styledbody = '<font face=\'CorpoS\'><p style=\'font-size:14.5px\'>' + body + '</p></font>';
  return replaceAllOccurances(styledbody, '\n', '<br>');
};
