// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ExcessMileageCalculatorDetails from '../Components/ExcessMileageCalculatorDetails';
import TextField from '@mui/material/TextField';
import { handleCalculationDateChangeEvent, handleExcessMileageChangeEvent,
  handleStartTachoChangeEvent, handleSaveStartTacho } from '../Libs/mileageCalculatorHelper';
// Resources
import DeleteIcon from '@mui/icons-material/Help';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

const useStyles = makeStyles((theme) => ({
  startMileageStyle: {
    paddingBottom: '8px',
    fontSize: '14px',
  },
}));

export default function ExcessMileageCalculatorPopup({
  popupOpen, excessMileageData, setExcessMileageData, scheduleData,
  claimsData, setSnackbarProps, mileageReviewClosed,
}) {
  const classes = useStyles();
  const sxClasses = {
    dialogTitle: {
      paddingTop: '8px',
      padding: 0,
      textAlign: 'center',
    },
    textFieldStyle: {
    },
    iconButton: {
      position: 'absolute',
      top: '8px',
      right: '8px',
      padding: 0,
    },
    saveIconButton: {
      padding: 0,
      position: 'absolute',
      top: '70px',
      marginLeft: '8px',
    },
    editIconButton: {
      padding: 0,
      position: 'absolute',
      top: '44px',
      marginLeft: '80px',
    },
    cancelButtonStyle: {
      position: 'absolute',
      width: '13vw',
      right: '10px',
      bottom: '10px',
    },
    calculationDateStyle: {
      position: 'absolute',
      top: '4px',
      left: '24px',
    },
  };

  ExcessMileageCalculatorPopup.propTypes = {
    popupOpen: PropTypes.bool.isRequired,
    excessMileageData: PropTypes.object.isRequired,
    setExcessMileageData: PropTypes.func.isRequired,
    scheduleData: PropTypes.object.isRequired,
    claimsData: PropTypes.array.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
    mileageReviewClosed: PropTypes.func.isRequired,
  };

  const [allowTachoEdit, setAllowTachoEdit] = React.useState(false);
  const [tempScheduleLastTacho, setTempScheduleLastTacho] = React.useState(scheduleData.SchedLastTacho);

  return (
    <Dialog open={popupOpen} onClose={mileageReviewClosed} maxWidth="md">
      <DialogTitle sx={sxClasses.dialogTitle} id="form-dialog-title">
        Excess Mileage Calculation
      </DialogTitle>
      <IconButton sx={sxClasses.iconButton} onClick={()=>{
        setSnackbarProps({ open: true,
          message: 'Charge Due = (Excess Mileage * PPK) - Charges already processed in previous years ',
          severity: 'info' });
      }}>
        <DeleteIcon />
      </IconButton>
      <DialogContent>
        <DesktopDatePicker
          margin="normal"
          id="txtCalculationDate"
          inputFormat="dd/MM/yyyy"
          sx={sxClasses.calculationDateStyle}
          value={excessMileageData.calculationDate}
          onChange={(e) => {
            handleCalculationDateChangeEvent(e, excessMileageData, setExcessMileageData);
          }}
          KeyboardButtonProps={{
            'aria-label': 'Calculation Date',
          }}
          renderInput={(params) => <TextField {...params}
            size="small"
            sx={sxClasses.calculationDateStyle}/>}
        />
        {allowTachoEdit ?
          <Box sx={{ textAlign: 'center', width: '100%' }}>
            <TextField
              margin="dense"
              size="small"
              name="scheduleLastTacho"
              label="Start Mileage"
              sx={sxClasses.textFieldStyle}
              value={tempScheduleLastTacho}
              onChange={(e) => {
                handleStartTachoChangeEvent(e, setTempScheduleLastTacho, setSnackbarProps);
              }}
            />
            <IconButton sx={sxClasses.saveIconButton} onClick={()=>{
              handleSaveStartTacho(excessMileageData, setExcessMileageData, tempScheduleLastTacho,
                  scheduleData, claimsData);
              setAllowTachoEdit(false);
            }}>
              <SaveIcon />
            </IconButton>
          </Box> :
          <Box sx={{ textAlign: 'center', width: '100%' }}>
            <div className={classes.startMileageStyle}>
              {'Start Mileage - ' + excessMileageData.scheduleLastTacho}
            </div>
            <IconButton sx={sxClasses.editIconButton} onClick={()=>{
              setTempScheduleLastTacho(Number(scheduleData.SchedLastTacho));
              setAllowTachoEdit(true);
            }}>
              <EditIcon />
            </IconButton>
          </Box>
        }
        <ExcessMileageCalculatorDetails
          excessMileageData={excessMileageData}
          setExcessMileageData={setExcessMileageData}
          handleExcessMileageChangeEvent={handleExcessMileageChangeEvent}
          scheduleData={scheduleData}
          claimsData={claimsData}
          setSnackbarProps={setSnackbarProps}/>
      </DialogContent>
      <DialogActions>
        <Button sx={sxClasses.cancelButtonStyle} variant='contained' color='primary'
          onClick={mileageReviewClosed}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
