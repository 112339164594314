// Functions
import React, { useState } from 'react';
import RoutesAll from '../Components/RoutesAll.js';
import { useLocation } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';
import API, { graphqlOperation } from '@aws-amplify/api';
import { onCreateSessionRecord } from '../graphql/subscriptions';
import { checkApprovedGroup, checkReadOnlyGroup } from '../Libs/fileHelper';
import { currentStage, getCurrentEnvironment } from '../Libs/StageConfig';
import { emptyCustomerData } from '../Libs/emptyDataStructures';
import { getEmailConfigData } from '../Libs/emailAPIHelper';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useIdleTimer } from 'react-idle-timer';
// Components
import Header from '../Components/AppComponents/Header';
import Theme from '../Components/AppComponents/Theme';
import CustomizedSnackbars from '../Components/AppComponents/SnackBar';
import ConfidentialPage from '../Components/AppComponents/Confidential';
import NoApproval from '../Components/AppComponents/NoApproval';
import IdlePopup from '../Components/AppComponents/IdlePopup';
// Resources
import config from '../Libs/Config';

const useStyles = makeStyles((theme) => ({
  root: {
    'min-width': '1200px',
    'backgroundColor': '#eeeeee',
  },
}));

export default function App(appProps) {
  const classes=useStyles();
  const location = useLocation();

  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [currUser, setCurrUser] = useState(null);

  const [customerData, setCustomerData] = useState(emptyCustomerData);
  const [pendingData, setPendingData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [actionCodeLookup, setActionCodeLookup] = useState([]);
  const [emailConfigData, setEmailConfigData] = useState({
    refreshed: false,
    default: true,
    defaultMailbox: '',
    signature: '',
    showPopup: false,
  });

  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    message: 'default message',
    severity: 'success',
  });

  const props = {
    isAuthenticated,
    userHasAuthenticated,
    setCurrUser,
    currUser,
    snackbarProps,
    setSnackbarProps,
    customerData,
    setCustomerData,
    pendingData,
    setPendingData,
    scheduleData,
    setScheduleData,
    actionCodeLookup,
    setActionCodeLookup,
    emailConfigData,
  };

  const visitHandler = (value) => {
    if (value === new Date(Date.now()).toLocaleDateString()) {
      return true;
    } else {
      return false;
    }
  };

  const [visitedBeforeToday, setVisitedBeforeToday] = useState(
      visitHandler(localStorage.getItem('lastVisited')),
  );

  const updateConfidentialAfterDelay = () => {
    setTimeout(() => {
      setVisitedBeforeToday(true);
      localStorage.setItem('lastVisited', new Date(Date.now()).toLocaleDateString());
    }, 3000);
  };

  const fetchEmailConfigData = (user) => {
    if (!emailConfigData.refreshed && checkApprovedGroup(user) && !checkReadOnlyGroup(user)) {
      getEmailConfigData(setEmailConfigData, setSnackbarProps);
    }
  };

  const authenticateUser = async () => {
    const user = await Auth.currentAuthenticatedUser();
    setCurrUser(user);
    userHasAuthenticated(true);
    setIsAuthenticating(false);
    updateConfidentialAfterDelay();
    fetchEmailConfigData(user);
  };

  React.useEffect(() => {
    const onLoad = async () => {
      Hub.listen('auth', ({ payload: { event, data } }) => {
        switch (event) {
          case 'signIn':
            authenticateUser();
            break;
          case 'signOut':
            setCurrUser(null);
            break;
          case 'cognitoHostedUI_failure':
            window.location = ('https://' +
            config[getCurrentEnvironment()][currentStage()].cognito.DOMAIN +
            '/login?client_id=' +
            config[getCurrentEnvironment()][currentStage()].cognito.APP_CLIENT_ID +
            '&response_type=code&scope=aws.cognito.signin.user.admin+openid&redirect_uri=' +
            config[getCurrentEnvironment()][currentStage()].cognito.REDIRECT_SIGN_IN);
            break;
          default:
        }
      });
      try {
        await authenticateUser();
      } catch (e) {
        if (e === 'not authenticated' || e === 'The user is not authenticated') {
          if (!location.search.includes('?code=')) {
            Auth.federatedSignIn({ provider: 'GAS' });
          } else {
            await Auth.signOut();
            userHasAuthenticated(false);
            if (currentStage() === 'prod') {
              window.location = 'https://login.mercedes-benz.com/signout';
            } else {
              window.location = 'https://login-int.mercedes-benz.com/signout';
            }
          }
        } else {
          await Auth.signOut();
          userHasAuthenticated(false);
          if (currentStage() === 'prod') {
            window.location = 'https://login.mercedes-benz.com/signout';
          } else {
            window.location = 'https://login-int.mercedes-benz.com/signout';
          }
        }
      }
    };

    if (currUser) {
      subcribeToAppSync();
    } else {
      onLoad();
    }
  }, [location.search, currUser] );

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarProps({
      ...snackbarProps,
      open: false,
    });
  };

  const subcribeToAppSync = () => {
    try {
      const subscription = API
          .graphql(graphqlOperation(onCreateSessionRecord))
          .subscribe({
            next: async (event) => {
              const sessionEvent = event.value?.data?.onCreateSessionRecord;
              if (sessionEvent?.user === currUser.username) {
                await Auth.signOut();
                userHasAuthenticated(false);
              }
            },
            error: (error) => {
              setTimeout(() => {
                subcribeToAppSync();
              }, 60000);
            },
          });

      return () => {
        subscription.unsubscribe();
      };
    } catch (e) {
      setTimeout(() => {
        subcribeToAppSync();
      }, 60000);
    }
  };

  const [headerAnchorEl, setHeaderAnchorEl] = React.useState(null);

  const toggleHeaderPopover = (event) => {
    setHeaderAnchorEl(headerAnchorEl ? null : event.currentTarget);
  };

  async function handleLogout() {
    toggleHeaderPopover();
    await Auth.signOut();
    userHasAuthenticated(false);
  }

  // Idle Handler
  const handleOnIdle = (event) => {
    idleTextCountDown();
    setUserIsIdle(true);
  };

  const handleOnActive = async (event) => {
    if (((new Date()) - getLastActiveTime()) > millisecondsInMinutes(16)) {
      if (currentStage() === 'prod') {
        window.location = 'https://login.mercedes-benz.com/signout';
      } else {
        window.location = 'https://login-int.mercedes-benz.com/signout';
      }
    }
    setUserIsIdle(false);
    clearInterval(idleCountAnimationID.current);
    setIdleTimeRemaining(60);
  };

  const millisecondsInMinutes = (minutes) => {
    return (minutes * 1000 * 60);
  };

  const handleOnAction = async (event) => {
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: millisecondsInMinutes(15),
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: true,
    },
  });

  const [userIsIdle, setUserIsIdle] = useState(false);
  const [idleTimeRemaining, setIdleTimeRemaining] = React.useState(60);
  const idleCountAnimationID = React.useRef(0);

  const idleTextCountDown = async () => {
    let i = 0;
    idleCountAnimationID.current = setInterval(async () => {
      const newIdleText = idleTimeRemaining - i;
      setIdleTimeRemaining(newIdleText);
      i+= 1;
      if (newIdleText < 0) {
        await Auth.signOut();
        userHasAuthenticated(false);
      }
    }, 1000);
  };


  return (
    <React.Fragment>
      {isAuthenticating?
        <div>Loading</div> :
      <div>
        {visitedBeforeToday ?
        <ThemeProvider theme={Theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className={classes.root}>
              <Header currUser={currUser}
                handleLogout={handleLogout} headerAnchorEl={headerAnchorEl}
                toggleHeaderPopover={toggleHeaderPopover} isAuthenticated={isAuthenticated}
                emailConfigData={emailConfigData} setEmailConfigData={setEmailConfigData}
                setSnackbarProps={setSnackbarProps}/>
              {!checkApprovedGroup(currUser) ? <NoApproval userHasAuthenticated={userHasAuthenticated} /> :
                  <RoutesAll appProps={props} />
              }
            </div>
            <CustomizedSnackbars snackbarProps={snackbarProps} setSnackbarProps={setSnackbarProps}
              handleSnackBarClose={handleSnackBarClose}/>
            <IdlePopup isIdle={userIsIdle} idleTimeRemaining={idleTimeRemaining}/>
          </LocalizationProvider>
        </ThemeProvider> :
        <ConfidentialPage />
        }
      </div>
      }
    </React.Fragment>
  );
}
