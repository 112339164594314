// import React from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const TitlePaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'variant',
})(({ variant, theme }) => ({
  width: '165px',
  border: '1px solid',
  padding: theme.spacing(1),
  textAlign: 'left',
  borderColor: '#000000',
  backfaceVisibility: 'hidden',
  ...((variant === 'filled') ? {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
  } : {
    backgroundColor: '#ffffff',
    color: '#000000',
  }),
}));

export default TitlePaper;
