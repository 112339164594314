// Functions
import { API } from 'aws-amplify';
import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { currencyFormatter, dateToMMMYYYString, reverseSortArrayByProperty,
  convertDateToUTCEpoch, ukDateStringToDate } from '../../Libs/fileHelper';
import * as XLSX from 'xlsx-js-style';
import { convertDateToExcel, createExcelFile, fileType } from '../../Libs/reportingHelper';
// Resources

export default function InvoiceHistory({ customerData, invoiceData, invoiceManagementData,
  setInvoiceData, nextMonthInvoiceDate, filteredScheduleData, setSnackbarProps }) {
  const sxClasses = {
    paperStyle: {
      width: 'calc(100% - 24px)',
      border: '1px solid',
      borderColor: '#000000',
      height: '185px',
      paddingLeft: 1,
      marginTop: 0.5,
      marginLeft: 1,
    },
  };

  const cellStyle = {
    padding: '2px',
    borderBottom: '0px',
  };

  InvoiceHistory.propTypes = {
    customerData: PropTypes.shape({
      CustomerID: PropTypes.string.isRequired,
      CustomerName: PropTypes.string,
      CustomerAddress: PropTypes.string,
      CustomerPostcode: PropTypes.string,
      CustomerEmail: PropTypes.string,
      CustomerPhone: PropTypes.string,
      CustomerMobile: PropTypes.string,
    }),
    invoiceData: PropTypes.array.isRequired,
    invoiceManagementData: PropTypes.array.isRequired,
    setInvoiceData: PropTypes.func.isRequired,
    nextMonthInvoiceDate: PropTypes.instanceOf(Date).isRequired,
    filteredScheduleData: PropTypes.func.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
  };

  const callGenerateInvoice = async () => {
    if (filteredScheduleData('Ending').length !== 0) {
      setSnackbarProps({
        open: true,
        message: 'You have records in the Ending state. Please extend or Remove them.',
        severity: 'info',
      });
      return;
    }
    if (filteredScheduleData('To Add').length !== 0) {
      setSnackbarProps({
        open: true,
        message: 'You have records in the To Add state. Please add them or clear fleet data.',
        severity: 'info',
      });
      return;
    }
    if (filteredScheduleData('To Remove').length !== 0) {
      setSnackbarProps({
        open: true,
        message: 'You have records in the To Add state. Please remove them or clear fleet data.',
        severity: 'info',
      });
      return;
    }

    return await API.post('Saltmine-Van-API-Invoice', '/generateInvoice', {
      body: {
        customerID: customerData?.CustomerID,
        invoiceDate: convertDateToUTCEpoch(nextMonthInvoiceDate),
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      if (result.statusCode === 200) {
        const invoiceObject = JSON.parse(result.body);
        setInvoiceData([
          ...invoiceData,
          invoiceObject,
        ]);
      } else {
        setSnackbarProps({
          open: true,
          message: JSON.stringify(result),
          severity: 'error',
        });
      }
    }).catch( (error) => {
      setSnackbarProps({
        open: true,
        message: JSON.stringify(error),
        severity: 'error',
      });
    });
  };

  const callGetInvoiceDetails = async (invoiceID) => {
    return await API.get('Saltmine-Van-API-Invoice', '/getInvoiceDetails', {
      queryStringParameters: {
        invoiceID: invoiceID,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      if (!result.body) {
        setSnackbarProps({
          open: true,
          message: 'Something When Wrong: ' + JSON.stringify(result),
          severity: 'error',
        });
        return;
      }
      const invoiceDetails = JSON.parse(result.body);
      const invoiceMain = invoiceDetails.find((item)=>(item.SK.includes('Invoice')));
      const invoiceRecords = invoiceDetails.filter((item)=>(item.EventType === 'InvoiceTriggered'));
      if (invoiceMain.numberOfVehicles !== invoiceRecords.length) {
        setSnackbarProps({
          open: true,
          message: 'Invoice Still generating. Please try again in a few seconds.',
          severity: 'info',
        });
        return;
      }
      exportInvoiceData(invoiceRecords, invoiceMain);
    }).catch( (error) => {
      console.log(error);
      setSnackbarProps({
        open: true,
        message: JSON.stringify(error),
        severity: 'error',
      });
    });
  };

  const exportInvoiceData = (invoiceRecords, invoiceMain) => {
    for (let i=0; i<invoiceRecords.length; i++) {
      const additionalScheduleData = invoiceManagementData.find(
          (item)=>(item.ScheduleID === invoiceRecords[i].ScheduleID));
      invoiceRecords[i] = staticColumnOrder(invoiceRecords[i], additionalScheduleData);
    }

    // const ws = XLSX.utils.json_to_sheet(invoiceRecords);
    const ws = XLSX.utils.book_new();

    const row1 = { cell1: 'Customer', cell2: customerData.CustomerName };
    const row2 = { cell1: 'Invoice To Date', cell2: convertDateToExcel(invoiceMain.invoiceDate) };
    const row3 = { cell1: 'Invoice Total EXC Vat', cell2: currencyFormatter(invoiceMain.invoiceTotal) };
    const row4 = { cell1: 'Vehicles Invoiced', cell2: invoiceMain.numberOfVehicles };


    const creatExcelData = [row1, row2, row3, row4];
    XLSX.utils.sheet_add_json(ws, creatExcelData, { origin: 'A1', skipHeader: true });
    XLSX.utils.sheet_add_json(ws, invoiceRecords, { origin: 'A6' });

    // Style
    const boldBlackAndWhite = {
      font: { bold: true, color: { rgb: 'FFFFFF' } },
      fill: { fgColor: { rgb: '000000' } },
    };
    const titleCells = ['A1', 'A2', 'A3', 'A4', 'A6', 'B6', 'C6', 'D6', 'E6', 'F6', 'G6', 'H6', 'I6', 'J6',
      'K6', 'L6'];
    for (let i=0; i<titleCells.length; i++) {
      ws[titleCells[i]].s = boldBlackAndWhite;
    }

    const titleValueStyle = {
      alignment: { horizontal: 'center' },
      border: { right: { style: 'thin', color: '000000' }, bottom: { style: 'thin', color: '000000' } },
    };
    const titleValueCells = ['B1', 'B2', 'B3', 'B4'];
    for (let i=0; i<titleValueCells.length; i++) {
      ws[titleValueCells[i]].s = titleValueStyle;
    }

    // Style Column Widths
    const wscols = [
      { wch: 20 }, { wch: 20 }, { wch: 13 },
      { wch: 13 }, { wch: 13 }, { wch: 15 },
      { wch: 13 }, { wch: 13 }, { wch: 13 },
      { wch: 13 }, { wch: 13 }, { wch: 13 },
    ];

    ws['!cols'] = wscols;

    // handle date formatting
    ws['B2'].z = 'dd/mm/yyyy';
    const dateColumns = ['F', 'G', 'H', 'I', 'J'];
    for (let j=0; j<dateColumns.length; j++) {
      for (let i = 0; i < invoiceRecords.length; i++) {
        if (ws[dateColumns[j] + (i+7).toString()] !== undefined) {
          ws[dateColumns[j] + (i+7).toString()].z = 'dd/mm/yyyy';
        }
      }
    }
    ws['B4'].z = '@';
    // handle Currency Format
    for (let i = 0; i < invoiceRecords.length; i++) {
      if (ws['K' + (i+7).toString()] !== undefined) {
        ws['K' + (i+7).toString()].z = '£#,##0.00';
      }
    }

    const wb = { Sheets: { 'Invoice': ws }, SheetNames: ['Invoice'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    createExcelFile(data, customerData.CustomerName + ' Invoice ' + invoiceMain.invoiceDate + '.xlsx');
  };

  const staticColumnOrder = (object, additionalScheduleData) => {
    if (!additionalScheduleData) {
      return ({
        ['Schedule ID']: object.ScheduleID,
        ['Invoiced From']: convertDateToExcel(object.EventDetails.invoiceFromDate),
        Amount: object.EventDetails.invoiceAmount,
        ['Days Charged']: object.EventDetails.daysInvoiced,
      });
    }
    return ({
      Registration: additionalScheduleData.Registration,
      ['Schedule ID']: object.ScheduleID,
      Contract: additionalScheduleData.CustomerID,
      ['ODO At Start']: additionalScheduleData.SchedLastTacho,
      Emission: additionalScheduleData.euroType,
      ['Registration Date']: convertDateToExcel(
          ukDateStringToDate(additionalScheduleData.RegistrationDate).getTime()),
      ['Start Date']: convertDateToExcel(ukDateStringToDate(additionalScheduleData.StartDate).getTime()),
      ['End Date']: convertDateToExcel(ukDateStringToDate(additionalScheduleData.EndDate).getTime()),
      ['Date Removed']: calculateDateRemoved(additionalScheduleData),
      ['Invoiced From']: convertDateToExcel(object.EventDetails.invoiceFromDate),
      Amount: object.EventDetails.invoiceAmount,
      ['Days Charged']: object.EventDetails.daysInvoiced,
    });
  };

  const calculateDateRemoved = (object) => {
    if (!object?.removeFromFleetDate) {
      return '';
    }
    if (object.removeFromFleetDate > object.addToFleetDate) {
      return convertDateToExcel(object.removeFromFleetDate);
    }
    return '';
  };

  return (
    <TableContainer component={Paper}
      sx={sxClasses.paperStyle}>
      <Table sx={cellStyle} size='small' aria-label='a dense table' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={sxClasses.cellStyle}>Invoice Date</TableCell>
            <TableCell sx={sxClasses.cellStyle} align='center'>Total</TableCell>
            <TableCell sx={sxClasses.cellStyle} align='center'>Vehicles</TableCell>
            <TableCell sx={sxClasses.cellStyle} align='center'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={cellStyle} align='left'>
              {dateToMMMYYYString(nextMonthInvoiceDate)}
            </TableCell>
            <TableCell sx={cellStyle} align='center'>
            </TableCell>
            <TableCell sx={cellStyle} align='center'>
            </TableCell>
            <TableCell sx={cellStyle} align='center'>
              <Button variant='contained' color='primary'
                onClick={() => {
                  callGenerateInvoice();
                }}>
                Generate Invoice
              </Button>
            </TableCell>
          </TableRow>
          {reverseSortArrayByProperty(invoiceData, 'invoiceDate').map((item)=>(
            <TableRow key={item.SK}>
              <TableCell sx={cellStyle} align='left'>
                {dateToMMMYYYString(new Date(item.invoiceDate))}
              </TableCell>
              <TableCell sx={cellStyle} align='center'>
                {currencyFormatter(item.invoiceTotal)}
              </TableCell>
              <TableCell sx={cellStyle} align='center'>
                {item.numberOfVehicles}
              </TableCell>
              <TableCell sx={cellStyle} align='center'>
                <Button variant='contained' color='primary'
                  onClick={() => {
                    callGetInvoiceDetails(item.invoiceID);
                  }}>
                  View Details
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
