
import { API } from 'aws-amplify';

export const createFlexItem =
    async (body, setSnackbarProps) => {
      return await API.post('Saltmine-Van-API-Flex', '', {
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((result) => {
        if (result.statusCode !== 200 & result.ID === undefined) {
          setSnackbarProps({ open: true, message: 'Something went wrong: ' + result.body, severity: 'error' });
          return { status: result.statusCode, result: result.body };
        } else {
          return { status: 200, id: result.ID };
        }
      }).catch( (error) => {
        setSnackbarProps({ open: true, message: 'Something went wrong: ' + error, severity: 'error' });
        return { status: 500, result: error };
      });
    };

export const updateFlexItem = async (body, setSnackbarProps) => {
  return await API.post('Saltmine-Van-API-Flex', '/update', {
    body: body,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((result) => {
    if (result.statusCode !== 200) {
      setSnackbarProps({ open: true, message: 'Something went wrong: ' + result.body, severity: 'error' });
    }
    return { status: result.statusCode };
  }).catch( (error) => {
    setSnackbarProps({ open: true, message: 'Something went wrong: ' + error, severity: 'error' });
    return { status: 500, result: error };
  });
};

export const deleteFlexItem = async (body, setSnackbarProps) => {
  return await API.del('Saltmine-Van-API-Flex', '/', {
    body: body,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((result) => {
    return { status: 200 };
  }).catch( (error) => {
    setSnackbarProps({ open: true, message: 'Something went wrong: ' + error, severity: 'error' });
    return { status: 500, result: error };
  });
};
