import { Storage } from 'aws-amplify';
import { carVanSwitch } from '../Libs/StageConfig';

const getCoreFileNames = () => {
  const carFileNames = [
    'Claims Report for Saltmine.csv',
    'Income Report For Saltmine.csv',
    'ICON All Contracts for Saltmine.csv',
  ];
  const vanFileNames = [
    'Claims Report for Saltmine-Van.csv',
    'Income Report For Saltmine-Van.csv',
    'ICON All Contracts for Saltmine-Van.csv',
  ];
  return carVanSwitch(carFileNames, vanFileNames);
};

export const isCoreFileName = (filename) => {
  return getCoreFileNames().includes(filename);
};

export const isAddacsFileName = (filename) => {
  if (typeof filename !== 'string') return false;
  if (filename.toLowerCase().substring(0, 6) === 'addacs' && filename.slice(-4) === '.xml') {
    return true;
  } else if (filename.toLowerCase().substring(0, 5) === 'awacs' && filename.slice(-4) === '.xml') {
    return true;
  } else if (filename.toLowerCase().substring(0, 5) === 'arucs' && filename.slice(-4) === '.xml') {
    return true;
  } else if (filename.toLowerCase().substring(0, 5) === 'brddi' && filename.slice(-4) === '.xml') {
    return true;
  } else if (filename.toLowerCase().substring(0, 5) === 'ddica' && filename.slice(-4) === '.xml') {
    return true;
  } else {
    return false;
  }
};

export const isAgedFileName = (filename) => {
  if (typeof filename !== 'string') return false;
  if (filename.toLowerCase().substring(0, 11) === 'open items ' &&
  filename.slice(-4) === '.csv') {
    return true;
  } else {
    return false;
  }
};

export const acceptableFileNames = (filename) => {
  if (isCoreFileName(filename)) {
    return true;
  } else if (isAddacsFileName(filename)) {
    return true;
  } else if (isAgedFileName(filename)) {
    return true;
  } else {
    return false;
  }
};

const validCoreVanFileContents = (text, filename) => {
  if (filename === 'Claims Report for Saltmine-Van.csv') {
    if (text.substring(0, 155) ===
    'Claim_Date,Schedule_Chassis,Schedule_Number,Claim_Parts_Amt,Claim_Labour_Amt,' +
    'Claim_Other_Amt,Claim_Total_Amt,Claim_Distance_KM,Service_Type,Dealer_Number\r\n') {
      return true;
    } else {
      return false;
    }
  } else if (filename === 'Income Report For Saltmine-Van.csv') {
    if (text.substring(0, 97) ===
    'Claim Against Local Schedule,Sched Status,Finance Total Paid,Work Done Date,Initial Payment,POL\r\n') {
      return true;
    } else {
      return false;
    }
  } else if (filename === 'ICON All Contracts for Saltmine-Van.csv') {
    if (text.substring(0, 492) ===
    'ScheduleID,ScheduleNo,RegistrationDate,LaunchDate,StartDate,EndDate,TerminatedDate,Term,Registration,' +
    'Status,PencePerMile,ModelGroup,PaymentFrequency,ScheduleRemarks,ChargeAmount,OneOffAmount,ChassisNo,' +
    'ServicesAvailable,ServicesUsed,ProductCode,CustomerID,CustomerTitle,CustomerName,CustomerEmail,' +
    'CustomerMobile,CustomerPhone,CustomerAddress,CustomerPostcode,CustomerCofico,Sched Last Tacho,' +
    'Schedule Est Distance Array,Schedule Ppk Array,Schedule Act Distance Array,' +
    'Schedule Adjustment Array\r\n') {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};


const validCoreCarFileContents = (text, filename) => {
  if (filename === 'Claims Report for Saltmine.csv') {
    if (text.substring(0, 141) ===
    'Claim_Date,Schedule_Chassis,Schedule_Number,Claim_Parts_Amt,Claim_Labour_Amt,' +
    'Claim_Other_Amt,Claim_Total_Amt,Claim_Distance_KM,Service_Type\r\n') {
      return true;
    } else {
      return false;
    }
  } else if (filename === 'Income Report For Saltmine.csv') {
    if (text.substring(0, 97) ===
    'Claim Against Local Schedule,Sched Status,Finance Total Paid,Work Done Date,Initial Payment,POL\r\n') {
      return true;
    } else {
      return false;
    }
  } else if (filename === 'ICON All Contracts for Saltmine.csv') {
    if (text.substring(0, 374) ===
    'ScheduleID,ScheduleNo,RegistrationDate,LaunchDate,StartDate,EndDate,TerminatedDate,Term,Registration,' +
    'Status,PencePerMile,ModelGroup,PaymentFrequency,ScheduleRemarks,ChargeAmount,OneOffAmount,ChassisNo,' +
    'ServicesAvailable,ServicesUsed,ProductCode,CustomerID,CustomerTitle,CustomerName,CustomerEmail,' +
    'CustomerMobile,CustomerPhone,CustomerAddress,CustomerPostcode,CustomerCofico\r\n') {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const validAddacsFileContents = (text, filename) => {
  if (filename.toLowerCase().substring(0, 6) === 'addacs') {
    if (text.substring(0, 42) ===
   '<?xml version = \'1.0\' encoding = \'UTF-8\'?>' &&
   text.slice(-15) === '</BACSDocument>' &&
   text.includes('advice-type="ADDACS"')) {
      return true;
    } else {
      return false;
    }
  } else if (filename.toLowerCase().substring(0, 5) === 'awacs') {
    if (text.substring(0, 42) ===
   '<?xml version = \'1.0\' encoding = \'UTF-8\'?>' &&
   text.slice(-15) === '</BACSDocument>' &&
   text.includes('advice-type="AWACS"')) {
      return true;
    } else {
      return false;
    }
  } else if (filename.toLowerCase().substring(0, 5) === 'arucs') {
    if (text.substring(0, 47) ===
   '<?xml version = \'1.0\' encoding = \'ISO-8859-1\'?>' &&
   text.slice(-15) === '</BACSDocument>' &&
   text.includes('<ARUCS>')) {
      return true;
    } else {
      return false;
    }
  } else if (filename.toLowerCase().substring(0, 5) === 'brddi') {
    if (text.substring(0, 42) ===
   '<?xml version = \'1.0\' encoding = \'UTF-8\'?>' &&
   text.slice(-15) === '</BACSDocument>' &&
   text.includes('advice-type="AUDDIS"')) {
      return true;
    } else {
      return false;
    }
  } else if (filename.toLowerCase().substring(0, 5) === 'ddica') {
    if (text.substring(0, 47) ===
   '<?xml version = \'1.0\' encoding = \'ISO-8859-1\'?>' &&
   text.slice(-15) === '</VocaDocument>' &&
   text.includes('<DDICAdvice')) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const validAgedFileContents = (text) => {
  if (text.substring(0, 61)=== '                         Reference,,,,,,,,,,,,,,,,\r\n Customer') {
    return true;
  } else {
    return false;
  }
};

export const acceptableFileContents = (text, filename) => {
  if (isCoreFileName(filename)) {
    return carVanSwitch(validCoreCarFileContents(text, filename), validCoreVanFileContents(text, filename));
  } else if (isAddacsFileName(filename)) {
    return validAddacsFileContents(text, filename);
  } else if (isAgedFileName(filename)) {
    return validAgedFileContents(text);
  } else {
    return false;
  }
};

const uploadEmailAttachment = async (file) => {
  return await Storage.put(('Email Attachments/' + file.name), file, {
    progressCallback(progress) {
      console.log(progress);
    },
  }).then( (result) => {
    return { statusCode: 200, fileName: file.name };
  }).catch( (error) => {
    return { statusCode: 400, messgae: error };
  });
};

export const processEmailAttachment = async (file) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = async function(e) {
    // The file's text will be printed here
      const result = await uploadEmailAttachment(file);
      resolve(result);
    };
    reader.onerror = () => {
      reader.abort();
    };

    reader.readAsText(file);
  });
};

export const convertFileNames = (fileName) => {
  return fileName.replace(/[^a-zA-Z0-9.]/g, '').toLowerCase();
};
