
export const emptyCustomerData = {
  CustomerID: '',
  CustomerName: '',
  CustomerAddress: '',
  CustomerPostcode: '',
  CustomerEmail: '',
  CustomerPhone: '',
  CustomerMobile: '',
};

export const emptyScheduleData = {
  ScheduleID: '',
  ScheduleNo: 0,
  ChassisNo: '',
  Registration: '',
  CustomerCofico: '',
  StartDate: '',
  ProductCode: '',
  EndDate: '',
  PaymentFrequency: '',
  RegistrationDate: '',
  ModelGroup: '',
  LaunchDate: '',
  PencePerMile: '',
  Term: '',
  HasVAT: '',
  Status: '',
  ServicesAvailable: '',
  ChargeAmount: '',
  ChargeAmountIncVAT: '',
  Remarks: '',
};
