// Functions
import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { sortArrayByProperty } from '../Libs/fileHelper';
// Resources

export default function BulkCommunicationQueuePopup({ popupOpen, setPopupOpen, actionCodesArray,
  addQueuetoData }) {
  const sxClasses = {
    dialogTitle: {
      paddingTop: 1,
      textAlign: 'center',
    },
  };

  BulkCommunicationQueuePopup.propTypes = {
    popupOpen: PropTypes.bool.isRequired,
    setPopupOpen: PropTypes.func.isRequired,
    actionCodesArray: PropTypes.array.isRequired,
    addQueuetoData: PropTypes.func.isRequired,
  };

  const [actionCodeSelect, setActionCodeSelect] = React.useState('');

  const handleClose = () => {
    setPopupOpen(false);
  };

  return (
    <Dialog open={popupOpen} onClose={handleClose} aria-labelledby="form-dialog-title" >
      <DialogTitle sx={sxClasses.dialogTitle} id="form-dialog-title">Add Data from Queue</DialogTitle>
      <DialogContent>
        <TextField select
          margin="dense"
          id="actionCodeSelect"
          label="Action Code"
          variant='outlined'
          onChange={(event)=>{
            setActionCodeSelect(event.target.value);
          }}
          fullWidth
          value={actionCodeSelect}>
          {sortArrayByProperty(actionCodesArray || [], 'ActionType').map((actionCode, i) => (
            <MenuItem key={i} value={actionCode.ActionType}>
              {actionCode.ActionType + ' - ' + actionCode.ActionDescription}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>{
          addQueuetoData(actionCodeSelect);
        }} fullWidth variant='contained' color="primary">
          Add to Data
        </Button>
      </DialogActions>
      <DialogActions>
        <Button onClick={handleClose} fullWidth variant='contained' color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
