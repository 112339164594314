// Functions
import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import { ukDateStringToDate } from '../../Libs/fileHelper';
// Resources

export default function ScheduleAdjustForm({ popupOpen, setPopupOpen, scheduleData, setScheduleData,
  callAddSchedule, setSnackbarProps, callUpdateSchedule }) {
  const sxClasses = {
    dialogTitle: {
      paddingTop: 1,
      textAlign: 'center',
    },
    textFieldStyle: {
      width: '100%',
    },
  };

  ScheduleAdjustForm.propTypes = {
    popupOpen: PropTypes.bool.isRequired,
    setPopupOpen: PropTypes.func.isRequired,
    scheduleData: PropTypes.object.isRequired,
    setScheduleData: PropTypes.func.isRequired,
    callAddSchedule: PropTypes.func.isRequired,
    callUpdateSchedule: PropTypes.func.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const handleFieldChange = (event) => {
    const newValue = event.target.value;
    setScheduleData({
      ...scheduleData,
      [event.target.name]: newValue,
    });
  };

  const handleAddFleetChange = (event) => {
    if (scheduleData.invoiceFromDate) {
      if (event.getTime() <= scheduleData.invoiceFromDate) {
        setSnackbarProps({ open: true,
          message: 'This vehicle has already been invoiced. You cannot enter it into the past',
          severity: 'info',
        });
        setScheduleData({
          ...scheduleData,
          addToFleetDate: scheduleData.invoiceFromDate + 1,
        });
        return;
      }
    }
    setScheduleData({
      ...scheduleData,
      addToFleetDate: event.getTime(),
    });
  };

  const handleRollOnChange = (event) => {
    if (event.getTime() <= ukDateStringToDate(scheduleData.EndDate).getTime()) {
      setSnackbarProps({ open: true,
        message: 'You cannot roll an end date backwards. Please remove from fleet instead.',
        severity: 'info',
      });
      setScheduleData({
        ...scheduleData,
        rollOnDate: ukDateStringToDate(scheduleData.EndDate).getTime(),
      });
      return;
    } else {
      setScheduleData({
        ...scheduleData,
        rollOnDate: event.getTime(),
      });
    }
  };

  return (
    <Dialog open={popupOpen} aria-labelledby="form-dialog-title"
      maxWidth={false}>
      <DialogTitle sx={sxClasses.dialogTitle} id="form-dialog-title">
        {'Schedule: ' + scheduleData?.ScheduleID}
      </DialogTitle>
      <DialogContent>
        <TextField select fullWidth
          sx={{ marginTop: '8px' }}
          label="Euro Type"
          value={scheduleData.euroType || ''}
          onChange={handleFieldChange}
          name='euroType'
          id='euroType'>
          <MenuItem key={0} value={'5'}>
            5
          </MenuItem>
          <MenuItem key={1} value={'6'}>
            6
          </MenuItem>
        </TextField>
        {(scheduleData.fleetStatus === 'On Fleet' ||
          scheduleData.addToFleetDate < scheduleData.removeFromFleetDate) ?
        <>
          <DesktopDatePicker
            margin="normal"
            id="rollOnDate"
            name="rollOnDate"
            label="Roll on End Date To"
            inputFormat="dd/MM/yyyy"
            value={scheduleData.rollOnDate || ukDateStringToDate(scheduleData.EndDate)}
            onChange={handleRollOnChange}
            renderInput={(params) => <TextField {...params} margin="dense" sx={sxClasses.textFieldStyle}/>}
          />
        </>:
        <DesktopDatePicker
          margin="normal"
          id="addToFleetDate"
          name="addToFleetDate"
          label="Add To Fleet Date"
          inputFormat="dd/MM/yyyy"
          value={scheduleData.addToFleetDate}
          onChange={handleAddFleetChange}
          renderInput={(params) => <TextField {...params} margin="dense" sx={sxClasses.textFieldStyle}/>}
        />}
        <TextField
          margin="dense"
          id="manualRate"
          name="manualRate"
          label="Manual Rate"
          type="manualRate"
          fullWidth
          onChange={handleFieldChange}
          value={scheduleData.manualRate || ''}
        />
      </DialogContent>
      <DialogActions>
        {(scheduleData.fleetStatus === 'On Fleet' ||
          scheduleData.addToFleetDate < scheduleData.removeFromFleetDate) ?
          <Button onClick={()=>{
            callUpdateSchedule();
            setPopupOpen(false);
          }} color="primary">
            Update
          </Button> :
          <Button onClick={()=>{
            callAddSchedule();
            setPopupOpen(false);
          }} color="primary">
            Add To Fleet
          </Button>
        }
        <Button onClick={closePopup} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
