// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Components
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import AddedContactEntry from '../../Components/DeceasedPage/DeceasedAddContactEntryForm';
import { convertToPhoneFormat } from '../../Libs/fileHelper';
// Resources
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles((theme) => ({
  containerStyle: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  buttonStyle: {
    width: '200px',
  },
  GridStyle: {
    'border': '1px solid',
    'borderColor': '#000000',
    'padding': theme.spacing(2),
    'backgroundColor': theme.palette.primary.backgroundFore,
  },
  innerGridStyle: {
    borderColor: '#BBBBBB',
    border: '1px dashed',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  textFieldStyle: {
    width: '100%',
    textAlign: 'left',
  },
  titleStyle: {
    position: 'absolute',
    backgroundColor: theme.palette.primary. backgroundFore,
    backfaceVisibility: 'hidden',
    marginTop: '-13px',
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  aTagStyle: {
    marginLeft: '4px',
    marginRight: '4px',
  },
}));

export default function DeceasedAddedContacts({ decFields, handleDecChange, changesDisabled,
  setSnackbarProps }) {
  const classes = useStyles();
  const sxClasses = {
    iconButton: {
      position: 'absolute',
      right: '60px',
      padding: 0,
    },
  };

  DeceasedAddedContacts.propTypes = {
    decFields: PropTypes.object,
    handleDecChange: PropTypes.func.isRequired,
    changesDisabled: PropTypes.bool.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
  };

  const [addContactPopup, setAddContactPopup] = React.useState(false);
  const [contactEditIndex, setContactEditIndex] = React.useState(0);
  const [editedContactData, setEditedContactData] = React.useState({});

  const addNewContact = () => {
    if (changesDisabled) {
      setSnackbarProps({ open: true,
        message: 'This DEC item has been completed. Changes cannot be made.',
        severity: 'info' });
      return;
    }
    setContactEditIndex(-1);
    setEditedContactData({});
    setAddContactPopup(true);
  };

  const handleContactFieldChange = (event) => {
    setEditedContactData({
      ...editedContactData,
      [event.target.name]: event.target.value,
    });
  };

  const handleEmptyContacts = () => {
    if (decFields.Contacts) {
      return decFields.Contacts;
    } else {
      return [];
    }
  };

  const editContactRecord = (index) => {
    setContactEditIndex(index);
    setEditedContactData(decFields.Contacts[index]);
    setAddContactPopup(true);
  };

  const handleEditContactSave = () => {
    const tempDecFieldsContacts = [...handleEmptyContacts()];
    if (tempDecFieldsContacts[contactEditIndex]) {
      tempDecFieldsContacts[contactEditIndex] = editedContactData;
    } else {
      tempDecFieldsContacts.push(editedContactData);
    }
    handleDecChange(
        {
          target: {
            name: 'Contacts',
            value: tempDecFieldsContacts,
          },
        },
    );
    setAddContactPopup(false);
  };

  const handleEditContactDelete = () => {
    const tempDecFieldsContacts = [...handleEmptyContacts()];
    tempDecFieldsContacts.splice(contactEditIndex, 1);
    handleDecChange(
        {
          target: {
            name: 'Contacts',
            value: tempDecFieldsContacts,
          },
        },
    );
    setAddContactPopup(false);
  };

  return (
    <div className={classes.containerStyle} >
      <Grid container spacing={0} component={Paper} className={classes.GridStyle}>
        {handleEmptyContacts().map((row, index) => (
          <Grid item xs={12} key={index}>
            <div className={classes.titleStyle}>
              {row.contactType} Contact: {row.contactName}{row.relation && ' (' + row.relation + ')'}
              {row.executor && ' (Executor)'}
            </div>
            <Grid container spacing={1} className={classes.innerGridStyle}>
              <Grid item xs={12}>
                <div className={classes.textFieldStyle}>
                  Address: {row.addressLine1}
                  {row.addressLine2 && ', ' + row.addressLine2}
                  {row.postCode && ', ' + row.postCode}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.textFieldStyle}>
                  <React.Fragment>
                    Phone:
                    <a className={classes.aTagStyle} href={
                      'tel:9' + convertToPhoneFormat(row.homePhone)
                    }>{convertToPhoneFormat(row.homePhone)}</a>
                    {(row.homePhone && row.mobilePhone) && <React.Fragment>-</React.Fragment> }
                    <a className={classes.aTagStyle} href={
                      'tel:9' + convertToPhoneFormat(row.mobilePhone)
                    }>{convertToPhoneFormat(row.mobilePhone)}</a>
                  </React.Fragment>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.textFieldStyle}>
                  Email:
                  <a className={classes.aTagStyle} href={
                    'mailto:' + row.email +
                    '?subject=Mercedes-Benz Service Contract'
                  }>{row.email}</a>
                </div>
              </Grid>
              <IconButton sx={sxClasses.iconButton} onClick={()=>(editContactRecord(index))}>
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button className={classes.buttonStyle} variant='contained' color='primary' onClick={addNewContact}>
            Add New Contact
          </Button>
        </Grid>
      </Grid>
      {addContactPopup &&
      <AddedContactEntry
        closeContactEntry={
          ()=>(setAddContactPopup(false))
        }
        contactData={editedContactData}
        handleContactFieldChange={handleContactFieldChange}
        handleEditContactSave={handleEditContactSave}
        handleEditContactDelete={handleEditContactDelete}
        contactEditIndex={contactEditIndex}
      />}
    </div>
  );
}
