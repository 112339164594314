// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
// Components
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CustomerDetails from '../Components/GeneralComponents/CustomerDetails';
import CustomerScheduleSelect from '../Components/GeneralComponents/CustomerScheduleSelect';
import Grid from '@mui/material/Grid';
import ScheduleDetails from '../Components/GeneralComponents/ScheduleDetails';
import Paper from '@mui/material/Paper';
import DeceasedItemDetails from '../Components/DeceasedPage/DeceasedItemDetails';
import DeceasedPendingInteraction from '../Components/DeceasedPage/DeceasedPendingInteraction';
import DeceasedAddedContacts from '../Components/DeceasedPage/DeceasedAddedContacts';
import DeceasedCommunicationConfiguration from '../Components/DeceasedPage/DeceasedCommunicationConfiguration';
import EmailPopup from '../FunctionalComponents/EmailPopup';
import { getCurrentUser } from '../Libs/fileHelper';
import { deceasedTemplateEmailText, deceasedTemplatePDFText } from '../Libs/templateHelper';
import { updatePendingItem, createAndCompletePendingItem } from '../Libs/apiHelper';
import { generateEMLReference } from '../Libs/emailHelper';
import { emptyScheduleData } from '../Libs/emptyDataStructures';
// Resources

const useStyles = makeStyles((theme) => ({
  contentSections: {
    marginTop: theme.spacing(0.5),
    border: '1px solid',
    borderColor: '#000000',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  contentSectionsNoBorder: {
    'padding': '2px',
    'textAlign': 'center',
  },
  contentSectionsNoBorderNoSpacing: {
    'textAlign': 'center',
  },
  mainStyle: {
    width: '100%',
    maxWidth: '98%',
    padding: '2px',
  },
  exitContainerStyle: {
    'textAlign': 'right',
  },
}));

export default function DeceasedPage(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();

  DeceasedPage.propTypes = {
    scheduleData: PropTypes.array.isRequired,
    actionCodeLookup: PropTypes.array.isRequired,
    customerData: PropTypes.object.isRequired,
    pendingData: PropTypes.array.isRequired,
    currUser: PropTypes.object.isRequired,
    setScheduleData: PropTypes.func.isRequired,
    setPendingData: PropTypes.func.isRequired,
    setActionCodeLookup: PropTypes.func.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
    emailConfigData: PropTypes.object.isRequired,
  };

  const pendingID = params.PendingItem;
  const selectedDecItem = () => {
    if (props.pendingData) {
      const foundPendingItem = props.pendingData.find(
          (item) => {
            return item.ID == pendingID;
          },
      );
      if (foundPendingItem) {
        return foundPendingItem;
      } else {
        return {};
      }
    } else {
    // Trigger back, refresh would require query on ID to pending table which isnt currently available.
    // To-Do add in later?
      return {};
    }
  };

  const changesDisabled = (selectedDecItem().CompletedDate !== undefined &&
    selectedDecItem().CompletedDate !== '');

  const [selectedScheduleIndex, setSelectedScheduleIndex] = React.useState(0);
  const selectedSchedule = () => {
    if (props.scheduleData.length === 0 ) {
      return emptyScheduleData;
    } else {
      return props.scheduleData[selectedScheduleIndex];
    }
  };
  const [decFields, setDecFields] = React.useState(selectedDecItem()?.decFields || {});
  const currDecFieldsRef = React.useRef(decFields);
  const handleDecChange = (event, name) => {
    if (changesDisabled) {
      props.setSnackbarProps({ open: true,
        message: 'This DEC item has been completed. Changes cannot be made.',
        severity: 'info' });
      return;
    }
    if (event) {
      if (event.target !== undefined && event.target !== null) {
        setDecFields({ ...decFields, [event.target.name]: event.target.value });
        currDecFieldsRef.current = { ...decFields, [event.target.name]: event.target.value };
      } else {
        if (event.getTime()) {
          setDecFields({ ...decFields, [name]: event.getTime() });
          currDecFieldsRef.current = { ...decFields, [name]: event.getTime() };
        }
      }
    }
  };

  const handleBifIntCombinedChase = () => {
    if (changesDisabled) {
      props.setSnackbarProps({ open: true,
        message: 'This DEC item has been completed. Changes cannot be made.',
        severity: 'info' });
      return;
    }
    setDecFields({ ...decFields, bifChase: new Date().getTime(), intentionChase: new Date().getTime() });
  };

  const handleDecActionButtonPress = (name) => {
    if (changesDisabled) {
      props.setSnackbarProps({ open: true,
        message: 'This DEC item has been completed. Changes cannot be made.',
        severity: 'info' });
      return;
    }
    if (name === 'bankNotificationCall' || name === 'bifRecieved' || name === 'deathCertificateRecieved') {
      // No Letters or emails for these stages
      handleDecChange({
        target: {
          name: name,
          value: new Date().getTime(),
        },
      });
      return;
    }
    generateCommunication(name);
  };

  const generateCommunication = (templateName) => {
    const sentToDetails = getSentToDetails();

    let startingBody = '';
    if (generateLetter) {
      startingBody = deceasedTemplatePDFText(templateName, getSentToDetails().CustomerName, 'XX/XX/XXXX');
    } else {
      startingBody = deceasedTemplateEmailText(
          templateName, selectedSchedule(), sentToDetails, 'XX/XX/XXXX', props.emailConfigData.signature,
      );
    }
    let tempState = {
      emailSentTo: sentToDetails.CustomerEmail,
      emailSubject: 'Mercedes-Benz Service Contract - ' + selectedSchedule().ScheduleID + ' - ' +
        selectedSchedule().Registration,
      emailBody: startingBody,
      sendAsPDF: generateLetter,
      sendAction: (event)=>{
        storeDataChange(templateName);
        addEmailSentHistoryItem(event);
      },
    };
    if (templateName === 'bifSent' || templateName === 'bifChase' || templateName === 'bifIntChase') {
      tempState = {
        ...tempState,
        emailAttachment: 'System/Bereavement Instruction Form.pdf',
      };
    }
    setEmailStates(tempState);
    setSendingEmail(true);
  };

  const storeDataChange = (name) => {
    if (name === 'bifIntChase') {
      handleBifIntCombinedChase();
      return;
    }
    handleDecChange({
      target: {
        name: name,
        value: new Date().getTime(),
      },
    });
  };

  const getSentToDetails = () => {
    if (sendTo === 'To Whom it may Concern') {
      return ({ ...props.customerData, CustomerName: sendTo });
    } else {
      const selectedContactIndex = decFields.Contacts.findIndex(
          (contact) => contact.contactName === sendTo,
      );
      const selectedContact = decFields.Contacts[selectedContactIndex];
      return ({
        CustomerName: sendTo,
        CustomerAddress: (selectedContact.addressLine1 || '') + ', ' + (selectedContact.addressLine2 || ''),
        CustomerPostcode: selectedContact.postCode || '',
        CustomerEmail: selectedContact.email || '',
      });
    }
  };

  const handleScheduleChange = (scheduleClicked) => {
    setSelectedScheduleIndex(
        props.scheduleData.findIndex(
            (schedInfo) => schedInfo.ScheduleID === scheduleClicked,
        ),
    );
  };

  const [generateLetter, setGenerateLetter] = React.useState(true);
  const [sendTo, setSendTo] = React.useState('To Whom it may Concern');

  const handleGenerateLetterChange = (event, newValue) => {
    console.log(decFields);
    // Note this function doesnt use set(!newValue) to flip as user can click same button twice
    if (newValue === true) {
      setGenerateLetter(true);
    } else {
      setGenerateLetter(false);
    }
  };

  const handleSendToChange = (event) => {
    console.log(decFields);
    setSendTo(event.target.value);
  };

  const triggerSaveAndBack = async () => {
    // Update Existing Record
    const body = {
      ID: selectedDecItem().ID,
      decFields: decFields,
      userId: getCurrentUser(props.currUser),
    };

    const response = await updatePendingItem(body, props.setSnackbarProps);
    if (response.status === 200) {
      saveLocalData();
      navigate(-1);
    }
  };

  const saveLocalData = () => {
    const index = props.pendingData.findIndex((pendItem) => (pendItem.ID === selectedDecItem().ID));
    const pendingDataCopy = [...props.pendingData];
    pendingDataCopy[index] = {
      ...pendingDataCopy[index],
      decFields: currDecFieldsRef.current,
    };
    props.setPendingData(pendingDataCopy);
  };

  const [letterTemplateSelected, setLetterTemplateSelected] = React.useState('Please Select a Template');

  const handleLetterTemplateChange = (event) => {
    setLetterTemplateSelected(event.target.value);
  };

  const letterTemplateOptions = () => {
    const letterTemplates = [
      { name: 'Account Clear Letter', value: 'Account Clear Letter' },
      { name: 'Cancellation Acknowledgement Letter', value: 'Cancellation Acknowledgement Letter' },
      { name: 'Novation Completed Confirmation', value: 'Novation Completed Confirmation' },
      { name: 'Novation Request Bank Details Letter', value: 'Novation Request Bank Details Letter' },
      { name: 'Credit Balance Letter', value: 'Credit Balance Letter' },
      { name: 'Credit Balance Chase Letter', value: 'Credit Balance Chase Letter' },
    ];
    if (decFields.bankNotificationSent !== undefined) {
      letterTemplates.push({
        name: 'Bank Notification Sent', value: 'bankNotificationSent',
      });
    }
    if (decFields.bankNotificationChase !== undefined) {
      letterTemplates.push({
        name: 'Bank Notification Chase', value: 'bankNotificationChase',
      });
    }
    if (decFields.bifSent !== undefined) {
      letterTemplates.push({
        name: 'BIF Sent', value: 'bifSent',
      });
    }
    if (decFields.bifChase !== undefined) {
      letterTemplates.push({
        name: 'BIF Chase', value: 'bifChase',
      });
    }
    if (decFields.intentionChase !== undefined) {
      letterTemplates.push({
        name: 'Intention Chase', value: 'intentionChase',
      });
    }
    if (decFields.bifChase !== undefined && decFields.intentionChase) {
      letterTemplates.push({
        name: 'BIF INT Chase', value: 'bifIntChase',
      });
    }
    return letterTemplates;
  };

  const rerunLetterTemplate = () => {
    if (letterTemplateSelected === 'Please Select a Template') {
      props.setSnackbarProps({ open: true,
        message: 'Please select a template to run.',
        severity: 'info' });
      return;
    }
    generateCommunication(letterTemplateSelected);
  };

  // Email Popup Functions
  const [sendingEmail, setSendingEmail] = React.useState(false);
  const [emailStates, setEmailStates] = React.useState({
    emailSentTo: props.customerData.CustomerEmail,
    emailSentFrom: props.emailConfigData.defaultMailbox,
    emailSubject: '',
    emailBody: '',
    sendAsPDF: false,
    sendAction: ()=>{},
  });
  const sendEmailFromCustomerDetails = () => {
    if (emailStates.emailBody === '') {
      setEmailStates({
        emailSentTo: props.customerData.CustomerEmail,
        emailSentFrom: props.emailConfigData.defaultMailbox,
        emailSubject: 'Mercedes-Benz Service Contract - ' + selectedSchedule().ScheduleID + ' - ' +
          selectedSchedule().Registration,
        emailBody: '\n\n' + props.emailConfigData.signature,
        sendAsPDF: false,
        sendAction: addEmailSentHistoryItem,
      });
    }
    setSendingEmail(true);
  };

  const addEmailSentHistoryItem = async (eventEmailStates) => {
    console.log('Email Sent');
    let body = {
      ScheduleID: selectedSchedule().ScheduleID,
      Status: 'History',
      CustomerName: selectedSchedule().CustomerName,
      ScheduleStatus: selectedSchedule().Status,
      CustomerID: selectedSchedule().CustomerID,
      CustomerCofico: selectedSchedule().CustomerCofico,
    };
    if (eventEmailStates.sendAsPDF) {
      body = {
        ...body,
        ActionType: 'LET',
        Reference: 'Letter Sent: \n\n' + eventEmailStates.emailBody,
      };
    } else {
    // Log EML item
      body = {
        ...body,
        ActionType: 'EML',
        Reference: generateEMLReference(eventEmailStates.groupMailbox, eventEmailStates.emailSentTo,
            eventEmailStates.emailSubject, eventEmailStates.emailBody, eventEmailStates.emailAttachment),
      };
    }

    const response = await createAndCompletePendingItem(body,
        getCurrentUser(props.currUser), props.pendingData, props.setPendingData, props.setSnackbarProps);

    if (response.status === 200) {
      console.log('Success');
    }

    setEmailStates({
      emailSentTo: props.customerData.CustomerEmail,
      emailSentFrom: props.emailConfigData.defaultMailbox,
      emailSubject: '',
      emailBody: '',
      sendAsPDF: false,
      sendAction: ()=>{},
    });
  };

  return (
    <Container component='main' className={classes.mainStyle} maxWidth={false}>
      {props.customerData.CustomerID === '' ? <Button variant='contained' color='primary' onClick={()=>{
        navigate(-1);
      }}>Back</Button> :
      <React.Fragment>
        <Grid container spacing={0}>
          <Grid item xs={6} >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <CustomerDetails customerData={props.customerData} scheduleData={selectedSchedule()}
                      sendEmailFromCustomerDetails={sendEmailFromCustomerDetails}/>
                  </Grid>
                  <Grid item xs= {7} className={classes.contentSectionsNoBorderNoSpacing}>
                    <CustomerScheduleSelect
                      scheduleData={props.scheduleData}
                      handleScheduleChange={handleScheduleChange}
                      hideButtons={true}
                      selectedSchedule={selectedSchedule().ScheduleID}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <DeceasedItemDetails
                  decFields={decFields}
                  handleDecChange={handleDecChange}
                  handleDecActionButtonPress={handleDecActionButtonPress}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} >
            <Grid container spacing={0}>
              <Grid item xs={12} component={Paper} className={classes.contentSections} >
                <ScheduleDetails scheduleData={selectedSchedule()} />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: 1 }}>
                <DeceasedCommunicationConfiguration
                  contacts={decFields?.Contacts}
                  handleGenerateLetterChange={handleGenerateLetterChange}
                  handleSendToChange={handleSendToChange}
                  sendTo={sendTo}
                  generateLetter={generateLetter}
                  letterTemplateOptions={letterTemplateOptions()}
                  rerunLetterTemplate={rerunLetterTemplate}
                  letterTemplateSelected={letterTemplateSelected}
                  handleLetterTemplateChange={handleLetterTemplateChange}
                />
              </Grid>
              <Grid item xs={12}>
                <DeceasedAddedContacts decFields={decFields} handleDecChange={handleDecChange}
                  changesDisabled={changesDisabled} setSnackbarProps={props.setSnackbarProps}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.contentSectionsNoBorder} >
                  <DeceasedPendingInteraction
                    createdDate={selectedDecItem().CreatedDate}
                    reference={selectedDecItem().Reference}
                    triggerSave={triggerSaveAndBack}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
      }
      <EmailPopup
        popupOpen={sendingEmail}
        setPopupOpen={setSendingEmail}
        emailStates={emailStates}
        setEmailStates={setEmailStates}
        setSnackbarProps={props.setSnackbarProps}
        customerData={getSentToDetails()}
        scheduleData={selectedSchedule()}
        currUser={props.currUser}
        emailConfigData={props.emailConfigData}/>
    </Container>
  );
}
