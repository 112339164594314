// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { convertToPhoneFormat, toTitleCase } from '../../Libs/fileHelper';
// Resources

const useStyles = makeStyles((theme) => ({
  fakeLink: {
    color: '-webkit-link',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

export default function CustomerDetails(props) {
  const classes = useStyles();
  const sxClasses = {
    paperStyle: {
      width: '100%',
      border: '1px solid',
      borderColor: '#000000',
      height: '185px',
      paddingLeft: 1,
      marginTop: 0.5,
    },
    paperStyleShort: {
      width: '100%',
      border: '1px solid',
      borderColor: '#000000',
      height: '105px',
      paddingLeft: 1,
      paddingTop: 1,
    },
  };

  const cellStyle = {
    padding: '2px',
    borderBottom: '0px',
  };

  CustomerDetails.propTypes = {
    customerData: PropTypes.shape({
      CustomerID: PropTypes.string.isRequired,
      CustomerName: PropTypes.string,
      CustomerAddress: PropTypes.string,
      CustomerPostcode: PropTypes.string,
      CustomerEmail: PropTypes.string,
      CustomerPhone: PropTypes.string,
      CustomerMobile: PropTypes.string,
    }),
    scheduleData: PropTypes.object.isRequired,
    sendEmailFromCustomerDetails: PropTypes.func.isRequired,
    terminationPage: PropTypes.bool,
  };

  return (
    <TableContainer component={Paper}
      sx={ props.terminationPage ? sxClasses.paperStyleShort : sxClasses.paperStyle}>
      {props.terminationPage ?
      <Table sx={cellStyle} size='small' aria-label='a dense table'>
        <TableBody>
          <TableRow>
            <TableCell sx={cellStyle}
              component='th' scope='row'>
                Customer ID:
            </TableCell>
            <TableCell sx={cellStyle} align='left'>
              {props.customerData.CustomerID}
            </TableCell>
            <TableCell sx={cellStyle} component='th' scope='row'>
                Email:
            </TableCell>
            <TableCell sx={cellStyle} align='left'
              onClick={props.sendEmailFromCustomerDetails}>
              <a className={classes.fakeLink} >{props.customerData.CustomerEmail}</a>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={cellStyle} component='th' scope='row'>
                Name:
            </TableCell>
            <TableCell sx={cellStyle} align='left'>
              {props.customerData.CustomerName}
            </TableCell>
            <TableCell sx={cellStyle} component='th' scope='row'>
                Telephone:
            </TableCell>
            <TableCell sx={cellStyle} align='left'>
              <a className="tel" href={'tel:9' + convertToPhoneFormat(props.customerData.CustomerPhone)}>
                {convertToPhoneFormat(props.customerData.CustomerPhone)}
              </a>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={cellStyle} component='th' scope='row'>
                Address:
            </TableCell>
            <TableCell sx={cellStyle} align='left'>
              {toTitleCase(props.customerData.CustomerAddress)}, {props.customerData.CustomerPostcode}
            </TableCell>
            <TableCell sx={cellStyle} component='th' scope='row'>
                Mobile:
            </TableCell>
            <TableCell sx={cellStyle} align='left'>
              <a className="tel" href={
                'tel:9' + convertToPhoneFormat(props.customerData.CustomerMobile)
              }>{convertToPhoneFormat(props.customerData.CustomerMobile)}</a>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table> :
      <Table sx={cellStyle} size='small' aria-label='a dense table'>
        <TableBody>
          <TableRow>
            <TableCell sx={cellStyle}
              component='th' scope='row'>
                Customer ID:
            </TableCell>
            <TableCell sx={cellStyle} align='left'>
              {props.customerData.CustomerID}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={cellStyle} component='th' scope='row'>
                Name:
            </TableCell>
            <TableCell sx={cellStyle} align='left'>
              {props.customerData.CustomerName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={cellStyle} component='th' scope='row'>
                Address:
            </TableCell>
            <TableCell sx={cellStyle} align='left'>
              {toTitleCase(props.customerData.CustomerAddress)}, {props.customerData.CustomerPostcode}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={cellStyle} component='th' scope='row'>
                Email:
            </TableCell>
            <TableCell sx={cellStyle} align='left'
              onClick={props.sendEmailFromCustomerDetails}>
              <a className={classes.fakeLink} >{props.customerData.CustomerEmail}</a>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={cellStyle} component='th' scope='row'>
                Telephone:
            </TableCell>
            <TableCell sx={cellStyle} align='left'>
              <a className="tel" href={'tel:9' + convertToPhoneFormat(props.customerData.CustomerPhone)}>
                {convertToPhoneFormat(props.customerData.CustomerPhone)}
              </a>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={cellStyle} component='th' scope='row'>
                Mobile:
            </TableCell>
            <TableCell sx={cellStyle} align='left'>
              <a className="tel" href={
                'tel:9' + convertToPhoneFormat(props.customerData.CustomerMobile)
              }>{convertToPhoneFormat(props.customerData.CustomerMobile)}</a>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      }
    </TableContainer>
  );
}
