// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { reverseSortArrayByProperty } from '../../Libs/fileHelper';
// Components
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// Resources

const useStyles = makeStyles((theme) => ({
  buttonContainerLeft: {
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
  },
  buttonContainerRight: {
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
  },
  tableStyle: {
    height: '141px',
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    border: '1px solid',
    borderColor: '#000000',
    backgroundColor: theme.palette.primary.backgroundFore,
  },
  tableStyleHidden: {
    height: '185px',
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '4px',
    padding: theme.spacing(1),
    border: '1px solid',
    borderColor: '#000000',
    backgroundColor: theme.palette.primary.backgroundFore,
  },
  selectedRow: {
    background: theme.palette.primary.selectedRow,
  },
  tablePadding: {
    paddingRight: theme.spacing(4),
  },
}));

export default function CustomerScheduleSelect(
    { scheduleData, handleScheduleChange, cancellationQuotePressed, worldpayPressed,
      hideButtons, selectedSchedule },
) {
  const classes = useStyles();
  const sxClasses = {
    buttonStyle: {
      marginBottom: 1,
      width: '100%',
      height: '36px',
    },
    cellStyle: {
      'padding': '0px',
      'textAlign': 'center',
    },
  };

  CustomerScheduleSelect.propTypes = {
    scheduleData: PropTypes.array.isRequired,
    handleScheduleChange: PropTypes.func.isRequired,
    cancellationQuotePressed: PropTypes.func,
    worldpayPressed: PropTypes.func,
    hideButtons: PropTypes.bool.isRequired,
    selectedSchedule: PropTypes.string,
  };

  const variableTableStyle = () => {
    if (!hideButtons) {
      return classes.tableStyle;
    } else {
      return classes.tableStyleHidden;
    }
  };

  const suspendedStatusCheck = (status, suspended) => {
    if (suspended === 'Y') {
      return status + ' - Sus';
    } else {
      return status;
    }
  };

  const [pageIndex, setPageIndex] = React.useState(0);
  const handleChangePage = (event, newPage) => {
    setPageIndex(newPage);
  };
  const sliceDataByPage = () => {
    return reverseSortArrayByProperty([...scheduleData], 'ScheduleID').slice(
        pageIndex*100, (pageIndex+1) * 100);
  };

  return (
    <React.Fragment>
      {!hideButtons &&
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <div className={classes.buttonContainerLeft}>
              <Button sx={sxClasses.buttonStyle} variant='contained' color='primary' onClick={() => {
                cancellationQuotePressed();
              }}>
              Cancellation Quote
              </Button>
            </div>
          </Grid>
          <Grid item xs={6} >
            <div className={classes.buttonContainerRight}>
              <Button sx={sxClasses.buttonStyle} variant='contained' color='primary' onClick={() => {
                worldpayPressed();
              }}>
              WorldPay
              </Button>
            </div>
          </Grid>
        </Grid>
      }
      <div className={classes.tablePadding}>
        <TableContainer className={variableTableStyle()} component={Paper}
          sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <Table sx={sxClasses.cellStyle} size='small' aria-label='a dense table' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={sxClasses.cellStyle}>Schedule ID</TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>Start Date</TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>End Date</TableCell>
                <TableCell sx={sxClasses.cellStyle} align='center'>Schedule Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sliceDataByPage().map((row) => (
                <TableRow key={row.ScheduleID}
                  className={(row.ScheduleID === selectedSchedule) ? classes.selectedRow : null}
                  onClick={() => {
                    handleScheduleChange(row.ScheduleID);
                  }}>
                  <TableCell sx={sxClasses.cellStyle} component='th' scope='row'>
                    {row.ScheduleID}
                  </TableCell>
                  <TableCell sx={sxClasses.cellStyle} align='center'>{row.StartDate}</TableCell>
                  <TableCell sx={sxClasses.cellStyle} align='center'>{row.EndDate}</TableCell>
                  <TableCell sx={sxClasses.cellStyle} align='center'>
                    {suspendedStatusCheck(row.Status, row.IsSuspended)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination sx={{ padding: 0 }}
            rowsPerPageOptions={[100]}
            component="div"
            count={scheduleData.length}
            rowsPerPage={100}
            page={pageIndex}
            onPageChange={handleChangePage}
          />
        </TableContainer>
      </div>
    </React.Fragment>
  );
}
