// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Components
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { excessMileageCalculation, excessMileageChargeCalculation,
  mileagePredictionBasedOnClaims, calculateCurrentYear } from '../Libs/mileageCalculatorHelper';
import { currencyFormatter } from '../Libs/fileHelper';
// Resources

const useStyles = makeStyles((theme) => ({
  textFieldStyle: {
    width: '100%',
  },
  rowStyleHighlighted: {
    background: theme.palette.primary.selectedRow,
  },
}));

export default function ExcessMileageCalculatorDetails({
  excessMileageData, setExcessMileageData, handleExcessMileageChangeEvent, scheduleData, claimsData,
  setSnackbarProps,
}) {
  const classes = useStyles();

  ExcessMileageCalculatorDetails.propTypes = {
    excessMileageData: PropTypes.object.isRequired,
    setExcessMileageData: PropTypes.func.isRequired,
    handleExcessMileageChangeEvent: PropTypes.func.isRequired,
    scheduleData: PropTypes.object.isRequired,
    claimsData: PropTypes.array.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
  };

  return (
    <TableContainer className={classes.tableStyle}>
      <Table className={classes.cellStyle} size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cellStyle}>Year</TableCell>
            <TableCell className={classes.cellStyle} align='center'>Yearly Allowance</TableCell>
            <TableCell className={classes.cellStyle} align='center'>Mileage Reading</TableCell>
            <TableCell className={classes.cellStyle} align='center'>Claims Predicted Mileage</TableCell>
            <TableCell className={classes.cellStyle} align='center'>PPK</TableCell>
            <TableCell className={classes.cellStyle} align='center'>Excess Mileage</TableCell>
            <TableCell className={classes.cellStyle} align='center'>Charge Due</TableCell>
            <TableCell className={classes.cellStyle} align='center'>Charge Processed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {excessMileageData.yearlyData.map((row, index) => (
            <TableRow className={index == calculateCurrentYear(scheduleData) ?
              classes.rowStyleHighlighted : null}
            key={index}>
              <TableCell className={classes.cellStyle} component='th' scope='row'>
                {row.reviewYear}
              </TableCell>
              <TableCell className={classes.cellStyle} align='center'>
                <TextField
                  margin="dense"
                  name="yearlyAllowance"
                  className={classes.textFieldStyle}
                  value={row.yearlyAllowance}
                  onChange={(e) => {
                    handleExcessMileageChangeEvent(e, row.reviewYear, excessMileageData,
                        setExcessMileageData, setSnackbarProps);
                  }}
                />
              </TableCell>
              <TableCell className={classes.cellStyle} align='center'>
                <TextField
                  margin="dense"
                  name="mileageReading"
                  className={classes.textFieldStyle}
                  value={row.mileageReading}
                  onChange={(e) => {
                    handleExcessMileageChangeEvent(e, row.reviewYear, excessMileageData,
                        setExcessMileageData, setSnackbarProps);
                  }}
                />
              </TableCell>
              <TableCell className={classes.refCellStyle} align='left'>
                {mileagePredictionBasedOnClaims(scheduleData.StartDate, scheduleData.EndDate, claimsData,
                    excessMileageData.scheduleLastTacho, row.reviewYear)}
              </TableCell>
              <TableCell className={classes.cellStyle} align='center'>
                <TextField
                  margin="dense"
                  name="pPK"
                  className={classes.textFieldStyle}
                  onChange={(e) => {
                    handleExcessMileageChangeEvent(e, row.reviewYear, excessMileageData,
                        setExcessMileageData, setSnackbarProps);
                  }}
                  value={row.pPK}
                />
              </TableCell>
              <TableCell className={classes.refCellStyle} align='left'>
                {excessMileageCalculation(excessMileageData, row.reviewYear)}
              </TableCell>
              <TableCell className={classes.refCellStyle} align='left'>
                {currencyFormatter(excessMileageChargeCalculation(excessMileageData, row.reviewYear))}
              </TableCell>
              <TableCell className={classes.refCellStyle} align='left'>
                {currencyFormatter(row.processedCharge)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
