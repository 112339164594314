// Functions
import { API } from 'aws-amplify';
import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ScheduleAdjustForm from '../../Components/InvoiceManagement/ScheduleAdjustForm';
import ScheduleRemoveForm from '../../Components/InvoiceManagement/ScheduleRemoveForm';
import ScheduleHistoryPopup from '../../Components/InvoiceManagement/ScheduleHistoryPopup';
// Resources
import { upgradedDate, reverseSortArrayByProperty, convertDateToUTCEpoch,
  ukDateStringToDate, currencyFormatter } from '../../Libs/fileHelper';
import QuestionIcon from '@mui/icons-material/Help';

export default function InvoiceHistory({ selectedSchedule, scheduleEventData, scheduleDataChange,
  nextMonthInvoiceDate, setSnackbarProps }) {
  const sxClasses = {
    paperStyle: {
      width: '100%',
      border: '1px solid',
      borderColor: '#000000',
      height: 'calc(100vh - 80px)',
      minHeight: '250px',
      paddingLeft: 1,
      marginTop: 0.5,
      marginLeft: 1,
    },
  };

  InvoiceHistory.propTypes = {
    selectedSchedule: PropTypes.object,
    scheduleEventData: PropTypes.array.isRequired,
    scheduleDataChange: PropTypes.func.isRequired,
    nextMonthInvoiceDate: PropTypes.instanceOf(Date).isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
  };

  const [amendRecord, setAmendRecord] = React.useState(false);
  const [removeRecord, setRemoveRecord] = React.useState(false);
  const [showEventDetails, setShowEventDetails] = React.useState(false);
  const [eventDetails, setEventDetails] = React.useState({});
  const [scheduleDataCopy, setScheduleDataCopy] = React.useState(selectedSchedule);

  const callAddSchedule = async () => {
    return await API.post('Saltmine-Van-API-Invoice', '/addSchedule', {
      body: {
        scheduleID: selectedSchedule?.ScheduleID,
        customerID: selectedSchedule?.CustomerID,
        euroType: scheduleDataCopy.euroType,
        manualRate: parseFloat(scheduleDataCopy.manualRate),
        startDate: selectedSchedule?.StartDate,
        registrationDate: selectedSchedule?.RegistrationDate,
        addToFleetDate: convertDateToUTCEpoch(scheduleDataCopy.addToFleetDate || Date.now()),
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      if (result.statusCode !== 200) {
        setSnackbarProps({
          open: true,
          message: JSON.stringify(result),
          severity: 'error',
        });
        return;
      }
      scheduleDataChange({ ...scheduleDataCopy,
        addToFleetDate: convertDateToUTCEpoch(scheduleDataCopy.addToFleetDate || Date.now()),
        fleetStatus: 'On Fleet',
        fleetAction: undefined });
    }).catch( (error) => {
      setSnackbarProps({
        open: true,
        message: error,
        severity: 'error',
      });
    });
  };


  const callUpdateSchedule = async () => {
    return await API.post('Saltmine-Van-API-Invoice', '/Update', {
      body: {
        scheduleID: selectedSchedule?.ScheduleID,
        customerID: selectedSchedule?.CustomerID,
        euroType: scheduleDataCopy.euroType,
        manualRate: parseFloat(scheduleDataCopy.manualRate),
        rollOnDate: scheduleDataCopy.rollOnDate,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      if (result.statusCode !== 200) {
        setSnackbarProps({
          open: true,
          message: JSON.stringify(result),
          severity: 'error',
        });
        return;
      }
      scheduleDataChange({ ...scheduleDataCopy,
        euroType: scheduleDataCopy.euroType,
        manualRate: parseFloat(scheduleDataCopy.manualRate),
        monthlyRate: currencyFormatter(parseFloat(scheduleDataCopy.manualRate)),
        rollOnDate: scheduleDataCopy.rollOnDate,
        RealEndDate: scheduleDataCopy.rollOnDate,
      });
    }).catch( (error) => {
      setSnackbarProps({
        open: true,
        message: error,
        severity: 'error',
      });
    });
  };

  const callRemoveSchedule = async () => {
    return await API.post('Saltmine-Van-API-Invoice', '/removeSchedule', {
      body: {
        scheduleID: selectedSchedule?.ScheduleID,
        customerID: selectedSchedule?.CustomerID,
        removeFromFleetDate: scheduleDataCopy.removeFromFleetDate,
        fleetAction: undefined,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      if (result.statusCode !== 200) {
        setSnackbarProps({
          open: true,
          message: JSON.stringify(result),
          severity: 'error',
        });
        return;
      }
      scheduleDataChange({
        ...scheduleDataCopy,
        fleetStatus: 'Off Fleet',
      });
    }).catch( (error) => {
      setSnackbarProps({
        open: true,
        message: error,
        severity: 'error',
      });
    });
  };

  const openAdjustForm = () => {
    if (selectedSchedule?.ScheduleID) {
      setScheduleDataCopy(selectedSchedule);
      setAmendRecord(true);
    }
  };

  const openRemoveForm = () => {
    if (selectedSchedule?.ScheduleID) {
      // Auto populate End date for remove date
      let autoPopulateEndDate = selectedSchedule;
      if (selectedSchedule.addToFleetDate &&
        (ukDateStringToDate(selectedSchedule.EndDate) < nextMonthInvoiceDate)) {
        if (ukDateStringToDate(selectedSchedule.EndDate).getTime() >
        selectedSchedule.addToFleetDate) {
          autoPopulateEndDate = {
            ...autoPopulateEndDate,
            removeFromFleetDate: ukDateStringToDate(selectedSchedule.EndDate).getTime(),
          };
        } else {
          autoPopulateEndDate = {
            ...autoPopulateEndDate,
            removeFromFleetDate: selectedSchedule.addToFleetDate+1,
          };
        }
      }
      setScheduleDataCopy(autoPopulateEndDate);
      setRemoveRecord(true);
    }
  };

  return (
    <Paper sx={sxClasses.paperStyle}>
      {'Selected Schedule - ' + (selectedSchedule?.ScheduleID || 0)}
      <br></br>
      {'Current Status - ' + (selectedSchedule?.Status || '')}
      <br></br>
      {'End Date: ' + (selectedSchedule?.EndDate || '')}
      <br></br>
      {'Roll On Date: ' + (upgradedDate(selectedSchedule?.rollOnDate) || '')}
      <br></br>
      <br></br>
      {selectedSchedule?.fleetStatus !== 'On Fleet' ?
      <Button variant='contained' color='primary'
        onClick={openAdjustForm}>
        Add
      </Button> :
      <Button variant='contained' color='primary'
        onClick={openRemoveForm}>
        Remove
      </Button>
      }
      {selectedSchedule?.addToFleetDate &&
        <Button variant='contained' color='primary'
          onClick={openAdjustForm}>
          Amend
        </Button>
      }
      <br></br>Schedule History<br></br><br></br>
      {reverseSortArrayByProperty(scheduleEventData, 'EventTime').map((item)=>(
        <div key={item.SK}>
          {item.EventType + ' - ' + upgradedDate(item.EventTime)}
          <IconButton sx={{ padding: '0px' }} onClick={()=>{
            setEventDetails(item);
            setShowEventDetails(true);
          }}>
            <QuestionIcon />
          </IconButton>
        </div>
      ))}
      <ScheduleAdjustForm popupOpen={amendRecord} setPopupOpen={setAmendRecord}
        scheduleData={scheduleDataCopy} setScheduleData={setScheduleDataCopy}
        callAddSchedule={callAddSchedule} callUpdateSchedule={callUpdateSchedule}
        setSnackbarProps={setSnackbarProps}/>
      <ScheduleRemoveForm popupOpen={removeRecord} setPopupOpen={setRemoveRecord}
        scheduleData={scheduleDataCopy} setScheduleData={setScheduleDataCopy}
        callRemoveSchedule={callRemoveSchedule}
        setSnackbarProps={setSnackbarProps}/>
      <ScheduleHistoryPopup popupOpen={showEventDetails} setPopupOpen={setShowEventDetails}
        scheduleEventData={eventDetails}/>
    </Paper>
  );
}
