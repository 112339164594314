import metadata from '../metadata.json';
import environmentData from '../environment.json';

export const currentStage = () => {
  return 'prod';
};

export const getCurrentEnvironment = () => {
  return environmentData['environment'];
};

export const isCarEnvironment = () => {
  return (getCurrentEnvironment() === 'car');
};

export const isVanEnvironment = () => {
  return (getCurrentEnvironment() === 'van');
};

export const carVanSwitch = (carReturn, vanReturn) => {
  if (isCarEnvironment()) {
    return carReturn;
  }
  if (isVanEnvironment()) {
    return vanReturn;
  }
  throw new Error('Unexpected Environment');
};

export const getStageHeaderText = () => {
  if (currentStage() === 'prod') {
    return;
  } else {
    return (getCurrentEnvironment() + '-' + currentStage() + '-' +
      metadata['version'] + '.' + metadata['build']);
  }
};
