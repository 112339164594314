// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { upgradedDate, reverseSortArrayByProperty } from '../Libs/fileHelper';
// Components
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { isCarEnvironment, isVanEnvironment } from '../Libs/StageConfig';
// Resources


const useStyles = makeStyles((theme) => ({
  containerStyle: {
    paddingRight: theme.spacing(3),
  },
  rowStyle: {
    borderTop: '1px solid',
    borderColor: '#cccccc',
  },
  selectedRowStyle: {
    borderTop: '1px solid',
    borderColor: '#cccccc',
    background: theme.palette.primary.selectedRow,
  },
}));

export default function CustomerHistoryItems(props) {
  const classes = useStyles();
  const sxClasses = {
    cellStyle: {
      padding: '5px',
      borderBottom: '0px',
    },
    cellStylePriority: {
      padding: '5px',
      borderBottom: '0px',
      color: '#FF0000',
    },
    refCellStyle: {
      padding: '5px',
      borderBottom: '0px',
      paddingLeft: 1,
      maxHeight: '20px',
      minWidth: '270px',
      maxWidth: '23vw',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    refCellStylePriority: {
      padding: '5px',
      borderBottom: '0px',
      paddingLeft: 1,
      maxHeight: '20px',
      minWidth: '270px',
      maxWidth: '23vw',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: '#FF0000',
    },
    tableStyle: {
      paddingLeft: 1,
      paddingRight: 1,
      height: 'calc(100vh - 230px)',
      width: '100%',
      overflowX: 'hidden',
      minHeight: '415px',
      border: '1px solid',
      borderColor: '#000000',
    },
    listItemStyle: {
      padding: '0px',
      height: '20px',
      textAlign: 'right',
    },
    listIconStyle: {
      minWidth: '0px',
    },
  };

  CustomerHistoryItems.propTypes = {
    setSelected: PropTypes.func.isRequired,
    pendingDisabled: PropTypes.bool.isRequired,
    pendingData: PropTypes.array.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
    selectedScheduleID: PropTypes.string.isRequired,
    selectedPendingItem: PropTypes.object.isRequired,
  };

  const selectHistoryItem = (pendingData) => {
    if (props.pendingDisabled) {
      props.setSelected({
        ID: pendingData.ID,
        ScheduleID: pendingData.ScheduleID,
        Status: undefinedHandler(pendingData.Status),
        ActionType: undefinedHandler(pendingData.ActionType),
        CreatedDate: undefinedHandler(pendingData.CreatedDate),
        Reference: undefinedHandler(pendingData.Reference),
        CreatedBy: undefinedHandler(pendingData.CreatedBy),
        CompletedDate: undefinedHandler(pendingData.CompletedDate),
        CompletedBy: undefinedHandler(pendingData.CompletedBy),
        ScheduledDate: undefinedScheduledHandler(pendingData.ScheduledDate),
        CustomerCofico: undefinedScheduledHandler(pendingData.CustomerCofico),
        decFields: undefinedScheduledHandler(pendingData.decFields),
        Priority: undefinedScheduledHandler(pendingData.Priority),
      });
    } else {
      props.setSnackbarProps({ open: true,
        message: 'You are currently editing an item. Press cancel to select a new record.',
        severity: 'warning' });
    }
  };

  const undefinedHandler = (value) => {
    if (value === undefined) {
      return '';
    } else {
      return value;
    }
  };

  const undefinedScheduledHandler = (value) => {
    if (value === undefined) {
      return null;
    } else {
      return value;
    }
  };

  const checkNotNullOrUndefined = (value) => {
    if (value === undefined || value === null || value === '') {
      return false;
    } else {
      return true;
    }
  };

  const [showAllHistory, setShowAllHistory] = React.useState(isCarEnvironment());

  const convertScheduleIDtoScheduleNo = (scheduleID) => {
    const extractedSchedule = parseInt(scheduleID.slice(-6));
    return [extractedSchedule, extractedSchedule + 1000000];
  };

  const filteredPendingDataBySelectedSchedule = () => {
    if (!showAllHistory) {
      return (props.pendingData.filter((item) => (item.ScheduleID === props.selectedScheduleID ||
      item.ScheduleNumber === convertScheduleIDtoScheduleNo(props.selectedScheduleID)[0] ||
      item.ScheduleNumber === convertScheduleIDtoScheduleNo(props.selectedScheduleID)[1])));
    } else {
      return (props.pendingData);
    }
  };

  const handleCellStyle = (row) => {
    if (row.Priority) {
      return sxClasses.cellStylePriority;
    } else {
      return sxClasses.cellStyle;
    }
  };

  const handleRefCellStyle = (row) => {
    if (row.Priority) {
      return sxClasses.refCellStylePriority;
    } else {
      return sxClasses.refCellStyle;
    }
  };

  return (
    <div className={classes.containerStyle}>
      <TableContainer component={Paper} sx={sxClasses.tableStyle}>
        {isVanEnvironment() &&
        <ListItem id={'FilterHistory'} sx={sxClasses.listItemStyle}>
          <ListItemText id={'CDecDetailsReportLabel'} primary={
            'Show All'
          } />
          <ListItemIcon sx={sxClasses.listIconStyle}>
            <Checkbox
              edge="end"
              onClick={
                ()=>{
                  setShowAllHistory(!showAllHistory);
                }
              }
              checked={showAllHistory}
              inputProps={{ 'aria-labelledby': 'CDecDetailsReportLabel' }}
            />
          </ListItemIcon>
        </ListItem>
        }
        <Table sx={sxClasses.cellStyle} size='small' aria-label='a dense table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={sxClasses.cellStyle}>Status</TableCell>
              {(showAllHistory && isVanEnvironment()) &&
                <TableCell sx={sxClasses.cellStyle} align='center'>ScheduleID</TableCell>
              }
              <TableCell sx={sxClasses.cellStyle} align='center'>Action Type</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Created</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Completed</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Reference</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reverseSortArrayByProperty(
                reverseSortArrayByProperty(
                    filteredPendingDataBySelectedSchedule(), 'CreatedDate'), 'Status').map((row, index) => (
              <React.Fragment key={index}>
                {checkNotNullOrUndefined(row.DeletedDate) ? null :
                  <TableRow onClick={()=>{
                    selectHistoryItem(row);
                  }} className={(row.ID === props.selectedPendingItem.ID) ?
                      classes.selectedRowStyle : classes.rowStyle}>
                    <TableCell sx={handleCellStyle(row)} component='th' scope='row'>
                      {row.Status}
                    </TableCell>
                    {(showAllHistory && isVanEnvironment()) &&
                      <TableCell sx={handleCellStyle(row)} align='center'>
                        {row.ScheduleID}
                      </TableCell>
                    }
                    <TableCell sx={handleCellStyle(row)} align='center'>
                      {row.ActionType}
                    </TableCell>
                    <TableCell sx={handleCellStyle(row)} align='center'>
                      {upgradedDate(row.CreatedDate)}
                    </TableCell>
                    <TableCell sx={handleCellStyle(row)} align='center'>
                      {upgradedDate(row.CompletedDate)}
                    </TableCell>
                    <TableCell sx={handleRefCellStyle(row)} align='left'>
                      {row.Reference}
                    </TableCell>
                  </TableRow>
                }
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
