// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
// Components
import ExitButton from '../Components/StyledMUIComponents/ExitButton';
import CustomerDetails from '../Components/GeneralComponents/CustomerDetails';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import InvoiceHistory from '../Components/InvoiceManagement/InvoiceHistory';
import ScheduleHistory from '../Components/InvoiceManagement/ScheduleHistory';
import FleetManagement from '../Components/InvoiceManagement/FleetManagement';
import { getCustomerData, getInvoiceManagementCustomerData, getCustomerDataOnCofico,
  getInvoiceManagementScheduleEvents } from '../Libs/apiHelper';
import { reverseSortArrayByProperty, monthsBetween, ukDateStringToDate, isActiveSchedule,
  firstOfNextMonth, currencyFormatter } from '../Libs/fileHelper';
// Resources

export default function InvoiceManagementPage(props) {
  const navigate = useNavigate();
  const params = useParams();

  const [selectedSchedule, setSelectedSchedule] = React.useState('');
  const [invoiceManagementData, setInvoiceManagementData] = React.useState([]);
  const [invoiceData, setInvoiceData] = React.useState([]);
  const [scheduleEventData, setScheduleEventData] = React.useState([]);
  const [fleetActionData, setFleetActionData] = React.useState([]);

  InvoiceManagementPage.propTypes = {
    scheduleData: PropTypes.array.isRequired,
    actionCodeLookup: PropTypes.array.isRequired,
    customerData: PropTypes.object.isRequired,
    pendingData: PropTypes.array.isRequired,
    currUser: PropTypes.object.isRequired,
    setScheduleData: PropTypes.func.isRequired,
    setCustomerData: PropTypes.func.isRequired,
    setPendingData: PropTypes.func.isRequired,
    setActionCodeLookup: PropTypes.func.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
    emailConfigData: PropTypes.object.isRequired,
  };

  React.useEffect(async () => {
    const customerID = params.CustomerID;
    if (invoiceManagementData.length !== 0) {
      return;
    }
    const apiResponseCustomer = await getCustomerData(
        customerID, props.setCustomerData, props.setScheduleData, props.setSnackbarProps);
    if (apiResponseCustomer.length === 0) {
      return;
    }
    const apiResponseCofico = await getCustomerDataOnCofico(
        apiResponseCustomer[0].CustomerCofico, props.setCustomerData, props.setScheduleData,
        props.setSnackbarProps);
    const apiResponseInvoice = await getInvoiceManagementCustomerData(
        customerID, ()=>{}, props.setSnackbarProps);
    createCombinedData(apiResponseCofico, apiResponseInvoice);
  }, [] );

  const handleScheduleChange = async (value) => {
    setSelectedSchedule(value);
    setScheduleEventData([]);
    const eventData = await getInvoiceManagementScheduleEvents(value, props.setSnackbarProps);
    setScheduleEventData(eventData);
  };

  const createCombinedData = (scheduleData, invoiceManageAPIData) => {
    const scheduleDataCopy = [...scheduleData];
    for (let i=0; i<scheduleDataCopy.length; i++) {
      const index = invoiceManageAPIData.findIndex(
          (item)=> (item.ScheduleID === scheduleDataCopy[i].ScheduleID));
      if (index !== -1) {
        let fleetStatus = '';
        if (invoiceManageAPIData[index].addToFleetDate < invoiceManageAPIData[index].removeFromFleetDate) {
          fleetStatus = 'Off Fleet';
        } else {
          fleetStatus = 'On Fleet';
        }
        scheduleDataCopy[i] = {
          ...invoiceManageAPIData[index],
          fleetStatus: fleetStatus,
          StartDate: scheduleDataCopy[i].StartDate,
          EndDate: scheduleDataCopy[i].EndDate,
          RegistrationDate: scheduleDataCopy[i].RegistrationDate,
          Status: scheduleDataCopy[i].Status,
          Registration: scheduleDataCopy[i].Registration,
          Vin: scheduleDataCopy[i].Vin,
          RealEndDate: getRealEndDate(scheduleDataCopy[i].EndDate, invoiceManageAPIData[index].rollOnDate),
          monthlyRate: currencyFormatter(getMonthlyRate(
              invoiceManageAPIData[index].manualRate,
              scheduleDataCopy[i].RegistrationDate,
              scheduleDataCopy[i].StartDate)),
        };
      } else {
        scheduleDataCopy[i] = {
          ...scheduleDataCopy[i],
          fleetStatus: 'Off Fleet',
          RealEndDate: scheduleDataCopy[i].EndDate,
          monthlyRate: currencyFormatter(getMonthlyRate(
              undefined,
              scheduleDataCopy[i].RegistrationDate,
              scheduleDataCopy[i].StartDate)),
        };
      }
    }
    setInvoiceManagementData(scheduleDataCopy);
    setInvoiceData(invoiceManageAPIData.filter((item)=>item.SK?.includes('Invoice')));
  };

  const selectedScheduleDetails = () => {
    const index = invoiceManagementData.findIndex(
        (item)=> (item.ScheduleID === selectedSchedule));
    if (index !== -1) {
      return {
        ...invoiceManagementData[index],
        CustomerID: params.CustomerID,
      };
    } else {
      return {
        ScheduleID: selectedSchedule,
      };
    }
  };

  const scheduleDataChange = (newScheduleData) => {
    let invoiceManagementDataCopy = [...invoiceManagementData];
    const index = invoiceManagementDataCopy.findIndex(
        (item)=> (item.ScheduleID === newScheduleData.ScheduleID));
    if (index === -1) {
      invoiceManagementDataCopy = [...invoiceManagementDataCopy, newScheduleData];
    } else {
      invoiceManagementDataCopy[index] = newScheduleData;
    }
    setInvoiceManagementData(invoiceManagementDataCopy);

    const actionIndex = fleetActionData.findIndex(
        (item)=> (item.ScheduleID === newScheduleData.ScheduleID));
    if (actionIndex !== -1) {
      const fleetActionCopy = [...fleetActionData];
      fleetActionCopy.splice(actionIndex, 1);
      setFleetActionData(fleetActionCopy);
    }
  };

  const calculateNextMonthInvoiceDate = () => {
    const latestInvoice = reverseSortArrayByProperty(invoiceData, 'invoiceDate')[0];
    if (latestInvoice) {
      return firstOfNextMonth(new Date(latestInvoice.invoiceDate));
    }
    const invoiceFromMaxDate = Math.max(...invoiceManagementData.map((o) => o.invoiceFromDate || 0), 0);
    if (invoiceFromMaxDate) {
      return firstOfNextMonth(new Date(invoiceFromMaxDate));
    }
    return firstOfNextMonth(new Date(Date.now()));
  };

  const getMonthlyRate = (manualRate, registrationDate, startDate) => {
    if (params.CustomerID === 'R6000009') {
      return getMonthlyRateAsda(manualRate, registrationDate, startDate);
    }
    if (params.CustomerID === 'GP000001') {
      return getMonthlyRateDPD(manualRate, registrationDate, startDate);
    }
    return 0;
  };


  const getMonthlyRateDPD = (manualRate, registrationDate, startDate) => {
    if (manualRate) {
      return manualRate;
    }
    const startDateEpoch = ukDateStringToDate(startDate).getTime();
    const regDate = ukDateStringToDate(registrationDate);

    const monthsBetweenInvoiceAndRegDate = monthsBetween(
        regDate, calculateNextMonthInvoiceDate(),
    );
    let rateArrayIndex = Math.floor(monthsBetweenInvoiceAndRegDate / 12);
    if (rateArrayIndex < 0) {
      // Invoice Date is before reg Date
      return 0;
    }
    if (rateArrayIndex > 4) {
      rateArrayIndex = 4;
    }
    // Rates prior to 31st March 2016
    if (startDateEpoch <= 1459425600000) {
      const rateArray = [27.5, 47.92, 76.25, 135, 147.50];
      return rateArray[rateArrayIndex];
    }

    // Rates prior to 31st March 2017
    if (startDateEpoch <= 1490961600000) {
      const rateArray = [27.5, 47.92, 76.25, 135, 147.50];
      return rateArray[rateArrayIndex];
    }

    // Rates prior to 31st August 2018
    if (startDateEpoch <= 1535716800000) {
      const rateArray = [28.55, 49.76, 79.18, 140.18, 153.16];
      return rateArray[rateArrayIndex];
    }

    // Rates prior to 31st Dec 2018
    if (startDateEpoch <= 1546257600000) {
      const rateArray = [29.12, 50.76, 80.76, 142.98, 156.22];
      return rateArray[rateArrayIndex];
    }

    // Rates prior to 20th Feb 2020
    if (startDateEpoch <= 1546257600000) {
      const rateArray = [29.77, 53.01, 83.01, 145.23, 158.47];
      return rateArray[rateArrayIndex];
    }

    // Rates prior to 31st Dec 2020
    if (startDateEpoch <= 1609416000000) {
      const rateArray = [30.62, 54.52, 85.38, 149.37, 162.99];
      return rateArray[rateArrayIndex];
    }

    // Current Rates
    const rateArray = [31.62, 56.29, 88.15, 154.22, 168.29];
    return rateArray[rateArrayIndex];
  };

  const getMonthlyRateAsda = (manualRate, registrationDate, startDate) => {
    if (manualRate) {
      return manualRate;
    }
    const regDate = ukDateStringToDate(registrationDate);

    const monthsBetweenInvoiceAndRegDate = monthsBetween(
        regDate, calculateNextMonthInvoiceDate(),
    );
    const monthsBetweenRegDateAndStartDate = monthsBetween(
        regDate, ukDateStringToDate(startDate),
    );
    if (monthsBetweenInvoiceAndRegDate >= 66) {
      if (monthsBetweenRegDateAndStartDate > 36) {
        return 0;
      }
      // 66+ extension for 3 year start - £385.47
      if (monthsBetweenRegDateAndStartDate > 12) return 385.47;
      // 66+ extension for 1 year start - £272.69
      return 272.69;
    }
    if (monthsBetweenInvoiceAndRegDate >= 60) {
      if (monthsBetweenRegDateAndStartDate > 48) {
        return 0;
      }
      // 60 to 66 extension for 4 year start - £362.05
      if (monthsBetweenRegDateAndStartDate > 36) return 362.05;
      // 60 to 66 extension for 3 year start - £365.05
      if (monthsBetweenRegDateAndStartDate > 12) return 365.05;
      // 60 to 66 extension for 1 year start - £258.24
      return 258.24;
    }
    if (monthsBetweenRegDateAndStartDate > 48) {
      return 0;
    }
    // 3 to 4 Years - £292.75
    if (monthsBetweenRegDateAndStartDate > 36) return 292.75;
    // 1 to 3 Years - £295.05
    if (monthsBetweenRegDateAndStartDate > 12) return 295.05;
    // Up to 1 Year - £213.59
    return 213.59;
  };

  const getRealEndDate = (endDate, rollOnDate) => {
    if (!rollOnDate) {
      return ukDateStringToDate(endDate);
    }
    if (ukDateStringToDate(endDate) > rollOnDate) {
      return ukDateStringToDate(endDate);
    } else {
      return rollOnDate;
    }
  };

  const filteredScheduleData = (filter) => {
    if (filter === 'All') {
      return invoiceManagementData;
    }
    if (filter === 'Off') {
      return invoiceManagementData.filter((item) => (item.fleetStatus !== 'On Fleet'));
    }
    if (filter === 'On') {
      return invoiceManagementData.filter((item) => (item.fleetStatus === 'On Fleet'));
    }
    if (filter === 'On Not Active') {
      return invoiceManagementData.filter((item) => (item.fleetStatus === 'On Fleet') &&
        (!isActiveSchedule(item.Status)));
    }
    if (filter === 'Manual Rate') {
      return invoiceManagementData.filter((item) => (item.monthlyRate === '£0.00' && item.addToFleetDate));
    }
    if (filter === 'To Add') {
      return fleetActionData.filter((item) => (item.fleetAction === 'To Add'));
    }
    if (filter === 'To Remove') {
      return fleetActionData.filter((item) => (item.fleetAction === 'To Remove'));
    }
    if (filter === 'Ending') {
      const nextMonthInvoiceDate = calculateNextMonthInvoiceDate();
      return invoiceManagementData.filter((item) => (item.fleetStatus === 'On Fleet' &&
        item.RealEndDate < nextMonthInvoiceDate));
    }
    return invoiceManagementData.filter((item) => (item.Status === filter));
  };

  return (
    <Container component='main' maxWidth={false}>
      <React.Fragment>
        <Grid container spacing={0}>
          <Grid item xs={9} >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <CustomerDetails customerData={props.customerData} scheduleData={{}}
                      sendEmailFromCustomerDetails={()=>{}}/>
                  </Grid>
                  <Grid item xs={7}>
                    <InvoiceHistory customerData={props.customerData}
                      invoiceData={invoiceData}
                      invoiceManagementData={invoiceManagementData}
                      setInvoiceData={setInvoiceData}
                      nextMonthInvoiceDate={calculateNextMonthInvoiceDate()}
                      filteredScheduleData={filteredScheduleData}
                      setSnackbarProps={props.setSnackbarProps}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FleetManagement scheduleData={invoiceManagementData} selectedSchedule={selectedSchedule}
                  setSelectedSchedule={handleScheduleChange}
                  nextMonthInvoiceDate={calculateNextMonthInvoiceDate()}
                  filteredScheduleData={filteredScheduleData}
                  setFleetActionData={setFleetActionData}
                  setSnackbarProps={props.setSnackbarProps}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} >
            <ScheduleHistory selectedSchedule={selectedScheduleDetails()}
              scheduleEventData={scheduleEventData} scheduleDataChange={scheduleDataChange}
              nextMonthInvoiceDate={calculateNextMonthInvoiceDate()}
              setSnackbarProps={props.setSnackbarProps}/>
          </Grid>
        </Grid>
      </React.Fragment>
      <ExitButton variant='contained' color='primary' onClick={() => {
        navigate(-1);
      }}>
        Back
      </ExitButton>
    </Container>
  );
}
