// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
// Resources
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme) => ({
  popupBackgroundStyle: {
    backgroundColor: '#eeeeee',
    position: 'absolute',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    opacity: 0.5,
    zIndex: 20,
  },
  popupContainerStyle: {
    position: 'absolute',
    padding: theme.spacing(1),
    backgroundColor: 'rgba(255, 255, 255, 1)',
    width: '30vw',
    height: '80vh',
    margin: 'auto',
    overflow: 'auto',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    filter: 'drop-shadow(2px 4px 6px black)',
    whiteSpace: 'break-spaces',
    zIndex: 21,
  },
}));


export default function AddedContactEntry({ closeContactEntry, contactData, handleContactFieldChange,
  handleEditContactSave, handleEditContactDelete, contactEditIndex }) {
  const classes = useStyles();
  const sxClasses = {
    textFieldStyle: {
      width: '100%',
      margin: 0.5,
    },
    saveButtonStyle: {
      position: 'fixed',
      width: '13vw',
      left: '10px',
      bottom: '10px',
    },
    cancelButtonStyle: {
      position: 'fixed',
      width: '13vw',
      right: '10px',
      bottom: '10px',
    },
    iconButton: {
      position: 'absolute',
      top: 1,
      right: 1,
      padding: 0,
    },
  };

  AddedContactEntry.propTypes = {
    closeContactEntry: PropTypes.func.isRequired,
    contactData: PropTypes.object.isRequired,
    handleContactFieldChange: PropTypes.func.isRequired,
    handleEditContactSave: PropTypes.func.isRequired,
    handleEditContactDelete: PropTypes.func.isRequired,
    contactEditIndex: PropTypes.number.isRequired,
  };

  return (
    <div>
      <div className={classes.popupBackgroundStyle} />
      <div className={classes.popupContainerStyle}>
        {contactEditIndex !== -1 ?
          <div>
            Edit Contact
          </div> :
          <div>
            Add Contact
          </div>
        }
        <TextField select
          sx={sxClasses.textFieldStyle}
          label="Contact Type"
          inputProps={{
            style: {
              color: '#000000',
            },
          }}
          onChange={handleContactFieldChange}
          value={contactData.contactType || ''}
          name='contactType'>
          <MenuItem value={'Primary'}>
              Primary
          </MenuItem>
          <MenuItem value={'Secondary'}>
              Secondary
          </MenuItem>
          <MenuItem value={'No Communication'}>
              No Communication
          </MenuItem>
        </TextField>
        {contactEditIndex !== -1 &&
          <IconButton sx={sxClasses.iconButton} onClick={handleEditContactDelete}>
            <DeleteIcon />
          </IconButton>
        }
        <TextField
          sx={sxClasses.textFieldStyle}
          value={contactData.contactName || ''}
          label="Name"
          inputProps={{
            style: {
              color: '#000000',
            },
          }}
          onChange={handleContactFieldChange}
          name='contactName' />
        <TextField select
          sx={sxClasses.textFieldStyle}
          label="Relation"
          inputProps={{
            style: {
              color: '#000000',
            },
          }}
          onChange={handleContactFieldChange}
          value={contactData.relation || ''}
          name='relation'>
          <MenuItem value={'Partner'}>
              Partner
          </MenuItem>
          <MenuItem value={'Family'}>
              Family
          </MenuItem>
          <MenuItem value={'Solicitors'}>
              Solicitors
          </MenuItem>
          <MenuItem value={'Other'}>
              Other
          </MenuItem>
        </TextField>
        <TextField select
          sx={sxClasses.textFieldStyle}
          label="Executor"
          inputProps={{
            style: {
              color: '#000000',
            },
          }}
          onChange={handleContactFieldChange}
          value={contactData.executor || ''}
          name='executor'>
          <MenuItem value={'Executor'}>
            Yes
          </MenuItem>
          <MenuItem value={''}>
            -
          </MenuItem>
        </TextField>
        <TextField
          sx={sxClasses.textFieldStyle}
          value={contactData.addressLine1 || ''}
          label="Address Line 1"
          inputProps={{
            style: {
              color: '#000000',
            },
          }}
          onChange={handleContactFieldChange}
          name='addressLine1' />
        <TextField
          sx={sxClasses.textFieldStyle}
          value={contactData.addressLine2 || ''}
          label="Address Line 2"
          inputProps={{
            style: {
              color: '#000000',
            },
          }}
          onChange={handleContactFieldChange}
          name='addressLine2' />
        <TextField
          sx={sxClasses.textFieldStyle}
          value={contactData.postCode || ''}
          label="Postcode"
          inputProps={{
            style: {
              color: '#000000',
            },
          }}
          onChange={handleContactFieldChange}
          name='postCode' />
        <TextField
          sx={sxClasses.textFieldStyle}
          value={contactData.homePhone || ''}
          label="Phone Number"
          inputProps={{
            style: {
              color: '#000000',
            },
          }}
          onChange={handleContactFieldChange}
          name='homePhone' />
        <TextField
          sx={sxClasses.textFieldStyle}
          value={contactData.mobilePhone || ''}
          label="Mobile Number"
          inputProps={{
            style: {
              color: '#000000',
            },
          }}
          onChange={handleContactFieldChange}
          name='mobilePhone' />
        <TextField
          sx={sxClasses.textFieldStyle}
          value={contactData.email || ''}
          label="Email Address"
          inputProps={{
            style: {
              color: '#000000',
            },
          }}
          onChange={handleContactFieldChange}
          name='email' />
        <Button sx={sxClasses.saveButtonStyle} variant='contained' color='primary'
          onClick={handleEditContactSave}>
          Save
        </Button>
        <Button sx={sxClasses.cancelButtonStyle} variant='contained' color='primary'
          onClick={closeContactEntry}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
