// Functions
import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Resources

export default function FleetManagementPopup({ popupOpen, setPopupOpen, fleetPopupData,
  handleFleetPopupChange, handleFleetPopupSubmit, handleFleetPopupClear }) {
  const sxClasses = {
    dialogTitle: {
      paddingTop: 1,
      textAlign: 'center',
    },
    textFieldStyle: {
      width: '100%',
    },
  };

  FleetManagementPopup.propTypes = {
    popupOpen: PropTypes.bool.isRequired,
    setPopupOpen: PropTypes.func.isRequired,
    fleetPopupData: PropTypes.string.isRequired,
    handleFleetPopupChange: PropTypes.func.isRequired,
    handleFleetPopupSubmit: PropTypes.func.isRequired,
    handleFleetPopupClear: PropTypes.func.isRequired,
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <Dialog open={popupOpen} aria-labelledby="form-dialog-title"
      maxWidth={false}>
      <DialogTitle sx={sxClasses.dialogTitle} id="form-dialog-title">
        Please paste Registration or Vin Numbers
      </DialogTitle>
      <DialogContent>
        <TextField fullWidth
          multiline
          maxRows={12}
          sx={{ marginTop: '8px' }}
          label="Data Entry"
          value={fleetPopupData}
          onChange={handleFleetPopupChange}
          name='regVinData'
          id='regVinData'>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleFleetPopupSubmit} color="primary">
          Submit
        </Button>
        <Button onClick={handleFleetPopupClear} color="primary">
          Clear
        </Button>
        <Button onClick={closePopup} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
