// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// Components
import { API } from 'aws-amplify';
import Button from '@mui/material/Button';
import EditableTable from '../Components/EditableTable/EditableTable';
import Container from '@mui/material/Container';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ExitButton from '../Components/StyledMUIComponents/ExitButton';
import FullWidthButton from '../Components/StyledMUIComponents/FullWidthButton';
import BulkSendingPopup from '../Components/BulkSendingPopup';
import BulkEmailPreviewPopup from '../Components/BulkEmailPreviewPopup';
import BulkCommunicationQueuePopup from '../Components/BulkCommunicationQueuePopup';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import { validateEmailAddress, groupMailboxes,
  generateEMLReference, convertBodyBackToHTML } from '../Libs/emailHelper';
import { processEmailAttachment } from '../Libs/fileUploadHelper';
import { createMultiPDF } from '../Libs/pdfHelper';
import { sortArrayByProperty, replaceAllOccurances, checkWholeNumber,
  getCurrentUserEmail, isValidScheduleID, currencyFormatter } from '../Libs/fileHelper';
import { getEmailTemplateData, postUpdateEmailTemplate, deleteEmailTemplate,
  postSendEmail } from '../Libs/emailAPIHelper';
import { convertPasteDataToArray, pasteSpamPrevention } from '../Libs/pasteHelper';
import { getResultsForActionCode } from '../Libs/apiHelper';
import { sxClasses, iconFieldsArray, templatesConfigurationInformation, removeHTMLtags,
  startingTemplateText, schedContainedInArray, convertTrueFalseForTabs,
  convertTabsValueToTrueFalse } from '../Containers/PageFunctions/BulkCommunications';
import config from '../Libs/Config';
import { currentStage, getCurrentEnvironment } from '../Libs/StageConfig';
// Resources
import LetterIcon from '@mui/icons-material/Print';
import EmailIcon from '@mui/icons-material/Email';

// To-Do List:
// Figure out how to handle Bold and other formatting in Letters?

export default function BulkCommunicationsPage({ ...props }) {
  const navigate = useNavigate();
  BulkCommunicationsPage.propTypes = {
    actionCodeLookup: PropTypes.array.isRequired,
    setActionCodeLookup: PropTypes.func.isRequired,
    currUser: PropTypes.object.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
    emailConfigData: PropTypes.object.isRequired,
  };
  const [templatesData, setTemplatesData] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [emailConfiguration, setEmailConfiguration] = React.useState({
    selectedTemplate: 'Please Select A Template',
    emailSender: '',
    emailSubject: '',
    automaticRegInSubject: true,
    includeAttachment: false,
    previewOpen: false,
    previewIndex: 0,
    automaticIconData: true,
    generateLetter: false,
    previewComplete: false,
  });
  const [configuringTemplates, setConfiguringTemplates] = React.useState(false);
  const [iconDataLoading, setIconDataLoading] = React.useState(false);
  const [addQueuePopupOpen, setAddQueuePopupOpen] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [sendingProgress, setSendingProgress] = React.useState('');
  const handleEmailConfigurationChange = (event, newValue) => {
    const newConfiguration = {
      ...emailConfiguration,
      [event.target.name]: event.target.value,
      previewComplete: false,
    };
    setEmailConfiguration(newConfiguration);
  };

  React.useEffect(() => {
    const onLoad = async () => {
      setTemplatesData(await getEmailTemplateData(props.setSnackbarProps));
    };
    onLoad();
    if (props.actionCodeLookup?.length === 0) {
      refreshActionCodes();
    }
  }, [props.actionCodeLookup] );

  React.useEffect(()=> {
    window.addEventListener('paste', pasteListener);
    // cleanup this component
    return () => {
      window.removeEventListener('paste', pasteListener);
    };
  }, [emailConfiguration, tableData, configuringTemplates]);

  const extractHeaders = (includeAll, overrideObject) => {
    const templateIndex = templatesData.findIndex(
        (item)=>(item.templateName === emailConfiguration.selectedTemplate),
    );
    let fieldsArray = [];
    if (templateIndex !== -1) {
      const regexExtractFieldNameFinder = /(?<=«)(.*?)(?=»)/gm;
      fieldsArray = Array.from(
          templatesData[templateIndex].templateText.matchAll(regexExtractFieldNameFinder), (m) => (m[0]));
    }
    if (fieldsArray.includes('ScheduleID')) {
      // Move Schedule ID to start of array
      const scheduleIndex = fieldsArray.findIndex((item)=>(item === 'ScheduleID'));
      fieldsArray.splice(scheduleIndex, 1);
    }
    if (!fieldsArray.includes('CustomerName')) {
      fieldsArray.push('CustomerName');
    }
    if (!fieldsArray.includes('CustomerEmail') && !emailConfiguration.generateLetter) {
      fieldsArray.push('CustomerEmail');
    }
    if (!fieldsArray.includes('Registration')) {
      fieldsArray.push('Registration');
    }
    if (!fieldsArray.includes('CustomerCofico')) {
      fieldsArray.push('CustomerCofico');
    }
    if (!fieldsArray.includes('Attachment') && emailConfiguration.includeAttachment &&
      !emailConfiguration.generateLetter) {
      fieldsArray.push('Attachment');
    }
    if (!fieldsArray.includes('CustomerAddress') && emailConfiguration.generateLetter) {
      fieldsArray.push('CustomerAddress');
    }
    if (!fieldsArray.includes('CustomerPostcode') && emailConfiguration.generateLetter) {
      fieldsArray.push('CustomerPostcode');
    }

    if (emailConfiguration.automaticIconData && !includeAll) {
      // Remove Icon Fields
      iconFieldsArray.forEach( function(iconfield) {
        const iconfieldIndex = fieldsArray.findIndex((item)=>(item === iconfield));
        if (iconfieldIndex !== -1) {
          fieldsArray.splice(iconfieldIndex, 1);
        }
      });
    }
    return ['ScheduleID', ...fieldsArray];
  };

  const scheduleIDValidation = (value) => {
    if (isValidScheduleID(value)) {
      return true;
    }
    props.setSnackbarProps({ open: true,
      message: 'Schedule ID must be a whole number',
      severity: 'info' });
    return false;
  };

  const saveTemplateChange = async (newData) => {
    if (!newData.templateName) {
      props.setSnackbarProps({
        open: true,
        message: 'Please enter the template\'s name',
        severity: 'info' });
      return false;
    }
    if (!newData.templateText) {
      props.setSnackbarProps({
        open: true,
        message: 'Please enter the template\'s body',
        severity: 'info' });
      return false;
    }
    if (newData.templateText.includes('«result»')) {
      props.setSnackbarProps({
        open: true,
        message: 'Result is a protected field and can\'t be added to a template',
        severity: 'info' });
      return false;
    }
    const result = await postUpdateEmailTemplate(newData, props.setSnackbarProps);
    return (result.status === 200);
  };

  const deleteTemplateChange = async (newData) => {
    const result = await deleteEmailTemplate(newData.originalData.templateName, props.setSnackbarProps);
    return (result.status === 200);
  };

  const calculatePreviewBody = () => {
    const templateIndex = templatesData.findIndex(
        (item)=>(item.templateName === emailConfiguration.selectedTemplate),
    );
    if (templateIndex === -1) {
      return '';
    }

    if (iconDataLoading) {
      return '......Data Loading.....';
    }

    let emailBody = templatesData[templateIndex].templateText;
    emailBody = processTemplateText(emailBody, tableData[emailConfiguration.previewIndex]);
    return emailBody;
  };

  const performTemplateReplacement = (text, replacementValues) => {
    extractHeaders(true).forEach((header) => {
      text = replaceAllOccurances(text, '«' + header + '»',
          replacementValues?.[header]?.toString() || '');
    });
    return text;
  };

  const processTemplateText = (text, replacementValues) => {
    let convertedText = startingTemplateText() + text;
    convertedText = performTemplateReplacement(convertedText, replacementValues);
    if (emailConfiguration.generateLetter) {
      return removeHTMLtags(convertedText);
    } else {
      return (convertedText + '\n\n' + props.emailConfigData?.signature);
    }
  };

  const previewButtonPressed = () => {
    if (emailConfiguration.automaticIconData) {
      setIconDataLoading(true);
      getIconDataForRecords();
      setEmailConfiguration({
        ...emailConfiguration,
        previewOpen: true,
        automaticIconData: false,
        previewComplete: true,
      });
    } else {
      setEmailConfiguration({
        ...emailConfiguration,
        previewOpen: true,
        previewComplete: true,
      });
    }
    setSendingProgress('');
  };

  const getIconDataForRecords = async () => {
    console.log('getting iCON Data');
    let processedCount = 0;
    const dataCopy = [...tableData];
    for (let i=0; i<tableData.length; i++) {
      console.log('beginning loop');
      let apiToUse = '';
      let queryStringParameters = {};
      if (checkWholeNumber(tableData[i].ScheduleID)) {
        apiToUse = '/getschedulenumber';
        queryStringParameters = {
          ScheduleNo: parseInt(tableData[i].ScheduleID),
        };
      } else {
        apiToUse = '/getscheduleid';
        queryStringParameters = {
          ScheduleID: tableData[i].ScheduleID,
        };
      }

      API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.ICONInfo.NAME, apiToUse, {
        queryStringParameters: queryStringParameters,
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((result) => {
        const jsonResult = JSON.parse(result.body);
        if (jsonResult.length) {
          dataCopy[i] = {
            ...dataCopy[i],
            ...jsonResult[0],
          };
        } else {
          dataCopy[i] = {
            ...dataCopy[i],
            ...jsonResult,
          };
        }
        dataCopy[i] = {
          ...dataCopy[i],
          ChargeAmount: currencyFormatter(dataCopy[i].ChargeAmount),
          ChargeAmountIncVAT: currencyFormatter(dataCopy[i].ChargeAmount * 1.2),
        };
        setTableData(dataCopy);
        processedCount += 1;
        if (processedCount === tableData.length) {
          const duplicateRemoval = dataCopy.filter(
              (ele, ind) => ind === dataCopy.findIndex((elem) => elem.ScheduleID === ele.ScheduleID));
          setTableData(duplicateRemoval);
          setIconDataLoading(false);
        }
      }).catch( (error) => {
        props.setSnackbarProps({ open: true,
          message: 'Issue getting iCON data: Check thoroughly before sending',
          severity: 'error' });
        processedCount += 1;
        if (processedCount === tableData.length) {
          setIconDataLoading(false);
        }
      });
    }
  };

  const sendButtonLabelText = () => {
    if (emailConfiguration.generateLetter) {
      if (tableData.length === 1) {
        return 'Generate PDFs - 1 PDF';
      } else {
        return 'Generate PDFs - ' + tableData.length + ' PDFs';
      }
    } else {
      if (tableData.length === 1) {
        return 'Send Emails - 1 Email';
      } else {
        return 'Send Emails - ' + tableData.length + ' Emails';
      }
    }
  };

  // Start Paste Logic
  const isInvalidPasteLine = (pasteData) => {
    if (pasteData.length > extractHeaders().length) {
      props.setSnackbarProps({
        open: true,
        message: 'Data pasted has too many columns',
        severity: 'warning' });
      return true;
    }
    if (!isValidScheduleID(pasteData[0])) {
      props.setSnackbarProps({
        open: true,
        message: 'Schedule ID is not properly formatted',
        severity: 'warning' });
      return true;
    }
    return false;
  };

  const addItemToAppendData = (tabSplitPasteDataArray, appendData) => {
    let newItem = {};
    for (let j=0; j<tabSplitPasteDataArray.length; j++) {
      if (j===0) {
        newItem = { ...newItem,
          [extractHeaders()[j]]: tabSplitPasteDataArray[j] };
      } else {
        if (extractHeaders()[j] !== 'Attachment') {
          newItem = { ...newItem, [extractHeaders()[j]]: tabSplitPasteDataArray[j] };
        }
      }
    }
    appendData.push(newItem);
  };

  const editExistingAppendData = (tabSplitPasteDataArray, appendData) => {
    const itemIndex = appendData.findIndex(
        (item)=>(item.ScheduleID === tabSplitPasteDataArray[0]),
    );
    for (let j=1; j<tabSplitPasteDataArray.length; j++) {
      if (extractHeaders()[j] !== 'Attachment') {
        appendData[itemIndex] = { ...appendData[itemIndex],
          [extractHeaders()[j]]: tabSplitPasteDataArray[j] };
      }
    }
  };

  const processPasteData = (pasteDataArray) => {
    const appendData = [...tableData];
    for (let i=0; i<pasteDataArray.length; i++) {
      const tabSplitPasteDataArray = pasteDataArray[i].split('\t');

      if (tabSplitPasteDataArray.length === 1 && tabSplitPasteDataArray[0] === '') continue;
      if (isInvalidPasteLine(tabSplitPasteDataArray)) return;

      if (!schedContainedInArray(appendData, tabSplitPasteDataArray[0])) {
        addItemToAppendData(tabSplitPasteDataArray, appendData);
      } else {
        editExistingAppendData(tabSplitPasteDataArray, appendData);
      }
    }
    setTableData(appendData);
  };

  const pasteListener = (e) => {
    if (pasteSpamPrevention()) return;
    if (configuringTemplates) return;
    const pasteDataArray = convertPasteDataToArray(e);
    if (pasteDataArray.length === 0) return;
    processPasteData(pasteDataArray);
    // Prevent the default paste action.
    e.preventDefault();
    return;
  };
  // End Paste Logic

  const tableDataAdditionalSave = (newData) => {
    if (schedContainedInArray(tableData, newData.ScheduleID)) {
      if (newData.originalData?.ScheduleID !== newData.ScheduleID) {
        props.setSnackbarProps({
          open: true,
          message: 'Schedule ID already exists, please edit instead',
          severity: 'warning' });
        return false;
      }
    }
    setEmailConfiguration({
      ...emailConfiguration,
      previewComplete: false,
    });
    return true;
  };

  // To-DO Move this to a helper function
  const refreshActionCodes = () => {
    API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.ActionType.NAME, '/', {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      props.setActionCodeLookup(JSON.parse(result.body));
    }).catch( (error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    });
  };

  const addActionCodeQueueToData = async (selectedCode) => {
    const response = await getResultsForActionCode(
        selectedCode, props.setSnackbarProps,
    );
    if (response.status === 200) {
      const actionCodeData = response.result;
      const tableDataCopy = [...tableData];
      for (let i = 0; i < actionCodeData.length; i++) {
        if (!schedContainedInArray(tableDataCopy, actionCodeData[i].ScheduleID)) {
          tableDataCopy.push({
            ScheduleID: actionCodeData[i].ScheduleID,
            ID: actionCodeData[i].ID,
          });
        }
      }
      if (tableDataCopy.length === tableData.length) {
        props.setSnackbarProps({ open: true,
          message: 'No Data Added. Schedule IDs already in queue',
          severity: 'info' });
      } else {
        props.setSnackbarProps({ open: true,
          message: tableDataCopy.length - tableData.length + ' records added.',
          severity: 'success' });
      }
      setTableData(tableDataCopy);
    } else if (response.status === 201) {
      props.setSnackbarProps({ open: true,
        message: 'No Records in Queue',
        severity: 'info' });
    }
  };

  const fileUploadValidation = (files) => {
    if (files.length === 0) {
      return false;
    }
    if (tableData.length === 0) {
      props.setSnackbarProps({ open: true,
        message: 'You have not added any records to attach to.',
        severity: 'info' });
      return false;
    }
    if (files.length !== 1) {
      props.setSnackbarProps({ open: true,
        message: 'You may only attach one file.',
        severity: 'info' });
      return false;
    }
    if (files[0].size > 10000000) {
      props.setSnackbarProps({ open: true,
        message: 'File size is too large. You cannot add attachments over 10mb',
        severity: 'info' });
      return false;
    }
    return true;
  };

  const recordSuccessfulUpload = (fileName) => {
    const tableDataCopy = [...tableData];
    for (let i = 0; i < tableDataCopy.length; i++) {
      tableDataCopy[i] = { ...tableDataCopy[i], Attachment: fileName };
    }
    setTableData(tableDataCopy);
    handleEmailConfigurationChange({
      target: {
        name: 'includeAttachment',
        value: true,
      },
    });
  };

  const calculateSubject = (dataArray, index) => {
    if (emailConfiguration.automaticRegInSubject) {
      return (emailConfiguration.emailSubject + ' - ' +
        (dataArray[index]?.Registration || '') +
        ' - ' + (dataArray[index]?.ScheduleID || ''));
    } else {
      return emailConfiguration.emailSubject;
    }
  };

  const fileUploadHandler = async (e) => {
    e.persist();
    const files = e.target.files;
    if (!fileUploadValidation(files)) return;
    const uploadResult = await processEmailAttachment(files[0]);
    if (uploadResult.statusCode === 200) {
      recordSuccessfulUpload(uploadResult.fileName);
    } else {
      props.setSnackbarProps({ open: true,
        message: 'Error: ' + uploadResult.message,
        severity: 'error' });
    }
  };

  const trackSendingProgress = (sendingData, progressCount) => {
    setSendingProgress(progressCount + ' of ' + sendingData.length);
  };

  const sendButtonPressed = async () => {
    console.log('send Called');
    // Validate ready to send
    if (iconDataLoading) {
      console.log('iCON still loading');
      return;
    }
    const templateIndex = templatesData.findIndex(
        (item)=>(item.templateName === emailConfiguration.selectedTemplate),
    );
    if (templateIndex === -1) {
      return '';
    }
    for (let i=0; i<extractHeaders().length; i++) {
      if (extractHeaders()[i] !== 'Attachment') {
        for (let j=0; j<tableData.length; j++) {
          if (!(tableData[j][extractHeaders()[i]])) {
            props.setSnackbarProps({ open: true,
              message: 'Error: No Data in row ' + (j+1) + ' - ' + extractHeaders()[i],
              severity: 'error' });
            return;
          }
        }
      }
    }
    if (!emailConfiguration.generateLetter) {
      if (emailConfiguration.emailSubject === '') {
        props.setSnackbarProps({ open: true,
          message: 'You have not set the Email Subject.',
          severity: 'info' });
        return;
      }
      if (emailConfiguration.emailSender === '') {
        props.setSnackbarProps({ open: true,
          message: 'You have not selected an email address to send from.',
          severity: 'info' });
        return;
      }
    }

    const sendingData = [...tableData];
    let progressCount = 0;

    setSending(true);
    setSendingProgress('0 of ' + sendingData.length);

    for (let j=0; j<sendingData.length; j++) {
      // Update Template Text
      let templateText = templatesData[templateIndex].templateText;
      templateText = processTemplateText(templateText, sendingData[j]);
      sendingData[j] = {
        ...sendingData[j],
        templateText: templateText,
      };
      // Get CustomerID for adding records
      if (!sendingData[j].CustomerID) {
        await API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.ICONInfo.NAME,
            '/getscheduleid', {
              queryStringParameters: {
                ScheduleID: sendingData[j].ScheduleID,
              },
              headers: {
                'Content-Type': 'application/json',
              },
            }).then((result) => {
          const jsonResult = JSON.parse(result.body);
          sendingData[j] = {
            ...sendingData[j],
            ...jsonResult,
          };
        }).catch( (error) => {
          sendingData[j] = {
            ...sendingData[j],
            result: 'Invalid Schedule',
          };
          progressCount = progressCount + 1;
          trackSendingProgress(sendingData, progressCount);
        });
      }
    }

    if (emailConfiguration.generateLetter) {
      for (let j=0; j<sendingData.length; j++) {
        if (!sendingData[j].CustomerID) {
          sendingData[j] = {
            ...sendingData[j],
            result: 'Invalid Schedule',
          };
          continue;
        }

        // API call to add record to history
        const body = {
          ScheduleID: sendingData[j].ScheduleID,
          Status: 'History',
          ActionType: 'LET',
          CustomerName: sendingData[j].CustomerName,
          ScheduleStatus: sendingData[j].Status,
          Reference: 'Letter generated for customer: \n\n' + sendingData[j].templateText,
          CustomerID: sendingData[j].CustomerID,
          CustomerCofico: sendingData[j].CustomerCofico,
        };
        const response = await addHistoryRecord(body);
        if (response.statusCode !== 200) {
          sendingData[j] = {
            ...sendingData[j],
            result: 'Issue adding History Record',
          };
        }
        progressCount = progressCount + 1;
        trackSendingProgress(sendingData, progressCount);
      }

      const failedRecords = sendingData.filter((item)=>(item.result !== undefined));
      const successRecords = sendingData.filter((item)=>(item.result === undefined));
      createMultiPDF(successRecords, props.currUser);
      setTableData(failedRecords);
    } else {
      // Send Email
      for (let j=0; j<sendingData.length; j++) {
        if (!sendingData[j].CustomerID) {
          sendingData[j] = {
            ...sendingData[j],
            result: 'Invalid Schedule',
          };
          continue;
        }

        if (!validateEmailAddress(sendingData[j].CustomerEmail)) {
          sendingData[j] = {
            ...sendingData[j],
            result: 'Invalid Email Address',
          };
          progressCount = progressCount + 1;
          trackSendingProgress(sendingData, progressCount);
          continue;
        }

        // API call to add record to history
        const historyApiBody = {
          ScheduleID: sendingData[j].ScheduleID,
          Status: 'History',
          ActionType: 'EML',
          CustomerName: sendingData[j].CustomerName,
          ScheduleStatus: sendingData[j].Status,
          Reference: generateEMLReference(emailConfiguration.emailSender, sendingData[j].CustomerEmail,
              emailConfiguration.emailSubject, sendingData[j].templateText, sendingData[j].Attachment),
          CustomerID: sendingData[j].CustomerID,
          CustomerCofico: sendingData[j].CustomerCofico,
        };
        const response = await addHistoryRecord(historyApiBody);
        if (response.statusCode !== 200) {
          sendingData[j] = {
            ...sendingData[j],
            result: 'Issue adding History Record',
          };
          progressCount = progressCount + 1;
          trackSendingProgress(sendingData, progressCount);
          continue;
        }

        let emailApiBody = {
          sendTo: sendingData[j].CustomerEmail,
          sendFrom: getCurrentUserEmail(props.currUser),
          groupMailbox: emailConfiguration.emailSender,
          subject: calculateSubject(sendingData, j),
          emailBody: convertBodyBackToHTML(sendingData[j].templateText),
        };
        if (sendingData[j].Attachment) {
          emailApiBody = { ...emailApiBody,
            emailAttachment: sendingData[j].Attachment,
          };
        }
        const sendResponse = await postSendEmail(emailApiBody, ()=>{});
        if (sendResponse.status !== 200) {
          sendingData[j] = {
            ...sendingData[j],
            result: 'Issue sending Email',
          };
        }
        progressCount = progressCount + 1;
        trackSendingProgress(sendingData, progressCount);
      }

      const failedRecords = sendingData.filter((item)=>(item.result !== undefined));
      setTableData(failedRecords);
    }

    setEmailConfiguration({
      ...emailConfiguration,
      previewComplete: false,
    });
    setSending(false);
    return;
  };

  const addHistoryRecord = async (body) => {
    return await API.post(config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory.NAME,
        '/completenew', {
          body: body,
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((result) => {
      if (result.statusCode !== 200 & result.body !== undefined) {
        return { statusCode: result.statusCode, result: result.body };
      } else {
        return { statusCode: 200, result: result.ID };
      }
    }).catch( (error) => {
      return { statusCode: 500, result: error };
    });
  };

  const individualUploadValidation = (newData) => {
    if (!newData) return true;
    if (newData.includes('Error:')) {
      props.setSnackbarProps({ open: true,
        message: newData,
        severity: 'error' });
      return false;
    }
    return true;
  };

  const tableConfigurationInformation = {
    ScheduleID: {
      label: 'Schedule ID',
      align: 'center',
      validationFunction: scheduleIDValidation,
    },
    Attachment: {
      type: 'uploadButton',
      validationFunction: individualUploadValidation,
    },
    CustomerName: {
      label: 'Customer Name',
    },
    CustomerEmail: {
      label: 'Customer Email',
    },
    CustomerAddress: {
      label: 'Customer Address',
    },
    CustomerPostcode: {
      label: 'Customer Postcode',
    },
  };

  return (
    <Container component="main" maxWidth={false} sx={sxClasses.mainContainer}>
      <Grid container spacing={3} >
        <Grid item xs={9}>
          <Paper sx={sxClasses.paperStyles}>
            {configuringTemplates ?
              <EditableTable
                configurationInformation={templatesConfigurationInformation}
                headerArray={['templateName', 'templateText']}
                tableData={templatesData}
                setTableData={setTemplatesData}
                additionalSaveFunction={saveTemplateChange}
                additionalDeleteFunction={deleteTemplateChange}/> :
              <EditableTable
                configurationInformation={tableConfigurationInformation}
                headerArray={extractHeaders(false)}
                tableData={tableData}
                setTableData={setTableData}
                additionalSaveFunction={tableDataAdditionalSave}/>
            }
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={sxClasses.paperStyles}>
            <TextField select
              disabled={configuringTemplates}
              margin="dense"
              id="selectedTemplate"
              name="selectedTemplate"
              label="Email Template"
              variant='outlined'
              fullWidth
              onChange={handleEmailConfigurationChange}
              value={emailConfiguration.selectedTemplate}
            >
              <MenuItem value={'Please Select A Template'}>
                Please Select A Template
              </MenuItem>
              {sortArrayByProperty(templatesData, 'templateName').map((item, index)=> (
                <MenuItem key={index} value={item.templateName}>
                  {item.templateName}
                </MenuItem>
              ))}
            </TextField>
            <FullWidthButton variant='contained' color='primary' sx={sxClasses.configureTemplatesButton}
              onClick={()=>{
                setConfiguringTemplates(!configuringTemplates);
              }}>
              {configuringTemplates ? 'Finish Configuring Templates' : 'Configure Template' }
            </FullWidthButton>
            <TextField select
              disabled={configuringTemplates || emailConfiguration.generateLetter}
              margin="dense"
              id="emailSender"
              name="emailSender"
              label="Send From"
              variant='outlined'
              onChange={handleEmailConfigurationChange}
              fullWidth
              value={emailConfiguration.emailSender}
            >
              {groupMailboxes.map((item, index)=> (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              disabled={configuringTemplates || emailConfiguration.generateLetter}
              margin="dense"
              id="emailSubject"
              name="emailSubject"
              label="Subject"
              variant='outlined'
              fullWidth
              onChange={handleEmailConfigurationChange}
              value={emailConfiguration.emailSubject}
            />
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={emailConfiguration.automaticRegInSubject}
                onChange={()=>{
                  handleEmailConfigurationChange({
                    target: {
                      name: 'automaticRegInSubject',
                      value: !emailConfiguration.automaticRegInSubject,
                    },
                  });
                }}
                disabled={configuringTemplates || emailConfiguration.generateLetter}/>}
              label="Add Schedule - Reg to Subject" />
              <FormControlLabel control={<Checkbox checked={emailConfiguration.automaticIconData}
                onChange={()=>{
                  handleEmailConfigurationChange({
                    target: {
                      name: 'automaticIconData',
                      value: !emailConfiguration.automaticIconData,
                    },
                  });
                }}/>} label="Provide Schedule Data Automatically" disabled={configuringTemplates}/>
              <FormControlLabel control={<Checkbox checked={emailConfiguration.includeAttachment}
                onChange={()=>{
                  handleEmailConfigurationChange({
                    target: {
                      name: 'includeAttachment',
                      value: !emailConfiguration.includeAttachment,
                    },
                  });
                }}/>} label="Add Attachment"
              disabled={configuringTemplates || emailConfiguration.generateLetter}/>
            </FormGroup>
            <input
              disabled={emailConfiguration.generateLetter}
              accept=".csv, .xlsx, .pdf"
              onChange={fileUploadHandler}
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
            />
            <label htmlFor="raised-button-file">
              <FullWidthButton disabled={configuringTemplates || emailConfiguration.generateLetter}
                variant='contained' color='primary' component="span">
              Add Attachment to All
              </FullWidthButton>
            </label>
            <Tabs
              disabled={configuringTemplates}
              value={convertTrueFalseForTabs(emailConfiguration.generateLetter)}
              onChange={(event, newValue) => {
                handleEmailConfigurationChange({
                  target: {
                    name: 'generateLetter',
                    value: convertTabsValueToTrueFalse(newValue),
                  },
                });
              }}
              centered
              sx={{ marginTop: '8px', marginBottom: '8px' }}
            >
              <Tab sx={emailConfiguration.generateLetter ? sxClasses.tabStlyeBackGround : sxClasses.tabStlye}
                icon={<LetterIcon />} value={1} label="Send As Letter"
                disabled={configuringTemplates} />
              <Tab sx={!emailConfiguration.generateLetter ? sxClasses.tabStlyeBackGround : sxClasses.tabStlye}
                icon={<EmailIcon />} value={0} label="Send As Email"
                disabled={configuringTemplates} />
            </Tabs>
            <FullWidthButton disabled={configuringTemplates} variant='contained' color='primary'
              onClick={()=>{
                setAddQueuePopupOpen(true);
              }}>
              Add Records From Queue
            </FullWidthButton>
            <Button variant='contained' color='primary' sx={sxClasses.previewButtonStyle}
              disabled={configuringTemplates || tableData.length === 0 ||
                emailConfiguration.selectedTemplate === 'Please Select A Template'}
              onClick={previewButtonPressed}>
              Preview
            </Button>
            <Button variant='contained' color='primary' sx={sxClasses.sendButtonStyle}
              disabled={configuringTemplates || !emailConfiguration.previewComplete || sending.inProgress}
              onClick={sendButtonPressed}>
              {sendButtonLabelText()}
            </Button>
          </Paper>
        </Grid>
      </Grid>
      <ExitButton variant='contained' color='primary' onClick={() => {
        navigate(-1);
      }}>
        Back
      </ExitButton>
      <BulkEmailPreviewPopup
        emailConfiguration={emailConfiguration}
        setEmailConfiguration={setEmailConfiguration}
        enteredData={tableData}
        calculatedEmailBody={calculatePreviewBody()}
        calculateSubject={calculateSubject(tableData, emailConfiguration.previewIndex)}
      />
      <BulkCommunicationQueuePopup
        popupOpen={addQueuePopupOpen}
        setPopupOpen={setAddQueuePopupOpen}
        actionCodesArray={props.actionCodeLookup}
        addQueuetoData={addActionCodeQueueToData}
      />
      <BulkSendingPopup
        popupOpen={sending}
        setPopupOpen={setSending}
        resultData={tableData.filter((item)=>(item.result))}
        setResultData={setTableData}
        sendingProgress={sendingProgress}
      />
    </Container>
  );
}
