// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { upgradedDateWithTime } from '../../Libs/fileHelper';
// Components
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
// Resources

const useStyles = makeStyles((theme) => ({
  containerStyle: {
    'textAlign': 'center',
  },
  GridStyle: {
    'border': '1px solid',
    'borderColor': '#000000',
    'padding': theme.spacing(1),
    'padding-left': theme.spacing(3),
    'padding-right': theme.spacing(3),
    'backgroundColor': theme.palette.primary.backgroundFore,
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));

export default function DeceasedPendingInteraction({ createdDate, reference, triggerSave }) {
  const classes = useStyles();
  const sxClasses = {
    buttonStyle: {
      margin: 1,
      width: '200px',
    },
    disabledTextStyles: {
      marginTop: '6px',
      width: '100%',
      color: '#000000',
    },
    actionCodeStyle: {
      marginTop: '6px',
      width: '100%',
    },
    referenceStyle: {
      marginTop: '10px',
      width: '100%',
    },
  };

  DeceasedPendingInteraction.propTypes = {
    createdDate: PropTypes.number.isRequired,
    reference: PropTypes.string.isRequired,
    triggerSave: PropTypes.func.isRequired,
  };

  return (
    <div className={classes.containerStyle} >
      <Grid container spacing={0} component={Paper} className={classes.GridStyle}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={7}>
              <TextField
                value={upgradedDateWithTime(createdDate) || ' '}
                sx={sxClasses.disabledTextStyles}
                label="Created Date"
                inputProps={{
                  style: {
                    padding: 5,
                    color: '#000000',
                  },
                }}
                id='txtCreated'/>
            </Grid>
            <Grid item xs={5}>
              <TextField disabled={true}
                sx={sxClasses.actionCodeStyle}
                label="Action Code"
                inputProps={{
                  style: {
                    padding: 5,
                    color: '#000000',
                  },
                }}
                value={'DEC'}
                id='txtActionCode'>
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TextField
                value={reference || ' '}
                sx={sxClasses.referenceStyle}
                maxRows={12}
                label={'Reference'}
                variant="outlined"
                id='txtReference'/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Button sx={sxClasses.buttonStyle} variant='contained' color='primary' onClick={triggerSave}>
        Save & Exit
      </Button>
    </div>
  );
}
