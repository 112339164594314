// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
// Components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ExitButton from '../Components/StyledMUIComponents/ExitButton';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LoadingPopup from '../Components/GeneralComponents/GeneralLoadingPopup';
import Paper from '@mui/material/Paper';
import TitlePaper from '../Components/StyledMUIComponents/TitlePaper';
import { getResultsForActionCode } from '../Libs/apiHelper';
import { sortArrayByProperty, safePropertyRead, checkWholeNumber } from '../Libs/fileHelper';
import { splitExcelCellString, currentDateAsStringHyph, getAndExportFlexReportData,
  convertDateToExcel } from '../Libs/reportingHelper';
import { API } from 'aws-amplify';
import * as XLSX from 'xlsx';
import config from '../Libs/Config';
import { currentStage, getCurrentEnvironment, isVanEnvironment } from '../Libs/StageConfig';
// Resources

const useStyles = makeStyles((theme) => ({
  titleFields: {
    position: 'absolute',
    marginTop: '-18px',
    marginLeft: '25px',
  },
  spacerVerticalGrid: {
    textAlign: '-webkit-right',
    padding: 0,
  },
  spacerVertical: {
    borderRight: '1px solid',
    borderColor: '#000000',
    width: '0px',
    height: '100%',
  },
}));

export default function ReportingPage(props) {
  const classes = useStyles();
  const sxClasses = {
    contentSectionsLeft: {
      marginTop: 2,
      height: '75vh',
      border: '1px solid',
      borderBottom: '0px',
      borderColor: '#000000',
      padding: 2,
      textAlign: 'center',
      overflow: 'scroll',
    },
    contentSectionsCustomReports: {
      marginTop: 0,
      height: '118px',
      border: '1px solid',
      borderColor: '#000000',
      padding: 0,
      textAlign: 'center',
    },
    contentSectionsActonCodes: {
      marginTop: 2,
      height: '50vh',
      border: '1px solid',
      borderColor: '#000000',
      overflow: 'scroll',
    },
    runButton: {
      width: '20vw',
      margin: 'auto',
      marginBottom: '8px',
    },
    runButton2: {
      width: '20vw',
      marginTop: 1,
      margin: 'auto',
    },
    dateButtons: {
      width: '80%',
      margin: '10px',
    },
    spacer: {
      width: '100%',
      height: '50px',
    },
    actionCodeStyle: {
      width: '60%',
      marginTop: 4,
    },
    titleStyle: {
      margin: 'auto',
    },
    listItemStyle: {
      height: '20px',
    },
  };
  const navigate = useNavigate();

  ReportingPage.propTypes = {
    setSnackbarProps: PropTypes.func.isRequired,
    actionCodeLookup: PropTypes.array.isRequired,
    setActionCodeLookup: PropTypes.func.isRequired,
  };

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const [selectedFromDate, setSelectedFromDate] = React.useState(
      new Date(Date.now() - 86400000).setHours(0, 0, 0, 0),
  );
  const [selectedToDate, setSelectedToDate] = React.useState(
      new Date(Date.now() - 86400000).setHours(23, 59, 59, 999),
  );

  const [pendingSelectValue, setPendingSelectValue] = React.useState('None');
  const [historySelectValue, setHistorySelectValue] = React.useState('None');
  const [actionTypeChecked, setActionTypeChecked] = React.useState([]);

  const [runningReport, setRunningReport] = React.useState(false);
  const [pendingDataReady, setPendingDataReady] = React.useState(false);
  const [combineSheet, setCombineSheet] = React.useState(true);
  const [decDetails, setDecDetails] = React.useState(false);
  const [filterHistory, setFilterHistory] = React.useState(false);
  const [pendingData, setPendingData] = React.useState([]);
  const [historyData, setHistoryData] = React.useState([]);
  const [agedData, setAgedData] = React.useState([]);
  const [agedDataReady, setAgedDataReady] = React.useState(false);
  const [historyDataReady, setHistoryDataReady] = React.useState(false);

  const pendingSelectOptions = ['None', 'All', 'Addacs', 'Aged', 'Custom'];
  const historySelectOptions = ['None', 'Today', 'Yesterday', 'Last Week',
    'Month To Date', 'Last Month', 'Custom'];

  React.useEffect(() => {
    if (props.actionCodeLookup.length === 0) {
      refreshActionCodes();
    }
    if (pendingDataReady && historyDataReady) {
      exportToXLSX(pendingData, historyData);
    }
    if (pendingDataReady && agedDataReady) {
      exportDataAged(pendingData, agedData);
    }
  }, [props.actionCodeLookup, pendingDataReady, historyDataReady, agedDataReady, pendingData, historyData] );

  const refreshActionCodes = () => {
    API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.ActionType.NAME, '/', {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      props.setActionCodeLookup(JSON.parse(result.body));
    }).catch( (error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    });
  };

  const handleFromDateChange = (date) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    if (date > tomorrow) {
      props.setSnackbarProps({
        open: true,
        message: 'Unfortunately Saltmine cannot report on the future at this time.' +
        ' Please try using Oracle instead.',
        severity: 'warning',
      });
      return;
    }
    if (date > selectedToDate) {
      props.setSnackbarProps({
        open: true,
        message: 'Date Range switched as selected date is greater than To Date.',
        severity: 'info',
      });
      setSelectedFromDate(selectedToDate);
      setSelectedToDate(date.getTime());
      return;
    }
    setSelectedFromDate(date.getTime());
  };

  const handleToDateChange = (date) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    if (date > tomorrow) {
      props.setSnackbarProps({
        open: true,
        message: 'Unfortunately Saltmine cannot report on the future at this time.' +
        ' Please try using Oracle instead.',
        severity: 'warning' });
      return;
    }
    if (date < selectedFromDate) {
      props.setSnackbarProps({
        open: true,
        message: 'Date Range switched as selected date is less than From Date.',
        severity: 'info' });
      setSelectedToDate(selectedFromDate);
      setSelectedFromDate(date.getTime());
      return;
    }
    setSelectedToDate(date.getTime());
  };

  const handleToggleActionCode = (value) => () => {
    const currentIndex = actionTypeChecked.indexOf(value);
    const newChecked = [...actionTypeChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setActionTypeChecked(newChecked);
  };

  const handleChangePendingSelect = (event) => {
    setPendingSelectValue(event.target.value);

    if (event.target.value === 'Addacs') {
      const arrayToCheck = [];
      for (let i = 0; i < props.actionCodeLookup.length; i++) {
        if (props.actionCodeLookup[i].AddacsProcess === '1') {
          arrayToCheck.push(props.actionCodeLookup[i].ActionType);
        }
      }
      setActionTypeChecked(arrayToCheck);
      setPendingSelectValue('Custom');
    } else if (event.target.value === 'Aged') {
      const arrayToCheck = [];
      for (let i = 0; i < props.actionCodeLookup.length; i++) {
        if (props.actionCodeLookup[i].AgedProcess === '1') {
          arrayToCheck.push(props.actionCodeLookup[i].ActionType);
        }
      }
      setActionTypeChecked(arrayToCheck);
      setPendingSelectValue('Custom');
    } else if (event.target.value === 'Custom') {
      setActionTypeChecked([]);
    }
  };

  const handleChangeHistorySelect = (event) => {
    setHistorySelectValue(event.target.value);

    if (event.target.value === 'Today') {
      changeDateToToday();
    } else if (event.target.value === 'Yesterday') {
      changeDateToYesterday();
    } else if (event.target.value === 'Last Week') {
      changeDateToLastWeek();
    } else if (event.target.value === 'Month To Date') {
      changeDateToMTD();
    } else if (event.target.value === 'Last Month') {
      changeDateToLastMonth();
    } else if (event.target.value === 'Custom' || event.target.value === 'None') {
      // Options that dont need to do anything
    } else {
      props.setSnackbarProps({ open: true,
        message: 'Unexpected History option selected',
        severity: 'error' });
    }
  };

  const handleListChange = (event, id) => {
    if (id === 'CombineSheet') {
      setCombineSheet(!combineSheet);
    } else if (id === 'DecDetails') {
      setDecDetails(!decDetails);
    } else if (id === 'FilterHistory') {
      setFilterHistory(!filterHistory);
    }
  };

  const changeDateToToday = () => {
    setSelectedFromDate(new Date().setHours(0, 0, 0, 0));
    setSelectedToDate(Date.now());
  };

  const changeDateToYesterday = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    setSelectedFromDate(new Date(yesterday).setHours(0, 0, 0, 0));
    setSelectedToDate(new Date(yesterday).setHours(23, 59, 59, 999));
  };

  const changeDateToLastWeek = () => {
    const startOfWeek = new Date();
    const currentDay = new Date().getDay();
    const diff = startOfWeek.getDate() - currentDay + (currentDay === 0 ? -6:1); // adjust when day is sunday

    setSelectedFromDate(new Date((new Date()).setDate(diff-7)).setHours(0, 0, 0, 0));
    setSelectedToDate(new Date((new Date()).setDate(diff-1)).setHours(23, 59, 59, 999));
  };

  const changeDateToMTD = () => {
    const date = new Date();
    setSelectedFromDate(new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0));
    setSelectedToDate(Date.now());
  };

  const changeDateToLastMonth = () => {
    const date = new Date();
    setSelectedFromDate(new Date(date.getFullYear(), date.getMonth()-1, 1).setHours(0, 0, 0, 0));
    setSelectedToDate(new Date(date.getFullYear(), date.getMonth(), 0).setHours(23, 59, 59, 999));
  };

  const runReport = () => {
    setRunningReport(true);
    setPendingData([]);
    setHistoryData([]);

    if (pendingSelectValue === 'None') {
      setPendingDataReady(true);
    } else if (pendingSelectValue === 'All') {
      callPendingReportingAPI([], undefined, 0);
    } else {
      if (actionTypeChecked.length === 0) {
        setPendingDataReady(true);
      } else {
        getReportDataActionType([], 0, actionTypeChecked);
      }
    }

    if (historySelectValue === 'None') {
      setHistoryDataReady(true);
    } else {
      callPendHistReportingAPI([], undefined, 0);
    }
  };

  const runAgedDebtReport = async () => {
    setRunningReport(true);
    setAgedDataReady(false);
    setPendingDataReady(false);

    // Check Aged Last Updated Date
    const lastAgedUpdate = await getAgedLastUpdate();

    if (lastAgedUpdate === 0) {
      setRunningReport(false);
      props.setSnackbarProps({
        open: true,
        message: 'There is no Aged Debt data loaded.',
        severity: 'warning',
      });
      return;
    }

    // Get Aged Debt data
    setAgedData([]);
    getAgedData([], lastAgedUpdate, 0);

    const agedActionTypes = [];
    const agedActionData = props.actionCodeLookup.filter((item) => (item.AgedProcess === '1'));
    for (let i=0; i< agedActionData.length; i++) {
      agedActionTypes.push(agedActionData[i].ActionType);
    }
    // Get Pending Activity on Specific Codes
    setPendingData([]);
    getReportDataActionType([], 0, agedActionTypes);

    // Export from use Effect
  };

  const getAgedLastUpdate = async () => {
    return await API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.AgedData.NAME,
        '/getlastupdate', {
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((result) => {
      if (result.body === undefined) {
        return 0;
      } else {
        return result.body;
      }
    }).catch( (error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
      return 0;
    });
  };

  const getAgedData = async (
      dataArray, lastUpdate, callNumber, lastEvaluatedKeyCofico, lastEvaluatedKeyDocumentNo,
  ) => {
    callNumber += 1;
    let queryStringParameters = { LastUpdate: lastUpdate };
    if (lastEvaluatedKeyCofico !== undefined && lastEvaluatedKeyDocumentNo !== undefined) {
      queryStringParameters = { ...queryStringParameters,
        ExclusiveStartKeyCofico: lastEvaluatedKeyCofico,
        ExclusiveStartKeyDocumentNo: lastEvaluatedKeyDocumentNo,
      };
    }
    const response = await API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.AgedData.NAME,
        '/getreporting', {
          queryStringParameters: queryStringParameters,
          headers: {
            'Content-Type': 'application/json',
          },
        }).then( async (result) => {
      if (result.body === undefined) {
        return { result: [] };
      } else {
        return { LastEvaluatedKey: result.LastEvaluatedKey, result: result.body };
      }
    }).catch( (error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
      setAgedDataReady(true);
      return { result: [] };
    });

    if (response.result === undefined) {
      return;
    }

    for (let i = 0; i < response.result.length; i++) {
      if (!documentNoContainedInArray(dataArray, response.result[i].DocumentNo)) {
        dataArray.push(response.result[i]);
      }
    }

    if (response.LastEvaluatedKey === undefined) {
      if (dataArray.length === 0) {
        await setAgedData([]);
        setAgedDataReady(true);
      } else {
        await setAgedData(dataArray);
        setAgedDataReady(true);
      }
    } else {
      if (response.LastEvaluatedKey !== undefined && response.LastEvaluatedKey !== '') {
        if (response.LastEvaluatedKey.DocumentNo !== lastEvaluatedKeyDocumentNo) {
          if (callNumber > 10) {
            props.setSnackbarProps({ open: true,
              message: 'Something went wrong: Excessive Data Calls Error',
              severity: 'error' });
            setRunningReport(false);
          } else {
            getAgedData(
                dataArray,
                lastUpdate,
                callNumber,
                response.LastEvaluatedKey.CustomerCofico,
                response.LastEvaluatedKey.DocumentNo,
            );
          }
        } else {
          props.setSnackbarProps({ open: true,
            message: 'Something went wrong: API Exclusive Key Error',
            severity: 'error' });
          setRunningReport(false);
        }
      }
    }
  };

  const idContainedInArray = (arr, id) => {
    if (arr != null && arr.length >0) {
      for (let i=0; i<arr.length; i++) {
        if (arr[i].ID === id) {
          return true;
        }
      }
    }
    return false;
  };

  const documentNoContainedInArray = (arr, documentNo) => {
    if (arr != null && arr.length >0) {
      for (let i=0; i<arr.length; i++) {
        if (arr[i].DocumentNo === documentNo) {
          return true;
        }
      }
    }
    return false;
  };

  const callPendHistReportingAPI = async (dataArray, lastEvaluatedKeyID, lastEvaluatedKeyDate) => {
    const response = await API.get(
        config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory.NAME, '/reporting', {
          queryStringParameters: {
            dateFrom: new Date(selectedFromDate).setHours(0, 0, 0, 0),
            dateTo: new Date(selectedToDate).setHours(23, 59, 59, 999),
            ExclusiveStartKeyID: lastEvaluatedKeyID,
            ExclusiveStartKeyDate: lastEvaluatedKeyDate,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((result) => {
      if (result.body === undefined) {
        return { result: [] };
      } else {
        return { LastEvaluatedKey: result.LastEvaluatedKey, result: JSON.parse(result.body) };
      }
    }).catch( (error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
      return { result: [] };
    });

    if (response.result === undefined) {
      return;
    }

    for (let i = 0; i < response.result.length; i++) {
      if (!idContainedInArray(dataArray, response.result[i].ID)) {
        dataArray.push(response.result[i]);
      }
    }

    if (response.LastEvaluatedKey === undefined) {
      if (dataArray.length === 0) {
        await setHistoryData([]);
        setHistoryDataReady(true);
      } else {
        // Perform Date format change
        for (let i=0; i<dataArray.length; i++) {
          dataArray[i] = {
            ...dataArray[i],
            CreatedDate: convertDateToExcel(dataArray[i].CreatedDate),
            ScheduledDate: convertDateToExcel(dataArray[i].ScheduledDate),
            CompletedDate: convertDateToExcel(dataArray[i].CompletedDate),
          };
        }
        await setHistoryData(dataArray);
        setHistoryDataReady(true);
      }
    } else {
      if (response.LastEvaluatedKey !== undefined && response.LastEvaluatedKey !== '') {
        if (response.LastEvaluatedKey.ID !== lastEvaluatedKeyID) {
          callPendHistReportingAPI(
              dataArray, response.LastEvaluatedKey.ID, response.LastEvaluatedKey.CompletedDate,
          );
        }
      }
    }
  };

  const getReportDataActionType = async (dataArray, actionTypeNumber, actionTypeArray) => {
    const response = await getResultsForActionCode(
        actionTypeArray[actionTypeNumber], props.setSnackbarProps,
    );
    const reportDataArray = response.result;

    let combinedData;
    if (response.status === 200) {
      combinedData = dataArray.concat(reportDataArray);
    } else {
      combinedData = dataArray;
    }

    if (actionTypeNumber >= (actionTypeArray.length - 1) ) {
      // all data Acquired. Perform date conversion
      for (let i=0; i<combinedData.length; i++) {
        combinedData[i] = {
          ...combinedData[i],
          CreatedDate: convertDateToExcel(combinedData[i].CreatedDate),
          ScheduledDate: convertDateToExcel(combinedData[i].ScheduledDate),
        };
      }
      await setPendingData(combinedData);
      setPendingDataReady(true);
    } else {
      getReportDataActionType(combinedData, actionTypeNumber + 1, actionTypeArray);
    }
  };

  const callPendingReportingAPI = async (dataArray, lastEvaluatedKeyID, lastEvaluatedKeyDate) => {
    const response = await API.get(
        config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory.NAME, '/reportingpending', {
          queryStringParameters: {
            ExclusiveStartKeyID: lastEvaluatedKeyID,
            ExclusiveStartKeyDate: lastEvaluatedKeyDate,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((result) => {
      if (result.body === undefined) {
        return { result: [] };
      } else {
        return { LastEvaluatedKey: result.LastEvaluatedKey, result: JSON.parse(result.body) };
      }
    }).catch( (error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
      return { result: [] };
    });

    if (response.result === undefined) {
      return;
    }

    for (let i = 0; i < response.result.length; i++) {
      if (!idContainedInArray(dataArray, response.result[i].ID)) {
        dataArray.push(response.result[i]);
      }
    }

    if (response.LastEvaluatedKey === undefined) {
      if (dataArray.length === 0) {
        await setPendingData([]);
        setPendingDataReady(true);
      } else {
        // Perform Date format change
        for (let i=0; i<dataArray.length; i++) {
          dataArray[i] = {
            ...dataArray[i],
            CreatedDate: convertDateToExcel(dataArray[i].CreatedDate),
            ScheduledDate: convertDateToExcel(dataArray[i].ScheduledDate),
          };
        }
        await setPendingData(dataArray);
        setPendingDataReady(true);
      }
    } else {
      if (response.LastEvaluatedKey !== undefined && response.LastEvaluatedKey !== '') {
        if (response.LastEvaluatedKey.ID !== lastEvaluatedKeyID) {
          callPendingReportingAPI(
              dataArray, response.LastEvaluatedKey.ID, response.LastEvaluatedKey.CreatedDate,
          );
        }
      }
    }
  };

  const exportToXLSX = (pendingResult, historyResult) => {
    setPendingDataReady(false);
    setHistoryDataReady(false);

    if (filterHistory) {
      historyResult = historyResult.filter((item) => actionTypeChecked.indexOf(item.ActionType) >= 0);
    }

    if (pendingResult.length === 0 && historyResult.length === 0) {
      setRunningReport(false);
      props.setSnackbarProps({
        open: true,
        message: 'The criteria you have selected contains no records.' +
        'Please select a different set of options.',
        severity: 'warning',
      });
      return;
    }

    if (combineSheet) {
      exportDataCombined(pendingResult, historyResult);
    } else {
      exportDataSeperated(pendingResult, historyResult);
    }
  };

  const convertScheduleIDtoScheduleNo = (scheduleID) => {
    if (!scheduleID) return undefined;
    if (checkWholeNumber(scheduleID)) {
      return scheduleID;
    }
    if (scheduleID.length < 10) return undefined;
    if (parseInt(scheduleID.slice(-6)) < 100) return undefined;
    if (parseInt(scheduleID.charAt(9)) >= 5) {
      return parseInt(scheduleID.slice(-6));
    } else {
      return parseInt('1' + scheduleID.slice(-6));
    }
  };

  const staticColumnOrder = (object) => {
    return ({
      ID: object.ID,
      ScheduleID: object.ScheduleID || object.ScheduleNumber,
      ScheduleNo: convertScheduleIDtoScheduleNo(object.ScheduleID),
      CustomerID: object.CustomerID,
      CustomerName: object.CustomerName,
      CustomerCofico: object.CustomerCofico || object.CoficoNo,
      ScheduleStatus: object.ScheduleStatus,
      Status: object.Status,
      CreatedDate: object.CreatedDate,
      CreatedBy: object.CreatedBy,
      ScheduledDate: object.ScheduledDate,
      ActionType: object.ActionType,
      Reference: longReferenceCrop(object.Reference),
      CompletedDate: object.CompletedDate,
      CompletedBy: object.CompletedBy,
    });
  };

  const staticColumnOrderWithDec = (object) => {
    return ({
      ID: object.ID,
      ScheduleID: object.ScheduleID || object.ScheduleNumber,
      ScheduleNo: convertScheduleIDtoScheduleNo(object.ScheduleID),
      CustomerID: object.CustomerID,
      CustomerName: object.CustomerName,
      CustomerCofico: object.CustomerCofico || object.CoficoNo,
      ScheduleStatus: object.ScheduleStatus,
      Status: object.Status,
      CreatedDate: object.CreatedDate,
      CreatedBy: object.CreatedBy,
      ScheduledDate: object.ScheduledDate,
      ActionType: object.ActionType,
      Reference: longReferenceCrop(object.Reference),
      CompletedDate: object.CompletedDate,
      CompletedBy: object.CompletedBy,
      AccountBalance: safePropertyRead(object.decFields, 'accountBalance'),
      AccountSuspended: safePropertyRead(object.decFields, 'accountSuspended'),
      PDAttribute: safePropertyRead(object.decFields, 'pdAttribute'),
      BankNotificationSentDate: convertDateToExcel(
          safePropertyRead(object.decFields, 'bankNotificationSent')),
      BankNotificationChaseDate: convertDateToExcel(
          safePropertyRead(object.decFields, 'bankNotificationChase')),
      BankNotificationCallDate: convertDateToExcel(
          safePropertyRead(object.decFields, 'bankNotificationCall')),
      BIFSentDate: convertDateToExcel(safePropertyRead(object.decFields, 'bifSent')),
      BIFChaseDate: convertDateToExcel(safePropertyRead(object.decFields, 'bifChase')),
      BIFRecievedDate: convertDateToExcel(safePropertyRead(object.decFields, 'bifRecieved')),
      DeathCertificateRecievedDate: convertDateToExcel(
          safePropertyRead(object.decFields, 'deathCertificateRecieved')),
      ExecutorDetailsAdded: safePropertyRead(object.decFields, 'executorDetailsAdded'),
      AddressDetailsRemoved: safePropertyRead(object.decFields, 'addressDetailsRemoved'),
      IntentionSentDate: convertDateToExcel(safePropertyRead(object.decFields, 'intentionSent')),
      IntentionChaseDate: convertDateToExcel(safePropertyRead(object.decFields, 'intentionChase')),
      IntentionRecieved: safePropertyRead(object.decFields, 'intentionRecieved'),
      RefundActioned: safePropertyRead(object.decFields, 'refundActioned'),
      AccountClear: safePropertyRead(object.decFields, 'accountClear'),
      ClosedCofico: safePropertyRead(object.decFields, 'closedCofico'),
    });
  };

  const longReferenceCrop = (reference) => {
    if (reference.length > 30000) {
      return reference.slice(0, 30000);
    }
    return reference;
  };

  const exportDataCombined = (pendingResult, historyResult) => {
    // Data Combined Together
    const dataCombined = [].concat(pendingResult, historyResult);

    // Redo Column Ordering
    for (let i=0; i<dataCombined.length; i++) {
      if (decDetails) {
        dataCombined[i] = staticColumnOrderWithDec(dataCombined[i]);
      } else {
        dataCombined[i] = staticColumnOrder(dataCombined[i]);
      }
    }

    const ws3 = XLSX.utils.json_to_sheet(dataCombined);
    const dateColumns = [];
    for (const cell in ws3) {
      if (splitExcelCellString(cell).row === '1') {
        if (ws3[cell].v.toLowerCase().includes('date')) {
          dateColumns.push(splitExcelCellString(cell).column);
        }
      }
    }

    for (let j = 0; j < dateColumns.length; j++) {
      for (let i = 0; i < dataCombined.length; i++) {
        if (ws3[dateColumns[j] + (i+2).toString()] !== undefined) {
          ws3[dateColumns[j] + (i + 2).toString()].z = 'dd/mm/yyyy';
        }
      }
    }

    const wb = { Sheets: { 'data': ws3 }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    createExcelFile(data, 'Saltmine Report ' + selectedToDate + selectedFromDate + ' .xlsx');
  };

  const exportDataSeperated = (pendingResult, historyResult) => {
    // Redo Column Ordering
    for (let i=0; i<pendingResult.length; i++) {
      if (decDetails) {
        pendingResult[i] = staticColumnOrderWithDec(pendingResult[i]);
      } else {
        pendingResult[i] = staticColumnOrder(pendingResult[i]);
      }
    }
    for (let i=0; i<historyResult.length; i++) {
      if (decDetails) {
        historyResult[i] = staticColumnOrderWithDec(historyResult[i]);
      } else {
        historyResult[i] = staticColumnOrder(historyResult[i]);
      }
    }

    const ws = XLSX.utils.json_to_sheet(pendingResult);
    let dateColumns = [];
    for (const cell in ws) {
      if (splitExcelCellString(cell).row === '1') {
        if (ws[cell].v.toLowerCase().includes('date')) {
          dateColumns.push(splitExcelCellString(cell).column);
        }
      }
    }

    for (let j = 0; j < dateColumns.length; j++) {
      for (let i = 0; i < pendingResult.length; i++) {
        if (ws[dateColumns[j] + (i+2).toString()] !== undefined) {
          ws[dateColumns[j] + (i + 2).toString()].z = 'dd/mm/yyyy';
        }
      }
    }

    const ws2 = XLSX.utils.json_to_sheet(historyResult);
    dateColumns = [];
    for (const cell in ws2) {
      if (splitExcelCellString(cell).row === '1') {
        if (ws2[cell].v.toLowerCase().includes('date')) {
          dateColumns.push(splitExcelCellString(cell).column);
        }
      }
    }

    for (let j = 0; j < dateColumns.length; j++) {
      for (let i = 0; i < historyResult.length; i++) {
        if (ws2[dateColumns[j] + (i+2).toString()] !== undefined) {
          ws2[dateColumns[j] + (i + 2).toString()].z = 'dd/mm/yyyy';
        }
      }
    }

    const wb = { Sheets: { 'Pending': ws, 'History': ws2 }, SheetNames: ['Pending', 'History'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    createExcelFile(data, 'Saltmine Report ' + selectedToDate + selectedFromDate + ' .xlsx');
  };

  const pendingDebtActivity = (pendingData, contracts) => {
    let pendingActivity = 'None';
    if (contracts === undefined) {
      return pendingActivity;
    }
    for (let i = 0; i < contracts.length; i++) {
      if (pendingData.filter((item) => (item.CustomerID === contracts[i])).length > 0) {
        pendingActivity = 'Pending Debt Activity';
      }
    }
    return pendingActivity;
  };

  const convertCustArrayToString = (custArray) => {
    if (custArray === undefined) {
      return '';
    }
    let returnValue = '';
    for (let i = 0; i < custArray.length; i++) {
      if (returnValue === '') {
        returnValue = returnValue + custArray[i];
      } else {
        returnValue = returnValue + ' - ' + custArray[i];
      }
    }
    return returnValue;
  };

  const compareDatesReturnHighest = (date1, date2) => {
    if (date1 === undefined || date1 === null) {
      return date2;
    }
    if (date2 === undefined || date2 === null) {
      return date1;
    }
    if (date1 > date2) {
      return date1;
    } else {
      return date2;
    }
  };

  const exportDataAged = (pendingResult, agedResult) => {
    setPendingDataReady(false);
    setAgedDataReady(false);

    if (pendingResult.length === 0 && agedResult.length === 0) {
      setRunningReport(false);
      props.setSnackbarProps({
        open: true,
        message: 'The criteria you have selected contains no records.' +
        'Please select a different set of options.',
        severity: 'warning',
      });
      return;
    }

    let processedAged = [];
    for (let i = 0; i < agedResult.length; i++) {
      const index = processedAged.findIndex((item)=>(item.CustomerCofico === agedResult[i].CustomerCofico));
      if (index === -1) {
        processedAged = [...processedAged, {
          ConnectedContracts: convertCustArrayToString(agedResult[i].CustomerID),
          CustomerCofico: agedResult[i].CustomerCofico,
          CustomerName: agedResult[i].CustomerName,
          DocumentType: agedResult[i].DocumentType,
          RentalsInArrears: 1,
          LatestRental: convertDateToExcel(agedResult[i].NetDueDate),
          TotalDebt: parseFloat(agedResult[i].DebtAmount.toString().replace(',', '')),
          pendingDebtActivity: pendingDebtActivity(pendingData, agedResult[i].CustomerID),
        }];
      } else {
        processedAged[index] = {
          ...processedAged[index],
          LatestRental: compareDatesReturnHighest(
              processedAged[index].LatestRental, convertDateToExcel(agedResult[i].NetDueDate),
          ),
          RentalsInArrears: processedAged[index].RentalsInArrears + 1,
          TotalDebt: processedAged[index].TotalDebt + parseFloat(
              agedResult[i].DebtAmount.toString().replace(',', ''),
          ),
        };
      }
    }

    const ws = XLSX.utils.json_to_sheet(processedAged);
    const dateColumns = [];
    for (const cell in ws) {
      if (splitExcelCellString(cell).row === '1') {
        if (ws[cell].v.toLowerCase().includes('latestrental')) {
          dateColumns.push(splitExcelCellString(cell).column);
        }
      }
    }

    for (let j = 0; j < dateColumns.length; j++) {
      for (let i = 0; i < processedAged.length; i++) {
        if (ws[dateColumns[j] + (i+2).toString()] !== undefined) {
          ws[dateColumns[j] + (i + 2).toString()].z = 'dd/mm/yyyy';
        }
      }
    }

    const wb = { Sheets: { 'C7 Debt': ws }, SheetNames: ['C7 Debt'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    createExcelFile(data, 'Aged Debt Report ' + currentDateAsStringHyph() + '.xlsx');
  };

  const createExcelFile = (data, name) => {
    let textFile = null;

    textFile = window.URL.createObjectURL(data);

    const link = document.createElement('a');
    link.href = textFile;
    link.style.display = 'block';
    link.download = name;
    link.id = 'DownloadLink';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setRunningReport(false);
  };

  return (
    <Container component='main'>
      <Grid container spacing={3} sx={sxClasses.contentSectionsLeft}>
        <Grid item xs={5} >
          <TitlePaper className={classes.titleFields} >
            Pending
          </TitlePaper>
          <div className={classes.spacer} />
          <TextField select
            sx={sxClasses.actionCodeStyle}
            label="Include"
            value={pendingSelectValue}
            onChange={handleChangePendingSelect}
            id='txtPendingSelect'>
            {pendingSelectOptions.map((codes, index) => (
              <MenuItem key={index} value={codes}>
                {codes}
              </MenuItem>
            ))}
          </TextField>
          { pendingSelectValue === 'Custom' ?
              <Paper sx={sxClasses.contentSectionsActonCodes} >
                <List>
                  {sortArrayByProperty(props.actionCodeLookup, 'ActionType').map((value) => {
                    const labelId = `checkbox-list-label-${value}`;

                    return (
                      <ListItem key={value.ActionType} role={undefined} dense button
                        onClick={handleToggleActionCode(value.ActionType)}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={actionTypeChecked.indexOf(value.ActionType) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={
                          value.ActionType + ' - ' + value.ActionDescription
                        } />
                      </ListItem>
                    );
                  })}
                </List>
              </Paper> :
            null }
        </Grid>
        <Grid item xs={1} sx={sxClasses.spacerVerticalGrid} >
          <div className={classes.spacerVertical} />
        </Grid>
        <Grid item xs={6} >
          <TitlePaper className={classes.titleFields} >
            History
          </TitlePaper>
          <div className={classes.spacer} />
          <TextField select
            sx={sxClasses.actionCodeStyle}
            label="Include"
            value={historySelectValue}
            onChange={handleChangeHistorySelect}
            id='txHistorySelect'>
            {historySelectOptions.map((codes, index) => (
              <MenuItem key={index} value={codes}>
                {codes}
              </MenuItem>
            ))}
          </TextField>
          { historySelectValue === 'Custom' ?
            <Grid container spacing={3} sx={{ marginTop: 1 }}>
              <Grid item xs={3} sx={sxClasses.titleStyle}>
              Date From:
              </Grid>
              <Grid item xs={9} >
                <DesktopDatePicker
                  margin="normal"
                  id="time-from-picker"
                  inputFormat="dd/MM/yyyy"
                  value={selectedFromDate}
                  onChange={handleFromDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              <Grid item xs={3} sx={sxClasses.titleStyle}>
              Date To:
              </Grid>
              <Grid item xs={9} >
                <DesktopDatePicker
                  margin="normal"
                  id="time-to-picker"
                  inputFormat="dd/MM/yyyy"
                  value={selectedToDate}
                  onChange={handleToDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </Grid> :
          null }
        </Grid>
        <Button variant='contained' color='primary' sx={sxClasses.runButton} onClick={runReport}>
          Run
        </Button>
      </Grid>
      <ExitButton variant='contained' color='primary' onClick={() => {
        navigate(-1);
      }}>
        Back
      </ExitButton>
      {runningReport &&
        <LoadingPopup loadingText='Running...'/>
      }
      <Grid container spacing={3} sx={sxClasses.contentSectionsCustomReports}>
        <Grid item xs={5}>
          <Grid container spacing={1} >
            <Grid item xs={12}>
              <ListItem id='CombineSheet' sx={sxClasses.listItemStyle}
                dense button onClick={(e)=>{
                  handleListChange(e, 'CombineSheet');
                }}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={combineSheet}
                    inputProps={{ 'aria-labelledby': 'CombinedReportLabel' }}
                  />
                </ListItemIcon>
                <ListItemText id={'CombinedReportLabel'} primary={
                  'Combined Output On Single Sheet'
                } />
              </ListItem>
            </Grid>
            <Grid item xs={12}>
              <ListItem id='DecDetails' sx={sxClasses.listItemStyle}
                dense button onClick={(e)=>{
                  handleListChange(e, 'DecDetails');
                }}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={decDetails}
                    inputProps={{ 'aria-labelledby': 'CDecDetailsReportLabel' }}
                  />
                </ListItemIcon>
                <ListItemText id={'CDecDetailsReportLabel'} primary={
                  'Include Details on DEC items'
                } />
              </ListItem>
            </Grid>
            {(pendingSelectValue !== 'All' &&
             pendingSelectValue !== 'None' &&
             historySelectValue !== 'None') &&
              <Grid item xs={12}>
                <ListItem id={'FilterHistory'} sx={sxClasses.listItemStyle}
                  dense button onClick={(e)=>{
                    handleListChange(e, 'FilterHistory');
                  }}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={filterHistory}
                      inputProps={{ 'aria-labelledby': 'CDecDetailsReportLabel' }}
                    />
                  </ListItemIcon>
                  <ListItemText id={'CDecDetailsReportLabel'} primary={
                    'Filter history to selected Pending Items'
                  } />
                </ListItem>
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={1} sx={sxClasses.spacerVerticalGrid} >
          <div className={classes.spacerVertical} />
        </Grid>
        <Grid item xs={6}>
          <Button variant='contained' color='primary' sx={sxClasses.runButton}
            onClick={runAgedDebtReport}>
            Aged Debt Report
          </Button>
          {isVanEnvironment() &&
            <Button variant='contained' color='primary' sx={sxClasses.runButton2}
              onClick={() => {
                getAndExportFlexReportData(0, '', props.setSnackbarProps, []);
              }}>
              Flex Data Extract
            </Button>
          }
        </Grid>
      </Grid>
    </Container>
  );
}
