// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { API } from 'aws-amplify';
import { Buffer } from 'buffer';
// Components
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CustomerAgedDebt from '../Components/GeneralComponents/CustomerAgedDebt';
import CustomerClaimsData from '../Components/GeneralComponents/CustomerClaimsData';
import CustomerDetails from '../Components/GeneralComponents/CustomerDetails';
import CustomerScheduleSelect from '../Components/GeneralComponents/CustomerScheduleSelect';
import CustomerHistoryItems from '../Components/CustomerHistoryItems';
import CustomerPendingInteraction from '../FunctionalComponents/CustomerPendingInteraction';
import EmailPopup from '../FunctionalComponents/EmailPopup';
import EmailTemplateSelectPopup from '../FunctionalComponents/EmailTemplateSelectPopup';
import TerminationWarningPopup from '../Components/TerminationWarningPopup';
import Grid from '@mui/material/Grid';
import ScheduleDetails from '../Components/GeneralComponents/ScheduleDetails';
import Paper from '@mui/material/Paper';
import ExcessMileageCalculatorPopup from '../Components/ExcessMileageCalculatorPopup';
import { ukDateStringToDate, getCurrentUser, checkWholeNumber, isActiveSchedule } from '../Libs/fileHelper';
import { createAndCompletePendingItem, createPendingItem, getCustomerData,
  getClaimsData } from '../Libs/apiHelper';
import { createYearlyMileageData, mileageReviewAnniversaryDate,
  calculateCurrentYear, excessMileageCalculation, excessMileageChargeCalculation,
} from '../Libs/mileageCalculatorHelper';
import { emailTemplateText } from '../Libs/templateHelper';
import { generateEMLReference } from '../Libs/emailHelper';
import { emptyScheduleData } from '../Libs/emptyDataStructures';
import config from '../Libs/Config';
import { currentStage, getCurrentEnvironment, isVanEnvironment } from '../Libs/StageConfig';
// Resources

const useStyles = makeStyles((theme) => ({
  contentSectionsNoBorder: {
    'padding': '2px',
    'textAlign': 'center',
  },
  contentSectionsNoBorderNoSpacing: {
    'textAlign': 'center',
  },
  exitContainerStyle: {
    'textAlign': 'right',
  },
  buttonStyle: {
    width: '200px',
  },
}));

export default function CustomerPage(props) {
  const classes = useStyles();
  const sxClasses = {
    contentSections: {
      marginTop: 0.5,
      border: '1px solid',
      borderColor: '#000000',
      textAlign: 'center',
    },
    conditionalButtonStyle: {
      width: '300px',
      marginTop: 0.5,
      marginBottom: 0.5,
    },
  };
  const location = useLocation();
  const navigate = useNavigate();

  CustomerPage.propTypes = {
    scheduleData: PropTypes.array.isRequired,
    actionCodeLookup: PropTypes.array.isRequired,
    customerData: PropTypes.object.isRequired,
    pendingData: PropTypes.array.isRequired,
    currUser: PropTypes.object.isRequired,
    setScheduleData: PropTypes.func.isRequired,
    setCustomerData: PropTypes.func.isRequired,
    setPendingData: PropTypes.func.isRequired,
    setActionCodeLookup: PropTypes.func.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
    emailConfigData: PropTypes.object.isRequired,
  };

  const [selectedScheduleIndex, setSelectedScheduleIndex] = React.useState(0);
  const selectedSchedule = () => {
    if (props.scheduleData.length === 0 ) {
      return emptyScheduleData;
    } else {
      return props.scheduleData[selectedScheduleIndex];
    }
  };

  const handleBlankAged = () => {
    if (selectedSchedule().AgedData === undefined ) {
      return [];
    } else {
      return selectedSchedule().AgedData.filter((item) => item.LastUpdate === agedLastUpdate);
    }
  };

  const handleBlankClaims = () => {
    if (selectedSchedule().ClaimsData === undefined ) {
      return [];
    } else {
      return selectedSchedule().ClaimsData;
    }
  };

  const [selectedPendingItem, setSelectedPendingItem] = React.useState({
    ID: 0,
    ScheduleID: '',
    Status: '',
    ActionType: '',
    CreatedDate: '',
    Reference: '',
    CreatedBy: '',
    CompletedDate: '',
    CompletedBy: '',
    ScheduledDate: null,
  });
  const [pendingDisabled, setPendingDisabled] = React.useState(true);
  const [selectingTemplate, setSelectingTemplate] = React.useState(false);
  const [expandAged, setExpandAged] = React.useState(false);
  const [agedLastUpdate, setAgedLastUpdate] = React.useState();
  const [claimLastUpdate, setClaimLastUpdate] = React.useState();
  const [claimsDataLoading, setClaimsDataLoading] = React.useState(true);
  const [agedDataLoading, setAgedDataLoading] = React.useState(true);
  const [terminationWarningPopup, setTerminationWarningPopup] = React.useState(false);
  const [hotTermination, setHotTermination] = React.useState(false);
  const [selectedCancellationReason, setSelectedCancellationReason] = React.useState('');

  React.useEffect(() => {
    handlePageRefresh();
  }, [] );

  const handlePageRefresh = async () => {
    const customerID = location.search.substring(2, 10);
    getPendHistData(customerID);
    // Trigger action code Lookup refresh if needed
    if (props.actionCodeLookup.length === 0) {
      refreshActionCodes();
    }
    if (props.customerData.CustomerID === customerID) {
      updateClaimsData(props.scheduleData);
      updateAgedData(props.scheduleData);
      return;
    }
    const returnedData = await getCustomerData(
        customerID, props.setCustomerData, props.setScheduleData, props.setSnackbarProps);
    updateClaimsData(returnedData);
    updateAgedData(returnedData);
  };

  const getPendHistData = (customerID) => {
    // Call PendHist List
    API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory.NAME, '/getcustomerid', {
      queryStringParameters: {
        CustomerID: customerID,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      props.setPendingData(JSON.parse(result.body));
    }).catch( (error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    });
  };

  const refreshActionCodes = () => {
    API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.ActionType.NAME, '/', {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      props.setActionCodeLookup(JSON.parse(result.body));
    }).catch( (error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    });
  };

  const updateAgedData = async (scheduleArray) => {
    setAgedLastUpdate(await API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.AgedData.NAME,
        '/getlastupdate', {
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((result) => {
      if (result.body !== undefined) {
        return (result.body);
      } else {
        props.setSnackbarProps({ open: true,
          message: 'Something went wrong: ' + result.statusCode,
          severity: 'error' });
      }
    }).catch((error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    }));


    const uniqueCoficos = [];
    scheduleArray.map((schedules) => {
      if (uniqueCoficos.indexOf(parseInt(schedules.CustomerCofico)) === -1) {
        if (!isNaN(parseInt(schedules.CustomerCofico)) && schedules.CustomerCofico !== '-') {
          uniqueCoficos.push(parseInt(schedules.CustomerCofico));
        }
      }
    });

    if (!uniqueCoficos.length) {
      setAgedDataLoading(false);
      return;
    }
    if (uniqueCoficos.length === 0) {
      setAgedDataLoading(false);
      return;
    }

    let processedCount = 0;
    const agedArray = [];
    for (let i=0; i<uniqueCoficos.length; i++) {
      API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.AgedData.NAME,
          '/getoncustomercofico', {
            queryStringParameters: {
              CustomerCofico: uniqueCoficos[i],
            },
            headers: {
              'Content-Type': 'application/json',
            },
          }).then((result) => {
        for (const agedItem of JSON.parse(result.body)) {
          agedArray.push(agedItem);
        }
        processedCount += 1;
        if (processedCount === uniqueCoficos.length) {
          for (const schedule of scheduleArray) {
            const index = scheduleArray.findIndex(
                (schedulefind) => schedulefind.ScheduleID === schedule.ScheduleID);
            scheduleArray[index] = {
              ...scheduleArray[index],
              AgedData: agedArray,
            };
          }
          setAgedDataLoading(false);
        }
      }).catch( (error) => {
        props.setSnackbarProps({ open: true,
          message: 'Something went wrong: ' + error,
          severity: 'error' });
      });
    }
  };

  const updateClaimsData = async (scheduleArray) => {
    setClaimLastUpdate(await API.get(
        config[getCurrentEnvironment()][currentStage()].apiGateway.ClaimsData.NAME, '/getlastupdate', {
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((result) => {
      if (!result.body) {
        props.setSnackbarProps({ open: true,
          message: 'Something went wrong: ' + result.statusCode,
          severity: 'error' });
      }
      if (result.statusCode === 200) return result.body;
      return 0;
    }).catch((error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    }));

    const uniqueVins = [];
    scheduleArray.map((schedules) => {
      if (uniqueVins.indexOf(schedules.ChassisNo) === -1) {
        uniqueVins.push(schedules.ChassisNo);
      }
    });

    if (!uniqueVins.length) {
      setClaimsDataLoading(false);
      return;
    }
    if (uniqueVins.length === 0) {
      setClaimsDataLoading(false);
      return;
    }

    if (uniqueVins.length > 50) {
      props.setSnackbarProps({ open: true,
        message: 'Customer has more than 50 schedules, Claims data will be loaded dynamically.',
        severity: 'info' });
      updateIndividualClaims(scheduleArray[0].ChassisNo, scheduleArray);
      return;
    }

    let processedCount = 0;
    for (let i=0; i<uniqueVins.length; i++) {
      const claimsResponse = await getClaimsData(uniqueVins[i], props.setSnackbarProps);
      for (const schedule of scheduleArray.filter((item) => item.ChassisNo === uniqueVins[i])) {
        const index = scheduleArray.findIndex(
            (schedulefind) => schedulefind.ScheduleID === schedule.ScheduleID);
        scheduleArray[index] = {
          ...scheduleArray[index],
          ClaimsData: claimsResponse,
        };
      }
      props.setScheduleData(scheduleArray);
      processedCount += 1;
      if (processedCount === uniqueVins.length) {
        setClaimsDataLoading(false);
      }
    }
  };

  const emailButtonPressed = () => {
    if (emailStates.emailBody === '') {
      setSelectingTemplate(true);
    } else {
      setSendingEmail(true);
    }
  };

  const performBack = () => {
    if (pendingDisabled) {
      if (location.search.substring(11, 19) === 'tab=true') {
        window.close();
      } else {
        navigate(-1);
      }
    } else {
      props.setSnackbarProps({ open: true,
        message: 'You are currently editing an item. Press cancel to exit customer page.',
        severity: 'warning' });
    }
  };

  const handleExpandedChangeAged = () => {
    if (expandAged) {
      setExpandAged(false);
    } else {
      setExpandAged(true);
    }
  };

  const handleScheduleChange = (scheduleClicked) => {
    if (!pendingDisabled) {
      props.setSnackbarProps({ open: true,
        message: 'You are currently editing an item. Press cancel before changing schedules.',
        severity: 'warning' });
    }

    const scheduleIndex = props.scheduleData.findIndex(
        (schedInfo) => schedInfo.ScheduleID === scheduleClicked,
    );
    setSelectedScheduleIndex(scheduleIndex);
    updateIndividualClaims(props.scheduleData[scheduleIndex].ChassisNo, props.scheduleData);
  };

  // Customer Schedule Select Button Functions
  function cancellationQuotePressed() {
    setHotTermination(false);
    if (claimsDataLoading) {
      props.setSnackbarProps({ open: true,
        message: 'Claims Data is still loading. Please wait and try again in a moment.',
        severity: 'info' });
    } else if (agedDataLoading) {
      props.setSnackbarProps({ open: true,
        message: 'Aged Data is still loading. Please wait and try again in a moment.',
        severity: 'info' });
    } else if (!pendingDisabled) {
      props.setSnackbarProps({ open: true,
        message: 'You are currently editing an item. Press cancel to go to termination page.',
        severity: 'warning' });
    } else if (selectedSchedule().LaunchDate === null) {
      props.setSnackbarProps({ open: true,
        message: 'This schedule has not been launched and so cannot be cancelled.',
        severity: 'warning' });
    } else if (terminationWarningMessages().length !== 0) {
      setTerminationWarningPopup(true);
    } else {
      pushToTerminationPage();
    }
  }

  function hotTerminationPressed() {
    // Bug fix for migrated pending items having scheduleNO in ScheduleID field
    if (checkWholeNumber(selectedPendingItem.ScheduleID)) {
      if (selectedSchedule().ScheduleNo != selectedPendingItem.ScheduleID) {
        props.setSnackbarProps({ open: true,
          message: 'Please select schedule ' + selectedPendingItem.ScheduleID + ' to continue.',
          severity: 'info' });
        return;
      }
    } else {
      handleScheduleChange(selectedPendingItem.ScheduleID);
    }
    if (claimsDataLoading) {
      props.setSnackbarProps({ open: true,
        message: 'Claims Data is still loading. Please wait and try again in a moment.',
        severity: 'info' });
    } else if (agedDataLoading) {
      props.setSnackbarProps({ open: true,
        message: 'Aged Data is still loading. Please wait and try again in a moment.',
        severity: 'info' });
    } else if (!pendingDisabled) {
      props.setSnackbarProps({ open: true,
        message: 'You are currently editing an item. Press cancel to go to termination page.',
        severity: 'warning' });
    } else if (selectedSchedule().LaunchDate === null) {
      props.setSnackbarProps({ open: true,
        message: 'This schedule has not been launched and so cannot be cancelled.',
        severity: 'warning' });
    } else if (terminationWarningMessages().length !== 0) {
      setHotTermination(true);
      setTerminationWarningPopup(true);
    } else {
      navigate('/Termination?=' + selectedScheduleIndex + '&HotTerm=true');
    }
  }

  function deceasedProcessPressed() {
    navigate('/DecPendingItem/' + selectedPendingItem.ID);
  }

  function pushToTerminationPage() {
    if (hotTermination) {
      navigate('/Termination?=' + selectedScheduleIndex + '&HotTerm=true');
    } else {
      navigate('/Termination?=' + selectedScheduleIndex);
    }
    setHotTermination(false);
  }

  function worldpayPressed() {
    const base64CustText = Buffer.from(
        props.customerData.CustomerName + '\n' +
      props.customerData.CustomerAddress + '\n' +
      props.customerData.CustomerPostcode + '\n' +
      props.customerData.CustomerEmail + '\n' +
      props.customerData.CustomerPhone + '\n' +
      props.customerData.CustomerMobile + '\n' +
      selectedSchedule().CustomerCofico,
    ).toString('base64');

    if (navigator.clipboard !== undefined) {
      navigator.clipboard.writeText(base64CustText);
      props.setSnackbarProps({ open: true,
        message: 'Customer data copied to Clipboard for automation.',
        severity: 'success' });
    } else if (window.clipboardData) {
      window.clipboardData.setData('Text', base64CustText);
      props.setSnackbarProps({ open: true,
        message: 'Customer data copied to Clipboard for automation.',
        severity: 'success' });
    } else {
      fallbackCopyTextToClipboard(base64CustText);
    }
  }

  function fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        props.setSnackbarProps({ open: true,
          message: 'Customer data copied to Clipboard for automation.',
          severity: 'success' });
      } else {
        props.setSnackbarProps({ open: true,
          message: 'Something went wrong copying customer data.',
          severity: 'warning' });
      }
    } catch (err) {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong copying customer data.',
        severity: 'warning' });
    }

    document.body.removeChild(textArea);
  }

  function terminationWarningMessages() {
    const warningMessages = [];
    if ((new Date()) > ukDateStringToDate(selectedSchedule().EndDate)) {
      warningMessages.push('This schedule has an End Date in past.');
    }
    if (!isActiveSchedule(selectedSchedule().Status)) {
      warningMessages.push('This schedule is not live. The status is ' + selectedSchedule().Status + '.');
    }
    if (parseInt(selectedSchedule().ChargeAmount) === 0) {
      warningMessages.push(
          'Customer is not paying for this schedule as the Charge Amount is zero. ' +
        ' Do not refund or charge customer without considering the schedule type.');
    }
    if (handleBlankClaims().filter(
        (item) => item.ScheduleID !== selectedSchedule().ScheduleID,
    ).length !== 0) {
      warningMessages.push(
          'Customer has claims on another schedule matching this schedule\'s chassis. ' +
         'Please validate claims total before confirming quote');
    }
    return warningMessages;
  }

  const addEmailSentHistoryItem = async (eventEmailStates) => {
    let body = {
      ScheduleID: selectedSchedule().ScheduleID,
      Status: 'History',
      CustomerName: selectedSchedule().CustomerName,
      ScheduleStatus: selectedSchedule().Status,
      CustomerID: selectedSchedule().CustomerID,
      CustomerCofico: selectedSchedule().CustomerCofico,
    };
    if (eventEmailStates.sendAsPDF) {
      body = {
        ...body,
        ActionType: 'LET',
        Reference: 'Letter Sent: \n\n' + eventEmailStates.emailBody,
      };
    } else {
    // Log EML item
      body = {
        ...body,
        ActionType: 'EML',
        Reference: generateEMLReference(eventEmailStates.groupMailbox, eventEmailStates.emailSentTo,
            eventEmailStates.emailSubject, eventEmailStates.emailBody),
      };
    }

    const response = await createAndCompletePendingItem(body,
        getCurrentUser(props.currUser), props.pendingData, props.setPendingData, props.setSnackbarProps);

    if (response.status === 200) {
      console.log('Success');
    }

    if (eventEmailStates.emailSubject.substring(0, 28) === 'Cancellation Acknowledgement') {
      const body2 = {
        ScheduleID: selectedSchedule().ScheduleID,
        Status: 'Pending',
        ActionType: 'TRM',
        CustomerName: selectedSchedule().CustomerName,
        ScheduleStatus: selectedSchedule().Status,
        Reference: 'Cancellation Acknowledgement: ' + selectedCancellationReason,
        CustomerID: selectedSchedule().CustomerID,
        userId: getCurrentUser(props.currUser),
        ScheduledDate: null,
        CustomerCofico: selectedSchedule().CustomerCofico,
      };
      const response2 = await createPendingItem(body2,
          getCurrentUser(props.currUser),
          response.updatedPendingData,
          props.setPendingData,
          props.setSnackbarProps);

      if (response2.status === 200) {
        console.log('Success');
      }
    }

    if (eventEmailStates.emailSubject.substring(0, 18) === 'Cancellation Quote') {
      const body2 = {
        ScheduleID: selectedSchedule().ScheduleID,
        Status: 'Pending',
        ActionType: 'TQT',
        CustomerName: selectedSchedule().CustomerName,
        ScheduleStatus: selectedSchedule().Status,
        Reference: 'Termination Quote: ' + selectedCancellationReason,
        CustomerID: selectedSchedule().CustomerID,
        userId: getCurrentUser(props.currUser),
        ScheduledDate: null,
        CustomerCofico: selectedSchedule().CustomerCofico,
      };
      const response2 = await createPendingItem(body2,
          getCurrentUser(props.currUser),
          response.updatedPendingData,
          props.setPendingData,
          props.setSnackbarProps);

      if (response2.status === 200) {
        console.log('Success');
      }
    }

    setEmailStates({
      emailSentTo: props.customerData.CustomerEmail,
      emailSentFrom: props.emailConfigData.defaultMailbox,
      emailSubject: '',
      emailBody: '',
      sendAsPDF: false,
      sendAction: ()=>{},
    });
  };

  // Handling Massive Customers
  const updateIndividualClaims = (chassisNo, scheduleArray) => {
    if (!chassisNo) {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: No Chassis found',
        severity: 'error' });
      return;
    }
    // Check update needed
    for (const schedule of scheduleArray.filter((item) => item.ChassisNo === chassisNo)) {
      const index = scheduleArray.findIndex(
          (schedulefind) => schedulefind.ScheduleID === schedule.ScheduleID);
      if (scheduleArray[index].ClaimsData !== undefined) {
        if (scheduleArray[index].ClaimsData.length > 0) {
          setClaimsDataLoading(false);
          return;
        }
      }
    }

    setClaimsDataLoading(true);
    API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.ClaimsData.NAME, '/getonchassis', {
      queryStringParameters: {
        ChassisNo: chassisNo,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      for (const schedule of scheduleArray.filter((item) => item.ChassisNo === chassisNo)) {
        const index = scheduleArray.findIndex(
            (schedulefind) => schedulefind.ScheduleID === schedule.ScheduleID);
        scheduleArray[index] = {
          ...scheduleArray[index],
          ClaimsData: JSON.parse(result.body),
        };
      }
      props.setScheduleData(scheduleArray);
      setClaimsDataLoading(false);
    }).catch( (error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    });
  };

  // Email Popup Functions
  const [sendingEmail, setSendingEmail] = React.useState(false);
  const [emailStates, setEmailStates] = React.useState({
    emailSentTo: props.customerData.CustomerEmail,
    emailSentFrom: props.emailConfigData.defaultMailbox,
    emailSubject: '',
    emailBody: '',
    sendAsPDF: false,
    sendAction: ()=>{},
  });
  const sendEmailFromCustomerDetails = () => {
    if (emailStates.emailBody === '') {
      setEmailStates({
        emailSentTo: props.customerData.CustomerEmail,
        emailSentFrom: props.emailConfigData.defaultMailbox,
        emailSubject: 'Mercedes-Benz Service Contract - ' + selectedSchedule().ScheduleID + ' - ' +
          selectedSchedule().Registration,
        emailBody: '\n\n' + props.emailConfigData.signature,
        sendAsPDF: false,
        sendAction: addEmailSentHistoryItem,
      });
    }
    setSendingEmail(true);
  };
  const resendEmail = () => {
    setEmailStates({
      emailSentTo: props.customerData.CustomerEmail,
      emailSentFrom: props.emailConfigData.defaultMailbox,
      emailSubject: 'Email Resend - Mercedes-Benz Service Contract - ' +
        selectedSchedule().ScheduleID + ' - ' + selectedSchedule().Registration,
      emailBody: '\n\n-----\n' + selectedPendingItem.Reference,
      sendAsPDF: false,
      sendAction: addEmailSentHistoryItem,
    });
    setSendingEmail(true);
  };

  const selectTemplateHandleNext = (selectedTemplate, setSelectedTemplate, showCancellationReason) => {
    if (selectedTemplate === '') {
      props.setSnackbarProps({ open: true,
        message: 'Please select a template.',
        severity: 'warning' });
      return;
    }
    if (showCancellationReason(selectedTemplate) && selectedCancellationReason === '') {
      props.setSnackbarProps({ open: true,
        message: 'Please select a reason.',
        severity: 'warning' });
      return;
    }

    setSelectingTemplate(false);
    setSendingEmail(true);
    setEmailStates({
      emailSentTo: props.customerData.CustomerEmail,
      emailSentFrom: props.emailConfigData.defaultMailbox,
      emailSubject: selectedTemplate + ' - ' + selectedSchedule().ScheduleID + ' - ' +
        selectedSchedule().Registration,
      emailBody: emailTemplateText(selectedTemplate, selectedCancellationReason,
          props.customerData, selectedSchedule(), props.emailConfigData.signature),
      sendAsPDF: false,
      sendAction: addEmailSentHistoryItem,
    });
    setSelectedTemplate('');
    setSelectedCancellationReason('');
  };

  // Excess Mileage Functionality
  const [excessMileageData, setExcessMileageData] = React.useState({
    popupOpen: false,
    scheduleLastTacho: selectedSchedule().SchedLastTacho || 0,
    yearlyData: createYearlyMileageData(selectedSchedule(), handleBlankClaims(),
        selectedSchedule().SchedLastTacho || 0),
    calculationDate: mileageReviewAnniversaryDate(selectedSchedule().StartDate, selectedSchedule().EndDate),
    scheduleStartDate: ukDateStringToDate(selectedSchedule().StartDate),
  });

  const mileageReviewButtonPressed = () => {
    setExcessMileageData({
      scheduleLastTacho: Number(selectedSchedule().SchedLastTacho) || 0,
      yearlyData: createYearlyMileageData(selectedSchedule(), handleBlankClaims(),
          selectedSchedule().SchedLastTacho || 0),
      popupOpen: true,
      calculationDate: mileageReviewAnniversaryDate(selectedSchedule().StartDate, selectedSchedule().EndDate),
      scheduleStartDate: ukDateStringToDate(selectedSchedule().StartDate),
    });
  };

  const mileageReviewClosed = () => {
    setExcessMileageData({
      ...excessMileageData,
      popupOpen: false,
    });

    const reviewYear = calculateCurrentYear(selectedSchedule()) + 1;
    const reviewData = { ...excessMileageData.yearlyData[reviewYear - 1],
      excessMileage: excessMileageCalculation(excessMileageData, reviewYear),
      chargeDue: excessMileageChargeCalculation(excessMileageData, reviewYear),
    };

    let referenceString = 'Mileage Review - ' + selectedPendingItem.ScheduleID + '\n\n';

    if (Number(selectedSchedule().SchedLastTacho) !== excessMileageData.scheduleLastTacho) {
      referenceString = referenceString + 'Start Tacho Adjusted: ' +
      excessMileageData.scheduleLastTacho + '\n';
    }

    for (const key of Object.keys(reviewData)) {
      referenceString = referenceString + camelCaseToSentenceCase(key) + ': ' + reviewData[key] + '\n';
    }

    setSelectedPendingItem({
      ...selectedPendingItem,
      Reference: referenceString,
    });
  };

  const camelCaseToSentenceCase = (string) => {
    const result = string.replace(/([A-Z])/g, ' $1');
    return (result.charAt(0).toUpperCase() + result.slice(1));
  };

  const isFlexContract = () => {
    if (!selectedSchedule().ProductCode) {
      return false;
    }
    if (selectedSchedule().ProductCode === 'AA') {
      return true;
    }
    if (selectedSchedule().ProductCode === 'AB') {
      return true;
    }
    if (selectedSchedule().ProductCode.toLowerCase().includes('flex')) {
      return true;
    }
    return false;
  };

  const isInvoiceManagedContract = () => {
    // ASDA Customer ID
    if (selectedSchedule().CustomerID === 'R6000009') {
      return true;
    }
    // DPD Customer ID
    if (selectedSchedule().CustomerID === 'GP000001') {
      return true;
    }
    return false;
  };

  const conditionalNavigationButton = (condition, url, label) => {
    if (condition) {
      return (<Button sx={sxClasses.conditionalButtonStyle} variant='contained' color='primary'
        onClick={() => {
          navigate(url);
        }}>
        {label}
      </Button>);
    } else {
      return null;
    }
  };

  return (
    <Container component='main' maxWidth={false}>
      {props.customerData.CustomerID === '' ?
      <div>
        Loading...
      </div> :
      <React.Fragment>
        <Grid container spacing={0}>
          <Grid item xs={6} >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <CustomerDetails customerData={props.customerData} scheduleData={selectedSchedule()}
                      sendEmailFromCustomerDetails={sendEmailFromCustomerDetails} />
                  </Grid>
                  <Grid item xs= {7} className={classes.contentSectionsNoBorderNoSpacing}>
                    <CustomerScheduleSelect
                      scheduleData={props.scheduleData}
                      handleScheduleChange={handleScheduleChange}
                      cancellationQuotePressed={cancellationQuotePressed}
                      worldpayPressed={worldpayPressed}
                      hideButtons={false}
                      selectedSchedule={selectedSchedule().ScheduleID}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CustomerHistoryItems
                  pendingData={props.pendingData}
                  pendingDisabled={pendingDisabled}
                  setSelected={setSelectedPendingItem}
                  selectedPendingItem={selectedPendingItem}
                  setSnackbarProps={props.setSnackbarProps}
                  selectedScheduleID={selectedSchedule().ScheduleID}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} >
            <Grid container spacing={0}>
              <Grid item xs={12} component={Paper} sx={sxClasses.contentSections} >
                <ScheduleDetails scheduleData={selectedSchedule()} />
              </Grid>
              <Grid item xs={12} className={classes.contentSectionsNoBorderNoSpacing} >
                {conditionalNavigationButton(
                    isFlexContract() && isVanEnvironment(),
                    '/FlexManagement/' + selectedSchedule().ScheduleID,
                    'Flex Management')}
              </Grid>
              <Grid item xs={12} className={classes.contentSectionsNoBorderNoSpacing} >
                {conditionalNavigationButton(
                    isInvoiceManagedContract() && isVanEnvironment(),
                    '/InvoiceManagement/' + selectedSchedule().CustomerID,
                    'Invoice Management')}
              </Grid>
              <Grid item xs={12} >
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <CustomerAgedDebt agedData={handleBlankAged()} expanded={expandAged}
                          handleExpandedChange={handleExpandedChangeAged}
                          agedLastUpdate={agedLastUpdate}/>
                      </Grid>
                      <Grid item xs= {6}>
                        <CustomerClaimsData claimsData={handleBlankClaims()} expanded={expandAged}
                          handleExpandedChange={handleExpandedChangeAged}
                          claimLastUpdate={claimLastUpdate}/>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.contentSectionsNoBorder} >
                      <CustomerPendingInteraction
                        scheduleData={selectedSchedule()}
                        currUser={props.currUser}
                        pendingData={props.pendingData}
                        setPendingData={props.setPendingData}
                        pendingDisabled={pendingDisabled}
                        setPendingDisabled={setPendingDisabled}
                        SelectedData={selectedPendingItem}
                        setSelected={setSelectedPendingItem}
                        actionCodeLookup={props.actionCodeLookup}
                        setSnackbarProps={props.setSnackbarProps}
                        hotTerminationPressed={hotTerminationPressed}
                        deceasedProcessPressed={deceasedProcessPressed}
                        resendEmail={resendEmail}
                        mileageReviewButtonPressed={mileageReviewButtonPressed}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <Button className={classes.buttonStyle} variant='contained' color='primary'
                      onClick={() => {
                        emailButtonPressed();
                      }}>
                      Email Customer
                    </Button>
                  </Grid>
                  <Grid item xs={6} className={classes.exitContainerStyle}>
                    <Button className={classes.buttonStyle} variant='contained' color='primary'
                      onClick={() => {
                        performBack();
                      }}>
                      Exit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <EmailPopup
          popupOpen={sendingEmail}
          setPopupOpen={setSendingEmail}
          emailStates={emailStates}
          setEmailStates={setEmailStates}
          setSnackbarProps={props.setSnackbarProps}
          customerData={props.customerData}
          scheduleData={selectedSchedule()}
          currUser={props.currUser}
          emailConfigData={props.emailConfigData}/>
        <EmailTemplateSelectPopup
          popupOpen={selectingTemplate}
          setPopupOpen={setSelectingTemplate}
          scheduleData={selectedSchedule()}
          selectTemplateHandleNext={selectTemplateHandleNext}
          selectedCancellationReason={selectedCancellationReason}
          setSelectedCancellationReason={setSelectedCancellationReason}
        />
        <ExcessMileageCalculatorPopup
          popupOpen={excessMileageData.popupOpen}
          excessMileageData={excessMileageData}
          setExcessMileageData={setExcessMileageData}
          scheduleData={selectedSchedule()}
          claimsData={handleBlankClaims()}
          setSnackbarProps={props.setSnackbarProps}
          mileageReviewClosed={mileageReviewClosed} />
        <TerminationWarningPopup
          warningMessageArray={terminationWarningMessages()}
          pushToTerminationPage={pushToTerminationPage}
          terminationWarningPopup={terminationWarningPopup}
          setTerminationWarningPopup={setTerminationWarningPopup}/>
      </React.Fragment>
      }
    </Container>
  );
}
