// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import { useNavigate, useParams } from 'react-router-dom';
// Components
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CustomerDetails from '../Components/GeneralComponents/CustomerDetails';
import FlexContractDetails from '../Components/FlexComponents/FlexContractDetails';
import FlexClaimsData from '../Components/FlexComponents/FlexClaimsData';
import FlexContractRecords from '../Components/FlexComponents/FlexContractRecords';
import FlexRecordsAddPopup from '../Components/FlexComponents/FlexRecordsAddPopup';
import FlexRecordsUpdateMainPopup from '../Components/FlexComponents/FlexRecordsUpdateMainPopup';
import EmailPopup from '../FunctionalComponents/EmailPopup';
import Grid from '@mui/material/Grid';
import ScheduleDetails from '../Components/GeneralComponents/ScheduleDetails';
import Paper from '@mui/material/Paper';
import { toTitleCase, getCurrentUser, checkWholeNumber, handleBackwardsAddress,
  isActiveSchedule } from '../Libs/fileHelper';
import { createAndCompletePendingItem, createPendingItem, getClaimsData,
  getFlexData } from '../Libs/apiHelper';
import { updateFlexItem, createFlexItem, deleteFlexItem } from '../Libs/flexAPIHelper';
import { emptyScheduleData } from '../Libs/emptyDataStructures';
import { generateEMLReference } from '../Libs/emailHelper';
import * as XLSX from 'xlsx';
// Resources

export default function FlexPage(props) {
  const sxClasses = {
    contentSections: {
      marginTop: 0.5,
      border: '1px solid',
      borderColor: '#000000',
      textAlign: 'center',
      marginBottom: 1,
    },
    contentSectionsNoBorderNoSpacing: {
      textAlign: 'center',
    },
    exitContainerStyle: {
      textAlign: 'right',
    },
    buttonStyle: {
      marginTop: 1,
      width: '200px',
    },
  };
  const navigate = useNavigate();

  FlexPage.propTypes = {
    scheduleData: PropTypes.array.isRequired,
    actionCodeLookup: PropTypes.array.isRequired,
    customerData: PropTypes.object.isRequired,
    pendingData: PropTypes.array.isRequired,
    currUser: PropTypes.object.isRequired,
    setScheduleData: PropTypes.func.isRequired,
    setCustomerData: PropTypes.func.isRequired,
    setPendingData: PropTypes.func.isRequired,
    setActionCodeLookup: PropTypes.func.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
    emailConfigData: PropTypes.object.isRequired,
  };


  const params = useParams();
  const customerID = params.CustomerID;
  const scheduleIdEnd = params.ScheduleIDend;
  const scheduleID = customerID + '/' + scheduleIdEnd;

  const selectedSchedule = () => {
    if (props.scheduleData.length === 0 ) {
      return emptyScheduleData;
    } else {
      const schedule = props.scheduleData.filter((item) => item.ScheduleID == scheduleID);
      return schedule[0];
    }
  };

  const handleBlankClaims = () => {
    if (selectedSchedule()?.ClaimsData === undefined ) {
      return [];
    } else {
      return selectedSchedule().ClaimsData;
    }
  };

  const [claimLastUpdate, setClaimLastUpdate] = React.useState();
  const [selectedCancellationReason] = React.useState('');
  const [flexData, setFlexData] = React.useState([]);
  const [dealerList, setDealerList] = React.useState([]);
  const [manageClaims, setManageClaims] = React.useState(false);
  const [loadingTextState, setLoadingTextState] = React.useState('Loading');
  const [loadingInProgress, setLoadingInProgress] = React.useState(true);
  const loadingAnimationID = React.useRef(0);

  const loadingText = () => {
    let i = 0;

    loadingAnimationID.current = setInterval(() => {
      i+= 1;
      const newLoadingText = loadingTextState + Array(i+1).join('.');
      setLoadingTextState(newLoadingText);
    }, 500);
  };

  const handleDeniedClaims = (claimsData, manageClaimsState) => {
    if (manageClaimsState === true) {
      return claimsData;
    }
    if (manageClaimsState === false) {
      return claimsData.filter((item) => item.Denied !== true);
    }
    if (manageClaims) {
      return claimsData;
    }
    return claimsData.filter((item) => item.Denied !== true);
  };

  const unLinkedClaimsRefresh = (filterData, claimsData, manageClaimsState, refreshedFrom) => {
    console.log(refreshedFrom);
    const current = new Date();
    const time = current.toLocaleTimeString('en-GB');
    console.log('performing Refresh ' + time);
    if (claimsData) {
      const approvedClaims = handleDeniedClaims(claimsData, manageClaimsState);
      const filteredArray = approvedClaims.filter(function(array1) {
        return filterData.filter(function(array2) {
          return array2.ConnectedClaimDetails?.IndexDateOfClaimMileage == array1.IndexDateOfClaimMileage;
        }).length == 0;
      });
      const current2 = new Date();
      const time2 = current2.toLocaleTimeString('en-GB');
      console.log('finish Refresh ' + time2);
      return filteredArray;
    } else {
      const approvedClaims = handleDeniedClaims(handleBlankClaims(), manageClaimsState);
      const filteredArray = approvedClaims.filter(function(array1) {
        return filterData.filter(function(array2) {
          return array2.ConnectedClaimDetails?.IndexDateOfClaimMileage == array1.IndexDateOfClaimMileage;
        }).length == 0;
      });
      const current2 = new Date();
      const time2 = current2.toLocaleTimeString('en-GB');
      console.log('finish Refresh ' + time2);
      return filteredArray;
    }
  };
  const [unLinkedClaims, setUnlinkedClaims] = React.useState([]);
  const getMainFlexData = () => {
    return (flexData.filter((item) => item.UUID === 'Main')[0] || {});
  };

  const aServicesAvailable = () => {
    if (getMainFlexData().AServices !== undefined) {
      return getMainFlexData().AServices;
    }
    if (getMainFlexData().TotalServices) {
      return getMainFlexData().TotalServices / 2;
    }
    return 0;
  };

  const bServicesAvailable = () => {
    if (getMainFlexData().BServices !== undefined) {
      return getMainFlexData().BServices;
    }
    if (getMainFlexData().TotalServices) {
      return getMainFlexData().TotalServices / 2;
    }
    return 0;
  };

  React.useEffect(() => {
    loadingText();
    getCustomerData(customerID);
    getDealerList();
  }, [] );

  const getCustomerData = (customerID) => {
    console.log('Getting Customer Data on ' + customerID);
    if (props.customerData.CustomerID === customerID) {
      console.log('Customer Data already stored');
      updateClaimsData(props.scheduleData);
      return;
    }

    // Call Schedule List
    API.get('Saltmine-Van-API-ICONData', '/getcustomernumber', {
      queryStringParameters: {
        CustomerID: customerID,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      const jsonBody = JSON.parse(result.body);
      if (jsonBody.length === 0) {
        console.log('CustomerID not Found');
        props.setScheduleData([]);
        props.setCustomerData({
          CustomerID: customerID,
          CustName: 'Unknown',
          CustAddress: 'Unknown',
          CustPostcode: 'Unknown',
          CustomerEmail: 'Unknown',
          CustomerPhone: '0',
          CustomerMobile: '0',
        });
      } else {
        props.setScheduleData(jsonBody);
        props.setCustomerData({
          CustomerID: jsonBody[0].CustomerID,
          CustName: toTitleCase(jsonBody[0].CustomerName),
          CustAddress: handleBackwardsAddress(jsonBody[0].CustomerAddress),
          CustPostcode: jsonBody[0].CustomerPostcode,
          CustomerEmail: jsonBody[0].CustomerEmail,
          CustomerPhone: jsonBody[0].CustomerPhone,
          CustomerMobile: jsonBody[0].CustomerMobile,
        });
        updateClaimsData(jsonBody);
      }
    }).catch( (error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    });
  };

  const getDealerList = () => {
    API.get('Saltmine-Van-API-Aux', '/dealer', {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      const jsonBody = JSON.parse(result.body);
      setDealerList(jsonBody);
    }).catch( (error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    });
  };

  const updateFlexData = async (claimsData) => {
    const flexResponse = await getFlexData(scheduleID, props.setSnackbarProps);
    setFlexData(flexResponse);
    const unLinkedClaimsData = unLinkedClaimsRefresh(flexResponse, claimsData, undefined, 'updateFlexData');
    setUnlinkedClaims(unLinkedClaimsData);

    clearInterval(loadingAnimationID.current);
    setLoadingInProgress(false);
  };

  const updateClaimsData = async (scheduleArray) => {
    setClaimLastUpdate(await API.get('Saltmine-Van-API-Claims', '/getlastupdate', {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      if (result.body !== undefined) {
        return (result.body);
      } else {
        props.setSnackbarProps({ open: true,
          message: 'Something went wrong: ' + result.statusCode,
          severity: 'error' });
      }
    }).catch((error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    }));

    const scheduleVin = scheduleArray.filter((item)=>(item.ScheduleID === scheduleID))[0]?.ChassisNo;

    if (scheduleVin === undefined) {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: VIN Missing From Schedule',
        severity: 'error' });
      return;
    }

    const claimsResponse = await getClaimsData(scheduleVin, props.setSnackbarProps);
    const index = scheduleArray.findIndex(
        (schedulefind) => schedulefind.ScheduleID === scheduleID);
    scheduleArray[index] = {
      ...scheduleArray[index],
      ClaimsData: claimsResponse,
    };
    props.setScheduleData(scheduleArray);
    updateFlexData(claimsResponse);
  };

  const performBack = () => {
    navigate(-1);
  };

  const addEmailSentHistoryItem = async (eventEmailStates) => {
    let body = {
      ScheduleID: selectedSchedule().ScheduleID,
      Status: 'History',
      CustomerName: selectedSchedule().CustomerName,
      ScheduleStatus: selectedSchedule().Status,
      CustomerID: selectedSchedule().CustomerID,
      CustomerCofico: selectedSchedule().CustomerCofico,
    };
    if (eventEmailStates.sendAsPDF) {
      body = {
        ...body,
        ActionType: 'LET',
        Reference: 'Letter Sent: \n\n' + eventEmailStates.emailBody,
      };
    } else {
    // Log EML item
      body = {
        ...body,
        ActionType: 'EML',
        Reference: generateEMLReference(eventEmailStates.groupMailbox, eventEmailStates.emailSentTo,
            eventEmailStates.emailSubject, eventEmailStates.emailBody),
      };
    }

    const response = await createAndCompletePendingItem(body,
        getCurrentUser(props.currUser), props.pendingData, props.setPendingData, props.setSnackbarProps);

    if (response.status === 200) {
      console.log('Success');
    }

    if (eventEmailStates.emailSubject.substring(0, 28) === 'Cancellation Acknowledgement') {
      const body2 = {
        ScheduleID: selectedSchedule().ScheduleID,
        Status: 'Pending',
        ActionType: 'TRM',
        CustomerName: selectedSchedule().CustomerName,
        ScheduleStatus: selectedSchedule().Status,
        Reference: 'Cancellation Acknowledgement: ' + selectedCancellationReason,
        CustomerID: selectedSchedule().CustomerID,
        userId: getCurrentUser(props.currUser),
        ScheduledDate: null,
        CustomerCofico: selectedSchedule().CustomerCofico,
      };
      const response2 = await createPendingItem(body2,
          getCurrentUser(props.currUser),
          response.updatedPendingData,
          props.setPendingData,
          props.setSnackbarProps);

      if (response2.status === 200) {
        console.log('Success');
      }
    }

    if (eventEmailStates.emailSubject.substring(0, 18) === 'Cancellation Quote') {
      const body2 = {
        ScheduleID: selectedSchedule().ScheduleID,
        Status: 'Pending',
        ActionType: 'TQT',
        CustomerName: selectedSchedule().CustomerName,
        ScheduleStatus: selectedSchedule().Status,
        Reference: 'Termination Quote: ' + selectedCancellationReason,
        CustomerID: selectedSchedule().CustomerID,
        userId: getCurrentUser(props.currUser),
        ScheduledDate: null,
        CustomerCofico: selectedSchedule().CustomerCofico,
      };
      const response2 = await createPendingItem(body2,
          getCurrentUser(props.currUser),
          response.updatedPendingData,
          props.setPendingData,
          props.setSnackbarProps);

      if (response2.status === 200) {
        console.log('Success');
      }
    }

    setEmailStates({
      emailSentTo: props.customerData.CustomerEmail,
      emailSubject: '',
      emailBody: '',
      sendAsPDF: false,
      sendAction: ()=>{},
    });
  };

  // Email Popup Functions
  const [sendingEmail, setSendingEmail] = React.useState(false);
  const [emailStates, setEmailStates] = React.useState({
    emailSentTo: props.customerData.CustomerEmail,
    emailSubject: '',
    emailBody: '',
    sendAsPDF: false,
    sendAction: ()=>{},
  });
  const sendEmailFromCustomerDetails = () => {
    if (emailStates.emailBody === '') {
      setEmailStates({
        emailSentTo: props.customerData.CustomerEmail,
        emailSubject: 'Mercedes-Benz Service Contract - ' + selectedSchedule().ScheduleID + ' - ' +
          selectedSchedule().Registration,
        emailBody: '\n\n' + props.emailConfigData.signature,
        sendAsPDF: false,
        sendAction: addEmailSentHistoryItem,
      });
    }
    setSendingEmail(true);
  };

  // Selecting Claims
  const [selectedClaimRow, setSelectedClaimRow] = React.useState(-1);
  const [selectedClaim, setSelectedClaim] = React.useState({});

  const handleClaimRowClick = (row, index) => {
    setSelectedClaim(row);
    setSelectedClaimRow(index);
  };

  const calculateNextMilage = () => {
    const max = Math.max(...flexData.map((o) => o.mileage || 0), 0);
    if (max >= 10000) {
      return max + 1;
    } else {
      return 10000;
    }
  };

  const linkClaim = async (flexRecord) => {
    if (selectedClaimRow === -1) {
      props.setSnackbarProps({ open: true,
        message: 'Please select a claim to link.',
        severity: 'info' });
      return;
    }
    const body = {
      ScheduleID: flexRecord.ScheduleID,
      UUID: flexRecord.UUID,
      ConnectedClaim: selectedClaim.ChassisNo + selectedClaim.IndexDateOfClaimMileage,
      ConnectedClaimDetails: selectedClaim,
    };
    // getCurrentUser(props.currUser),
    const response = await updateFlexItem(body, props.setSnackbarProps);

    if (response.status === 200) {
      const flexItemIndex = flexData.indexOf(flexRecord);
      const tempFlexData = [...flexData];
      tempFlexData[flexItemIndex] = {
        ...flexRecord,
        ConnectedClaim: selectedClaim.ChassisNo + selectedClaim.IndexDateOfClaimMileage,
        ConnectedClaimDetails: selectedClaim,
      };
      setFlexData(tempFlexData);
      setUnlinkedClaims(unLinkedClaimsRefresh(tempFlexData, undefined, undefined, 'linkClaim'));
      setSelectedClaim({});
      setSelectedClaimRow(-1);
    }
  };

  const unLinkClaim = async (flexRecord) => {
    const body = {
      ScheduleID: flexRecord.ScheduleID,
      UUID: flexRecord.UUID,
      ConnectedClaim: 'None',
      ConnectedClaimDetails: {},
    };
    // getCurrentUser(props.currUser),
    const response = await updateFlexItem(body, props.setSnackbarProps);

    if (response.status === 200) {
      const flexItemIndex = flexData.indexOf(flexRecord);
      const tempFlexData = [...flexData];
      tempFlexData[flexItemIndex] = {
        ...flexRecord,
        ConnectedClaim: 'None',
        ConnectedClaimDetails: {},
      };
      setFlexData(tempFlexData);
      setUnlinkedClaims(unLinkedClaimsRefresh(tempFlexData, undefined, undefined, 'unlinkClaim'));
      setSelectedClaim({});
      setSelectedClaimRow(-1);
    }
  };

  const flipCurrentValue = (value) => {
    if (value !== true) {
      return true;
    }
    return false;
  };

  const denyClaim = async () => {
    if (selectedClaimRow === -1) {
      props.setSnackbarProps({ open: true,
        message: 'Please select a claim to link.',
        severity: 'info' });
      return;
    }

    const tempScheduleData = [...props.scheduleData];
    const selectedScheduleIndex = tempScheduleData.findIndex(
        (item)=>item.ScheduleID === selectedSchedule().ScheduleID);
    const selectedClaimIndex = tempScheduleData[selectedScheduleIndex].ClaimsData.findIndex(
        (claim)=>claim.IndexDateOfClaimMileage === selectedClaim.IndexDateOfClaimMileage);
    const newDeniedValue = flipCurrentValue(
        props.scheduleData[selectedScheduleIndex].ClaimsData[selectedClaimIndex].Denied);

    const response = await API.post('Saltmine-Van-API-Claims', '/updateClaim', {
      body: {
        ChassisNo: selectedClaim.ChassisNo,
        IndexDateOfClaimMileage: selectedClaim.IndexDateOfClaimMileage,
        Denied: newDeniedValue,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      return result;
    }).catch((error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
      return error;
    });

    if (response.body?.Attributes?.Denied !== undefined) {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ',
        severity: 'error' });
      return;
    }

    tempScheduleData[selectedScheduleIndex].ClaimsData[selectedClaimIndex] = {
      ...tempScheduleData[selectedScheduleIndex].ClaimsData[selectedClaimIndex],
      Denied: newDeniedValue,
    };
    props.setScheduleData(tempScheduleData);

    // Set to new state
    setUnlinkedClaims(unLinkedClaimsRefresh(flexData, undefined, undefined, 'deny Claim'));
    setSelectedClaim({});
    setSelectedClaimRow(-1);
  };

  // Add Record Popup Functions
  const [addingRecord, setAddingRecord] = React.useState(false);
  const [addingRecordFields, setAddingRecordFields] = React.useState({
    ScheduleID: scheduleID,
    UUID: 'New',
    dealerRequestDate: new Date().setUTCHours(0, 0, 0, 0),
    dealerRequestor: '',
    mileage: calculateNextMilage(),
    serviceRegistration: '',
    serviceType: '',
    modelType: '',
    ConnectedClaim: 'None',
    ConnectedClaimDetails: {},
  });

  const handleRecordFieldsChange = (event) => {
    if (event.target === undefined) {
      setAddingRecordFields({
        ...addingRecordFields,
        dealerRequestDate: event.setUTCHours(0, 0, 0, 0),
      });
    } else {
      if (event.target.name === 'serviceRegistration') {
        setAddingRecordFields({
          ...addingRecordFields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      } else if (event.target.name === 'mileage') {
        if (checkWholeNumber(event.target.value)) {
          setAddingRecordFields({
            ...addingRecordFields,
            [event.target.name]: Number(event.target.value),
          });
        } else {
          props.setSnackbarProps({ open: true,
            message: 'You may only enter numbers.',
            severity: 'info' });
        }
      } else if (event.target.name === 'autoChecked') {
        setAddingRecordFields({
          ...addingRecordFields,
          [event.target.name]: !addingRecordFields.autoChecked || false,
        });
      } else {
        setAddingRecordFields({
          ...addingRecordFields,
          [event.target.name]: event.target.value,
        });
      }
    }
  };

  const addNewFlexRecord = () => {
    checkActiveContract();
    const flexDataMain = getMainFlexData();
    const flexServicesA = flexData.filter((item) => item.serviceType === 'A').length;
    const flexServicesB = flexData.filter((item) => item.serviceType === 'B').length;

    if (flexDataMain.TotalServices - flexServicesA - flexServicesB <= 0) {
      props.setSnackbarProps({ open: true,
        message: 'No more Claims are available.',
        severity: 'error',
      });
      return;
    }

    if (addingRecordFields.UUID !== 'New') {
      setAddingRecordFields({
        ScheduleID: scheduleID,
        UUID: 'New',
        dealerRequestDate: new Date().setUTCHours(0, 0, 0, 0),
        dealerRequestor: '',
        mileage: calculateNextMilage(),
        serviceRegistration: '',
        serviceType: '',
        modelType: '',
        ConnectedClaim: 'None',
        ConnectedClaimDetails: {},
      });
    } else {
      setAddingRecordFields({
        ...addingRecordFields,
        mileage: calculateNextMilage(),
      });
    }
    setAddingRecord(true);
  };

  const editFlexRecord = (flexRecord) => {
    checkActiveContract();
    setAddingRecordFields(flexRecord);
    setAddingRecord(true);
  };

  const deleteFlexRecord = async () => {
    checkActiveContract();
    if (addingRecordFields.UUID === 'New') {
      setAddingRecord(false);
      setAddingRecordFields({
        ScheduleID: scheduleID,
        UUID: 'New',
        dealerRequestDate: new Date().setUTCHours(0, 0, 0, 0),
        dealerRequestor: '',
        mileage: calculateNextMilage(),
        serviceRegistration: '',
        serviceType: '',
        modelType: '',
        ConnectedClaim: 'None',
        ConnectedClaimDetails: {},
      });
    } else {
      // Call Delete API Here
      setAddingRecord(false);
      const body = {
        ScheduleID: addingRecordFields.ScheduleID,
        UUID: addingRecordFields.UUID,
      };
      const response = await deleteFlexItem(body, props.setSnackbarProps);

      if (response.status === 200) {
        const flexItemIndex = flexData.findIndex((item) => item.UUID === addingRecordFields.UUID);
        const tempFlexData = [...flexData];
        tempFlexData.splice(flexItemIndex, 1);
        setFlexData(tempFlexData);
        setUnlinkedClaims(unLinkedClaimsRefresh(tempFlexData, undefined, undefined, 'delete flex'));
        setAddingRecord(false);
      }
    }
  };

  const saveFlexRecord = async () => {
    if (addingRecordFields.dealerRequestor === '') {
      props.setSnackbarProps({ open: true,
        message: 'Please enter the requesting dealer.',
        severity: 'info' });
      return;
    }
    if (addingRecordFields.serviceType === '') {
      props.setSnackbarProps({ open: true,
        message: 'Please enter the service type.',
        severity: 'info' });
      return;
    }
    if (addingRecordFields.mileage === '') {
      props.setSnackbarProps({ open: true,
        message: 'Please enter the mileage.',
        severity: 'info' });
      return;
    }
    if (addingRecordFields.serviceRegistration === '') {
      props.setSnackbarProps({ open: true,
        message: 'Please enter the service registration.',
        severity: 'info' });
      return;
    }
    if (addingRecordFields.serviceType === 'A') {
      const existingArecord = flexData.filter((item) => item.UUID === addingRecordFields.UUID &&
      item.serviceType === 'A').length;
      const flexServicesA = flexData.filter((item) => item.serviceType === 'A').length;

      if (aServicesAvailable() - flexServicesA + existingArecord <= 0) {
        props.setSnackbarProps({ open: true,
          message: 'No more A Claims are available.',
          severity: 'info',
        });
        return;
      }
    }
    if (addingRecordFields.serviceType === 'B') {
      const existingBrecord = flexData.filter((item) => item.UUID === addingRecordFields.UUID &&
      item.serviceType === 'B').length;
      const flexServicesB = flexData.filter((item) => item.serviceType === 'B').length;

      if (bServicesAvailable() - flexServicesB + existingBrecord <= 0) {
        props.setSnackbarProps({ open: true,
          message: 'No more B Claims are available.',
          severity: 'info',
        });
        return;
      }
    }
    if (!isActiveSchedule(selectedSchedule().Status)) {
      if (!addingRecordFields.activeChecked) {
        props.setSnackbarProps({ open: true,
          message: 'This schedule has ended, please press the checkbox to confirm you have understood this.',
          severity: 'error',
        });
        return;
      }
    }

    // Call Save API
    if (addingRecordFields.UUID === 'New') {
      const response = await createFlexItem(addingRecordFields, props.setSnackbarProps);

      if (response.status === 200) {
        const addedRecord = {
          ...addingRecordFields,
          UUID: response.id,
        };
        const tempFlexData = [...flexData, addedRecord];
        setFlexData(tempFlexData);
        setUnlinkedClaims(unLinkedClaimsRefresh(tempFlexData, undefined, undefined, 'save flex'));
        setAddingRecordFields({
          ScheduleID: scheduleID,
          UUID: 'New',
          dealerRequestDate: new Date().setUTCHours(0, 0, 0, 0),
          dealerRequestor: '',
          mileage: calculateNextMilage(),
          serviceRegistration: '',
          serviceType: '',
          modelType: '',
          ConnectedClaim: 'None',
          ConnectedClaimDetails: {},
        });
        setAddingRecord(false);
      }
    } else {
      const response = await updateFlexItem(addingRecordFields, props.setSnackbarProps);

      if (response.status === 200) {
        const flexItemIndex = flexData.findIndex((item) => item.UUID === addingRecordFields.UUID);
        const tempFlexData = [...flexData];
        tempFlexData[flexItemIndex] = addingRecordFields;
        setFlexData(tempFlexData);
        setUnlinkedClaims(unLinkedClaimsRefresh(tempFlexData, undefined, undefined, 'saveFlex'));
        setAddingRecord(false);
      }
    }
  };

  // Updating Main Flex Functionality
  const [updatingMainRecord, setUpdatingMainRecord] = React.useState(false);
  const [mainRecordFields, setMainRecordFields] = React.useState({
    ScheduleID: scheduleID,
    UUID: 'Main',
    TotalServices: '',
    AServices: '',
    BServices: '',
    autoChecked: false,
    AutoServices: '',
  });

  const updateMainFlex = () => {
    const flexMain = flexData.filter((item) => item.UUID === 'Main');
    if (flexMain.length !== 0) {
      setMainRecordFields({
        ...mainRecordFields,
        ...flexMain[0],
      });
    }
    setUpdatingMainRecord(true);
  };

  const handleMainRecordFieldsChange = (event) => {
    if (event.target.name === 'autoChecked') {
      setMainRecordFields({
        ...mainRecordFields,
        autoChecked: !mainRecordFields.autoChecked,
      });
    } else {
      if (checkWholeNumber(event.target.value)) {
        let value = parseInt(event.target.value);
        if (isNaN(value)) {
          value = '';
        }
        handleServicesChange(value, event.target.name);
      } else {
        props.setSnackbarProps({ open: true,
          message: 'You may only enter whole numbers.',
          severity: 'info',
        });
      }
    }
  };

  const handleServicesChange = (value, name) => {
    if (name === 'TotalServices') {
      setMainRecordFields({
        ...mainRecordFields,
        TotalServices: value,
        AServices: Math.ceil(value / 2),
        BServices: Math.floor(value / 2),
      });
      return;
    }
    if (name === 'AServices') {
      if (value > mainRecordFields.TotalServices) {
        props.setSnackbarProps({ open: true,
          message: 'You cannot have more services allocated than the total services.',
          severity: 'info',
        });
        setMainRecordFields({
          ...mainRecordFields,
          AServices: mainRecordFields.TotalServices,
          BServices: 0,
        });
        return;
      }
      setMainRecordFields({
        ...mainRecordFields,
        AServices: value,
        BServices: mainRecordFields.TotalServices - value,
      });
      return;
    }
    if (name === 'BServices') {
      if (value > mainRecordFields.TotalServices) {
        props.setSnackbarProps({ open: true,
          message: 'You cannot have more services allocated than the total services.',
          severity: 'info',
        });
        setMainRecordFields({
          ...mainRecordFields,
          AServices: 0,
          BServices: mainRecordFields.TotalServices,
        });
        return;
      }
      setMainRecordFields({
        ...mainRecordFields,
        AServices: mainRecordFields.TotalServices - value,
        BServices: value,
      });
      return;
    }
  };

  const saveMainRecord = async () => {
    const response = await createFlexItem(mainRecordFields, props.setSnackbarProps);

    if (response.status === 200) {
      const index = flexData.findIndex(
          (mainFind) => mainFind.UUID === 'Main');
      if (index === -1) {
        setFlexData([...flexData, mainRecordFields]);
      } else {
        const tempFlexData = [...flexData];
        tempFlexData[index] = mainRecordFields;
        setFlexData(tempFlexData);
      }
      setUpdatingMainRecord(false);
    }
  };

  // Reporting Functionality
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const exportFlexRecords = () => {
    const exportingFlexData = flexData.filter((item) => item.UUID !== 'Main');
    for (let i=0; i<exportingFlexData.length; i++) {
      exportingFlexData[i] = staticColumnOrder(exportingFlexData[i]);
    }

    const ws = XLSX.utils.json_to_sheet(exportingFlexData);
    const dateColumns = [];
    for (const cell in ws) {
      if (splitExcelCellString(cell).row === '1') {
        if (ws[cell].v.toLowerCase().includes('date')) {
          dateColumns.push(splitExcelCellString(cell).column);
        }
      }
    }

    for (let j = 0; j < dateColumns.length; j++) {
      for (let i = 0; i < exportingFlexData.length; i++) {
        if (ws[dateColumns[j] + (i+2).toString()] !== undefined) {
          ws[dateColumns[j] + (i + 2).toString()].z = 'dd/mm/yyyy';
        }
      }
    }

    const wb = { Sheets: { 'Data': ws }, SheetNames: ['Data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    createExcelFile(data, 'Saltmine-Van Flex Report ' + scheduleID + ' .xlsx');
  };

  const splitExcelCellString = (excelCell) => {
    const numberPosition = excelCell.search(/\d/);
    return { column: excelCell.substring(0, numberPosition),
      row: excelCell.substring(numberPosition, excelCell.length) };
  };

  const createExcelFile = (data, name) => {
    let textFile = null;

    textFile = window.URL.createObjectURL(data);

    const link = document.createElement('a');
    link.href = textFile;
    link.style.display = 'block';
    link.download = name;
    link.id = 'DownloadLink';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const staticColumnOrder = (object) => {
    return ({
      ScheduleID: object.ScheduleID,
      RequestDate: convertDateToExcel(object.dealerRequestDate),
      DealerRequestor: object.dealerRequestor,
      Registration: object.serviceRegistration,
      ClaimType: object.serviceType,
      AutoIncluded: object.autoChecked,
      Mileage: object.mileage,
      ClaimTotal: object.ConnectedClaimDetails?.ClaimTotalAmount,
      ClaimOther: object.ConnectedClaimDetails?.ClaimOtherAmount,
      ClaimLabour: object.ConnectedClaimDetails?.ClaimLabourAmount,
      ClaimParts: object.ConnectedClaimDetails?.ClaimPartsAmount,
    });
  };

  const convertDateToExcel = (value) => {
    if (value === undefined || value === null || value === '') {
      return value;
    } else {
      return (parseInt(value)/86400000) + 25569;
    }
  };

  const checkActiveContract = () => {
    if (!isActiveSchedule(selectedSchedule().Status)) {
      props.setSnackbarProps({ open: true,
        message: 'This Schedule is no longer live.',
        severity: 'info',
      });
      return false;
    }
  };

  const manageClaimsClicked = () => {
    setManageClaims(!manageClaims);
    setUnlinkedClaims(unLinkedClaimsRefresh(flexData, undefined, !manageClaims, 'manageClaims'));
  };

  return (
    <Container component='main' sx={sxClasses.mainStyle} maxWidth={false}>
      {props.customerData.CustomerID === '' ?
      <div>
        Loading...
      </div> :
      <React.Fragment>
        <Grid container spacing={0}>
          <Grid item xs={6} >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <CustomerDetails customerData={props.customerData} scheduleData={selectedSchedule()}
                      sendEmailFromCustomerDetails={sendEmailFromCustomerDetails} />
                  </Grid>
                  <Grid item xs= {7} sx={sxClasses.contentSectionsNoBorderNoSpacing}>
                    <FlexContractDetails
                      flexDataMain={getMainFlexData()}
                      flexDataRecords={flexData}
                      claimsData={unLinkedClaims}
                      scheduleData={selectedSchedule()}
                      updateMainFlex={updateMainFlex}
                      aServicesAvailable={aServicesAvailable}
                      bServicesAvailable={bServicesAvailable}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {loadingInProgress ? loadingTextState :
                  <FlexContractRecords
                    flexData={flexData}
                    setSnackbarProps={props.setSnackbarProps}
                    linkClaim={linkClaim}
                    unLinkClaim={unLinkClaim}
                    editFlexRecord={editFlexRecord}
                    exportFlexRecords={exportFlexRecords}/>
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} >
            <Grid container spacing={0}>
              <Grid item xs={12} component={Paper} sx={sxClasses.contentSections} >
                <ScheduleDetails scheduleData={selectedSchedule()} />
              </Grid>
              <Grid item xs= {12}>
                <FlexClaimsData claimsData={unLinkedClaims}
                  claimLastUpdate={claimLastUpdate}
                  selectedClaimRow={selectedClaimRow}
                  handleClaimRowClick={handleClaimRowClick}
                />
              </Grid>
              <Grid item xs={12} >
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    <Button sx={sxClasses.buttonStyle} variant='contained' color='primary'
                      onClick={() => {
                        addNewFlexRecord();
                      }}>
                      Add
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    {manageClaims &&
                      <Button sx={sxClasses.buttonStyle} variant='contained' color='primary'
                        onClick={() => {
                          denyClaim();
                        }}>
                        {!selectedClaim.Denied ? 'Mark Denied' : 'Remove Denied' }
                      </Button>
                    }
                  </Grid>
                  <Grid item xs={6} sx={sxClasses.exitContainerStyle}>
                    <Button sx={sxClasses.buttonStyle} variant='contained' color='primary'
                      onClick={() => {
                        performBack();
                      }}>
                      Exit
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button sx={sxClasses.buttonStyle} variant='contained' color='primary'
                      onClick={manageClaimsClicked}>
                      {!manageClaims ? 'Manage Claims' : 'Cancel' }
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <EmailPopup
          popupOpen={sendingEmail}
          setPopupOpen={setSendingEmail}
          emailStates={emailStates}
          setEmailStates={setEmailStates}
          setSnackbarProps={props.setSnackbarProps}
          customerData={props.customerData}
          scheduleData={selectedSchedule()}
          currUser={props.currUser}
          emailConfigData={props.emailConfigData}/>
        <FlexRecordsAddPopup
          popupOpen={addingRecord}
          setPopupOpen={setAddingRecord}
          addingRecordFields={addingRecordFields}
          handleRecordFieldsChange={handleRecordFieldsChange}
          deleteFlexRecord={deleteFlexRecord}
          saveFlexRecord={saveFlexRecord}
          autoChecked={flexData.filter((item) => item.UUID === 'Main')[0]?.autoChecked || false}
          existingFlexData={flexData}
          activeRecord={isActiveSchedule(selectedSchedule().Status)}
          dealerList={dealerList}/>
        <FlexRecordsUpdateMainPopup
          popupOpen={updatingMainRecord}
          setPopupOpen={setUpdatingMainRecord}
          mainRecordFields={mainRecordFields}
          handleMainRecordFieldsChange={handleMainRecordFieldsChange}
          saveMainRecord={saveMainRecord}/>
      </React.Fragment>
      }
    </Container>
  );
}
