import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Slide from '@mui/material/Slide';
import { makeStyles } from '@mui/styles';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function AppSnackBar({ snackbarProps, setSnackbarProps, handleSnackBarClose }) {
  const classes = useStyles();

  AppSnackBar.propTypes = {
    setSnackbarProps: PropTypes.func.isRequired,
    snackbarProps: PropTypes.shape({
      open: PropTypes.bool.isRequired,
      message: PropTypes.string.isRequired,
      severity: PropTypes.string.isRequired,
    }),
    handleSnackBarClose: PropTypes.func.isRequired,
  };

  // <Alert severity="error">This is an error message!</Alert>
  // <Alert severity="warning">This is a warning message!</Alert>
  // <Alert severity="info">This is an information message!</Alert>
  // <Alert severity="success">This is a success message!</Alert>

  const vertical = 'bottom';
  const horizontal = 'center';

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={snackbarProps.open} autoHideDuration={6000} onClose={handleSnackBarClose}
        anchorOrigin={{ vertical, horizontal }}
        TransitionComponent={SlideTransition}>
        <Alert onClose={handleSnackBarClose} severity={snackbarProps.severity} >
          <AlertTitle>{capitalizeFirstLetter(snackbarProps.severity)}</AlertTitle>
          {snackbarProps.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
