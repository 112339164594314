// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { API } from 'aws-amplify';
// Components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import FullWidthButton from '../Components/StyledMUIComponents/FullWidthButton';
import TitlePaper from '../Components/StyledMUIComponents/TitlePaper';
import FrontSearch from '../FunctionalComponents/FrontSearch';
import FrontPendingItem from '../Components/FrontPendingItem';
import Paper from '@mui/material/Paper';
import { checkAdminGroup, sortArrayByProperty, checkReadOnlyGroup } from '../Libs/fileHelper';
import { getAndExportFlexReportData } from '../Libs/reportingHelper';
import config from '../Libs/Config';
import { currentStage, getCurrentEnvironment } from '../Libs/StageConfig';
// Resources

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.palette.primary.backgroundBack,
    maxWidth: '1280px',
  },
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#ffffff',
    backgroundColor: theme.palette.primary.main,
    border: '1px solid',
    borderColor: '#000000',
  },
  contentSections: {
    'marginTop': '26px',
    'border': '1px solid',
    'borderColor': '#000000',
    'padding': theme.spacing(3),
    'textAlign': 'center',
    'backgroundColor': theme.palette.primary.backgroundFore,
  },
  contentSectionsLeft: {
    'marginTop': '26px',
    'height': '85vh',
    'border': '1px solid',
    'borderColor': '#000000',
    'padding': theme.spacing(3),
    'textAlign': 'center',
    'overflow-y': 'scroll',
    'backgroundColor': theme.palette.primary.backgroundFore,
  },
  titleFields: {
    'position': 'absolute',
    'marginTop': '8px',
    'marginLeft': '25px',
  },
  queuesHeader: {
    marginBottom: theme.spacing(2),
  },
}));

export default function FrontPage(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  FrontPage.propTypes = {
    currUser: PropTypes.object.isRequired,
    setScheduleData: PropTypes.func.isRequired,
    setCustomerData: PropTypes.func.isRequired,
    setPendingData: PropTypes.func.isRequired,
    actionCodeLookup: PropTypes.array.isRequired,
    setActionCodeLookup: PropTypes.func.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
  };

  const [actionCodeData, setActionCodeData] = React.useState([]);
  const [loadingTextState, setLoadingTextState] = React.useState('Loading');
  const loadingAnimationID = React.useRef(0);

  const navigateToReporting = () => {
    navigate('/Reporting');
  };

  const navigateToAdmin = () => {
    navigate('/Admin');
  };

  const navigateToWorkAssignment = () => {
    navigate('/WorkAssignment');
  };

  const navigateToDec = () => {
    navigate('/DecProgress');
  };

  const refreshActionCodeData = () => {
    API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory.NAME,
        '/scanactiontypes', {
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((result) => {
      processResults(result);
    }).catch( (error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    });
  };

  function processResults(result) {
    if (result.body === undefined) {
      props.setSnackbarProps({ open: true,
        message: 'No Results Found',
        severity: 'warning' });
      return;
    } else {
      const resultJSON = JSON.parse(result.body);
      let buildingPending = [];
      for (const key of Object.keys(resultJSON)) {
        buildingPending = [...buildingPending, {
          actionCode: key, actionDesc: 'Unknown Code: ' + key,
          actionTotal: resultJSON[key].countTotal, oldestRecord: resultJSON[key].oldestRecord }];
      }
      refreshActionDesc(buildingPending);

      if (result.LastEvaluatedKey !== undefined && result.LastEvaluatedKey !== '') {
        // More results to get
        refreshAdditionalActionCodes(
            result.LastEvaluatedKey.ID, result.LastEvaluatedKey.ActionType, buildingPending,
        );
      }
    }
  }

  const refreshAdditionalActionCodes = (lastEvaluatedKeyID, lastEvaluatedKeyActionType, buildingPending) => {
    API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory.NAME,
        '/scanactiontypes', {
          queryStringParameters: {
            ExclusiveStartKeyID: lastEvaluatedKeyID,
            ExclusiveStartKeyActionType: lastEvaluatedKeyActionType,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((result) => {
      // const resultJSON = JSON.parse(result.body);
      processAdditionalResults(result, buildingPending, lastEvaluatedKeyID);
    }).catch( (error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    });
  };

  const refreshActionDesc = (buildingPending) => {
    API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.ActionType.NAME, '/', {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      props.setActionCodeLookup(JSON.parse(result.body));

      for (const lines of JSON.parse(result.body)) {
        const index = buildingPending.findIndex((penditem) => penditem.actionCode === lines.ActionType);
        if (index !== -1) {
          buildingPending[index] = { ...buildingPending[index], actionDesc: lines.ActionDescription,
            SLA: lines.SLA };
        }
      }
      clearInterval(loadingAnimationID.current);
      setActionCodeData(sortArrayByProperty(buildingPending, 'actionCode'));
    }).catch( (error) => {
      props.setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    });
  };

  const processAdditionalResults = (result, buildingPending, lastEvaluatedKeyID) => {
    const resultJSON = JSON.parse(result.body);

    for (const key of Object.keys(resultJSON)) {
      const index = buildingPending.findIndex((penditem) => penditem.actionCode === key);
      if (index !== -1) {
        buildingPending[index] = { ...buildingPending[index],
          actionTotal: buildingPending[index].actionTotal + resultJSON[key] };
      } else {
        buildingPending = [...buildingPending, {
          actionCode: key, actionDesc: 'Unknown Code: ' + key, actionTotal: resultJSON[key] }];
      }
    }

    // Refresh with descritions once loaded
    if (props.actionCodeLookup !== []) {
      for (const lines of props.actionCodeLookup) {
        const index = buildingPending.findIndex((penditem) => penditem.actionCode === lines.ActionType);
        if (index !== -1) {
          buildingPending[index] = { ...buildingPending[index], actionDesc: lines.ActionDescription,
            SLA: lines.SLA };
        }
      }
    }

    clearInterval(loadingAnimationID.current);
    setActionCodeData(sortArrayByProperty(buildingPending, 'actionCode'));

    if (result.LastEvaluatedKey !== undefined && result.LastEvaluatedKey !== '') {
      // More results to get
      if (result.LastEvaluatedKey.ID !== lastEvaluatedKeyID) {
        refreshAdditionalActionCodes(
            result.LastEvaluatedKey.ID, result.LastEvaluatedKey.ActionType, buildingPending,
        );
      }
    }
  };

  const queueTotalCount = () => {
    let totalCount = 0;
    for (const records of actionCodeData) {
      totalCount = totalCount + records.actionTotal;
    }
    return totalCount;
  };

  const loadingText = () => {
    let i = 0;

    loadingAnimationID.current = setInterval(() => {
      i+= 1;
      const newLoadingText = loadingTextState + Array(i+1).join('.');
      setLoadingTextState(newLoadingText);
    }, 300);
  };

  React.useEffect(() => {
    loadingText();
    refreshActionCodeData();
  }, [] );

  return (
    <Container component='main' className={classes.main} maxWidth="1080px">
      <Grid container spacing={3}>
        <Grid item xs={6} >
          <TitlePaper className={classes.titleFields} >
            Activities
          </TitlePaper>
          <Paper className={classes.contentSectionsLeft} >
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    Action Code
                  </Grid>
                  <Grid item xs= {6}>
                    Action Description
                  </Grid>
                  <Grid item xs= {3}>
                    Action Amount
                  </Grid>
                </Grid>
              </Grid>
              {actionCodeData.length === 0 && loadingTextState}
              {actionCodeData.map((pendingGroup, i) => {
                if (!pendingGroup) {
                  // No data
                  return (null);
                } else {
                  return (
                    <FrontPendingItem
                      key={i}
                      count={i}
                      pendingGroup={pendingGroup}
                      navigate={navigate}
                    />
                  );
                }
              })}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6} >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TitlePaper className={classes.titleFields}>
              Queues
              </TitlePaper>
              <Paper className={classes.contentSections} >
                <div className={classes.queuesHeader}>Total In Queues: {queueTotalCount()}</div>
                <Grid container spacing={1}>
                  {checkAdminGroup(props.currUser) ?
                    <React.Fragment>
                      <Grid item xs={6}>
                        <FullWidthButton variant='contained' color='primary'
                          onClick={navigateToReporting}>
                        Reporting
                        </FullWidthButton>
                      </Grid>
                      <Grid item xs={6}>
                        <FullWidthButton variant='contained' color='primary'
                          onClick={navigateToWorkAssignment}>
                        Work Assignment
                        </FullWidthButton>
                      </Grid>
                      <Grid item xs={6}>
                        <FullWidthButton variant='contained' color='primary'
                          onClick={navigateToAdmin}>
                        Admin Page
                        </FullWidthButton>
                      </Grid>
                    </React.Fragment> :
                  null }
                  {!checkReadOnlyGroup(props.currUser) ?
                    <Grid item xs={6}>
                      <FullWidthButton variant='contained' color='primary'
                        onClick={navigateToDec}>
                        DEC Process
                      </FullWidthButton>
                    </Grid>:
                    <Grid item xs={6}>
                      <FullWidthButton variant='contained' color='primary'
                        onClick={() => {
                          getAndExportFlexReportData(0, '', props.setSnackbarProps, []);
                        }}>
                        Flex Data Extract
                      </FullWidthButton>
                    </Grid>
                  }
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} >
              <TitlePaper className={classes.titleFields}>
                Search
              </TitlePaper>
              <Paper className={classes.contentSections} >
                <FrontSearch
                  setSnackbarProps={props.setSnackbarProps}
                  setCustomerData={props.setCustomerData}
                  setScheduleData={props.setScheduleData}
                  setPendingData={props.setPendingData} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
