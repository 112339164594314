// import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ExitButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  width: '150px',
  right: '50px',
  bottom: '25px',
}));

export default ExitButton;
