// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
// Components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ExitButton from '../Components/StyledMUIComponents/ExitButton';
import DecResultsPendingItem from '../Components/DeceasedPage/DecResultsPendingItem';
import Paper from '@mui/material/Paper';
import TitlePaper from '../Components/StyledMUIComponents/TitlePaper';
import { getResultsForActionCode } from '../Libs/apiHelper';
// Resources

const useStyles = makeStyles((theme) => ({
  contentSectionsLeft: {
    'marginTop': '50px',
    'height': '80vh',
    'border': '1px solid',
    'borderColor': '#000000',
    'padding': theme.spacing(3),
    'textAlign': 'center',
    'overflow': 'scroll',
  },
  titleFields: {
    'position': 'absolute',
    'marginTop': '32px',
    'marginLeft': '25px',
  },
  selectedCode: {
    'position': 'absolute',
    'marginTop': '32px',
    'marginLeft': '215px',
  },
  mainStyle: {
    width: '100%',
    maxWidth: '100%',
  },
}));

export default function DecProcessPage(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [noResultsWarning, setNoResultsWarning] = React.useState('');
  const [decStages, setDecStages] = React.useState([]);

  DecProcessPage.propTypes = {
    setSnackbarProps: PropTypes.func.isRequired,
    currUser: PropTypes.object.isRequired,
  };

  React.useEffect(() => {
    getActionCodeData();
  }, [] );

  const getActionCodeData = async () => {
    const response = await getResultsForActionCode(
        'DEC', props.setSnackbarProps,
    );
    if (response.status === 200) {
      createStageData(response.result);
    } else if (response.status === 201) {
      setNoResultsWarning('No Results');
    }
  };

  const createStageData = (result) => {
    let decStagesCopy = [...decStages];
    decStagesCopy = noDataStage(result, decStagesCopy);
    decStagesCopy = notSuspended(result, decStagesCopy);
    decStagesCopy = coficoPD3Missing(result, decStagesCopy);
    decStagesCopy = accountBalanceMissing(result, decStagesCopy);
    decStagesCopy = bankNotificationNotSent(result, decStagesCopy);
    decStagesCopy = bankNotificationChaseDue(result, decStagesCopy);
    decStagesCopy = bankNotificationCallDue(result, decStagesCopy);
    decStagesCopy = awaitingBankNotification(result, decStagesCopy);
    decStagesCopy = bifNotSent(result, decStagesCopy);
    decStagesCopy = bifChaseDue(result, decStagesCopy);
    decStagesCopy = bifOverdue(result, decStagesCopy);
    decStagesCopy = awaitingBIF(result, decStagesCopy);
    decStagesCopy = noDeathCertificate(result, decStagesCopy);
    decStagesCopy = noExecutorDetails(result, decStagesCopy);
    decStagesCopy = addressNotRemoved(result, decStagesCopy);
    decStagesCopy = intentionNotSent(result, decStagesCopy);
    decStagesCopy = intentionChaseDue(result, decStagesCopy);
    decStagesCopy = intentionOverDue(result, decStagesCopy);
    decStagesCopy = awaitingIntention(result, decStagesCopy);
    decStagesCopy = refundOutstanding(result, decStagesCopy);
    decStagesCopy = clearAccountOustanding(result, decStagesCopy);
    decStagesCopy = closeInCoficoOutstanding(result, decStagesCopy);
    decStagesCopy = decNotClosed(result, decStagesCopy);
    setDecStages(decStagesCopy);
  };

  const safeDecFieldsRead = (decFields) => {
    if (decFields === undefined) {
      return {};
    }
    return decFields;
  };

  const noDataStage = (result, decStagesCopy) => {
    const filteredResults = result.filter((item) => item.decFields === undefined);
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'No DEC Field Data',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const notSuspended = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).accountSuspended === undefined &&
      safeDecFieldsRead(item.decFields).accountBalance !== undefined),
    );
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'Account Not Suspended',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const coficoPD3Missing = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).pdAttribute === undefined &&
      safeDecFieldsRead(item.decFields).accountBalance !== undefined),
    );
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'Cofico PD3 Missing',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const accountBalanceMissing = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).accountBalance === undefined),
    );
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'Account Balance Missing',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const bankNotificationNotSent = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).bankNotificationSent === undefined &&
        safeDecFieldsRead(item.decFields).bifSent === undefined &&
      safeDecFieldsRead(item.decFields).accountBalance !== undefined),
    );
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'Bank Notification Not Sent',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const bankNotificationChaseDue = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).bifSent === undefined &&
      safeDecFieldsRead(item.decFields).bankNotificationChase === undefined &&
      safeDecFieldsRead(item.decFields).bankNotificationSent !== undefined &&
      safeDecFieldsRead(item.decFields).bankNotificationSent <= (new Date((new Date()).setDate(-14)))
        ));
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'Bank Notification Chase Due',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const bankNotificationCallDue = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).bifSent === undefined &&
      safeDecFieldsRead(item.decFields).bankNotificationCall === undefined &&
      safeDecFieldsRead(item.decFields).bankNotificationChase !== undefined &&
      safeDecFieldsRead(item.decFields).bankNotificationChase <= (new Date((new Date()).setDate(-14)))
        ));
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'Bank Notification Call Due',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const awaitingBankNotification = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).bifSent === undefined &&
      ((safeDecFieldsRead(item.decFields).bankNotificationChase === undefined &&
      safeDecFieldsRead(item.decFields).bankNotificationSent !== undefined &&
      safeDecFieldsRead(item.decFields).bankNotificationSent >= (new Date((new Date()).setDate(-14)))) ||
      (safeDecFieldsRead(item.decFields).bankNotificationCall === undefined &&
      safeDecFieldsRead(item.decFields).bankNotificationChase !== undefined &&
      safeDecFieldsRead(item.decFields).bankNotificationChase >= (new Date((new Date()).setDate(-14)))))
        ));
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'Awaiting Bank Notification',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const bifNotSent = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).bifSent === undefined &&
      safeDecFieldsRead(item.decFields).bankNotificationCall !== undefined),
    );
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'BIF Not Sent',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const bifChaseDue = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).bifRecieved === undefined &&
      safeDecFieldsRead(item.decFields).bifChase === undefined &&
      safeDecFieldsRead(item.decFields).bifSent !== undefined &&
      safeDecFieldsRead(item.decFields).bifSent <= (new Date((new Date()).setDate(-30)))
        ));
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'BIF Chase Due',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const bifOverdue = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).bifRecieved === undefined &&
      safeDecFieldsRead(item.decFields).bifChase !== undefined) &&
      safeDecFieldsRead(item.decFields).bifChase <= (new Date((new Date()).setDate(-30))),
    );
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'BIF Overdue',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const awaitingBIF = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).bifRecieved === undefined &&
      ((safeDecFieldsRead(item.decFields).bifChase === undefined &&
      safeDecFieldsRead(item.decFields).bifSent !== undefined &&
      safeDecFieldsRead(item.decFields).bifSent >= (new Date((new Date()).setDate(-30)))) ||
      (safeDecFieldsRead(item.decFields).bifChase !== undefined &&
      safeDecFieldsRead(item.decFields).bifChase >= (new Date((new Date()).setDate(-30)))))
        ));
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'Awaiting BIF',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const noDeathCertificate = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).deathCertificateRecieved === undefined &&
      safeDecFieldsRead(item.decFields).bifRecieved !== undefined),
    );
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'No Death Certificate',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const noExecutorDetails = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).deathCertificateRecieved !== undefined &&
      safeDecFieldsRead(item.decFields).executorDetailsAdded === undefined),
    );
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'No Executor Details',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const addressNotRemoved = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).deathCertificateRecieved !== undefined &&
      safeDecFieldsRead(item.decFields).addressDetailsRemoved === undefined),
    );
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'Address Not Removed',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const intentionNotSent = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).bifSent === undefined &&
      safeDecFieldsRead(item.decFields).deathCertificateRecieved !== undefined),
    );
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'Intention Not Sent',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const intentionChaseDue = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).intentionRecieved === undefined &&
      safeDecFieldsRead(item.decFields).bifSent !== undefined &&
      safeDecFieldsRead(item.decFields).bifSent <= (new Date((new Date()).setDate(-14)))
        ));
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'Intention Chase Due',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const intentionOverDue = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).intentionRecieved === undefined &&
      safeDecFieldsRead(item.decFields).intentionChase !== undefined &&
      safeDecFieldsRead(item.decFields).intentionChase <= (new Date((new Date()).setDate(-14)))
        ));
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'Intention Overdue',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const awaitingIntention = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).intentionRecieved === undefined &&
      ((safeDecFieldsRead(item.decFields).intentionChase === undefined &&
      safeDecFieldsRead(item.decFields).bifSent !== undefined &&
      safeDecFieldsRead(item.decFields).bifSent >= (new Date((new Date()).setDate(-14)))) ||
      (safeDecFieldsRead(item.decFields).intentionChase !== undefined &&
      safeDecFieldsRead(item.decFields).intentionChase >= (new Date((new Date()).setDate(-14)))))
        ));
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'Awaiting Intention',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const refundOutstanding = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).refundActioned === undefined &&
      safeDecFieldsRead(item.decFields).intentionRecieved !== undefined),
    );
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'Refund Outstanding',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const clearAccountOustanding = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).accountClear === undefined &&
      safeDecFieldsRead(item.decFields).intentionRecieved !== undefined),
    );
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'Clear Account Outstanding',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const closeInCoficoOutstanding = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).closedCofico === undefined &&
      safeDecFieldsRead(item.decFields).intentionRecieved !== undefined),
    );
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'Close in Cofico Outstanding',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  const decNotClosed = (result, decStagesCopy) => {
    const filteredResults = result.filter(
        (item) => (safeDecFieldsRead(item.decFields).accountBalance !== undefined &&
      safeDecFieldsRead(item.decFields).bifRecieved !== undefined &&
      safeDecFieldsRead(item.decFields).deathCertificateRecieved !== undefined &&
      safeDecFieldsRead(item.decFields).refundActioned !== undefined &&
      safeDecFieldsRead(item.decFields).accountClear !== undefined &&
      safeDecFieldsRead(item.decFields).closedCofico !== undefined &&
      safeDecFieldsRead(item.decFields).intentionRecieved !== undefined),
    );
    if (filteredResults.length > 0) {
      decStagesCopy.push({
        stageName: 'DEC Not Closed',
        records: filteredResults,
      });
    }
    return decStagesCopy;
  };

  return (
    <Container component='main' className={classes.mainStyle}>
      <Grid container spacing={3}>
        <Grid item xs={12} >
          <TitlePaper className={classes.titleFields} variant='filled'>
            DEC Results
          </TitlePaper>
          <TitlePaper className={classes.selectedCode} >
            DEC
          </TitlePaper>
          <Paper className={classes.contentSectionsLeft}>
            <Grid container spacing={0}>
              <DecResultsPendingItem decStages={decStages} />
              {noResultsWarning}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <ExitButton variant='contained' color='primary' onClick={() => {
        navigate(-1);
      }}>
        Back
      </ExitButton>
    </Container>
  );
}
