// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FullWidthButton from '../../Components/StyledMUIComponents/FullWidthButton';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { upgradedDate, ukDateStringToDate } from '../../Libs/fileHelper';
import FleetManagementPopup from '../../Components/InvoiceManagement/FleetManagementPopup';
import { exportDataToSinglePageExcel, convertDateToExcel } from '../../Libs/reportingHelper';
// Resources

const useStyles = makeStyles((theme) => ({
  rowStyle: {
    borderTop: '1px solid',
    borderColor: '#cccccc',
  },
  selectedRowStyle: {
    borderTop: '1px solid',
    borderColor: '#cccccc',
    background: theme.palette.primary.selectedRow,
  },
}));

export default function FleetManagement({ scheduleData, selectedSchedule, setSelectedSchedule,
  nextMonthInvoiceDate, filteredScheduleData, setFleetActionData, setSnackbarProps }) {
  const classes = useStyles();
  const sxClasses = {
    paperStyle: {
      width: 'calc(100% - 24px)',
      border: '1px solid',
      borderColor: '#000000',
      minHeight: '200px',
      height: 'calc(100vh - 217px)',
      paddingLeft: 1,
      paddingRight: 1,
      position: 'relative',
    },
    tableStyle: {
      height: 'calc(100vh - 320px)',
    },
    addFleetButton: {
      position: 'absolute',
      top: '8px',
      right: '8px',
      paddingTop: 0.5,
      paddingBottom: 0.5,
      paddingLeft: 2,
      paddingRight: 2,
    },
    fleetReportingButton: {
      position: 'absolute',
      top: '8px',
      right: '168px',
      paddingTop: 0.5,
      paddingBottom: 0.5,
      paddingLeft: 2,
      paddingRight: 2,
    },
    reportingContainer: {
      padding: 1.5,
      width: '15vw',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    reportingPopoverButton: {
      marginTop: 1,
    },
  };


  FleetManagement.propTypes = {
    scheduleData: PropTypes.array.isRequired,
    selectedSchedule: PropTypes.string.isRequired,
    setSelectedSchedule: PropTypes.func.isRequired,
    nextMonthInvoiceDate: PropTypes.instanceOf(Date).isRequired,
    filteredScheduleData: PropTypes.func.isRequired,
    setFleetActionData: PropTypes.func.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
  };

  const [currentFilter, setCurrentFilter] = React.useState('All');
  const [pageIndex, setPageIndex] = React.useState(0);
  const [addingFleetDataPopup, setAddingFleetDataPopup] = React.useState(false);
  const [fleetPopupData, setFleetPopupData] = React.useState('');

  const [reportingAnchorEl, setreportingAnchorEl] = React.useState(null);

  const toggleReportingPopover = (event) => {
    setreportingAnchorEl(reportingAnchorEl ? null : event.currentTarget);
  };

  const handleFleetPopupChange = (event) => {
    setFleetPopupData(event.target.value);
  };

  const handleFleetPopupSubmit = () => {
    const scheduleDataCopy = [...scheduleData];
    for (let i=0; i<scheduleDataCopy.length; i++) {
      if (fleetPopupData.includes(scheduleDataCopy[i].Registration) ||
        fleetPopupData.includes(scheduleDataCopy[i].Vin)) {
        if (scheduleDataCopy[i].fleetStatus !== 'On Fleet') {
          scheduleDataCopy[i] = {
            ...scheduleDataCopy[i],
            fleetAction: 'To Add',
          };
        }
      } else {
        if (scheduleDataCopy[i].fleetStatus === 'On Fleet') {
          scheduleDataCopy[i] = {
            ...scheduleDataCopy[i],
            fleetAction: 'To Remove',
          };
        }
      }
    }
    setFleetActionData(scheduleDataCopy);
    setAddingFleetDataPopup(false);
  };

  const handleFleetPopupClear = () => {
    const scheduleDataCopy = [...scheduleData];
    for (let i=0; i<scheduleDataCopy.length; i++) {
      delete scheduleDataCopy[i].fleetAction;
    }
    setFleetActionData(scheduleDataCopy);
    setAddingFleetDataPopup(false);
  };

  const sliceDataByPage = () => {
    return filteredScheduleData(currentFilter).slice(pageIndex*100, (pageIndex+1) * 100);
  };

  const handleFilterChange = (event, newValue) => {
    if (newValue === 0) {
      return;
    }
    setCurrentFilter(newValue);
    setPageIndex(0);
  };

  const cellStyle = {
    padding: '2px',
    borderBottom: '0px',
  };

  const handleChangePage = (event, newPage) => {
    setPageIndex(newPage);
  };

  const extractEndingContracts = () => {
    const dateColumns = ['D', 'E', 'F'];
    let endingSoonDate = new Date(nextMonthInvoiceDate);
    endingSoonDate = new Date(endingSoonDate.setMonth(endingSoonDate.getMonth()+3));
    const dataToExport = [...scheduleData.filter((item)=>(
      item.fleetStatus === 'On Fleet' && item.RealEndDate < endingSoonDate))];
    if (dataToExport.length === 0) {
      setSnackbarProps({
        open: true,
        message: 'There are no contracts ending in the next 3 months.',
        severity: 'info',
      });
      return;
    }
    for (let i=0; i<dataToExport.length; i++) {
      dataToExport[i] = staticColumnOrder(dataToExport[i]);
    }
    exportDataToSinglePageExcel(dataToExport, dateColumns, 'Ending Contracts', 'Data');
  };

  const extractAllContracts = () => {
    const dateColumns = ['D', 'E', 'F'];
    const dataToExport = [...scheduleData];
    for (let i=0; i<dataToExport.length; i++) {
      dataToExport[i] = staticColumnOrder(dataToExport[i]);
    }
    exportDataToSinglePageExcel(dataToExport, dateColumns, 'Fleet Extract', 'Data');
  };

  const staticColumnOrder = (object) => {
    return ({
      Registration: object.Registration,
      ['Schedule ID']: object.ScheduleID,
      ['Fleet Status']: object.fleetStatus,
      ['Registration Date']: convertDateToExcel(
          ukDateStringToDate(object.RegistrationDate).getTime()),
      ['Start Date']: convertDateToExcel(ukDateStringToDate(object.StartDate).getTime()),
      ['End Date']: convertDateToExcel(ukDateStringToDate(object.EndDate).getTime()),
    });
  };

  return (
    <Paper sx={sxClasses.paperStyle}>
      <Tabs
        value={currentFilter}
        onChange={handleFilterChange}
      >
        <Tab value={'All'} label={<div>All<br></br>{filteredScheduleData('All').length}</div>} />
        <Tab value={'On'} label={<div>On Fleet<br></br>{filteredScheduleData('On').length}</div>} />
        <Tab value={'On Not Active'} label={<div>On Not Active<br></br>
          {filteredScheduleData('On Not Active').length}</div>} />
        <Tab value={'Off'} label={<div>Off Fleet<br></br>{filteredScheduleData('Off').length}</div>} />
        {filteredScheduleData('Ending').length !== 0 &&
          <Tab value={'Ending'} label={<div>Ending<br></br>{filteredScheduleData('Ending').length}</div>} />
        }
        {filteredScheduleData('Manual Rate').length !== 0 &&
          <Tab value={'Manual Rate'}
            label={<div>Missing Rate<br></br>{filteredScheduleData('Manual Rate').length}</div>} />
        }
        <Tab value={'To Remove'}
          label={<div>To Remove<br></br>{filteredScheduleData('To Remove').length}</div>} />
        <Tab value={'To Add'}
          label={<div>To Add<br></br>{filteredScheduleData('To Add').length}</div>} />
        <Tab value={0} label={''} />
      </Tabs>
      <Button variant='contained' color='primary' sx={sxClasses.addFleetButton}
        onClick={() => {
          setAddingFleetDataPopup(true);
        }}>
        Add Fleet Data
      </Button>
      <Button variant='contained' color='primary' sx={sxClasses.fleetReportingButton}
        onClick={toggleReportingPopover}>
        Reporting
      </Button>
      <Popover
        onClose={toggleReportingPopover}
        open={Boolean(reportingAnchorEl)}
        anchorEl={reportingAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={sxClasses.reportingContainer} >
          <FullWidthButton color='primary' variant="contained" onClick={extractAllContracts}>
          Full Fleet Extract
          </FullWidthButton>
          <FullWidthButton sx={sxClasses.reportingPopoverButton} color='primary' variant="contained"
            onClick={extractEndingContracts}>
          Ending Contracts
          </FullWidthButton>
        </Box>
      </Popover>
      <TableContainer sx={sxClasses.tableStyle}>
        <Table sx={cellStyle} size='small' aria-label='a dense table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={sxClasses.cellStyle}>Schedule ID</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Registration</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Status</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Start Date</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>End Date</TableCell>
              <TableCell sx={sxClasses.cellStyle} align='center'>Monthly Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sliceDataByPage().map((item)=>(
              <TableRow
                key={item.ScheduleID} onClick={()=>{
                  setSelectedSchedule(item.ScheduleID);
                }}
                className={item.ScheduleID === selectedSchedule ? classes.selectedRowStyle : classes.rowStyle}>
                <TableCell sx={cellStyle} align='left'>
                  {item.ScheduleID}
                </TableCell>
                <TableCell sx={cellStyle} align='center'>
                  {item.Registration}
                </TableCell>
                <TableCell sx={cellStyle} align='center'>
                  {item.Status}
                </TableCell>
                <TableCell sx={cellStyle} align='center'>
                  {item.StartDate}
                </TableCell>
                <TableCell sx={cellStyle} align='center'>
                  {item.rollOnDate ? upgradedDate(item.rollOnDate) : item.EndDate}
                </TableCell>
                <TableCell sx={cellStyle} align='center'>
                  {item.monthlyRate}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination sx={{ padding: 0 }}
        rowsPerPageOptions={[100]}
        component="div"
        count={filteredScheduleData(currentFilter).length}
        rowsPerPage={100}
        page={pageIndex}
        onPageChange={handleChangePage}
      />
      <FleetManagementPopup popupOpen={addingFleetDataPopup} setPopupOpen={setAddingFleetDataPopup}
        fleetPopupData={fleetPopupData} handleFleetPopupChange={handleFleetPopupChange}
        handleFleetPopupSubmit={handleFleetPopupSubmit} handleFleetPopupClear={handleFleetPopupClear} />
    </Paper>
  );
}
