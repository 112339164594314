// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { getSLAStyle } from '../Libs/fileHelper';
// Components
import Grid from '@mui/material/Grid';
// Resources

const useStyles = makeStyles((theme) => ({
  originalStyle: {
    backgroundColor: '#ffffff',
  },
  alternativeStyle: {
    backgroundColor: '#eeeeee',
  },
  slaGreen: theme.palette.slaGreen,
  slaAmber: theme.palette.slaAmber,
  slaRed: theme.palette.slaRed,
  emptyStyle: {},
}));

export default function FrontPendingItem({ count, pendingGroup, navigate }) {
  const classes = useStyles();

  FrontPendingItem.propTypes = {
    count: PropTypes.number.isRequired,
    pendingGroup: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
  };

  const handleSLABackground = () => {
    if (pendingGroup.SLA) {
      return getSLAStyle(pendingGroup.oldestRecord, pendingGroup.SLA, classes);
    }
    if (count % 2) {
      return classes.originalStyle;
    }
    return classes.alternativeStyle;
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={0} className={handleSLABackground()}
        onClick={() => {
          navigate('/Results?=' + pendingGroup.actionCode);
        }}>
        <Grid item xs={4}>
          {pendingGroup.actionCode}
        </Grid>
        <Grid item xs={4}>
          {pendingGroup.actionDesc}
        </Grid>
        <Grid item xs={4}>
          {pendingGroup.actionTotal}
        </Grid>
      </Grid>
    </Grid>
  );
}
