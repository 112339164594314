// Helper Functions

export const upgradedDate = (value) => {
  if (value === null || value === '' || value === undefined) {
    return '';
  } else {
    return new Date(parseInt(value)).toLocaleDateString();
  }
};

export const currentDateAsString = () => {
  const currDate = new Date();
  let currDay = '';
  let currMonth = '';
  if (currDate.getDate() <10 ) {
    currDay = '0' + currDate.getDate();
  } else {
    currDay = currDate.getDate();
  }
  if ((currDate.getMonth() + 1) <10) {
    currMonth = '0' + (currDate.getMonth() + 1);
  } else {
    currMonth = currDate.getMonth() +1;
  }
  return currDay + '/' + currMonth + '/' + currDate.getFullYear();
};

export const upgradedDateWithTime = (value) => {
  if (value === null || value === '' || value === undefined) {
    return '';
  } else {
    return new Date(parseInt(value)).toLocaleString('en-GB', { hour12: false } );
  }
};

export const checkWholeNumber = (string) => {
  if (string === '' || string === '-') {
    return true;
  }
  const regexp = /^-?[0-9]\d*$/;
  return regexp.test(string);
};

export const checkIsPositiveNumber = (string) => {
  if (!isString(string) && !isNumber(string)) {
    return false;
  }
  if (string === '') {
    return true;
  }
  const regexp = /^\d*\.?\d*$/;
  return regexp.test(string);
};

export const failsafeLocationParameter = (location, parameter) => {
  if (location?.search === undefined) {
    return '';
  } else {
    return new URLSearchParams(location?.search).get(parameter);
  }
};

export const sumAgedData = (agedData) => {
  if (!agedData) {
    return '0.00';
  }
  if (!isArray(agedData)) {
    return '0.00';
  }
  let agedTotal = 0.00;
  for (const debtLine of agedData) {
    agedTotal = agedTotal + parseFloat(debtLine.DebtAmount);
  }
  return agedTotal.toFixed(2);
};

// Completely duplicated code, no change in logic at all
export const sumClaimsData = (claimsData) => {
  if (!claimsData) {
    return '0.00';
  }
  if (!isArray(claimsData)) {
    return '0.00';
  }
  let claimsTotal = 0.00;
  for (const claimLine of claimsData) {
    claimsTotal = claimsTotal + parseFloat(claimLine.ClaimTotalAmount);
  }
  return claimsTotal.toFixed(2);
};

export const checkAdminGroup = (currUser) => {
  if (currUser === undefined || currUser === null) {
    return false;
  }
  if (currUser.signInUserSession === undefined) {
    return false;
  }
  if (currUser.signInUserSession.idToken === undefined) {
    return false;
  }
  if (currUser.signInUserSession.idToken.payload === undefined) {
    return false;
  }
  if (currUser?.signInUserSession.idToken.payload['cognito:groups'].includes('AdminUsers')) {
    return true;
  } else {
    return false;
  }
};

export const checkApprovedGroup = (currUser) => {
  if (currUser === undefined || currUser === null) {
    return false;
  }
  if (currUser.signInUserSession === undefined) {
    return false;
  }
  if (currUser.signInUserSession.idToken === undefined) {
    return false;
  }
  if (currUser.signInUserSession.idToken.payload === undefined) {
    return false;
  }
  if (currUser.signInUserSession.idToken.payload['cognito:groups'].includes('ApprovedUsers') ||
    currUser.signInUserSession.idToken.payload['cognito:groups'].includes('ApprovedReadOnlyUsers')) {
    return true;
  } else {
    return false;
  }
};

export const checkReadOnlyGroup = (currUser) => {
  if (currUser === undefined || currUser === null) {
    return false;
  }
  if (currUser.signInUserSession === undefined) {
    return false;
  }
  if (currUser.signInUserSession.idToken === undefined) {
    return false;
  }
  if (currUser.signInUserSession.idToken.payload === undefined) {
    return false;
  }
  if (currUser.signInUserSession.idToken.payload['cognito:groups'].includes('ApprovedReadOnlyUsers')) {
    return true;
  } else {
    return false;
  }
};

export const convertToPhoneFormat = (phoneNumber) => {
  if (phoneNumber === undefined || phoneNumber === null) {
    return '';
  }
  if (phoneNumber.toString().length > 11) {
    return phoneNumber;
  }
  const noSpacePhoneNumber = phoneNumber.toString().replace(/\s+/g, '');
  if (noSpacePhoneNumber.substring(0, 1) === '7' ||
    noSpacePhoneNumber.substring(0, 2) ==='07') {
    const paddedMobile = pad(noSpacePhoneNumber, 11);
    return (paddedMobile.substring(0, 5) + ' ' +
        paddedMobile.substring(5, 8) + ' ' +
        paddedMobile.substring(8, 11));
  } else {
    const paddedPhone = pad(noSpacePhoneNumber, 11);
    return (paddedPhone.substring(0, 5) + ' ' + paddedPhone.substring(5, 11));
  }
};

export function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = '0' + num;
  return num;
}

function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

function isNumber(value) {
  return typeof value === 'number' || value instanceof Number;
}

function isArray(value) {
  return Array.isArray(value) || value instanceof Array;
}

export function isDate(value) {
  return value instanceof Date;
}

export function sortArrayByProperty(array, property) {
  if (!array || !property) {
    return array;
  }
  if (!isArray(array) || !isString(property)) {
    return array;
  }
  return array.sort(function(a, b) {
    const x = a[property]; const y = b[property];
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}

export function reverseSortArrayByProperty(array, property) {
  if (!array || !property) {
    return array;
  }
  if (!isArray(array) || !isString(property)) {
    return array;
  }
  return array.sort(function(a, b) {
    const y = a[property]; const x = b[property];
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}

export const getCurrentUser = (currUser) => {
  if (!currUser) {
    return 'Unknown';
  }
  if (currUser.signInUserSession === undefined || currUser === null) {
    return 'Unknown';
  }
  if (currUser.signInUserSession.idToken === undefined) {
    return 'Unknown';
  }
  if (currUser.signInUserSession.idToken.payload === undefined) {
    return 'Unknown';
  }
  if (currUser.signInUserSession.idToken.payload.given_name === undefined) {
    return 'Unknown';
  }
  if (currUser.signInUserSession.idToken.payload.family_name === undefined) {
    return 'Unknown';
  }
  return currUser.signInUserSession.idToken.payload.given_name + ' ' +
    currUser.signInUserSession.idToken.payload.family_name;
};

export const getCurrentUserEmail = (currUser) => {
  if (!currUser) {
    return 'Unknown';
  }
  if (currUser.signInUserSession === undefined || currUser === null) {
    return 'Unknown';
  }
  if (currUser.signInUserSession.idToken === undefined) {
    return 'Unknown';
  }
  if (currUser.signInUserSession.idToken.payload === undefined) {
    return 'Unknown';
  }
  if (currUser.signInUserSession.idToken.payload.email === undefined) {
    return 'Unknown';
  }
  return currUser.signInUserSession.idToken.payload.email;
};

export const ukDateStringToDate = (dateString) => {
  if (!isString(dateString)) {
    return 0;
  }
  const dateParts = dateString.split('/');
  if (dateParts.length != 3) {
    return 0;
  }
  return (new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]));
};

export function convertDateToUTCEpoch(date) {
  let dateValue = date;
  if (!isDate(dateValue)) {
    dateValue = new Date(dateValue);
    if (!isDate(dateValue)) {
      return undefined;
    }
  }
  return Date.UTC(
      dateValue.getFullYear(),
      dateValue.getMonth(),
      dateValue.getDate(),
      dateValue.getHours(),
      dateValue.getMinutes(),
      dateValue.getSeconds(),
  );
}

export function convertDateFromUTC(date) {
  let dateValue = date;
  if (!isDate(dateValue)) {
    dateValue = new Date(dateValue);
    if (!isDate(dateValue)) {
      return undefined;
    }
  }
  return new Date(
      dateValue.getUTCFullYear(),
      dateValue.getUTCMonth(),
      dateValue.getUTCDate(),
      dateValue.getUTCHours(),
      dateValue.getUTCMinutes(),
      dateValue.getUTCSeconds(),
  );
}

export const dateToMMMYYYString = (datevalue) => {
  if (!isDate(datevalue)) {
    return '';
  }
  const monthNumber = (datevalue.getMonth());
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December'];
  return (monthNames[monthNumber] + ' ' + datevalue.getFullYear());
};


export const getNextMonthMMMMYYFormat = () => {
  const date = new Date();
  const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  return dateToMMMYYYString(nextMonth);
};

export const monthsBetweenInt = (startDate, endDate) => {
  if (!isDate(startDate) && !isDate(endDate)) {
    return undefined;
  }
  let months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
  months -= startDate.getMonth();
  months += endDate.getMonth();
  return months <= 0 ? 0 : months;
};


function numberOfDaysInMonth(dateValue) {
  return lastOfMonth(dateValue).getDate();
}

export function firstOfMonth(dateValue) {
  if (!isDate(dateValue)) {
    return undefined;
  }
  return new Date(new Date(dateValue.getFullYear(), dateValue.getMonth(), 1).setHours(0, 0, 0, 0));
}

function lastOfMonth(dateValue) {
  if (!isDate(dateValue)) {
    return undefined;
  }
  return new Date(new Date(dateValue.getFullYear(), dateValue.getMonth() + 1, 0).setHours(0, 0, 0, 0));
}

function daysBetween(dateValue1, dateValue2) {
  if (!isDate(dateValue1) && !isDate(dateValue2)) {
    return undefined;
  }
  const millisecondsBetweenDates = dateValue2 - dateValue1;
  return Math.floor((((millisecondsBetweenDates / 1000) / 60) / 60) / 24);
}

export const monthsBetween = (dateValue1, dateValue2) => {
  if (!isDate(dateValue1) && !isDate(dateValue2)) {
    return undefined;
  }
  const fullMonthsDifference = ((dateValue2.getFullYear() - dateValue1.getFullYear()) * 12) +
dateValue2.getMonth() - dateValue1.getMonth();
  if (fullMonthsDifference === 0) {
    return daysBetween(dateValue1, dateValue2) / numberOfDaysInMonth(dateValue1);
  } else {
    if (dateValue1.getDate() === dateValue2.getDate()) {
      return fullMonthsDifference;
    }
    if (dateValue1 < dateValue2) {
      const firstPortion = daysBetween(dateValue1, lastOfMonth(dateValue1)) / numberOfDaysInMonth(dateValue1);
      const lastPortion = daysBetween(firstOfMonth(dateValue2), dateValue2) / numberOfDaysInMonth(dateValue2);
      return (fullMonthsDifference - 1 + firstPortion + lastPortion);
    } else {
      const firstPortion = daysBetween(dateValue1, firstOfMonth(dateValue1)) / numberOfDaysInMonth(dateValue1);
      const lastPortion = daysBetween(lastOfMonth(dateValue2), dateValue2) / numberOfDaysInMonth(dateValue2);
      return (fullMonthsDifference + 1 + firstPortion + lastPortion);
    }
  }
};

export const yearsBetween = (endDate, startDate) => {
  if (!isDate(startDate) && !isDate(endDate)) {
    return undefined;
  }
  const fullYearDifference = endDate.getFullYear() - startDate.getFullYear();
  const startDateShifted = new Date(startDate);
  startDateShifted.setFullYear(startDate.getFullYear() + fullYearDifference);
  const partYearDifference = (endDate - startDateShifted) / (86400000 * 365);
  return (endDate.getFullYear()-startDate.getFullYear() + partYearDifference);
};

export const firstOfNextMonth = (dateValue) => {
  if (!isDate(dateValue)) {
    return undefined;
  }
  return new Date(new Date(dateValue.getFullYear(), dateValue.getMonth()+1, 1).setHours(0, 0, 0, 0));
};

export function replaceAllOccurances(str, find, replace) {
  if (isString(str) && isString(find) && isString(replace)) {
    return str.replace(new RegExp(find, 'g'), replace);
  } else {
    return str;
  }
}

export function toTitleCase(str) {
  if (!isString(str)) {
    return str;
  }
  if (str) {
    return str.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        },
    );
  } else {
    return str;
  }
}

export const undefinedToEmptyString = (value) => {
  if (value) {
    return value;
  }
  return '';
};

export const safePropertyRead = (object, property) => {
  if (!object || !property) {
    return object;
  }
  if (!isArray(object) && !isString(property)) {
    return object;
  }
  return object[property];
};

export const isCompleteProductCodeSchedule = (scheduleData) => {
  const completeProductCodes = ['CX', 'GP', 'LN', 'LX', 'MP', 'R4', 'R5', 'R6'];
  return (completeProductCodes.includes(scheduleData.ProductCode));
};

export const convertCheckBoxValue = (value) => {
  // Rename and move to lIb?
  if (value === '1' || value === 1 || value === true) {
    return true;
  } else {
    return false;
  }
};

export const parseCurrencyStrings = (value) => {
  if (typeof value !== 'string') return 0;
  // Remove commas from thousand sepearators and trim spaces
  value = replaceAllOccurances(value, ',', '');
  value = replaceAllOccurances(value, ' ', '');
  // Remove currency symbol from start
  if (!parseInt(value.substring(0, 1)) && value.substring(0, 1) !== '.' && value.substring(0, 1) !== '-') {
    value = value.substring(1);
  }
  // Remove currency symbol from end
  if (!parseInt(value.slice(-1))) {
    value = value.slice(0, -1);
  }
  return parseFloat(value);
};

export const currencyFormatter = (value) => {
  if (typeof value === 'string') value = parseCurrencyStrings(value);
  if (isNaN(value)) value = 0.0;
  if (typeof value !== 'number') value = 0.0;
  value = value.toFixed(2);

  const [currency, decimal] = value.split('.');
  return `£${currency.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ',',
  )}.${decimal}`;
};

export const isValidScheduleID = (value) => {
  if (value === '') {
    return true;
  }
  const strValue = value.toString();
  if (!strValue.length) {
    return false;
  }
  if (strValue.length === 0) {
    return false;
  }
  if (strValue.length > 15) {
    return false;
  }
  if (strValue.length > 8) {
    if (strValue.charAt(8) !== '/') {
      return false;
    }
  }
  // eslint picks up escaped backslash as unnecessary
  /* eslint-disable-next-line */
  const scheduleIDregexp = /^[A-Za-z0-9]*[\/]?[0-9]*$/;
  return scheduleIDregexp.test(strValue);
};

function hasNumber(myString) {
  return /\d/.test(myString);
}

export const handleBackwardsAddress = (value) => {
  if (!isString(value)) return '';
  const addressParts = value.split(',');
  const firstLineAddressParts = addressParts[0].split(' ');
  if (hasNumber(firstLineAddressParts[0])) return value;
  if (hasNumber(firstLineAddressParts[firstLineAddressParts.length - 1])) {
    // Move last part of first line of the address to the start of the string
    let recreatedAddress = firstLineAddressParts[firstLineAddressParts.length - 1];
    for (let i=0; i<firstLineAddressParts.length -1; i++) {
      recreatedAddress = recreatedAddress + ' ' + firstLineAddressParts[i];
    }
    addressParts.shift();
    for (let j=0; j<addressParts.length; j++) {
      recreatedAddress = recreatedAddress + ',' + addressParts[j];
    }
    return recreatedAddress;
  }
  return value;
};

export const isActiveSchedule = (status) => {
  if (isString(status)) {
    if (status === 'A' || status === 'Active' || status === 'contractActive') {
      return true;
    }
  }
  return false;
};


export const getSLAStyle = (comparisonDate, sla, classes) => {
  if (sla) {
    const currentDate = new Date();
    const slaRedDate = currentDate.setDate(currentDate.getDate() - parseInt(sla));
    const slaAmberDate = currentDate.setDate(currentDate.getDate() + 1);
    if (comparisonDate < slaRedDate) {
      return classes.slaRed;
    }
    if (comparisonDate < slaAmberDate) {
      return classes.slaAmber;
    }
    return classes.slaGreen;
  }
  return classes.emptyStyle;
};

export const isProjectLeoLive = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  // 1672531200000 is 1st Jan 2023
  if (today > 1672531200000) {
    return true;
  }
  return false;
};
