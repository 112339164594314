// Functions
import React from 'react';
import PropTypes from 'prop-types';
// Components
import EditableTableForm from '../../Components/EditableTable/EditableTableForm';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { sortArrayByProperty, convertCheckBoxValue } from '../../Libs/fileHelper';
// Resources
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

export default function EditableTable(props) {
  const {
    headerArray,
    tableData,
    setTableData,
    configurationInformation = {},
    additionalSaveFunction,
    additionalDeleteFunction,
  } = props;

  const sxClasses = {
    tableStyle: {
      display: 'block',
      height: '100%',
      overflowY: 'scroll',
      textAlign: 'end',
    },
    headerWithButtonStyle: {
      height: '32px',
      width: '30px',
      paddingRight: 4,
    },
    addButton: {
      minWidth: '10px',
      paddingRight: 1,
    },
  };

  EditableTable.propTypes = {
    headerArray: PropTypes.array.isRequired,
    tableData: PropTypes.array.isRequired,
    setTableData: PropTypes.func.isRequired,
    additionalSaveFunction: PropTypes.func,
    additionalDeleteFunction: PropTypes.func,
    configurationInformation: PropTypes.object,
  };

  const [editingData, setEditingData] = React.useState();
  const [editingIndex, setEditingIndex] = React.useState(-1);

  const handleDataSave = async () => {
    for (const key of Object.keys(configurationInformation)) {
      if (configurationInformation[key].validationFunction) {
        if (!configurationInformation[key].validationFunction(editingData[key])) {
          return;
        }
      }
    }

    if (additionalSaveFunction) {
      if (await additionalSaveFunction({ ...editingData,
        originalData: processDefaultDataSort(tableData)[editingIndex] }) !== true) {
        return;
      }
    }

    const isNewRecord = (editingIndex === -1);

    if (isNewRecord) {
      setTableData([...tableData, editingData]);
    } else {
      const dataCopy = [...processDefaultDataSort(tableData)];
      dataCopy[editingIndex] = editingData;
      setTableData(dataCopy);
    }
    setEditingData();
  };

  const handleDataDelete = async () => {
    if (additionalDeleteFunction) {
      if (await additionalDeleteFunction({ ...editingData,
        originalData: processDefaultDataSort(tableData)[editingIndex] }) !== true) {
        return;
      }
    }

    const dataCopy = [...processDefaultDataSort(tableData)];
    dataCopy.splice(editingIndex, 1);
    setTableData(dataCopy);
    setEditingData();
  };

  const handleConfigurationRenderTypes = (value, type) => {
    if (type === 'checkbox') {
      return (<input type="checkbox" readOnly checked={convertCheckBoxValue(value)}/>);
    }
    return value;
  };

  const processDefaultDataSort = (data) => {
    let dataCopy = [...data];
    for (const key of Object.keys(configurationInformation)) {
      if (configurationInformation[key].sort) {
        dataCopy = sortArrayByProperty(dataCopy, key);
      }
    }
    return dataCopy;
  };

  return (
    <TableContainer sx={sxClasses.tableStyle}>
      <Table size='small' aria-label='a dense table' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={sxClasses.headerWithButtonStyle}>
              <Button sx={sxClasses.addButton} variant='contained' color='primary'
                onClick={()=>{
                  setEditingData({});
                  setEditingIndex(-1);
                }} startIcon={<AddIcon />} />
            </TableCell>
            {headerArray.map((header, index) => (
              <TableCell align={configurationInformation[header]?.align} key={index}>
                {configurationInformation[header]?.label || header }
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {processDefaultDataSort(tableData).map((row, index) => (
            <TableRow key={index}>
              <TableCell >
                <IconButton onClick={()=>{
                  setEditingData(row);
                  setEditingIndex(index);
                }}>
                  <EditIcon/>
                </IconButton>
              </TableCell>
              {headerArray.map((header, index) => (
                <TableCell align={configurationInformation[header]?.align} key={index}>
                  {handleConfigurationRenderTypes(row[header], configurationInformation[header]?.type)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EditableTableForm
        editingData={editingData}
        setEditingData={setEditingData}
        editingIndex={editingIndex}
        headerArray={headerArray}
        handleSave={handleDataSave}
        handleDataDelete={handleDataDelete}
        configurationInformation={configurationInformation}
      />
    </TableContainer>
  );
}
