// Functions
import React from 'react';
import PropTypes from 'prop-types';
// Components
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import AuthProfile from './AuthProfile';
import Box from '@mui/material/Box';
import { getStageHeaderText } from '../../Libs/StageConfig';
// Resources
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function AppHeader({ currUser, handleLogout, headerAnchorEl,
  toggleHeaderPopover, isAuthenticated, emailConfigData, setEmailConfigData, setSnackbarProps }) {
  const sxClasses = {
    root: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    stageStyle: {
      background: '#FFFFFF',
      border: '1px solid',
      borderColor: '#000000',
      textAlign: 'center',
      padding: '4px',
    },
    headerBtn: {
      marginLeft: 'auto',
    },
  };
  AppHeader.propTypes = {
    currUser: PropTypes.object,
    handleLogout: PropTypes.func.isRequired,
    headerAnchorEl: PropTypes.object,
    toggleHeaderPopover: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    emailConfigData: PropTypes.object.isRequired,
    setEmailConfigData: PropTypes.func.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
  };

  const stageHeaderText = getStageHeaderText();

  return (
    <Box sx={sxClasses.root}>
      {stageHeaderText &&
        <Box sx={sxClasses.stageStyle}>
          {stageHeaderText}
        </Box>
      }
      <Toolbar>
        {isAuthenticated &&
          <IconButton onClick={toggleHeaderPopover}
            sx={sxClasses.headerBtn} edge="start" color="inherit" aria-label="menu">
            <AccountCircleIcon />
          </IconButton>
        }
      </Toolbar>
      <Popover
        onClose={toggleHeaderPopover}
        open={Boolean(headerAnchorEl)}
        anchorEl={headerAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {isAuthenticated?
        <AuthProfile currUser={currUser} handleLogout={handleLogout}
          emailConfigData={emailConfigData} setEmailConfigData={setEmailConfigData}
          setSnackbarProps={setSnackbarProps}/>:
        null
        }
      </Popover>
    </Box>
  );
}
