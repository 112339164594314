// Functions
import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FullWidthButton from '../../Components/StyledMUIComponents/FullWidthButton';
import Checkbox from '@mui/material/Checkbox';
import { safePropertyRead, convertCheckBoxValue } from '../../Libs/fileHelper';
import { processEmailAttachment } from '../../Libs/fileUploadHelper';
// Resources
import DeleteIcon from '@mui/icons-material/Delete';

export default function EditableTableForm({ editingData, setEditingData, editingIndex, headerArray, handleSave,
  handleDataDelete, configurationInformation }) {
  const sxClasses = {
    dialogTitle: {
      paddingTop: 1,
      textAlign: 'center',
    },
    iconButton: {
      position: 'absolute',
      top: 1,
      right: 1,
      padding: 0,
    },
  };

  EditableTableForm.propTypes = {
    editingData: PropTypes.object,
    setEditingData: PropTypes.func.isRequired,
    editingIndex: PropTypes.number.isRequired,
    headerArray: PropTypes.array.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleDataDelete: PropTypes.func.isRequired,
    configurationInformation: PropTypes.object,
  };

  const closePopup = () => {
    setEditingData();
  };

  const handleDataChange = (event) => {
    if (configurationInformation[event.target.id]?.validationFunction) {
      if (!configurationInformation[event.target.id].validationFunction(event.target.value)) {
        return;
      }
    }
    let newValue = event.target.value;
    if (configurationInformation[event.target.id]?.inputConversionFunction) {
      newValue = configurationInformation[event.target.id]?.inputConversionFunction(newValue);
    }
    setEditingData({
      ...editingData,
      [event.target.id]: newValue,
    });
  };

  const handleConfigurationRenderTypes = (header, index) => {
    if (configurationInformation[header]?.type === 'checkbox') {
      return (
        <FormGroup key={index}>
          <FormControlLabel control={
            <Checkbox checked={convertCheckBoxValue(safePropertyRead(editingData, header))} />
          }
          label={configurationInformation[header]?.label || header} onChange={()=> {
            handleDataChange({ target: { id: header,
              value: !convertCheckBoxValue(safePropertyRead(editingData, header)) } });
          }}/>
        </FormGroup>);
    }
    if (configurationInformation[header]?.type === 'longText') {
      return <TextField
        key={index}
        margin="dense"
        id={header}
        label={configurationInformation[header]?.label || header}
        fullWidth
        value={safePropertyRead(editingData, header) || ''}
        onChange={handleDataChange}
        multiline
        inputProps={{
          style: {
            minWidth: '60vw',
          },
        }}
        minRows={5}
        maxRows={18}/>;
    }
    if (configurationInformation[header]?.type === 'uploadButton') {
      return (<React.Fragment key={index}>
        <input
          accept=".csv, .xlsx, .pdf"
          onChange={fileUploadHandler}
          style={{ display: 'none' }}
          id="raised-button-file2"
          multiple
          type="file"
        />
        <label htmlFor="raised-button-file2">
          {safePropertyRead(editingData, header) || ''}
          <FullWidthButton
            variant='contained' color='primary' component="span">
          Add Attachment
          </FullWidthButton>
        </label>
      </React.Fragment>);
    }
    return (
      <TextField
        key={index}
        margin="dense"
        id={header}
        label={configurationInformation[header]?.label || header}
        fullWidth
        value={safePropertyRead(editingData, header) || ''}
        onChange={handleDataChange}
      />);
  };

  const fileUploadValidation = (files) => {
    if (files.length !== 1) {
      handleDataChange({ target: { id: 'Attachment',
        value: 'Error: You may only select 1 file.' } });
      return false;
    }
    if (files[0].size > 10000000) {
      handleDataChange({ target: { id: 'Attachment',
        value: 'Error: File is too big, must be under 10MB.' } });
      return false;
    }
    if (files[0].name.includes('Error:')) {
      handleDataChange({ target: { id: 'Attachment',
        value: 'Error: Invalid File Name.' } });
      return false;
    }
    return true;
  };

  const fileUploadHandler = async (e) => {
    e.persist();
    const files = e.target.files;
    if (!fileUploadValidation(files)) return;
    const uploadResult = await processEmailAttachment(files[0]);
    if (uploadResult.statusCode === 200) {
      handleDataChange({ target: { id: 'Attachment',
        value: uploadResult.fileName } });
    } else {
      handleDataChange({ target: { id: 'Attachment',
        value: 'Error: ' + uploadResult.message } });
    }
  };

  return (
    <Dialog open={editingData !== undefined} aria-labelledby="form-dialog-title"
      maxWidth={false}>
      <DialogTitle sx={sxClasses.dialogTitle} id="form-dialog-title">Record Edit</DialogTitle>
      {editingIndex !== -1 &&
        <IconButton sx={sxClasses.iconButton} onClick={handleDataDelete}>
          <DeleteIcon/>
        </IconButton>
      }
      <DialogContent>
        {headerArray.map((header, index) => (
          handleConfigurationRenderTypes(header, index)
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>{
          handleSave(editingData);
        }} color="primary">
          Save
        </Button>
        <Button onClick={closePopup} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
