// Functions
import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { upgradedDateWithTime, upgradedDate } from '../../Libs/fileHelper';
// Resources

export default function ScheduleHistoryPopup({ popupOpen, setPopupOpen, scheduleEventData }) {
  const sxClasses = {
    dialogTitle: {
      paddingTop: 1,
      textAlign: 'center',
    },
    textFieldStyle: {
      width: '100%',
    },
  };

  ScheduleHistoryPopup.propTypes = {
    popupOpen: PropTypes.bool.isRequired,
    setPopupOpen: PropTypes.func.isRequired,
    scheduleEventData: PropTypes.object.isRequired,
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const extractEventDetails = (eventDetails) => {
    if (!eventDetails) {
      return '';
    }
    let eventDetailsString = '';
    for (const key of Object.keys(eventDetails)) {
      if (key.includes('Date') && key !== 'registrationDate' && key !== 'startDate') {
        eventDetailsString = eventDetailsString + key + ': ' + upgradedDate(eventDetails[key]) + '\n';
      } else {
        eventDetailsString = eventDetailsString + key + ': ' + eventDetails[key] + '\n';
      }
    }
    return eventDetailsString;
  };

  return (
    <Dialog open={popupOpen} aria-labelledby="form-dialog-title"
      maxWidth={false}>
      <DialogTitle sx={sxClasses.dialogTitle} id="form-dialog-title">
        {scheduleEventData?.EventType + ' - ' + upgradedDateWithTime(scheduleEventData?.EventTime)}
      </DialogTitle>
      <DialogContent sx={{ whiteSpace: 'pre-line' }}>
        {scheduleEventData?.UserName && 'Created by ' + scheduleEventData?.UserName + '\n'}
        {extractEventDetails(scheduleEventData?.EventDetails)}
      </DialogContent>
      <DialogActions>
        <Button onClick={closePopup} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
