
// Resources
import EmailTemplatesCar from '../Res/EmailTemplatesCar';
import EmailTemplatesVan from '../Res/EmailTemplatesVan';
import DeceasedTemplates from '../Res/DeceasedTemplates';
import { replaceAllOccurances, currencyFormatter, getNextMonthMMMMYYFormat } from '../Libs/fileHelper';
import { carVanSwitch } from '../Libs/StageConfig';

const getReturnMailAddress = () => {
  return carVanSwitch('pccreditcontrol@mercedes-benz.com', 'van.servicecontract@mercedes-benz.com');
};

const getAdminReturnAddress = () => {
  return carVanSwitch('pcadmin@mercedes-benz.com', 'van.servicecontract@mercedes-benz.com');
};

const additionalVanSignature = (emailAdd) => {
  const extraText = 'Van Service Contract Administration\n' +
    'Phone: +44 (0) 0370 242 6200 – option 2\n' +
    'Email: ' + emailAdd + '\n\n';
  return carVanSwitch('', extraText);
};

export const emailTemplateText = (
    selectedTemplate, selectedCancellationReason, customerData, scheduleData, signature,
) => {
  let rawText = 'Dear ';
  rawText = rawText + customerData?.CustomerName + ',<br><br>';

  for (const templateLine of getMainTemplateText(selectedTemplate)) {
    rawText = rawText + templateLine + '<br> <br>';
  }

  // rawText = rawText + '</p></font></body>\n</html>';
  const emailAdd = '<a href="mailto:' + getReturnMailAddress() + '?subject=' + selectedTemplate +
  ' Response - ' +
  scheduleData?.ScheduleID + ' - ' +
  scheduleData?.Registration +
  '">' + getReturnMailAddress() + '</a>';
  rawText = rawText.replace('{Emailadd}', emailAdd);

  // Perform Replacements
  rawText = rawText.replace('{Term}', scheduleData?.Term);
  rawText = rawText.replace('{Postcode}', customerData?.CustomerPostcode);
  rawText = rawText.replace('{EndDate}', scheduleData?.EndDate);
  rawText = rawText.replace('{Reason}', selectedCancellationReason);
  // Add Ending Details and Signature
  rawText = rawText + additionalVanSignature(emailAdd);
  rawText = rawText + signature;
  rawText = replaceAllOccurances(rawText, '<br>', '\n');
  return rawText;
};

export const emailCancellationTemplateText = (selectedTemplate, props, signature) => {
  let rawText = '';
  for (const templateLine of getMainTemplateText(selectedTemplate)) {
    rawText = rawText + templateLine + '<br> <br>';
  }

  // Perform Replacements
  rawText = cancellationTextReplacements(rawText, props);

  const emailAdd = '<a href="mailto:' + getReturnMailAddress() + '?subject=Cancellation Response - ' +
  props?.scheduleData?.ScheduleID + ' - ' +
  props?.scheduleData?.Registration +
  '">' + getReturnMailAddress() + '</a>';
  rawText = rawText.replace('{Emailadd}', emailAdd);
  const emailAddRetailer = '<a href="mailto:' + getReturnMailAddress() + '?subject=Cancellation ' +
  'Response - ' + props?.scheduleData?.ScheduleID + ' - ' +
  props?.scheduleData?.Registration +
  '">' + getReturnMailAddress() + '</a>';
  rawText = rawText.replace('{EmailaddRetail}', emailAddRetailer);
  rawText = rawText + additionalVanSignature(emailAdd);
  rawText = rawText + signature;
  rawText = replaceAllOccurances(rawText, '<br>', '\n');

  return rawText;
};

export const pdfCancellationTemplateText = (selectedTemplate, props) => {
  let rawText = '';
  rawText = rawText + 'Dear ' +
  props?.customerData?.CustomerName + ',\n\n';

  for (const templateLine of getMainTemplateText(selectedTemplate)) {
    rawText = rawText + templateLine + '\n \n';
  }

  // Perform Replacements
  rawText = cancellationTextReplacements(rawText, props);

  const emailAdd = getReturnMailAddress();
  rawText = rawText.replace('{Emailadd}', emailAdd);
  rawText = rawText.replace('{EmailaddRetail}', emailAdd);

  rawText = htmlToPDFTemplateFormatting(rawText);

  return rawText;
};

const cancellationTextReplacements = (rawText, props) => {
  rawText = rawText.replace('{nextPaymentDate}', 'of ' + getNextMonthMMMMYYFormat());
  rawText = rawText.replace('{Term}', props?.scheduleData?.Term);
  rawText = rawText.replace('{Postcode}', props?.customerData?.CustomerPostcode);
  rawText = rawText.replace('{EndDate}', props?.scheduleData?.EndDate);
  rawText = rawText.replace('{Regist}', props?.scheduleData?.Registration);
  rawText = rawText.replace('{Lastrentalfin}', props?.terminationValues?.latestRental);
  rawText = rawText.replace('{Lastrentalfin}', props?.terminationValues?.latestRental);
  rawText = rawText.replace('{Usedserv}', props?.terminationValues?.adjStates?.servicesUsedAdj?.value);
  rawText = rawText.replace('{Maxserv}', props?.scheduleData?.ServicesAvailable);
  rawText = rawText.replace('{GrossTerm}', currencyFormatter(
      Math.abs(props?.terminationValues?.cancellationFigureGross),
  ));
  rawText = rawText.replace('{GrossTerm}', currencyFormatter(
      Math.abs(props?.terminationValues?.cancellationFigureGross),
  ));
  rawText = rawText.replace('{Incomegross}', currencyFormatter(
      Math.abs(props?.terminationValues?.incomeTotal * 1.2),
  ));
  rawText = rawText.replace('{Expendgross}', currencyFormatter(
      Math.abs((props?.terminationValues?.servicesAmountTotal + props?.terminationValues?.adminTotal) * 1.2),
  ));
  rawText = rawText.replace('{ExpendgrossNoAdmin}', currencyFormatter(
      Math.abs((props?.terminationValues?.servicesAmountTotal) * 1.2),
  ));
  rawText = rawText.replace('{GrossMileage}', currencyFormatter(
      Math.abs(props?.terminationValues?.excessMileageTotal * 1.2),
  ));
  rawText = rawText.replace('{GrossMileage}', currencyFormatter(
      Math.abs(props?.terminationValues?.excessMileageTotal * 1.2),
  ));
  rawText = rawText.replace('{RetailerName}', props?.validatorProps?.validatorData?.retailerName);
  rawText = rawText.replace('{RetailerName}', props?.validatorProps?.validatorData?.retailerName);
  return rawText;
};

export const htmlToPDFTemplateFormatting = (rawText) => {
  // Handle HTML
  rawText = replaceAllOccurances(rawText, '<br>', '\n');
  rawText = replaceAllOccurances(rawText, '<b>', '');
  rawText = replaceAllOccurances(rawText, '</b>', '');
  rawText = rawText.replace('</tr>', '\n');
  rawText = rawText.replace('</tr>', '\n');
  rawText = replaceAllOccurances(rawText, '</tr>', '');
  rawText = replaceAllOccurances(rawText, '<tr>', '');
  rawText = replaceAllOccurances(rawText, '<td>', '');
  rawText = replaceAllOccurances(rawText, '</td>', '');
  rawText = replaceAllOccurances(rawText, '</table>', '');
  rawText = replaceAllOccurances(rawText, '<table style=\'font-family:"CorpoS"\' cellspacing=10>', '');
  rawText = rawText.replace(/<[^>]+>/g, '');
  return rawText;
};

export const deceasedTemplatePDFText = (selectedTemplate, customerName, nextPaymentDate) => {
  let rawText = '';
  if (customerName !== 'To Whom it may Concern') {
    rawText = 'Dear ' + customerName + ',\n\n';
  } else {
    rawText = customerName + ',\n\n';
  }

  for (const templateLine of getDeceasedTemplateText(selectedTemplate)) {
    rawText = rawText + templateLine + '\n \n';
  }

  // Perform Replacements
  rawText = rawText.replace('{nextPaymentDate}', nextPaymentDate);
  const emailAdd = getAdminReturnAddress();
  rawText = rawText.replace('{Emailadd}', emailAdd);


  return rawText;
};

export const deceasedTemplateEmailText = (
    selectedTemplate, scheduleData, customerData, nextPaymentDate, signature) => {
  let rawText = '';
  if (customerData?.CustomerName !== 'To Whom it may Concern') {
    rawText = 'Dear ' + customerData?.CustomerName + ',<br><br>';
  } else {
    rawText = customerData?.CustomerName + ',<br><br>';
  }

  for (const templateLine of getDeceasedTemplateText(selectedTemplate)) {
    rawText = rawText + templateLine + '<br> <br>';
  }

  const emailAdd = '<a href="mailto:' + getAdminReturnAddress() + '?subject=Cancellation Response - ' +
  scheduleData?.ScheduleID + ' - ' +
  scheduleData?.Registration +
  '">' + getAdminReturnAddress() + '</a>';
  rawText = rawText.replace('{Emailadd}', emailAdd);
  rawText = rawText.replace('{nextPaymentDate}', nextPaymentDate);
  rawText = rawText + signature;
  rawText = replaceAllOccurances(rawText, '<br>', '\n');

  return rawText;
};

const getMainTemplateText = (selectedTemplate) => {
  return carVanSwitch(getMainTemplateTextCar(selectedTemplate), getMainTemplateTextVan(selectedTemplate));
};

const getMainTemplateTextCar = (selectedTemplate) => {
  if (EmailTemplatesCar[selectedTemplate] === undefined) {
    return EmailTemplatesCar.MissingTemplate;
  } else {
    return EmailTemplatesCar[selectedTemplate];
  }
};

const getMainTemplateTextVan = (selectedTemplate) => {
  if (EmailTemplatesVan[selectedTemplate] === undefined) {
    return EmailTemplatesVan.MissingTemplate;
  } else {
    return EmailTemplatesVan[selectedTemplate];
  }
};

const getDeceasedTemplateText = (selectedTemplate) => {
  if (DeceasedTemplates[selectedTemplate] === undefined) {
    return DeceasedTemplates.MissingTemplate;
  } else {
    return DeceasedTemplates[selectedTemplate];
  }
};

export const defaultEmailSignature = () => {
  return (
    'Kind Regards\n' +
    'Customer Support - ServiceCare\n' +
    'Mercedes-Benz Financial Services UK Limited\n' +
    'MK15 8BA\n\n' +
    'Mercedes-Benz Financial Services and Mercedes-Benz Finance are trading names of Mercedes-Benz ' +
    'Financial Services UK Limited. Registered in England and Wales (registered number 2472364). ' +
    'Delaware Drive, Tongwell, Milton Keynes, MK15 8BA. Authorised and regulated by the Financial Conduct ' +
    'Authority.\n\nMercedes-Benz Financial Services UK Limited takes your privacy seriously. Unless you ' +
    'have agreed otherwise your personal information will only be used to provide the products and ' +
    'services you have requested from us, and to administer your agreement. We will duly and diligently ' +
    'safeguard the privacy and confidentiality of your data. For more information on how we use your data, ' +
    'please see our <a href=' +
    '"https://www.mercedes-benz.co.uk/passengercars/content-pool/tool-pages/legal/privacy-policy.html"' +
    '>Privacy Policy</a>.'
  );
};
