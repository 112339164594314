// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { makeStyles, createStyles } from '@mui/styles';
import { API } from 'aws-amplify';
// Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { checkWholeNumber, sortArrayByProperty, toTitleCase, handleBackwardsAddress,
  reverseSortArrayByProperty, isValidScheduleID } from '../Libs/fileHelper';
import { getCustomerData } from '../Libs/apiHelper';
import LoadingPopup from '../Components/GeneralComponents/GeneralLoadingPopup';
import SearchPopup from '../Components/SearchPopup';
import config from '../Libs/Config';
import { currentStage, getCurrentEnvironment } from '../Libs/StageConfig';
// Resources

const useStyles = makeStyles((theme) => createStyles({
  quarterWidthButton: {
    width: '25%',
  },
  searchFields: {
    width: '100%',
  },
}));

export default function FrontSearch(
    { setSnackbarProps, setCustomerData, setScheduleData, setPendingData }) {
  const classes = useStyles();
  const navigate = useNavigate();

  // TO-DO Move apis on this page into apiHelper

  FrontSearch.propTypes = {
    setSnackbarProps: PropTypes.func.isRequired,
    setCustomerData: PropTypes.func.isRequired,
    setScheduleData: PropTypes.func.isRequired,
    setPendingData: PropTypes.func.isRequired,
  };

  const [searchSchedule, setSearchSchedule] = React.useState('');
  const [searchContract, setSearchContract] = React.useState('');
  const [searchCofico, setSearchCofico] = React.useState('');
  const [searchRegistration, setSearchRegistration] = React.useState('');
  const [searchChassis, setSearchChassis] = React.useState('');
  const [searchPostcode, setSearchPostcode] = React.useState('');
  const [searchEmail, setSearchEmail] = React.useState('');
  const [searchInProgress, setSearchInProgress] = React.useState(false);
  const [searchResultsMultiple, setSearchResultsMultiple] = React.useState([]);
  const [showSearchPopup, setShowSearchPopup] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState('');
  const [order, setOrder] = React.useState('');
  const [filterValue, setFilterValue] = React.useState('');

  const navigateToCustomerFromPopup = async (customerID) => {
    const win = window.open('/Customer?=' + customerID + '&tab=true', '_blank');
    win.focus();
  };

  const sortHandler = (sortValue) => {
    if (orderBy !== sortValue) {
      setOrderBy(sortValue);
      setOrder('asc');
    } else {
      if (order === 'asc') {
        setOrder('desc');
      } else {
        setOrder('asc');
      }
    }
  };

  const filteredData = () => {
    if (filterValue !== '') {
      return searchResultsMultiple.filter(
          (item) => (
            item.ScheduleID.toString().toLowerCase().includes(filterValue.toLowerCase()) ||
          item.CustomerID.toLowerCase().includes(filterValue.toLowerCase()) ||
          item.CustomerName.toLowerCase().includes(filterValue.toLowerCase()) ||
          item.Registration.toLowerCase().includes(filterValue.toLowerCase()) ||
          item.ChassisNo.toLowerCase().includes(filterValue.toLowerCase()) ||
          item.Status.toLowerCase().includes(filterValue.toLowerCase())
          ),
      );
    } else {
      return searchResultsMultiple;
    }
  };

  const filteredSortedData = () => {
    if (orderBy === '') {
      return filteredData();
    } else if (order === 'asc') {
      return sortArrayByProperty(filteredData(), orderBy);
    } else {
      return reverseSortArrayByProperty(filteredData(), orderBy);
    }
  };

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  function handleChangeSchedule(event) {
    if (isValidScheduleID(event.target.value.trim())) {
      setSearchSchedule(event.target.value.trim().toUpperCase());
    } else {
      setSnackbarProps({ open: true,
        message: 'Schedule ID must be of the format XXXXXXXX/XXXXXX',
        severity: 'warning' });
    }
  }

  function handleChangeContract(event) {
    setSearchContract(event.target.value.trim().toUpperCase());
  }
  function handleChangeCofico(event) {
    if (checkWholeNumber(event.target.value.trim())) {
      setSearchCofico(event.target.value.trim());
    } else {
      // Display message input must be Whole Numbers
      setSnackbarProps({ open: true,
        message: 'You may only enter whole numbers',
        severity: 'warning' });
    }
  }
  function handleChangeRegistration(event) {
    setSearchRegistration(event.target.value.toUpperCase());
  }
  function handleChangeChassis(event) {
    setSearchChassis(event.target.value.toUpperCase());
  }
  function handleChangePostcode(event) {
    const postCodetxt = event.target.value;
    const firstDigitPosition = postCodetxt.search(/\d/);
    if (postCodetxt.length >= 5 ) {
      if (!postCodetxt.includes(' ')) {
        const secondDigitPosition = postCodetxt.substring(firstDigitPosition+1).search(/\d/);
        const thirdDigitPosition = postCodetxt.substring(
            firstDigitPosition+secondDigitPosition+2).search(/\d/);
        if (thirdDigitPosition != -1) {
          const insertSpacePos = firstDigitPosition + secondDigitPosition + 2;
          setSearchPostcode([postCodetxt.slice(0, insertSpacePos),
            ' ', postCodetxt.slice(insertSpacePos)].join('').toUpperCase());
          return;
        } else if (secondDigitPosition != -1) {
          const insertSpacePos = firstDigitPosition + 1;
          setSearchPostcode([postCodetxt.slice(0, insertSpacePos),
            ' ', postCodetxt.slice(insertSpacePos)].join('').toUpperCase());
          return;
        }
      }
    }
    setSearchPostcode(postCodetxt.toUpperCase());
  }

  function handleChangeEmail(event) {
    setSearchEmail(event.target.value.toLowerCase());
  }

  const performClear = () => {
    setSearchSchedule('');
    setSearchContract('');
    setSearchCofico('');
    setSearchRegistration('');
    setSearchChassis('');
    setSearchPostcode('');
    setSearchEmail('');
  };

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      if (!searchInProgress) {
        performSearch();
      }
    }
  };

  const performSearch = async () => {
    if (searchSchedule === '' & searchContract === '' & searchChassis === '' &
     searchCofico === '' & searchPostcode === '' & searchRegistration === '' & searchEmail === '') {
      setSnackbarProps({ open: true,
        message: 'Please enter a search value',
        severity: 'warning' });
      return;
    }

    setSearchInProgress(true);
    let apiName = '';
    let params = {};
    if (searchSchedule !== '') {
      if (checkWholeNumber(searchSchedule)) {
        apiName = '/getschedulenumber';
        params = {
          ScheduleNo: parseInt(searchSchedule),
        };
      } else {
        apiName = '/getscheduleid';
        params = {
          ScheduleID: searchSchedule,
        };
      }
    } else if (searchContract !== '' ) {
      apiName = '/getcustomernumber';
      params = {
        CustomerID: searchContract,
      };
    } else if (searchChassis !== '' ) {
      apiName = '/getchassisnumber';
      params = {
        ChassisNo: searchChassis,
      };
    } else if (searchCofico !== '' ) {
      apiName = '/getcoficonumber';
      params = {
        CustomerCofico: searchCofico,
      };
    } else if (searchPostcode !== '' ) {
      apiName = '/getcustomerpostcode';
      params = {
        CustomerPostcode: searchPostcode,
      };
    } else if (searchRegistration !== '' ) {
      apiName = '/getregistration';
      params = {
        Registration: searchRegistration,
      };
    } else if (searchEmail !== '' ) {
      apiName = '/getcustomeremail';
      params = {
        CustomerEmail: searchEmail,
      };
    }

    API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.ICONInfo.NAME, apiName, {
      queryStringParameters: params,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      processResults(result);
    }).catch( (error) => {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
      setSearchInProgress(false);
    });
  };

  function processResults(result) {
    if (result.errorMessage !== undefined) {
      setSnackbarProps({ open: true,
        message: result.errorMessage,
        severity: 'warning' });
      setSearchInProgress(false);
    } else if (result.body === undefined || result.body === '[]') {
      setSnackbarProps({ open: true,
        message: 'No Results Found',
        severity: 'warning' });
      setSearchInProgress(false);
      return;
    } else {
      const resultJSON = JSON.parse(result.body);
      if (resultJSON.length === undefined) {
        // Single Result
        navigateToCustomer(resultJSON);
      } else {
        // Multiple Result Search
        if (resultJSON.length === 1) {
          // Single Result
          navigateToCustomer(resultJSON[0]);
        } else {
          if (resultJSON.filter((item) => item.CustomerID !== resultJSON[0].CustomerID).length === 0) {
            // Multiple Results Single Customer
            navigateToCustomer(resultJSON[0]);
          } else {
            // Multiple Results Multiple Customers
            setSearchResultsMultiple(sortArrayByProperty(resultJSON, 'CustomerName'));
            setShowSearchPopup(true);
            setSearchInProgress(false);
          }
        }
      }
    }
  }

  const navigateToCustomer = async (resultJSON) => {
    setCustomerData({
      CustomerID: resultJSON.CustomerID,
      CustomerName: toTitleCase(resultJSON.CustomerName),
      CustomerAddress: handleBackwardsAddress(resultJSON.CustomerAddress),
      CustomerPostcode: resultJSON.CustomerPostcode,
      CustomerEmail: resultJSON.CustomerEmail,
      CustomerPhone: resultJSON.CustomerPhone,
      CustomerMobile: resultJSON.CustomerMobile,
    });

    let finishedCallOne = false;
    let finishedCallTwo = false;

    // Call Schedule List
    API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.ICONInfo.NAME, '/getcustomernumber', {
      queryStringParameters: {
        CustomerID: resultJSON.CustomerID,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(async (result) => {
      if (result.LastEvaluatedKey) {
        const returnedData = await getCustomerData(
            resultJSON.CustomerID, setCustomerData, setScheduleData, setSnackbarProps);
        setScheduleData(orderItemToStartOfArray(returnedData, resultJSON));
      } else {
        setScheduleData(orderItemToStartOfArray(JSON.parse(result.body), resultJSON));
      }

      finishedCallOne = true;
      if (finishedCallTwo) {
        setSearchInProgress(false);
        navigate('/Customer?=' + resultJSON.CustomerID);
      }
    }).catch( (error) => {
      setSearchInProgress(false);
      setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
    });

    // Call PendHist List
    API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory.NAME, '/getcustomerid', {
      queryStringParameters: {
        CustomerID: resultJSON.CustomerID,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      setPendingData(JSON.parse(result.body));
      finishedCallTwo = true;
      if (finishedCallOne ) {
        setSearchInProgress(false);
        navigate('/Customer?=' + resultJSON.CustomerID);
      }
    }).catch( (error) => {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
      setSearchInProgress(false);
    });


    // window.location.href= 'Customer?=' + resultJSON.CustomerID;
  };

  const orderItemToStartOfArray = (scheduleArray, startSchedule) => {
    const i = scheduleArray.findIndex((arrayItem) => arrayItem.ScheduleID === startSchedule.ScheduleID);
    if (i===0) {
      return scheduleArray;
    }
    if (i>0) {
      scheduleArray.splice(i, 1);
    }
    return [startSchedule, ...scheduleArray];
  };

  return (
    <React.Fragment>
      <TextField id='searchSchedule' label='Schedule ID' className={classes.searchFields}
        value={searchSchedule} onKeyPress={handleKeypress} onChange={handleChangeSchedule}
        variant='standard'/>
      <TextField id='searchContract' label='Schedule Contract Number' className={classes.searchFields}
        value={searchContract} onKeyPress={handleKeypress} onChange={handleChangeContract}
        variant='standard'/>
      <TextField id='searchCofico' label='Cofico Number' className={classes.searchFields}
        value={searchCofico} onKeyPress={handleKeypress} onChange={handleChangeCofico}
        variant='standard'/>
      <TextField id='searchRegistration' label='Registration' className={classes.searchFields}
        value={searchRegistration} onKeyPress={handleKeypress} onChange={handleChangeRegistration}
        variant='standard'/>
      <TextField id='searchChassis' label='Chassis Number' className={classes.searchFields}
        value={searchChassis} onKeyPress={handleKeypress} onChange={handleChangeChassis}
        variant='standard'/>
      <TextField id='searchPostcode' label='Postcode' className={classes.searchFields}
        value={searchPostcode} onKeyPress={handleKeypress} onChange={handleChangePostcode}
        variant='standard'/>
      <TextField id='searchEmail' label='Email' className={classes.searchFields}
        value={searchEmail} onKeyPress={handleKeypress} onChange={handleChangeEmail}
        variant='standard'/>
      <Button variant='contained' color='primary' className={classes.quarterWidthButton}
        sx={{ margin: 1 }}
        onClick={performSearch}>
        Search
      </Button>
      <Button variant='contained' color='primary' className={classes.quarterWidthButton}
        sx={{ margin: 1 }}
        onClick={performClear}>
        Clear
      </Button>
      {searchInProgress &&
      <LoadingPopup loadingText='Searching...'/> }
      {showSearchPopup &&
      <SearchPopup
        setShowSearchPopup={setShowSearchPopup}
        orderBy={orderBy}
        order={order}
        filterValue={filterValue}
        handleFilterChange={handleFilterChange}
        sortHandler={sortHandler}
        filteredSortedData={filteredSortedData}
        navigateToCustomerFromPopup={navigateToCustomerFromPopup}
      />}
    </React.Fragment>
  );
}
