// Functions
import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Resources

export default function TerminationWarningPopup({
  warningMessageArray, pushToTerminationPage, terminationWarningPopup, setTerminationWarningPopup }) {
  TerminationWarningPopup.propTypes = {
    warningMessageArray: PropTypes.array.isRequired,
    pushToTerminationPage: PropTypes.func.isRequired,
    terminationWarningPopup: PropTypes.bool.isRequired,
    setTerminationWarningPopup: PropTypes.func.isRequired,
  };

  return (
    <Dialog open={terminationWarningPopup} onClose={()=>{
      setTerminationWarningPopup(false);
    }}
    aria-labelledby="form-dialog-title" >
      <DialogTitle id="form-dialog-title">Termination Warnings</DialogTitle>
      <DialogContent>
        {warningMessageArray.map((warningMessage, index) => (
          <div key={index}>{index + 1} - {warningMessage}</div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={pushToTerminationPage} color="primary">
          Acknowledge
        </Button>
        <Button onClick={()=>{
          setTerminationWarningPopup(false);
        }} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
