// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { postUpdateEmailConfig } from '../../Libs/emailAPIHelper';
import { groupMailboxes } from '../../Libs/emailHelper';
// Resources

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    width: '500px',
    margin: theme.spacing(1),
  },
  dialogTitle: {
    paddingTop: theme.spacing(1),
    textAlign: 'center',
  },
  textFieldStyle: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));

export default function EmailConfigPopup({ emailConfigData, setEmailConfigData, setSnackbarProps }) {
  const classes = useStyles();

  EmailConfigPopup.propTypes = {
    emailConfigData: PropTypes.object.isRequired,
    setEmailConfigData: PropTypes.func.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
  };


  function handleClose() {
    setEmailConfigData({
      ...emailConfigData,
      showPopup: false,
    });
  }

  function handleInputChange(event) {
    setEmailConfigData({
      ...emailConfigData,
      [event.target.name]: event.target.value,
    });
  }

  async function handleSave() {
    const body = {
      defaultMailbox: emailConfigData. defaultMailbox,
      signature: emailConfigData.signature,
    };
    const result = await postUpdateEmailConfig(body, setSnackbarProps);
    if (result.status === 200) {
      handleClose();
    }
  }

  return (
    <Dialog open={emailConfigData.showPopup} onClose={handleClose} aria-labelledby="form-dialog-title" >
      <DialogTitle className={classes.dialogTitle} id="form-dialog-title">Send Email</DialogTitle>
      <DialogContent className={classes.dialogContainer}>
        <TextField select
          margin="dense"
          id="emailSentFrom"
          name="defaultMailbox"
          label="Default Sending Account"
          type="email"
          fullWidth
          value={emailConfigData.defaultMailbox}
          onChange={handleInputChange}>
          {groupMailboxes.map((item, index)=> (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          value={emailConfigData.signature}
          className={classes.textFieldStyle}
          multiline
          maxRows={18}
          label={'Email Signature'}
          variant="outlined"
          onChange={handleInputChange}
          name="signature"
          id='emailConfigSignature'/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
