
import { API } from 'aws-amplify';
import { sortArrayByProperty, reverseSortArrayByProperty, toTitleCase,
  handleBackwardsAddress } from '../Libs/fileHelper';
import config from '../Libs/Config';
import { currentStage, getCurrentEnvironment } from '../Libs/StageConfig';

export const createAndCompletePendingItem =
  async (body, userName, pendingData, setPendingData, setSnackbarProps, selectedData, setSelected) => {
    return await API.post(config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory?.NAME,
        '/completenew', {
          body: body,
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((result) => {
      if (result.statusCode !== 200 & result.body !== undefined) {
        setSnackbarProps({ open: true,
          message: 'Something went wrong: ' + result.body,
          severity: 'error' });
        return { status: result.statusCode, result: result.body };
      } else {
        const updatedPendingData = [
          ...pendingData,
          { ID: result.ID,
            Status: 'History',
            ActionType: body.ActionType,
            CreatedDate: Date.now(),
            Reference: body.Reference,
            ScheduleID: body.ScheduleID,
            CreatedBy: userName,
            CompletedDate: Date.now(),
            CompletedBy: userName,
            ScheduledDate: null,
          },
        ];
        if (setSelected !== undefined) {
          setSelected({
            ...selectedData,
            ID: result.ID,
            Status: 'History',
            CompletedDate: Date.now(),
            CompletedBy: userName,
          });
        }
        setPendingData(updatedPendingData);
        return { status: 200, result: result.ID, updatedPendingData: updatedPendingData };
      }
    }).catch( (error) => {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
      return { status: 500, result: error };
    });
  };

export const createPendingItem =
    async (body, userName, pendingData, setPendingData, setSnackbarProps) => {
      return await API.post(config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory?.NAME,
          '/postnew', {
            body: body,
            headers: {
              'Content-Type': 'application/json',
            },
          }).then((result) => {
        if (result.statusCode !== 200 & result.body !== undefined) {
          setSnackbarProps({ open: true,
            message: 'Something went wrong: ' + result.body,
            severity: 'error' });
          return { status: result.statusCode, result: result.body };
        } else {
          setPendingData([
            ...pendingData,
            { ID: result.ID,
              Status: 'Pending',
              ActionType: body.ActionType,
              CreatedDate: Date.now(),
              Reference: body.Reference,
              ScheduleID: body.ScheduleID,
              CreatedBy: userName,
              ScheduledDate: null,
            },
          ]);
          return { status: 200, result: result.ID };
        }
      }).catch( (error) => {
        setSnackbarProps({ open: true,
          message: 'Something went wrong: ' + error,
          severity: 'error' });
        return { status: 500, result: error };
      });
    };

export const updatePendingItem = async (body, setSnackbarProps) => {
  return await API.post(config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory?.NAME,
      '/update', {
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((result) => {
    return { status: 200 };
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return { status: 500, result: error };
  });
};

export const postActionType = async (body, setSnackbarProps) => {
  return await API.post(config[getCurrentEnvironment()][currentStage()].apiGateway.ActionType?.NAME, '/', {
    body: body,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((result) => {
    if (result.statusCode !== 200 & result.body !== undefined) {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + result.body,
        severity: 'error' });
      return { status: result.statusCode, result: result.body };
    } else {
      return { status: 200 };
    }
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return { status: 500, result: error };
  });
};

export const postDealerRecord = async (body, setSnackbarProps) => {
  return await API.post(config[getCurrentEnvironment()][currentStage()].apiGateway.Aux?.NAME, '/dealer', {
    body: body,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((result) => {
    if (result.statusCode !== 200 & result.body !== undefined) {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + result.body,
        severity: 'error' });
      return { status: result.statusCode, result: result.body };
    } else {
      return { status: 200 };
    }
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return { status: 500, result: error };
  });
};

export const deleteActionType = async (actionType, setSnackbarProps) => {
  return await API.del(config[getCurrentEnvironment()][currentStage()].apiGateway.ActionType?.NAME, '/', {
    body: {
      ActionType: actionType,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((result) => {
    if (result.statusCode !== 200 & result.body !== undefined) {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + result.body,
        severity: 'error' });
      return { status: result.statusCode, result: result.body };
    } else {
      return { status: 200 };
    }
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return { status: 500, result: error };
  });
};

export const deleteDealerNumber = async (dealerNumber, setSnackbarProps) => {
  return await API.del(config[getCurrentEnvironment()][currentStage()].apiGateway.Aux?.NAME, '/dealer', {
    body: {
      dealerNumber: dealerNumber,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((result) => {
    if (result.statusCode !== 200 & result.body !== undefined) {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + result.body,
        severity: 'error' });
      return { status: result.statusCode, result: result.body };
    } else {
      return { status: 200 };
    }
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return { status: 500, result: error };
  });
};

export const getResultsForActionCode = async (actionCode, setSnackbarProps) => {
  return await API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory?.NAME,
      '/queryactiontypes', {
        queryStringParameters: { ActionType: actionCode },
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((result) => {
    const resultJSON = result.body;
    if (resultJSON.length === undefined || resultJSON.length === 0) {
      if (resultJSON.body === undefined) {
        // No Resuult
        return { status: 201, result: 'No Results' };
      } else {
      // Single Result
        return { status: 200, result: [resultJSON] };
      }
    } else {
      // Multiple Result
      return { status: 200, result: resultJSON };
    }
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return { status: 500, result: error };
  });
};

export const getActionCodesWithCount = async ( setSnackbarProps, setActionCodeLookup) => {
  return await API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.PendingHistory?.NAME,
      '/scanactiontypes', {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then( async (result) => {
    if (result.body === undefined) {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: No results found.',
        severity: 'error' });
      return;
    } else {
      const resultJSON = JSON.parse(result.body);
      let buildingPending = [];
      for (const key of Object.keys(resultJSON)) {
        buildingPending = [...buildingPending,
          { actionCode: key, actionDesc: 'Unknown Code: ' + key, actionTotal: resultJSON[key] }];
      }
      return await addActionDescriptionsToArray(buildingPending, setSnackbarProps, setActionCodeLookup);
    }
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return { status: 500, result: error };
  });
};

export const addActionDescriptionsToArray =
  async (buildingPending, setSnackbarProps, setActionCodeLookup) => {
    return await API.get(
        config[getCurrentEnvironment()][currentStage()].apiGateway.ActionType?.NAME, '/', {
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((result) => {
      setActionCodeLookup(JSON.parse(result.body));
      for (const lines of JSON.parse(result.body)) {
        const index = buildingPending.findIndex((penditem) => penditem.actionCode === lines.ActionType);
        if (index !== -1) {
          buildingPending[index] = { ...buildingPending[index], actionDesc: lines.ActionDescription };
        }
      }
      return { status: 200, result: sortArrayByProperty(buildingPending, 'actionCode') };
    }).catch( (error) => {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + error,
        severity: 'error' });
      return { status: 500, result: error };
    });
  };

export const getListOfApprovedUsers = async (setSnackbarProps, nextToken) => {
  let params = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (nextToken) {
    params = { ...params, queryStringParameters: { NextToken: nextToken } };
  }
  return await API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.UserInfo?.NAME,
      '/getApprovedUsers', params).then((result) => {
    return { status: 200, result: JSON.parse(result.body), nextToken: result.nextToken };
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return { status: 500, result: error };
  });
};

export const getCustomerData = async (customerID, setCustomerData, setScheduleData, setSnackbarProps,
    rollingData, startKey) => {
  const queryParams = {
    CustomerID: customerID,
    ExclusiveStartKeyScheduleID: startKey?.ScheduleID,
  };
  // Call Schedule List
  const response = await API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.ICONInfo?.NAME,
      '/getcustomernumber', {
        queryStringParameters: queryParams,
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((result) => {
    const jsonBody = JSON.parse(result.body || '[]');
    if (jsonBody.length === 0 || jsonBody.length === undefined) {
      setScheduleData([]);
      setCustomerData({
        CustomerID: customerID,
        CustomerName: 'Unknown',
        CustomerAddress: 'Unknown',
        CustomerPostcode: 'Unknown',
        CustomerEmail: 'Unknown',
        CustomerPhone: '0',
        CustomerMobile: '0',
      });
      return {};
    } else {
      return result;
    }
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return {};
  });

  const combinedData = JSON.parse(response.body || '[]');
  if (rollingData) {
    for (let i=0; i<rollingData.length; i++) {
      if (combinedData.filter((item) => (item.ScheduleID === rollingData[i].ScheduleID)).length === 0) {
        combinedData.push(rollingData[i]);
      }
    }
  }

  setScheduleData(reverseSortArrayByProperty(combinedData, 'ScheduleID'));
  setCustomerData({
    CustomerID: combinedData[0]?.CustomerID,
    CustomerName: toTitleCase(combinedData[0]?.CustomerName),
    CustomerAddress: handleBackwardsAddress(combinedData[0]?.CustomerAddress),
    CustomerPostcode: combinedData[0]?.CustomerPostcode,
    CustomerEmail: combinedData[0]?.CustomerEmail,
    CustomerPhone: combinedData[0]?.CustomerPhone,
    CustomerMobile: combinedData[0]?.CustomerMobile,
  });

  if (response.LastEvaluatedKey) {
    if (response.LastEvaluatedKey?.ScheduleID !== startKey?.ScheduleID) {
      const additionalData = await getCustomerData(
          customerID, setCustomerData, setScheduleData, setSnackbarProps, combinedData,
          response.LastEvaluatedKey);
      return additionalData;
    }
  }
  return combinedData;
};

export const getCustomerDataOnCofico = async (coficoNo, setCustomerData, setScheduleData, setSnackbarProps,
    rollingData, startKey) => {
  const queryParams = {
    CustomerCofico: coficoNo,
    ExclusiveStartKeyScheduleID: startKey?.ScheduleID,
  };
  // Call Schedule List
  const response = await API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.ICONInfo?.NAME,
      '/getcoficonumber', {
        queryStringParameters: queryParams,
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((result) => {
    const jsonBody = JSON.parse(result.body || '[]');
    if (jsonBody.length === 0 || jsonBody.length === undefined) {
      setScheduleData([]);
      return {};
    } else {
      return result;
    }
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return {};
  });

  const combinedData = JSON.parse(response.body || '[]');
  if (rollingData) {
    for (let i=0; i<rollingData.length; i++) {
      if (combinedData.filter((item) => (item.ScheduleID === rollingData[i].ScheduleID)).length === 0) {
        combinedData.push(rollingData[i]);
      }
    }
  }

  setScheduleData(reverseSortArrayByProperty(combinedData, 'ScheduleID'));
  setCustomerData({
    CustomerID: combinedData[0]?.CustomerID,
    CustomerName: toTitleCase(combinedData[0]?.CustomerName),
    CustomerAddress: handleBackwardsAddress(combinedData[0]?.CustomerAddress),
    CustomerPostcode: combinedData[0]?.CustomerPostcode,
    CustomerEmail: combinedData[0]?.CustomerEmail,
    CustomerPhone: combinedData[0]?.CustomerPhone,
    CustomerMobile: combinedData[0]?.CustomerMobile,
  });

  if (response.LastEvaluatedKey) {
    if (response.LastEvaluatedKey?.ScheduleID !== startKey?.ScheduleID) {
      const additionalData = await getCustomerDataOnCofico(
          coficoNo, setCustomerData, setScheduleData, setSnackbarProps, combinedData,
          response.LastEvaluatedKey);
      return additionalData;
    }
  }
  return combinedData;
};

export const getClaimsData = async (claimVin, setSnackbarProps, rollingData, startKey) => {
  const queryParams = {
    ChassisNo: claimVin,
    ExclusiveStartKeyIndexDateOfClaimMileage: startKey?.IndexDateOfClaimMileage,
  };
  // Call Claims List
  const response = await API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.ClaimsData.NAME,
      '/getonchassis', {
        queryStringParameters: queryParams,
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((result) => {
    const jsonBody = JSON.parse(result.body || '[]');
    if (jsonBody.length === undefined) {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + result.body,
        severity: 'error' });
      return '[]';
    } else {
      return result;
    }
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return '[]';
  });


  let combinedData = JSON.parse(response.body || '[]');

  if (rollingData) {
    combinedData = [...rollingData, ...combinedData];
  }

  if (response.LastEvaluatedKey) {
    if (response.LastEvaluatedKey?.IndexDateOfClaimMileage !==
      startKey?.IndexDateOfClaimMileage) {
      const additionalData = await getClaimsData(
          claimVin, setSnackbarProps, combinedData, response.LastEvaluatedKey);
      return additionalData;
    }
  }
  return combinedData;
};


export const getFlexData = async (scheduleID, setSnackbarProps, rollingData, startKey) => {
  const queryParams = {
    ScheduleID: scheduleID,
    ExclusiveStartKeyUUID: startKey?.UUID,
  };
  // Call Claims List
  const response = await API.get('Saltmine-Van-API-Flex', '/', {
    queryStringParameters: queryParams,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((result) => {
    const jsonBody = JSON.parse(result.body || '[]');
    if (jsonBody.length === undefined) {
      setSnackbarProps({ open: true,
        message: 'Something went wrong: ' + result.body,
        severity: 'error' });
      return '[]';
    } else {
      return result;
    }
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return '[]';
  });


  let combinedData = JSON.parse(response.body || '[]');

  if (rollingData) {
    combinedData = [...rollingData, ...combinedData];
  }

  if (response.LastEvaluatedKey) {
    if (response.LastEvaluatedKey?.UUID !==
      startKey?.UUID) {
      const additionalData = await getFlexData(
          scheduleID, setSnackbarProps, combinedData, response.LastEvaluatedKey);
      return additionalData;
    }
  }
  return combinedData;
};

export const getInvoiceManagementCustomerData = async (customerID, setInvoiceManagementData, setSnackbarProps,
    rollingData, startKey) => {
  let queryParams = {
    CustomerID: customerID,
  };
  if (startKey) {
    queryParams = {
      ...queryParams,
      ExclusiveStartKeySK: startKey.SK,
    };
  }
  // Call Schedule List
  const response = await API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.Invoice?.NAME,
      '/getCustomerData', {
        queryStringParameters: queryParams,
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((result) => {
    const jsonBody = JSON.parse(result.body || '[]');
    if (jsonBody.length === 0 || jsonBody.length === undefined) {
      return {};
    } else {
      return result;
    }
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return {};
  });

  const combinedData = JSON.parse(response.body || '[]');
  if (rollingData) {
    for (let i=0; i<rollingData.length; i++) {
      if (combinedData.filter((item) => (item.SK === rollingData[i].SK)).length === 0) {
        combinedData.push(rollingData[i]);
      }
    }
  }

  setInvoiceManagementData(combinedData);

  if (response.LastEvaluatedKey) {
    if (response.LastEvaluatedKey?.SK !== startKey?.SK) {
      const additionalData = await getInvoiceManagementCustomerData(
          customerID, setInvoiceManagementData, setSnackbarProps, combinedData,
          response.LastEvaluatedKey);
      return additionalData;
    }
  }
  return combinedData;
};

export const getInvoiceManagementScheduleEvents = async (scheduleID, setSnackbarProps,
    rollingData, startKey) => {
  let queryParams = {
    ScheduleID: scheduleID,
  };
  if (startKey) {
    queryParams = {
      ...queryParams,
      ExclusiveStartKeySK: startKey.SK,
    };
  }
  // Call Schedule List
  const response = await API.get(config[getCurrentEnvironment()][currentStage()].apiGateway.Invoice?.NAME,
      '/getScheduleEvents', {
        queryStringParameters: queryParams,
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((result) => {
    const jsonBody = JSON.parse(result.body);
    if (jsonBody.length === 0 || jsonBody.length === undefined) {
      return {};
    } else {
      return result;
    }
  }).catch( (error) => {
    setSnackbarProps({ open: true,
      message: 'Something went wrong: ' + error,
      severity: 'error' });
    return {};
  });

  const combinedData = JSON.parse(response.body || '[]');
  if (rollingData) {
    for (let i=0; i<rollingData.length; i++) {
      if (combinedData.filter((item) => (item.SK === rollingData[i].SK)).length === 0) {
        combinedData.push(rollingData[i]);
      }
    }
  }

  if (response.LastEvaluatedKey) {
    if (response.LastEvaluatedKey?.SK !== startKey?.SK) {
      await getInvoiceManagementCustomerData(scheduleID, setSnackbarProps,
          combinedData, response.LastEvaluatedKey);
    } else {
      return combinedData;
    }
  } else {
    return combinedData;
  }
};
