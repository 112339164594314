// Functions
import React from 'react';
import PropTypes from 'prop-types';
// Components
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ukDateStringToDate } from '../../Libs/fileHelper';
// Resources

export default function FlexContractDetails({ flexDataMain, flexDataRecords, claimsData, scheduleData,
  updateMainFlex, aServicesAvailable, bServicesAvailable }) {
  const sxClasses = {
    containerStyle: {
      paddingTop: '4px',
      paddingRight: '32px',
    },
    paperStyle: {
      width: '100%',
      border: '1px solid',
      borderColor: '#000000',
      height: '169px',
      padding: 1,
      marginLeft: 1,
      paddingTop: 1,
    },
    disabledTextStyles: {
      marginTop: '6px',
      width: '100%',
      color: '#000000',
      textAlignLast: 'center',
    },
    buttonStyle: {
      marginTop: 2,
      width: '100%',
    },
  };

  FlexContractDetails.propTypes = {
    flexDataMain: PropTypes.object.isRequired,
    flexDataRecords: PropTypes.array.isRequired,
    claimsData: PropTypes.array.isRequired,
    scheduleData: PropTypes.object.isRequired,
    updateMainFlex: PropTypes.func.isRequired,
    aServicesAvailable: PropTypes.func.isRequired,
    bServicesAvailable: PropTypes.func.isRequired,
  };

  const calculateForecastDate = () => {
    const latestFlexClaim = Math.max(...flexDataRecords.map((o) => o.dealerRequestDate || 0), 0);
    const percentageUsed = flexDataRecords.filter((item) => item.UUID !== 'Main').length /
      flexDataMain.TotalServices;
    if (latestFlexClaim === 0 || percentageUsed === 0) {
      if (scheduleData?.EndDate) {
        return (ukDateStringToDate(scheduleData.EndDate)).toLocaleDateString();
      } else {
        return 'N/A';
      }
    }
    const startAsDate = ukDateStringToDate(scheduleData.StartDate);
    const daysBetweenStartAndLatest = Math.floor(
        ( latestFlexClaim - Date.parse(startAsDate)
        ) / 86400000);
    startAsDate.setTime(
        startAsDate.getTime() + Math.floor((daysBetweenStartAndLatest / percentageUsed) * 86400000),
    );
    return startAsDate.toLocaleDateString();
  };

  const totalServicesText = () => {
    if (!flexDataMain.TotalServices) {
      return ' - ';
    }
    if (flexDataMain.AutoServices !== undefined && flexDataMain.AutoServices !== '') {
      return ('Main: ' + flexDataMain.TotalServices + '  Auto: ' + flexDataMain.AutoServices);
    } else {
      return flexDataMain.TotalServices;
    }
  };

  const aServicesText = () => {
    return ('Used: ' + flexDataRecords.filter((item) => item.serviceType === 'A').length + '  Remain: ' +
    (aServicesAvailable() - flexDataRecords.filter((item) => item.serviceType === 'A').length));
  };

  const bServicesText = () => {
    return ('Used: ' + flexDataRecords.filter((item) => item.serviceType === 'B').length + '  Remain: ' +
    (bServicesAvailable() - flexDataRecords.filter((item) => item.serviceType === 'B').length));
  };

  const autoServicesText = () => {
    return ('Used: ' + flexDataRecords.filter(
        (item) => item.autoChecked === true && item.UUID !== 'Main',
    ).length + '  Remain: ' +
     (flexDataMain.AutoServices - flexDataRecords.filter(
         (item) => item.autoChecked === true && item.UUID !== 'Main').length));
  };

  const autoServicesGridWidth = () => {
    if (flexDataMain.AutoServices !== undefined && flexDataMain.AutoServices !== '') {
      return 3;
    } else {
      return 6;
    }
  };

  return (
    <Box sx={sxClasses.containerStyle}>
      <Paper sx={sxClasses.paperStyle}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              value={totalServicesText()}
              sx={sxClasses.disabledTextStyles}
              label="Total Services Available"
              inputProps={{
                style: {
                  padding: 5,
                  color: '#000000',
                },
              }}
              id='txtTotalServices'/>
          </Grid>
          <Grid item xs={6}>
            <Button sx={sxClasses.buttonStyle} variant='contained' color='primary'
              onClick={updateMainFlex}>
              Update
            </Button>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={aServicesText()}
              sx={sxClasses.disabledTextStyles}
              label="A Services"
              inputProps={{
                style: {
                  padding: 5,
                  color: '#000000',
                },
              }}
              id='txtAServices'/>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={bServicesText()}
              sx={sxClasses.disabledTextStyles}
              label="B Services"
              inputProps={{
                style: {
                  padding: 5,
                  color: '#000000',
                },
              }}
              id='txtBServices'/>
          </Grid>
          <Grid item xs={autoServicesGridWidth()}>
            <TextField
              value={calculateForecastDate()}
              sx={sxClasses.disabledTextStyles}
              label="Forecast Used Date"
              inputProps={{
                style: {
                  padding: 5,
                  color: '#000000',
                },
              }}
              id='txtForecastDate'/>
          </Grid>
          <Grid item xs={autoServicesGridWidth()}>
            <TextField
              value={flexDataRecords.filter((item) => item.ConnectedClaim === 'None').length +
              ' - ' + claimsData.length}
              sx={sxClasses.disabledTextStyles}
              label="Unlinked Claims"
              inputProps={{
                style: {
                  padding: 5,
                  color: '#000000',
                },
              }}
              id='txtUnlinkedClaims'/>
          </Grid>
          {(flexDataMain.AutoServices !== undefined && flexDataMain.AutoServices !== '') &&
            <Grid item xs={6}>
              <TextField
                value={autoServicesText()}
                sx={sxClasses.disabledTextStyles}
                label="Auto Services"
                inputProps={{
                  style: {
                    padding: 5,
                    color: '#000000',
                  },
                }}
                id='txtAutoServices'/>
            </Grid>
          }
        </Grid>
      </Paper>
    </Box>
  );
}
