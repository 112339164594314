// Functions
import React from 'react';
import PropTypes from 'prop-types';
// Components
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
// Resources

export default function TerminationsRetailerNameEntry({ validatorData, setValidatorData }) {
  TerminationsRetailerNameEntry.propTypes = {
    validatorData: PropTypes.shape({
      hidden: PropTypes.bool.isRequired,
      retailerName: PropTypes.string.isRequired,
    }),
    setValidatorData: PropTypes.func.isRequired,
  };

  const sxClasses = {
    titleStyle: {
      margin: 'auto',
    },
    textFieldStyle: {
      width: '100%',
    },
  };

  function handleRetailerChange(event) {
    setValidatorData({ ...validatorData, retailerName: event.target.value });
  }

  return (
    <React.Fragment>
      {validatorData.hidden ? null :
      <Grid container spacing={0}>
        <Grid item xs={3} sx={sxClasses.titleStyle}>
          Retailer Name:
        </Grid>
        <Grid item xs={9} >
          <TextField id='txtRetailerName'
            value={validatorData.retailerName}
            sx={sxClasses.textFieldStyle}
            onChange={handleRetailerChange}/>
        </Grid>
      </Grid>
      }
    </React.Fragment>

  );
}
