// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Components
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { undefinedToEmptyString } from '../../Libs/fileHelper';
// Resources


const useStyles = makeStyles((theme) => ({
  containerStyle: {
    paddingRight: theme.spacing(3),
  },
  tableStyle: {
    padding: theme.spacing(1),
    height: 'calc(100vh - 230px)',
    width: '100%',
    minHeight: '415px',
    border: '1px solid',
    borderColor: '#000000',
  },
  titleFields: {
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderColor: '#BBBBBB',
    border: '1px dashed',
    marginBottom: theme.spacing(1),
  },
  textFieldStyle: {
    width: '100%',
  },
  buttonStyle: {
    width: '100%',
  },
}));

export default function DeceasedItemDetails({ decFields, handleDecChange, handleDecActionButtonPress }) {
  const classes = useStyles();

  DeceasedItemDetails.propTypes = {
    decFields: PropTypes.object.isRequired,
    handleDecChange: PropTypes.func.isRequired,
    handleDecActionButtonPress: PropTypes.func.isRequired,
  };

  return (
    <div className={classes.containerStyle}>
      <Paper className={classes.tableStyle}>
        <div className={classes.titleFields} >
          Account Details
          <Grid container spacing={2} sx={{ marginTop: -1 }}>
            <Grid item xs={4} >
              <TextField value={undefinedToEmptyString(decFields.accountBalance)}
                className={classes.textFieldStyle} size="small"
                onChange={handleDecChange} name='accountBalance' label='Account Balance'/>
            </Grid>
            <Grid item xs={4} >
              <TextField select value={undefinedToEmptyString(decFields.accountSuspended)}
                className={classes.textFieldStyle} size="small"
                onChange={handleDecChange} name='accountSuspended' label="Account Suspended">
                <MenuItem key={0} value={'Yes'}>
                  Yes
                </MenuItem>
                <MenuItem key={1} value={'N/A'}>
                  N/A
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={4} >
              <TextField select value={undefinedToEmptyString(decFields.pdAttribute)}
                className={classes.textFieldStyle} size="small"
                onChange={handleDecChange} name='pdAttribute' label="PD To Attribute 3">
                <MenuItem key={0} value={'Yes'}>
                  Yes
                </MenuItem>
                <MenuItem key={1} value={'N/A'}>
                  N/A
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </div>
        <div className={classes.titleFields} >
          Bank Notification
          <Grid container spacing={2} sx={{ marginTop: -1 }}>
            <Grid item xs={4} >
              <DeceasedActionField value={undefinedToEmptyString(decFields.bankNotificationSent)}
                name={'bankNotificationSent'}
                textlabel={'Letter Sent'}
                buttonLabel={'Send Letter'}
                isHidden={false}
                handleDecChange={handleDecChange}
                handleDecActionButtonPress={handleDecActionButtonPress}/>
            </Grid>
            <Grid item xs={4} >
              <DeceasedActionField value={undefinedToEmptyString(decFields.bankNotificationChase)}
                name={'bankNotificationChase'}
                textlabel={'Chase Sent'}
                buttonLabel={'Send Chase'}
                isHidden={!decFields.bankNotificationSent}
                handleDecChange={handleDecChange}
                handleDecActionButtonPress={handleDecActionButtonPress}/>
            </Grid>
            <Grid item xs={4} >
              <DeceasedActionField value={undefinedToEmptyString(decFields.bankNotificationCall)}
                name={'bankNotificationCall'}
                textlabel={'Chase Via Phone'}
                buttonLabel={'Call Made'}
                isHidden={(!decFields.bankNotificationChase || !decFields.bankNotificationSent)}
                handleDecChange={handleDecChange}
                handleDecActionButtonPress={handleDecActionButtonPress}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.titleFields} >
          BIF & INT
          <Grid container spacing={2} sx={{ marginTop: -1 }}>
            <Grid item xs={4} >
              <DeceasedActionField value={undefinedToEmptyString(decFields.bifSent)}
                name={'bifSent'}
                textlabel={'BIF Sent'}
                buttonLabel={'Send BIF'}
                isHidden={false}
                handleDecChange={handleDecChange}
                handleDecActionButtonPress={handleDecActionButtonPress}/>
            </Grid>
            <Grid item xs={4} >
              {decFields.bifSent &&
                <TextField select value={undefinedToEmptyString(decFields.intentionRecieved)}
                  className={classes.textFieldStyle} size="small"
                  onChange={handleDecChange} name='intentionRecieved' label="Intention Recieved">
                  <MenuItem key={0} value={'Terminate'}>
                    Terminate
                  </MenuItem>
                  <MenuItem key={1} value={'Novate'}>
                    Novation
                  </MenuItem>
                  <MenuItem key={2} value={'Other'}>
                    Other
                  </MenuItem>
                </TextField>
              }
            </Grid>
            <Grid item xs={4} >
              <DeceasedActionField value={undefinedToEmptyString(decFields.bifRecieved)}
                name={'bifRecieved'}
                textlabel={'BIF Recieved'}
                buttonLabel={'BIF Recieved'}
                isHidden={!decFields.bifSent}
                handleDecChange={handleDecChange}
                handleDecActionButtonPress={handleDecActionButtonPress}/>
            </Grid>
          </Grid>
        </div>
        {(!decFields.bifRecieved || !decFields.intentionRecieved) &&
          <div className={classes.titleFields} >
            BIF & INT Chase
            <Grid container spacing={2} sx={{ marginTop: -1 }}>
              <Grid item xs={4}>
                <DeceasedActionField value={''}
                  name={'bifIntChase'}
                  textlabel={'BIF & INT Chase'}
                  buttonLabel={'Chase BIF & INT'}
                  isHidden={(decFields.bifChase || decFields.intentionChase || !decFields.bifSent)}
                  handleDecChange={handleDecChange}
                  handleDecActionButtonPress={handleDecActionButtonPress}/>
              </Grid>
              <Grid item xs={4} >
                <DeceasedActionField value={undefinedToEmptyString(decFields.bifChase)}
                  name={'bifChase'}
                  textlabel={'BIF Chased'}
                  buttonLabel={'Chase BIF'}
                  isHidden={(decFields.bifRecieved || !decFields.bifSent)}
                  handleDecChange={handleDecChange}
                  handleDecActionButtonPress={handleDecActionButtonPress}/>
              </Grid>
              <Grid item xs={4} >
                <DeceasedActionField value={undefinedToEmptyString(decFields.intentionChase)}
                  name={'intentionChase'}
                  textlabel={'Intention Chased'}
                  buttonLabel={'Chase Intention'}
                  isHidden={(!decFields.bifSent || decFields.intentionRecieved)}
                  handleDecChange={handleDecChange}
                  handleDecActionButtonPress={handleDecActionButtonPress}/>
              </Grid>
            </Grid>
          </div>
        }
        <div className={classes.titleFields} >
          Executor
          <Grid container spacing={2} sx={{ marginTop: -1 }}>
            <Grid item xs={4} >
              <DeceasedActionField value={undefinedToEmptyString(decFields.deathCertificateRecieved)}
                name={'deathCertificateRecieved'}
                textlabel={'Death Certificate Recieved'}
                buttonLabel={'DC Recieved'}
                isHidden={false}
                handleDecChange={handleDecChange}
                handleDecActionButtonPress={handleDecActionButtonPress}/>
            </Grid>
            <Grid item xs={4} >
              <TextField select value={undefinedToEmptyString(decFields.executorDetailsAdded)}
                className={classes.textFieldStyle} size="small"
                onChange={handleDecChange} name='executorDetailsAdded' label="Executor Details Added">
                <MenuItem key={0} value={'Yes'}>
                  Yes
                </MenuItem>
                <MenuItem key={1} value={'N/A'}>
                  N/A
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={4} >
              <TextField select value={undefinedToEmptyString(decFields.addressDetailsRemoved)}
                className={classes.textFieldStyle} size="small"
                onChange={handleDecChange} name='addressDetailsRemoved'
                label="Address Details Removed">
                <MenuItem key={0} value={'Yes'}>
                  Yes
                </MenuItem>
                <MenuItem key={1} value={'N/A'}>
                  N/A
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </div>
        <div className={classes.titleFields} >
          Account Processing
          <Grid container spacing={2} sx={{ marginTop: -1 }}>
            <Grid item xs={4} >
              <TextField select value={undefinedToEmptyString(decFields.refundActioned)}
                className={classes.textFieldStyle} size="small"
                onChange={handleDecChange} name='refundActioned'
                label="Refund Actioned">
                <MenuItem key={0} value={'Yes'}>
                  Yes
                </MenuItem>
                <MenuItem key={1} value={'N/A'}>
                  N/A
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={4} >
              <TextField select value={undefinedToEmptyString(decFields.accountClear)}
                className={classes.textFieldStyle} size="small"
                onChange={handleDecChange} name='accountClear'
                label="Account Clear">
                <MenuItem key={0} value={'Yes'}>
                  Yes
                </MenuItem>
                <MenuItem key={1} value={'N/A'}>
                  N/A
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={4} >
              <TextField select value={undefinedToEmptyString(decFields.closedCofico)}
                className={classes.textFieldStyle} size="small"
                onChange={handleDecChange} name='closedCofico'
                label="Closed in Cofico">
                <MenuItem key={0} value={'Yes'}>
                  Yes
                </MenuItem>
                <MenuItem key={1} value={'N/A'}>
                  N/A
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </div>
  );
}


const DeceasedActionField = ({ value, name, textlabel, buttonLabel, isHidden, handleDecChange,
  handleDecActionButtonPress }) => {
  DeceasedActionField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string.isRequired,
    textlabel: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    isHidden: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
    handleDecChange: PropTypes.func.isRequired,
    handleDecActionButtonPress: PropTypes.func.isRequired,
  };

  const classes = useStyles();
  if (value) {
    return (
      <DesktopDatePicker
        className={classes.textFieldStyle}
        margin="normal"
        id={name}
        name={name}
        label={textlabel}
        inputFormat="dd/MM/yyyy"
        value={value}
        onChange={(event)=>(handleDecChange(event, name))}
        renderInput={(params) => <TextField {...params} />}
        KeyboardButtonProps={{
          'aria-label': textlabel,
        }}
        inputProps={{
          style: {
            padding: 7,
            color: '#000000',
          },
        }}
      />
    );
  }
  if (isHidden) {
    return (null);
  }
  return (
    <Button className={classes.buttonStyle} variant='contained' color='primary'
      onClick={()=>(handleDecActionButtonPress(name))}>
      {buttonLabel}
    </Button>
  );
};
