// Functions
import React from 'react';
import PropTypes from 'prop-types';
// Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function FlexRecordsUpdateMainPopup({ popupOpen, setPopupOpen, mainRecordFields,
  handleMainRecordFieldsChange, saveMainRecord }) {
  const sxClasses = {
    dialogTitle: {
      paddingTop: 1,
      textAlign: 'center',
    },
    textFieldStyle: {
      width: '100%',
    },
    iconButton: {
      position: 'absolute',
      top: 1,
      right: 1,
      padding: 0,
    },
    saveButtonStyle: {
      position: 'absolute',
      width: '8vw',
      left: '10px',
      bottom: '10px',
    },
    cancelButtonStyle: {
      position: 'absolute',
      width: '8vw',
      right: '10px',
      bottom: '10px',
    },
    dialogContentStyle: {
      marginBottom: '40px',
    },
  };

  FlexRecordsUpdateMainPopup.propTypes = {
    popupOpen: PropTypes.bool.isRequired,
    setPopupOpen: PropTypes.func.isRequired,
    mainRecordFields: PropTypes.object.isRequired,
    handleMainRecordFieldsChange: PropTypes.func.isRequired,
    saveMainRecord: PropTypes.func.isRequired,
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  return (
    <Dialog open={popupOpen} onClose={handleClose} aria-labelledby="form-dialog-title" >
      <DialogTitle sx={sxClasses.dialogTitle} id="form-dialog-title">
        Configure Flex Contract
      </DialogTitle>
      <DialogContent sx={sxClasses.dialogContentStyle}>
        {mainRecordFields.CreatedBy &&
        <div>
          Upated By: {mainRecordFields.CreatedBy}
        </div>}
        <TextField
          margin="dense"
          id="TotalServices"
          name="TotalServices"
          label="Total Services"
          type="text"
          fullWidth
          onChange={handleMainRecordFieldsChange}
          value={mainRecordFields.TotalServices}
          sx={sxClasses.textFieldStyle}
        />
        {
          mainRecordFields.TotalServices &&
          <>
            <TextField
              margin="dense"
              id="AServices"
              name="AServices"
              label="A Services"
              type="text"
              fullWidth
              onChange={handleMainRecordFieldsChange}
              value={mainRecordFields.AServices}
              sx={sxClasses.textFieldStyle}
            />
            <TextField
              margin="dense"
              id="BServices"
              name="BServices"
              label="B Services"
              type="text"
              fullWidth
              onChange={handleMainRecordFieldsChange}
              value={mainRecordFields.BServices}
              sx={sxClasses.textFieldStyle}
            />
          </>
        }
        <FormControlLabel
          control={
            <Checkbox
              checked={mainRecordFields.autoChecked}
              onChange={handleMainRecordFieldsChange}
              name="autoChecked"
              color="primary"
            />
          }
          label="Auto Included"
        />
        {mainRecordFields.autoChecked &&
          <TextField
            margin="dense"
            id="AutoServices"
            name="AutoServices"
            label="Auto Services"
            type="text"
            fullWidth
            onChange={handleMainRecordFieldsChange}
            value={mainRecordFields.AutoServices}
            sx={sxClasses.textFieldStyle}
          />
        }
      </DialogContent>
      <DialogActions>
        <Button sx={sxClasses.saveButtonStyle} variant='contained' color='primary'
          onClick={saveMainRecord}>
          Save
        </Button>
        <Button sx={sxClasses.cancelButtonStyle} variant='contained' color='primary'
          onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
