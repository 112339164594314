// Functions
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// Components
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
// Resources
import LetterIcon from '@mui/icons-material/Print';
import EmailIcon from '@mui/icons-material/Email';

const useStyles = makeStyles((theme) => ({
  containerStyle: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  GridStyle: {
    'border': '1px solid',
    'borderColor': '#000000',
    'padding': theme.spacing(1),
    'padding-left': theme.spacing(3),
    'padding-right': theme.spacing(3),
    'backgroundColor': theme.palette.primary.backgroundFore,
  },
  sendToStyle: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  tabStlye: {
    border: '1px solid',
    borderColor: '#000000',
  },
  tabStlyeBackGround: {
    border: '1px solid',
    borderColor: '#000000',
    backgroundColor: theme.palette.primary.mutedMain,
  },
  spacerHorizontal: {
    borderColor: '#BBBBBB',
    borderTop: '1px dashed',
    width: '100%',
    height: '0',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonStyle: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

export default function DeceasedCommunicationConfiguration({
  contacts, handleGenerateLetterChange, handleSendToChange, sendTo, generateLetter,
  letterTemplateOptions, rerunLetterTemplate, letterTemplateSelected, handleLetterTemplateChange }) {
  const classes = useStyles();

  DeceasedCommunicationConfiguration.propTypes = {
    contacts: PropTypes.array,
    handleGenerateLetterChange: PropTypes.func.isRequired,
    handleSendToChange: PropTypes.func.isRequired,
    sendTo: PropTypes.string.isRequired,
    generateLetter: PropTypes.bool.isRequired,
    letterTemplateOptions: PropTypes.array.isRequired,
    rerunLetterTemplate: PropTypes.func.isRequired,
    letterTemplateSelected: PropTypes.string.isRequired,
    handleLetterTemplateChange: PropTypes.func.isRequired,
  };

  const handleEmptyContacts = () => {
    if (contacts) {
      return contacts;
    }
    console.log('empty contacts');
    return [];
  };

  return (
    <div className={classes.containerStyle} >
      <Grid container spacing={0} component={Paper} className={classes.GridStyle}>
        <Grid item xs={12}>
          <TextField select
            className={classes.sendToStyle}
            label="Send To"
            inputProps={{
              style: {
                padding: 5,
                color: '#000000',
              },
            }}
            onChange={handleSendToChange}
            value={sendTo}
            id='txtSendTo'>
            <MenuItem key={0} value={'To Whom it may Concern'}>
              To Whom it may Concern
            </MenuItem>
            {handleEmptyContacts().map((item, index) => (
              <MenuItem key={index + 1} value={item.contactName}>
                {item.contactName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={generateLetter || 'stopFalseBeingVisuallyNoValue'}
            onChange={handleGenerateLetterChange}
            centered
          >
            <Tab className={generateLetter ? classes.tabStlyeBackGround : classes.tabStlye}
              icon={<LetterIcon />} value={true} label="Send As Letter" />
            <Tab className={!generateLetter ? classes.tabStlyeBackGround : classes.tabStlye}
              icon={<EmailIcon />} value={'stopFalseBeingVisuallyNoValue'} label="Send As Email" />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.spacerHorizontal} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <TextField select
                className={classes.sendToStyle}
                label="Manually Run Template"
                inputProps={{
                  style: {
                    padding: 5,
                    color: '#000000',
                  },
                }}
                onChange={handleLetterTemplateChange}
                value={letterTemplateSelected}
                id='txtRerunTemplate'>
                <MenuItem key={0} value={'Please Select a Template'}>
                  Please Select a Template
                </MenuItem>
                {letterTemplateOptions.map((item, index) => (
                  <MenuItem key={index + 1} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <Button className={classes.buttonStyle} variant='contained' color='primary'
                onClick={rerunLetterTemplate}>
                Rerun Template
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
